import React from "react";

function UscisAcceptanceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M11.5,18.6c.2,0,.3,0,.4-.1H12v-.1a.1.1,0,0,1,.1-.1c.1,0,.1-.1.2-.1a.349.349,0,0,1-.2-.1l-.2-.2h0a2.034,2.034,0,0,1-.5.1A3.3,3.3,0,0,1,10,18c-.2,0-.3-.1-.5-.2H9.4a.215.215,0,0,0-.2.2v.2h0c0,.1.1.1.2.1a6.373,6.373,0,0,0,.7.2A7.6,7.6,0,0,0,11.5,18.6Z"
          fill="#fff"
        />
        <path
          d="M10,16.3a9.735,9.735,0,0,0,1.6.1h.6c-.1,0-.1-.1-.2-.1s-.1-.1-.2-.2a.1.1,0,0,0-.1-.1v-.1c.1-.1.1-.1.2-.1H12l-.1-.1c-.1-.1-.1-.1-.1-.2s-.1-.1-.1-.2v-.5H11a4.465,4.465,0,0,1-2-.5c-.1,0-.2-.1-.3-.2H8.6c-.1,0-.1,0-.2.1a.349.349,0,0,0-.2.1.367.367,0,0,0,.1.3c0,.3.2.4.3.4a2.471,2.471,0,0,0,.9.4,5.884,5.884,0,0,0,1.7.2h.6a4.331,4.331,0,0,1-.5.1,6.17,6.17,0,0,1-1.8-.2c-.2-.1-.4-.1-.6-.2a.1.1,0,0,0-.1.1l-.1.1c0,.1-.1.1,0,.2,0,.1.1.1.2.2a.215.215,0,0,0,.2.2C9.4,16.2,9.7,16.2,10,16.3Z"
          fill="#fff"
        />
        <path
          d="M13,22.9c.2-.1.3-.2.4-.2l-.1-.1c0-.1-.1-.1,0-.2,0-.1,0-.1.1-.2h0c-.2,0-.3,0-.4-.1h0a1.38,1.38,0,0,1-.4.3,2.133,2.133,0,0,1-1.2.3h-.2c-.1.1-.1.2-.2.3,0,.1,0,.1.1.2l.1.1H12A4.179,4.179,0,0,0,13,22.9Z"
          fill="#fff"
        />
        <path
          d="M11.3,17.9a.749.749,0,0,0,.5-.1v-.2c0-.1.1-.1.2-.1h.2c-.1,0-.1-.1-.2-.1l-.2-.2V17a.756.756,0,0,0-.4.1,6.533,6.533,0,0,1-1.4,0c-.2,0-.3-.1-.5-.1h0l-.1.1a.31.31,0,0,0-.2.1c-.1,0-.1.1-.2.1v.1a.1.1,0,0,0,.1.1c.1.1.2.1.2.2a4.875,4.875,0,0,0,.7.3C10.5,17.9,11,18,11.3,17.9Z"
          fill="#fff"
        />
        <path
          d="M11.4,17.1h.4l.1-.1h0l-.1-.1v-.2a.1.1,0,0,1,.1-.1.31.31,0,0,1,.2-.1h.1a.1.1,0,0,1-.1-.1,3.536,3.536,0,0,1-.6.1H9.9a2.92,2.92,0,0,1-.9-.2H8.9a.1.1,0,0,0-.1.1v.1h0a2.186,2.186,0,0,0,.5.3,1.854,1.854,0,0,0,.7.2A8.17,8.17,0,0,0,11.4,17.1Z"
          fill="#fff"
        />
        <path
          d="M12.5,22.3c.2-.1.3-.2.4-.2l-.1-.1c0-.1,0-.2.1-.2l.1-.1h-.1a1.069,1.069,0,0,1-.5.3,3.549,3.549,0,0,1-1.5.1h-.1a.1.1,0,0,0-.1.1l-.1.1h0l.1.1c.1,0,.2.1.3.1.2,0,.4.1.7.1A3.385,3.385,0,0,1,12.5,22.3Z"
          fill="#fff"
        />
        <path
          d="M12.2,21.8a4.2,4.2,0,0,0,.5-.2h0l-.1-.1v-.2a.1.1,0,0,1,.1-.1h-.4l-.1-.1V21c-.1,0-.2.1-.4.2a2.251,2.251,0,0,1-1.2.1h-.3c-.1,0-.1,0-.2.1-.1,0-.2.1-.3.1h0a.1.1,0,0,0,.1.1c.1.1.2.1.3.2.1,0,.3.1.4.1A7.115,7.115,0,0,0,12.2,21.8Z"
          fill="#fff"
        />
        <path
          d="M11.5,20.2c.2,0,.3-.1.4-.1h0a.367.367,0,0,1,.1-.3c.1-.1.1-.1.2-.1a.31.31,0,0,1,.2-.1c-.1,0-.1,0-.2-.1a.1.1,0,0,1-.1-.1l-.1-.1h0c-.1,0-.2.1-.5.2a4.888,4.888,0,0,1-1.3.1.367.367,0,0,1-.3-.1H9.8c-.1,0-.2.1-.3.2,0,.1-.1.1-.1.2h0a.1.1,0,0,1,.1.1,1.483,1.483,0,0,0,.7.1A2.037,2.037,0,0,0,11.5,20.2Z"
          fill="#fff"
        />
        <path
          d="M10.8,21.1h0a3.75,3.75,0,0,0,1.1-.1c.2,0,.3-.1.4-.1v-.1c0-.1.1-.2.2-.3h0a.367.367,0,0,1-.3-.1.1.1,0,0,1-.1-.1.1.1,0,0,0-.1-.1c-.1.1-.2.1-.4.1a4.888,4.888,0,0,1-1.3.1.9.9,0,0,1-.5-.1v.1c0,.1-.1.2,0,.3l.1.1a.349.349,0,0,1,.2.1c.2,0,.3.1.5.1A.31.31,0,0,0,10.8,21.1Z"
          fill="#fff"
        />
        <path
          d="M10.2,19.5a5.578,5.578,0,0,0,1.3,0,1.213,1.213,0,0,0,.5-.1h0v-.2c0-.1.1-.1.2-.1.2-.1.4-.1.4,0a.774.774,0,0,0-.3.1l-.1.1v.1l.1.1h.1c.1,0,.1,0,.2.1a1.1,1.1,0,0,0,.8,0c.1,0,.1,0,.2-.1a.9.9,0,0,1,.1-.5.349.349,0,0,1-.2.1c-.2.1-.5,0-.9-.1-.2-.1-.3-.1-.4-.2l-.1-.1H12c-.1,0-.2.1-.4.1a2.818,2.818,0,0,1-1.3,0h-.1c-.1,0-.2.1-.4.1-.1,0-.1.1-.1.2v.2a.1.1,0,0,0,.1.1.367.367,0,0,0,.3.1C9.9,19.4,10.1,19.5,10.2,19.5Z"
          fill="#fff"
        />
        <path
          d="M12.1,16.7l-.1.1a.1.1,0,0,0,.1.1c.1.1.3.1.4.2.3.1.6.1.8.2h.3V17a.367.367,0,0,1-.3-.1c-.2-.1-.5-.2-.9-.4h-.2A.349.349,0,0,0,12.1,16.7Z"
          fill="#fff"
        />
        <path
          d="M11.9,17.1v.1l.1.1a.758.758,0,0,0,.4.2,3.091,3.091,0,0,0,.8.2h.3v-.5h-.3a3.445,3.445,0,0,1-.9-.1.758.758,0,0,1-.4-.2c.2.1.1.1,0,.2Z"
          fill="#fff"
        />
        <path
          d="M12.1,17.6H12a.1.1,0,0,0-.1.1v.1l.1.1a.758.758,0,0,0,.4.2,3.091,3.091,0,0,0,.8.2h.3v-.6c-.1,0-.2.1-.3.1a1.645,1.645,0,0,1-.9-.2c0,.1-.1.1-.2,0Z"
          fill="#fff"
        />
        <path
          d="M11,14.8h.7v-.1a.758.758,0,0,1-.2-.4v-.2H11a.756.756,0,0,1-.4-.1,8.318,8.318,0,0,1-2-.9c-.1,0-.2-.1-.3-.1H8a.215.215,0,0,0-.2.2c-.1.1,0,.2.1.3l.3.3.9.6A4.979,4.979,0,0,0,11,14.8Z"
          fill="#fff"
        />
        <path
          d="M11.9,15.8h0l-.1.1V16a.349.349,0,0,0,.2.1.758.758,0,0,0,.4.2,5.9,5.9,0,0,0,.8.4.367.367,0,0,0,.3.1c0-.1-.1-.3-.1-.4a.367.367,0,0,1-.3-.1,5.9,5.9,0,0,1-.8-.4c-.1,0-.1-.1-.2-.1l-.1-.1h-.1C12,15.8,12,15.8,11.9,15.8Z"
          fill="#fff"
        />
        <path
          d="M12,18.5l.1.1c.1.1.2.1.4.2a3.091,3.091,0,0,0,.8.2c.1,0,.2,0,.2-.1h0v-.4c-.1,0-.2.1-.3.1a1.645,1.645,0,0,1-.9-.2h-.1c0-.1-.1-.1-.2.1Z"
          fill="#fff"
        />
        <path
          d="M10.6,13.9l.8.2.1-.1h0c-.2-.2-.4-.3-.6-.5-.1,0-.2-.1-.3-.2a6.4,6.4,0,0,1-1.1-.6c-.5-.3-1-.6-1.6-.9a5.582,5.582,0,0,0-.8-.5H6.8a.1.1,0,0,0-.1.1c-.1,0,0,.2.1.3l.3.3c.1.1.3.2.4.3.3.2.6.4,1,.7A8.078,8.078,0,0,0,10.6,13.9Z"
          fill="#fff"
        />
        <path
          d="M12.9,15.6a.367.367,0,0,0,.3.1.215.215,0,0,0-.2-.2c-.2-.2-.4-.5-.6-.7l-.6-.6-.2-.2h-.1c-.1,0-.1.1,0,.2,0,.1.1.2.2.4.1.1.2.3.3.4A2.691,2.691,0,0,0,12.9,15.6Z"
          fill="#fff"
        />
        <path
          d="M9.7,12.7a4.073,4.073,0,0,0,1.1.6c.1,0,.1.1.2.1-.1-.1-.3-.2-.4-.3A11.408,11.408,0,0,1,9.5,12c-.1-.1-.2-.2-.2-.3s-.1-.1-.1-.2v-.2h.1c-.1,0-.2-.1-.3-.2a6.4,6.4,0,0,1-1.1-.6c-.5-.2-1-.5-1.6-.8a.6.6,0,0,1-.4-.1.349.349,0,0,1-.2-.1h0v.1A1.38,1.38,0,0,0,6,10l.4.4a6.779,6.779,0,0,0,1.8,1.3C8.7,12.1,9.3,12.4,9.7,12.7Z"
          fill="#fff"
        />
        <path
          d="M15.4,30a.31.31,0,0,1,.2-.1c-.1,0-.1-.1-.2-.1l-.3-.3a.1.1,0,0,0-.1.1c0,.1-.1.2-.2.3s-.1.2-.2.4c0,.1-.1.1-.1.2h0a.52.52,0,0,0,.4-.2C15.2,30.1,15.3,30,15.4,30Z"
          fill="#fff"
        />
        <path
          d="M16.1,29.9h0a.367.367,0,0,1-.3-.1l-.2.2-.3.3c-.1,0-.1.1-.2.1l.1.1c.1.1.3,0,.5-.1a.781.781,0,0,0,.4-.4C16,30,16,30,16.1,29.9Z"
          fill="#fff"
        />
        <path
          d="M16.2,29.9h0c-.1,0-.1.1-.1.2a.349.349,0,0,1-.1.2l-.1.1a.756.756,0,0,0,.4-.1c.1,0,.2-.1.3-.1l.1-.1h-.2C16.4,30.1,16.3,30,16.2,29.9Z"
          fill="#fff"
        />
        <path
          d="M15,29.6h0c-.2.1-.4.1-.5.2h-.2l-.1.1a.349.349,0,0,0-.1.2l-.1.1h.6c0-.1.1-.1.1-.2a1.439,1.439,0,0,0,.2-.3A.1.1,0,0,1,15,29.6Z"
          fill="#fff"
        />
        <path
          d="M21.1,27v2.9h.7a5.809,5.809,0,0,0-.1-1v-5h-.5v.9A14.79,14.79,0,0,0,21.1,27Z"
          fill="#fff"
        />
        <path
          d="M14.4,29.6c.2,0,.3-.1.5-.1.1,0,.2-.1.3-.1l-.1-.1c0-.1,0-.1-.1-.2a.367.367,0,0,1-.3-.1c-.1,0-.1-.1-.1-.2v-.1h-.1l-.1-.1v-.2c0,.1-.1.2-.2.3a1.38,1.38,0,0,0-.3.4.349.349,0,0,1-.1.2v.2h.3A.367.367,0,0,0,14.4,29.6Z"
          fill="#fff"
        />
        <path
          d="M16.1,33.1h0c.2-.1.3-.1.4-.2a2.651,2.651,0,0,0,.5-.6c.3-.4.5-.8.7-1.1a5.9,5.9,0,0,0,.4-.8.31.31,0,0,0,.1-.2h-.6a.774.774,0,0,1-.1.3,3.191,3.191,0,0,1-.6.7,10.475,10.475,0,0,1-1.1.8l-.3.3c-.1.1-.2.2-.2.3v.2c0,.1,0,.1.1.1.1.1.2.2.3.2h.4Z"
          fill="#fff"
        />
        <path d="M18,27v3h.7V24h-.5v.9A4.556,4.556,0,0,0,18,27Z" fill="#fff" />
        <path
          d="M14,23.9c.2-.2.3-.3.3-.4v-.1c0-.1,0-.1.1-.2v-.1h-.5a.1.1,0,0,1-.1.1l-.3.3a6.658,6.658,0,0,1-1.2.6c-.1,0-.3.1-.4.1v.5a.349.349,0,0,0,.2.1h.3a2.544,2.544,0,0,0,.7-.2A3.114,3.114,0,0,0,14,23.9Z"
          fill="#fff"
        />
        <path
          d="M13.7,24.9a3.515,3.515,0,0,0,.9-.7,1.439,1.439,0,0,0,.2-.3h-.2a.1.1,0,0,1-.1-.1l-.1-.1c0,.1-.1.2-.2.4v.1h0l-.1.1c-.1.1-.2.1-.3.2s-.2.1-.3.2c-.1,0-.2.1-.3.1a.349.349,0,0,0-.2.1v.4a.1.1,0,0,0,.1.1c.1,0,.2,0,.2-.1.1,0,.2-.1.3-.1C13.5,25,13.6,24.9,13.7,24.9Z"
          fill="#fff"
        />
        <path
          d="M15.1,24.4c.1-.1.2-.2.2-.3V24h0c-.1,0-.1-.1-.2-.1,0-.1-.1-.1-.1-.2v-.1l-.1.1a.1.1,0,0,0-.1.1c0,.1-.1.2-.2.4a2.361,2.361,0,0,1-.9.8c-.1.1-.2.1-.3.2-.1,0-.1.1-.2.1v.3c0,.1,0,.1.1.2,0,.1.1.1.2.1s.2,0,.2-.1a1.756,1.756,0,0,0,.5-.4C14.6,25,14.9,24.6,15.1,24.4Z"
          fill="#fff"
        />
        <path
          d="M13.5,23.5a1.439,1.439,0,0,1,.3-.2.1.1,0,0,0,.1-.1.1.1,0,0,1-.1-.1v-.2c0-.1.1-.1.1-.2a.6.6,0,0,1-.4-.1h0c0,.1-.2.2-.4.3a2.144,2.144,0,0,1-1.2.4h-.1a.1.1,0,0,0-.1.1l-.1.1a.349.349,0,0,0-.1.2c0,.1-.1.1,0,.2h.1a.1.1,0,0,1,.1.1,1.952,1.952,0,0,0,.7-.1A2.1,2.1,0,0,0,13.5,23.5Z"
          fill="#fff"
        />
        <path
          d="M15.6,24.7c.1-.3.2-.4.2-.5l-.1-.1a.1.1,0,0,0-.1-.1v-.1l-.1.1h-.3v.1c0,.1-.1.2-.2.3a3.24,3.24,0,0,1-.8.9l-.3.3a.1.1,0,0,1-.1.1c0,.1-.1.1-.1.2s0,.1.1.2h.3c.2,0,.4-.2.6-.4A2.715,2.715,0,0,0,15.6,24.7Z"
          fill="#fff"
        />
        <path
          d="M16.1,25.1c0-.1,0-.1.1-.2v-.7a.1.1,0,0,1-.1.1.139.139,0,0,1-.2,0h0a1.087,1.087,0,0,1-.1.5,4.888,4.888,0,0,1-.7,1.1c-.1.1-.3.2-.4.3v.1a.215.215,0,0,0,.2.2.756.756,0,0,0,.4-.1l.4-.4A3.919,3.919,0,0,1,16.1,25.1Z"
          fill="#fff"
        />
        <path
          d="M14.6,28.7h0c0-.2.1-.2.1-.3a.31.31,0,0,0,.1-.2v.4c0,.1,0,.2.1.2s.2.1.3.1h.4a.349.349,0,0,1-.2.1h-.2c.1.1.2.3.4.5.2.1.3.2.5.2h.1V26.8c-.1,0-.2.1-.3.2s-.3.1-.4.2c-.2.1-.3.2-.5.3l-.2.2v.2a.1.1,0,0,1-.1.1.756.756,0,0,0-.1.4Q14.45,28.7,14.6,28.7Z"
          fill="#fff"
        />
        <path d="M16.1,26.9Z" fill="#fff" />
        <path
          d="M16,26.8a.1.1,0,0,0,.1-.1V25.2a2.044,2.044,0,0,1-.5.9c-.1.1-.1.2-.2.2v.4c0,.1.1.1.2.1Z"
          fill="#fff"
        />
        <path
          d="M12.4,21h.3c.1,0,.1,0,.2-.1.1,0,.1,0,.2-.1l.1-.1.2-.2h0v-.4a.52.52,0,0,1-.4.2h-.3l-.1.1a.367.367,0,0,0-.1.3A.774.774,0,0,0,12.4,21Z"
          fill="#fff"
        />
        <path
          d="M9,26.4c.1.1.1.2.2.3v.1a1.38,1.38,0,0,0,.3.4.1.1,0,0,0,.1.1l.1.1h0c-.1-.3-.2-.7-.3-1v-.8h0v-.5a1.421,1.421,0,0,1,.2-.6c0-.1.1-.2.1-.4v-.4a2.2,2.2,0,0,0-.1-.8,1.072,1.072,0,0,0-.2-.6,1.087,1.087,0,0,1-.1-.5V22c0,.1-.1.2-.1.3-.1.1-.1.2-.2.3a1.268,1.268,0,0,0-.4.9,2.437,2.437,0,0,0,0,1.2A4.711,4.711,0,0,0,9,26.4Z"
          fill="#fff"
        />
        <path
          d="M10.7,31.2H11c.1-.1.2-.2.3-.2a.1.1,0,0,0,.1-.1h0a.215.215,0,0,1-.2-.2.31.31,0,0,1-.1-.2h-.2c-.1,0-.2,0-.2.1-.1,0-.1.1-.1.2a.367.367,0,0,0,.1.3c-.1-.1,0,0,0,.1Z"
          fill="#fff"
        />
        <path
          d="M10,28a2.186,2.186,0,0,0,.3.5.1.1,0,0,0,.1.1h0a1.756,1.756,0,0,1,.4.5h0c-.1-.1-.1-.3-.2-.5a3.933,3.933,0,0,0-.2-.6.756.756,0,0,0-.1-.4v-.8c0-.1,0-.2.1-.2,0-.1,0-.2.1-.2v-.9a1.445,1.445,0,0,0-.2-.7c-.1-.3-.1-.5-.2-.6h0v.1c0,.1,0,.1-.1.2,0,.1-.1.2-.2.3-.1.2-.3.4-.4.6a1.387,1.387,0,0,0,0,.9A10.719,10.719,0,0,0,10,28Z"
          fill="#fff"
        />
        <path
          d="M19.3,16.9h-.5a.367.367,0,0,0-.3.1h-.1a.1.1,0,0,0,.1.1.349.349,0,0,0,.2.1H19l.3-.3Z"
          fill="#fff"
        />
        <path
          d="M10.8,30.3v-.1c0-.1-.1-.2-.3-.4a6.342,6.342,0,0,0-1.2-1c-.1-.1-.3-.1-.4-.2-.1,0-.3-.1-.4-.1-.3,0-.5.1-.7.1H7.2a1.38,1.38,0,0,0,.4.3c.1.1.3.2.5.4a4.875,4.875,0,0,0,.7.3h.8a1.072,1.072,0,0,1,.6.2,1.38,1.38,0,0,1,.4.3l.1.1A.1.1,0,0,0,10.8,30.3Z"
          fill="#fff"
        />
        <path
          d="M9.2,7.8a.367.367,0,0,1,.1-.3c0-.2.1-.5.1-.4a1.75,1.75,0,0,1-.1.5c0,.2-.1.3-.1.4s.1.1.1.2c.1.1.1.2.2.3a4.076,4.076,0,0,1,.2-.7c.1-.2.1-.4.2-.6l-.1.1H9.6c-.1-.1-.1-.2-.2-.2v0a.349.349,0,0,1,.1.2h.1l.1-.1c.1-.1,0-.3-.1-.4.1-.1,0-.2-.1-.2a.367.367,0,0,0-.3.1v.7C9.1,7.5,9.2,7.7,9.2,7.8Z"
          fill="#fff"
        />
        <path
          d="M12.4,15.8a5.9,5.9,0,0,0,.8.4.367.367,0,0,0,.3.1c-.1-.2-.1-.4-.2-.6a.756.756,0,0,1-.4-.1,9.013,9.013,0,0,1-.9-.5l-.2-.2V15c0,.1,0,.1.1.2C11.9,15.4,12.2,15.6,12.4,15.8Z"
          fill="#fff"
        />
        <path
          d="M8.7,26.1a6.579,6.579,0,0,1-.4-1.5v-.9l-.2-.4c-.1-.1-.2-.3-.3-.4l-.2-.2c-.1-.1-.2-.1-.2-.2a7.272,7.272,0,0,1-.9-.8.31.31,0,0,1-.1-.2v1a2.769,2.769,0,0,0,.1.9c0,.2.1.3.1.4s.1.1.1.2.1.1.1.2c.1.2.3.4.4.6a.914.914,0,0,1,.3.5A3.6,3.6,0,0,1,8.7,26.1Z"
          fill="#fff"
        />
        <path
          d="M16.2,22.2v2.5h0v5.1h0c0,.1.1.1.2.1s.2.1.3.1h0V24h.7v5.1c0,.6,0,1-.1,1a6.734,6.734,0,0,0-.1-1v-5h-.5v6h1v-6h.7v6.1h.3V24.3h.7v5.1c0,.6,0,1-.1,1a6.734,6.734,0,0,0-.1-1v-5h-.5v5.9h.6c.1,0,.1,0,.2.1h.2c.1,0,.1,0,.2-.1h.6V24.4H20v5.1c0,.5,0,.9-.1.9a6.173,6.173,0,0,0-.1-.9V24.4h.7v6h.3V24.5h.7v5.1c0,.6,0,.9-.1,1h.4v-6h.7v6.1a.367.367,0,0,0,.3-.1.349.349,0,0,0,.2-.1h0V21.9a8.658,8.658,0,0,0-1.1.1h-5c-.6,0-1.1,0-1.1-.1v.3C16.2,21.6,16.2,21.8,16.2,22.2Z"
          fill="#fff"
        />
        <path
          d="M24.1,14.8a4.894,4.894,0,0,1,1.3-1.2,8.568,8.568,0,0,1,2.6-1c.1,0,.2-.1.3-.1a.349.349,0,0,0,.2-.1v-.2h0V10.8c-.1.1-.2.1-.2.2a1.421,1.421,0,0,1-.6.2,6.373,6.373,0,0,1-.7.2c-.4.1-.9.3-1.3.4a7.539,7.539,0,0,0-1.3.6,9.016,9.016,0,0,0-1,.9,4.44,4.44,0,0,0-.8,1.1l-.3.6h0l.1.1c.1.1.1.2.2.2.3.3.5.6.8.9l.1-.1c0-.1.1-.1.1-.2a.758.758,0,0,0,.2-.4A1.329,1.329,0,0,1,24.1,14.8Z"
          fill="#fff"
        />
        <path
          d="M11.2,11.2v1h0v.2c0,.1.1.1.2.1s.2.1.3.1a8.568,8.568,0,0,1,2.6,1,4.894,4.894,0,0,1,1.3,1.2,3.039,3.039,0,0,1,.5.7.758.758,0,0,1,.2.4c0,.1.1.1.1.2l.1.1c.3-.3.5-.6.8-.9.1-.1.1-.2.2-.2l.1-.1h0l-.3-.6a4.44,4.44,0,0,0-.8-1.1,8.12,8.12,0,0,0-1-.9,3.79,3.79,0,0,0-1.3-.6,6.468,6.468,0,0,0-1.3-.4,6.373,6.373,0,0,0-.7-.2c-.2-.1-.4-.1-.6-.2a.215.215,0,0,1-.2-.2C11.2,10.9,11.2,11,11.2,11.2Z"
          fill="#fff"
        />
        <path
          d="M30.3,8.1c0-.1.1-.1.1-.2s-.1-.2-.1-.4a1.75,1.75,0,0,1-.1-.5,4.2,4.2,0,0,1,.2.5.367.367,0,0,0,.1.3c0-.2.1-.3.1-.5V6.9c0-.1-.1-.2-.1-.3a.367.367,0,0,0-.3-.1.349.349,0,0,0-.2.1c-.1.1-.2.3-.1.4a.1.1,0,0,0,.1.1h.1c.1,0,.1-.1.1-.2v0a.349.349,0,0,0-.1.2h-.2a.1.1,0,0,1-.1-.1,3.933,3.933,0,0,0,.2.6v.9A1.38,1.38,0,0,0,30.3,8.1Z"
          fill="#fff"
        />
        <path
          d="M21.5,17.7h0a.349.349,0,0,1,.2.1,1.542,1.542,0,0,1,.7.7l.3.6c.1.2.1.5.2.7v.1h0a.1.1,0,0,0,.1-.1c.1-.1.2-.2.2-.3a5.9,5.9,0,0,1,.4-.8,14.241,14.241,0,0,0,.5-1.6,6.373,6.373,0,0,1,.2-.7v-.3a.1.1,0,0,0-.1-.1c-.1,0-.2,0-.2.1a.349.349,0,0,0-.2.1c-.1.1-.2.1-.3.2v.5a1.689,1.689,0,0,1-.2.5L23,18v.6a.367.367,0,0,0,.1.3v.7a1.75,1.75,0,0,0-.1-.5c0-.1,0-.2-.1-.2,0-.1-.1-.2-.1-.3v-.2h0a.1.1,0,0,0-.1-.1l-.5-.5a1.854,1.854,0,0,0-.7-.2h-.2c0,.1,0,.1.1.2C21.4,17.6,21.5,17.6,21.5,17.7Z"
          fill="#fff"
        />
        <path
          d="M24,15.8c.1,0,.2-.1.3-.1l-.1-.1c-.1-.1-.1-.2-.2-.3-.1.1-.2.2-.2.3a.758.758,0,0,0-.2.4c0,.1-.1.1-.1.2h.1l.3-.3C23.8,15.9,23.9,15.8,24,15.8Z"
          fill="#fff"
        />
        <path
          d="M17,17.9c0,.1.1.2.1.3a.31.31,0,0,1,.1-.2c0-.1.1-.2.2-.3a.349.349,0,0,1,.2-.1h.2a1.445,1.445,0,0,1,.7.2.349.349,0,0,1,.2.1v-.2c0-.1,0-.1-.1-.2a.1.1,0,0,1-.1-.1h-.2c-.2-.1-.4-.3-.4-.4a.1.1,0,0,0-.1-.1h-.3c-.1,0-.3.1-.3.2-.2,0-.2.2-.2.3Z"
          fill="#fff"
        />
        <path
          d="M15.9,15.6c-.1-.1-.2-.2-.2-.3,0,.1-.1.2-.2.3l-.1.1h0c.1,0,.2.1.3.1s.1.1.2.1l.3.3h0c0-.1-.1-.1-.1-.2S16,15.8,15.9,15.6Z"
          fill="#fff"
        />
        <path
          d="M16.1,16.3h0c0-.1-.1-.2-.2-.3a.758.758,0,0,0-.4-.2c-.1,0-.2,0-.2.1v.3a1.854,1.854,0,0,0,.2.7,14.242,14.242,0,0,0,.5,1.6c.1.3.2.5.3.8.1.1.1.2.2.4,0,.1.1.1.1.2l.1.1h0a.349.349,0,0,0,.1-.2c0-.1.1-.2.1-.3.1-.2.1-.4.2-.6a1.578,1.578,0,0,1,.5-.8l.1-.1a.1.1,0,0,0,.1-.1h-.4l-.2.2a.31.31,0,0,0-.1.2,1.238,1.238,0,0,1-.2-.3.31.31,0,0,1-.1.2l-.1.1v.1c0,.1-.1.2-.1.3a.349.349,0,0,1-.1.2,1.75,1.75,0,0,0-.1.5.693.693,0,0,1,0-.5v-.2a.367.367,0,0,1,.1-.3v-.6l-.3-.6c-.1-.2-.2-.3-.2-.5C16.1,16.5,16,16.4,16.1,16.3Z"
          fill="#fff"
        />
        <path
          d="M7.8,26.6c.1,0,.2.1.3.1a8.65,8.65,0,0,1,1,.7c.3.3.6.5.9.8,0-.1-.1-.1-.1-.2l-.3-.6H9.5l-.1-.1a1.538,1.538,0,0,1-.4-.6c-.1-.1-.1-.2-.2-.3l-.1-.1A8.344,8.344,0,0,0,7,25.2a6.9,6.9,0,0,0-1-.4,1.412,1.412,0,0,0-.9.1,1.087,1.087,0,0,1-.5.1H4.5a1.38,1.38,0,0,0,.4.3,2.181,2.181,0,0,0,.5.4,1.538,1.538,0,0,0,.6.4h.8a1.7,1.7,0,0,1,.7.1.349.349,0,0,1,.2.1c0,.2.1.2.1.3Z"
          fill="#fff"
        />
        <path
          d="M12.1,31.6h0l.1-.1v-.3h-.1c-.1,0-.1.1-.2.1h-.1v.2H12A.1.1,0,0,0,12.1,31.6Z"
          fill="#fff"
        />
        <path
          d="M13.5,22.6c.1,0,.2.1.4,0,.1,0,.1,0,.2-.1l.1-.1a.367.367,0,0,0,.1-.3v-.2c-.1-.1-.2-.1-.2-.2h0c0,.1,0,.1-.1.2s-.2.2-.3.2-.2.1-.3.1a.349.349,0,0,0-.1.2C13.4,22.4,13.4,22.5,13.5,22.6Z"
          fill="#fff"
        />
        <path
          d="M13.3,20.1c.1-.1.1-.1.1-.2v-.4a.1.1,0,0,0-.1.1,1.1,1.1,0,0,1-.8,0c-.1,0-.2.1-.3.2l-.1.1c0,.1-.1.1-.1.2l.1.1c.1,0,.2,0,.2.1h.5C13.1,20.2,13.2,20.2,13.3,20.1Z"
          fill="#fff"
        />
        <path
          d="M12.8,21.5H13a.367.367,0,0,0,.3-.1,2653781653554.261,2653781653554.261,0,0,0,.2-.2l.1-.1a.1.1,0,0,0-.1-.1c-.1-.1-.1-.2-.2-.3h0l-.1.1-.1.1c0,.1-.1.1-.1.2h-.1a.1.1,0,0,1-.1.1.1.1,0,0,1-.1.1c.1.1,0,.1.1.2Z"
          fill="#fff"
        />
        <path
          d="M14,23.1h.4a.887.887,0,0,0,.4-.5c0-.1.1-.2.1-.3-.1-.1-.3-.2-.4-.3v.2a.756.756,0,0,1-.1.4c0,.1-.1.1-.2.2-.1,0-.1.1-.2.1h0a.349.349,0,0,0-.1.2v.1A.1.1,0,0,1,14,23.1Z"
          fill="#fff"
        />
        <path
          d="M15.8,24l.1.1a.31.31,0,0,0,.2-.1h.1V23a.1.1,0,0,1-.1-.1H16v.5a1.421,1.421,0,0,0-.2.6Z"
          fill="#fff"
        />
        <path
          d="M14.6,23.6h.3a1.38,1.38,0,0,0,.3-.4.6.6,0,0,0,.1-.4,1.007,1.007,0,0,1,.1-.3c-.1-.1-.3-.2-.4-.3v.3a.367.367,0,0,1-.1.3.215.215,0,0,1-.2.2c0,.1-.1.1-.1.2v.2c-.1.1-.1.2,0,.2Z"
          fill="#fff"
        />
        <path
          d="M15.3,23.9h.1a.1.1,0,0,0,.1-.1c.1-.1.1-.2.2-.3,0-.1.1-.2.1-.3a.367.367,0,0,1,.1-.3c-.1-.1-.2-.1-.3-.2-.1,0-.1-.1-.2-.1v.3a.756.756,0,0,1-.1.4.349.349,0,0,1-.1.2v.2C15.2,23.8,15.3,23.8,15.3,23.9Z"
          fill="#fff"
        />
        <path
          d="M12.9,21.9h0c.2.1.3.2.5.2a.6.6,0,0,0,.4-.1c.1,0,.2-.1.3-.2a.31.31,0,0,0,.1-.2l-.1-.1a1.38,1.38,0,0,1-.3-.4.31.31,0,0,1-.1.2l-.1.1-.1.1c-.1,0-.2.1-.3.1a.349.349,0,0,0-.1.2C12.9,21.8,12.9,21.9,12.9,21.9Z"
          fill="#fff"
        />
        <path
          d="M11.3,29.9a.31.31,0,0,0-.1-.2c-.1-.1-.2-.3-.4-.5-.4-.4-1-1-1.7-1.6a6.071,6.071,0,0,0-1-.7.9.9,0,0,0-.5-.1.9.9,0,0,0-.5.1c-.1,0-.3.1-.4.1H6.4c-.1,0-.1,0-.2-.1a2.736,2.736,0,0,0,.5.4,2.651,2.651,0,0,1,.6.5c.1,0,.1.1.2.1a.1.1,0,0,1,.1.1.1.1,0,0,1,.1.1c0-.1.2-.1.3,0h.4c.1,0,.2,0,.2.1.1,0,.1.1.2.1.1.1.3.1.4.2s.2.1.3.2a2.252,2.252,0,0,1,.5.5l.5.5c.1.2.2.3.2.4v.1h.2c.3,0,.3-.2.4-.3h0v0Z"
          fill="#fff"
        />
        <path
          d="M11.3,31.3h0a.31.31,0,0,1-.1-.2V31a.769.769,0,0,1-.3.2c-.1,0-.2.1-.3.1h-.1a.367.367,0,0,0,.1.3c0,.1.1.1.2.1h.3c.1,0,.2-.1.3-.1l.1-.1s.1-.1,0-.1h0c.1-.1,0-.1-.2-.1Z"
          fill="#fff"
        />
        <path
          d="M11.3,30.6c.1.1.1.1.1.2h.3a.1.1,0,0,0-.1-.1.31.31,0,0,1-.2-.1c-.1,0-.2-.1-.3-.1C11.2,30.5,11.3,30.5,11.3,30.6Z"
          fill="#fff"
        />
        <path
          d="M17.8,32.7a6.607,6.607,0,0,0,1-1.8,4.5,4.5,0,0,0,.2-.8h-.5c0,.1,0,.1-.1.3a5.9,5.9,0,0,1-.4.8c-.2.3-.4.8-.7,1.2a3.394,3.394,0,0,1-.6.6.52.52,0,0,1-.4.2H16a.31.31,0,0,1-.1.2v.2l.1.1h.2a1.969,1.969,0,0,0,.8-.4C17.2,33.3,17.5,33,17.8,32.7Z"
          fill="#fff"
        />
        <path
          d="M11.2,31.8h0a.1.1,0,0,0,.1.1h.5a.367.367,0,0,0,.3-.1.1.1,0,0,0,.1-.1v-.3a.349.349,0,0,1,.1.2v.1h.1a1.38,1.38,0,0,1,.3.4,4.875,4.875,0,0,1,.3.7c0,.1.1.3.1.4s0,.2.1.2v.2c.3.2.6.3.9.5v-.3c0-.1-.1-.2-.1-.3-.1-.2-.1-.4-.2-.6a9.292,9.292,0,0,0-.5-1,3.775,3.775,0,0,0-.5-.8.1.1,0,0,1-.1.1c-.1.1-.1.1-.2.1h-.3v.2h-.3a.349.349,0,0,1-.2-.1h0c-.1,0-.1.1-.2.1C11.4,31.8,11.3,31.8,11.2,31.8Z"
          fill="#fff"
        />
        <path
          d="M9.3,11.6v.1c.1.1.1.2.2.3a10.949,10.949,0,0,0,1.1,1,14.961,14.961,0,0,0,1.3,1.1l.6.6a4.349,4.349,0,0,1,.6.7,3.876,3.876,0,0,1,.6,1.7,4.735,4.735,0,0,1-.1,1.7,7.719,7.719,0,0,0-.2,1.5v.3c0,.1.1.2.1.3.1.2.3.3.4.5.3.3.6.5.8.7.3.2.5.4.7.5a1.38,1.38,0,0,1,.4.3.1.1,0,0,1,.1.1V21.6h-.1a.367.367,0,0,1-.3-.1c-.1-.1-.3-.1-.5-.3a1.024,1.024,0,0,1-.5-.6,2.769,2.769,0,0,1-.1-.9,2.112,2.112,0,0,1,.4-1c.1-.2.2-.3.2-.5v-.5a5.359,5.359,0,0,0-.5-1.1,9.064,9.064,0,0,0-1.3-1.9c-.2-.3-.5-.5-.7-.8a4.349,4.349,0,0,0-.7-.6c-.1,0-.1-.1-.2-.1h0c0,.1.1.2.1.3.1.2.2.3.3.5a1.727,1.727,0,0,1,.3.4l-.3-.3c-.1-.1-.2-.3-.3-.4a.367.367,0,0,1-.1-.3v-.1h0l-.1-.1a1.38,1.38,0,0,0-.4-.3c-.2-.1-.4-.3-.7-.5l-.9-.6a.367.367,0,0,0-.3-.1c.3-.1.2-.2.1-.1Z"
          fill="#fff"
        />
        <path
          d="M12.4,29.1c.1-.2.2-.3.3-.5.1-.1.1-.2.2-.3l.1-.1.1-.1a.637.637,0,0,0,0-.6.764.764,0,0,0-.2-.5c0-.1-.1-.2-.1-.3a.367.367,0,0,1-.1.3c-.1.2-.2.3-.3.5-.1.1-.1.2-.2.3s-.1.2-.2.3a8.4,8.4,0,0,0-.4,1.2v.4h0c.1-.1.2-.1.4,0,.1,0,.2.1.3.2h0A2.2,2.2,0,0,1,12.4,29.1Z"
          fill="#fff"
        />
        <path
          d="M11.3,29.1c0-.2.1-.4.1-.6,0-.1.1-.2.1-.4,0-.1,0-.1.1-.2v-1c0-.2-.1-.4-.1-.6a4.331,4.331,0,0,0-.1-.5v.1a1.69,1.69,0,0,1-.2.5c-.1.2-.3.3-.4.6-.1.1-.1.2-.1.4v.4a2.982,2.982,0,0,0,.4,1.5c.1.2.2.3.2.4l.1.1v-.1h0v-.1A.9.9,0,0,1,11.3,29.1Z"
          fill="#fff"
        />
        <path
          d="M30.3,11.4h.1v.2c0,.1,0,.1-.1.2s-.1.2-.2.3A11.408,11.408,0,0,1,29,13.2a1.38,1.38,0,0,1-.4.3c.1,0,.1-.1.2-.1a9.054,9.054,0,0,1,1.1-.6c.4-.3,1-.6,1.5-.9a20.279,20.279,0,0,0,1.8-1.3l.4-.4c.1-.1.3-.3.3-.4V9.7h0c-.1,0-.2,0-.2.1-.2.1-.3.1-.5.2l-1.6.8a6.4,6.4,0,0,1-1.1.6A.139.139,0,0,0,30.3,11.4Z"
          fill="#fff"
        />
        <path
          d="M23.4,26.9v2.9h.1a1.689,1.689,0,0,0,.5-.2c.2-.2.4-.4.4-.5h-.2A.349.349,0,0,1,24,29h.4a.367.367,0,0,0,.3-.1c.1,0,.1-.1.1-.2v-.2c0-.1-.1-.2,0-.2a.349.349,0,0,1,.1.2c0,.1.1.2.1.3v.1h0c.1,0,.1-.1.1-.2s-.1-.3-.1-.4a.1.1,0,0,0-.1-.1V28c0-.1-.1-.1-.2-.2a2.186,2.186,0,0,0-.5-.3c-.2-.1-.3-.2-.4-.2C23.6,27,23.5,26.9,23.4,26.9Z"
          fill="#fff"
        />
        <path
          d="M25,28.8h0a.349.349,0,0,1-.1.2.367.367,0,0,1-.3.1.31.31,0,0,1-.1.2.1.1,0,0,1-.1.1.367.367,0,0,1,.3.1,4.331,4.331,0,0,0,.5.1h.6v-.2a.349.349,0,0,0-.1-.2,1.38,1.38,0,0,0-.3-.4c-.1-.1-.2-.2-.2-.3v.2c-.1,0-.1.1-.2.1Z"
          fill="#fff"
        />
        <path
          d="M23.6,23c-.1,0-.1.1-.2.1v1h.1c.1,0,.1.1.2.1a.1.1,0,0,0,.1-.1.1.1,0,0,1,.1-.1h0c-.1-.1-.1-.3-.2-.4v-.3a.774.774,0,0,1-.1-.3Z"
          fill="#fff"
        />
        <path d="M23.5,26.8Z" fill="#fff" />
        <path
          d="M23.9,24.8a1.75,1.75,0,0,1-.1-.5h-.2l-.1-.1h0v.7c0,.1,0,.1.1.2a9.012,9.012,0,0,0,.5.9c.1.2.3.3.4.4s.3.2.4.1a.215.215,0,0,0,.2-.2v-.1c-.1-.1-.3-.2-.4-.3A5.939,5.939,0,0,1,23.9,24.8Z"
          fill="#fff"
        />
        <path
          d="M24.1,26.3l-.2-.2a4.053,4.053,0,0,1-.5-.9v1.4a.1.1,0,0,0,.1.1.1.1,0,0,0,.1.1h.3c.1,0,.2,0,.2-.1v-.2C24.2,26.4,24.1,26.4,24.1,26.3Z"
          fill="#fff"
        />
        <path
          d="M25.6,25.8a.1.1,0,0,0-.1-.1l-.3-.3a5.739,5.739,0,0,1-.8-.9c-.1-.1-.1-.3-.2-.3v-.1h-.3c.1-.1.1-.1,0-.1v.1a.1.1,0,0,1-.1.1.1.1,0,0,1-.1.1,4.2,4.2,0,0,1,.2.5,5.939,5.939,0,0,0,.8,1.1,2.092,2.092,0,0,0,.6.4h.3c.1,0,.1-.1.1-.2A.438.438,0,0,0,25.6,25.8Z"
          fill="#fff"
        />
        <path
          d="M25,30.4c0-.1-.1-.1-.1-.2-.1-.1-.1-.3-.2-.4s-.1-.2-.2-.3a.1.1,0,0,1-.1-.1l-.3.3c-.1.1-.1.1-.2.1.1,0,.1.1.2.1.1.1.2.1.4.2a.55.55,0,0,0,.5.3Z"
          fill="#fff"
        />
        <path
          d="M23.5,30.1v-.2h-.1c-.1.1-.2.1-.2.2H23l.1.1c.1.1.1.1.3.1.1,0,.3.1.4.1l-.1-.1A.215.215,0,0,1,23.5,30.1Z"
          fill="#fff"
        />
        <path
          d="M24.7,23.6c.1,0,.1.1.2.1h.2l.1-.1v-.2c0-.1-.1-.1-.1-.2-.1-.1-.2-.1-.2-.2a.367.367,0,0,1-.1-.3v-.3a1.38,1.38,0,0,0-.4.3.367.367,0,0,0,.1.3c0,.1.1.2.1.4C24.5,23.4,24.6,23.5,24.7,23.6Z"
          fill="#fff"
        />
        <path
          d="M24.1,30.1l-.2-.2c-.1,0-.2.1-.3.1h0a.31.31,0,0,0,.1.2c.1.1.2.3.4.4a1.087,1.087,0,0,0,.5.1.1.1,0,0,0,.1-.1c-.1,0-.1-.1-.2-.1C24.3,30.2,24.2,30.1,24.1,30.1Z"
          fill="#fff"
        />
        <path
          d="M25.3,23.1h.4V23a.349.349,0,0,0-.1-.2h0a.349.349,0,0,1-.2-.1l-.2-.2a.6.6,0,0,1-.1-.4v-.2c-.1.1-.3.2-.4.3a.774.774,0,0,0,.1.3,1.333,1.333,0,0,0,.5.6Z"
          fill="#fff"
        />
        <path
          d="M24.1,23.9h.1c.1,0,.1-.1.2-.2v-.2a.31.31,0,0,1-.1-.2.6.6,0,0,1-.1-.4v-.3c-.1,0-.1.1-.2.1-.1.1-.2.1-.3.2a.774.774,0,0,0,.1.3.367.367,0,0,0,.1.3c0,.1.1.2.2.3C24,23.8,24.1,23.9,24.1,23.9Z"
          fill="#fff"
        />
        <path
          d="M26.1,22.6a.215.215,0,0,0,.2-.2c0-.1,0-.1-.1-.2a.367.367,0,0,1-.3-.1c-.1-.1-.2-.1-.3-.2a.31.31,0,0,1-.1-.2h0c-.1.1-.2.1-.2.2v.2c0,.1.1.2.1.3a.1.1,0,0,0,.1.1c.1,0,.1.1.2.1Z"
          fill="#fff"
        />
        <path
          d="M25.8,25a3.631,3.631,0,0,1-.9-.8c-.1-.2-.2-.3-.2-.4a.1.1,0,0,1-.1-.1l-.1-.1v.1a.31.31,0,0,1-.1.2c0,.1-.1.1-.2.1h0v.1c0,.1.1.2.2.3.2.2.5.6.8.9a1.756,1.756,0,0,0,.5.4.31.31,0,0,0,.2.1c.1,0,.1,0,.2-.1,0-.1.1-.1.1-.2v-.3c-.1,0-.1-.1-.2-.1A.31.31,0,0,1,25.8,25Z"
          fill="#fff"
        />
        <path
          d="M26.6,22h0a.31.31,0,0,0,.1-.2c0-.1-.1-.1-.1-.2a.367.367,0,0,1-.3-.1.1.1,0,0,1-.1-.1l-.1-.1c-.1-.1-.1-.1-.1-.2a1.38,1.38,0,0,0-.3.4l-.1.1a.31.31,0,0,0,.1.2.769.769,0,0,0,.3.2c.1,0,.2.1.4.1C26.4,22,26.5,22,26.6,22Z"
          fill="#fff"
        />
        <path
          d="M25.4,29.8h-.2c-.2,0-.3-.1-.5-.2h-.1a.349.349,0,0,1,.1.2c.1.1.2.2.2.3s.1.1.1.2h.6a.1.1,0,0,0-.1-.1c-.1-.1-.1-.1-.1-.2A.139.139,0,0,0,25.4,29.8Z"
          fill="#fff"
        />
        <path
          d="M29.6,18.7h-.1a5.578,5.578,0,0,1-1.3,0c-.2,0-.3-.1-.4-.1h-.1a.1.1,0,0,1-.1.1.758.758,0,0,1-.4.2,1.412,1.412,0,0,1-.9.1.349.349,0,0,1-.2-.1.9.9,0,0,0,.1.5.349.349,0,0,0,.2.1c.2.1.5,0,.8,0,.1,0,.2,0,.2-.1h.1a.1.1,0,0,0,.1-.1v-.1l-.1-.1c-.3-.1-.5-.1-.4-.1,0,0,.1-.1.4,0,.1,0,.1.1.2.1v.2h0a2.034,2.034,0,0,0,.5.1,2.818,2.818,0,0,0,1.3,0,.756.756,0,0,0,.4-.1c.1,0,.2-.1.3-.1l.1-.1v-.2c0-.1-.1-.1-.1-.2a1.056,1.056,0,0,1-.6,0Z"
          fill="#fff"
        />
        <path
          d="M30,17h0a4.331,4.331,0,0,1-.5.1,3.222,3.222,0,0,1-1.4,0,.6.6,0,0,1-.4-.1v.2c0,.1-.1.1-.2.2-.1,0-.1.1-.2.1h.2a.31.31,0,0,1,.2.1v.2a2.034,2.034,0,0,0,.5.1,6.638,6.638,0,0,0,1.3-.1c.2-.1.5-.2.7-.3.1-.1.2-.1.2-.2l.1-.1v-.1a.349.349,0,0,0-.2-.1c-.1,0-.1,0-.2-.1C30.1,17.1,30,17.1,30,17Z"
          fill="#fff"
        />
        <path
          d="M29.6,18a6.638,6.638,0,0,1-1.3.1,4.331,4.331,0,0,1-.5-.1h0c0,.1-.1.1-.2.2s-.1.1-.2.1a.31.31,0,0,1,.2.1l.1.1v.1h.1a.756.756,0,0,1,.4.1h1.3a1.445,1.445,0,0,0,.7-.2.349.349,0,0,0,.2-.1h0v-.2c0-.1-.2-.2-.2-.2h-.1A.454.454,0,0,0,29.6,18Z"
          fill="#fff"
        />
        <path
          d="M26.9,24.9h0c-.1-.1-.2-.1-.3-.2-.1,0-.2-.1-.3-.1-.1-.1-.2-.1-.3-.2s-.2-.1-.3-.2l-.1-.1h0V24c-.1-.2-.2-.3-.2-.4a.1.1,0,0,1-.1.1l-.1.1H25c0,.1.1.2.2.3a3.515,3.515,0,0,0,.9.7c.1.1.2.1.3.2a.367.367,0,0,0,.3.1.349.349,0,0,1,.2.1c.1,0,.1,0,.1-.1v-.2C26.9,25,26.9,24.9,26.9,24.9Z"
          fill="#fff"
        />
        <path
          d="M30.5,16.2a2.92,2.92,0,0,1-.9.2,4.267,4.267,0,0,1-1.6,0,3.536,3.536,0,0,1-.6-.1.1.1,0,0,0-.1.1h.1c.1,0,.1,0,.2.1l.1.1v.2a.1.1,0,0,1-.1.1h0a.1.1,0,0,1,.1.1h.4a7.6,7.6,0,0,0,1.4-.1,1.445,1.445,0,0,0,.7-.2,2.186,2.186,0,0,0,.5-.3h0v-.1l-.1-.1Z"
          fill="#fff"
        />
        <path
          d="M32.7,24.8c-.1.1-.2.1-.2.2h0c0-.2.1-.3.1-.5l.2.2v-1h.4c.1-.1.2-.1.3-.2s.2-.1.3-.2c.1,0,.1-.1.2-.1h0v.1c-.1.2-.1.4-.2.5a.349.349,0,0,1-.1.2v.1h0c-.1-.1-.1-.1-.2-.1l-.1.1-.1.1-.1.1c-.2,0-.2,0-.2.1a.31.31,0,0,0-.1.2h0v.1C32.8,24.7,32.8,24.7,32.7,24.8Z"
          fill="#fff"
        />
        <path
          d="M32.2,26.1c.1-.3.1-.5.2-.8,0-.1.1-.3.1-.4h0a.774.774,0,0,0,.3-.1c.1,0,.2-.1.3-.1s.1-.1.2-.1h0v.2c0,.1-.1.2-.1.4,0,.1-.1.3-.1.4s0,.1-.1.2v.1h-.1a.349.349,0,0,1-.2-.1.1.1,0,0,1-.1.1h0a.1.1,0,0,1-.1.1c-.2,0-.3,0-.3.1Z"
          fill="#fff"
        />
        <path
          d="M32,26.4c0-.1.1-.2.1-.3h0l.1-.1.1-.1.1-.1h.1a.1.1,0,0,1,.1.1c.1,0,.1.1.2.1-.1,0-.1.1-.2.1a.494.494,0,0,1-.3.1C32.2,26.3,32.1,26.4,32,26.4Z"
          fill="#fff"
        />
        <path
          d="M32.2,26.1c-.2.1-.5.3-.7.4a.1.1,0,0,0-.1.1.1.1,0,0,1,.1-.1,1.38,1.38,0,0,0,.3-.4c.1-.1.1-.2.2-.2l.1-.1h0a.367.367,0,0,0,.1.3Z"
          fill="#fff"
        />
        <path
          d="M27.1,10.6c-.1,0-.2,0-.2-.1v-.1h0c0-.1.1-.1.2-.2a.31.31,0,0,1,.2-.1,1.689,1.689,0,0,1,.5-.2A8.385,8.385,0,0,0,29.7,9c.1-.1.3-.2.4-.3a1.38,1.38,0,0,0,.3-.4.6.6,0,0,0,.1-.4V7.8a.6.6,0,0,0,.1.4,3.091,3.091,0,0,0,.2.8.349.349,0,0,0,.1.2v.1h0c0,.1-.1.1-.1.2l-.2.2-.1.1-.1.1-.2.2a1.38,1.38,0,0,1-.4.3,1.38,1.38,0,0,1-.4.3c-.1,0-.1.1-.2.1s-.1.1-.2.1a5.946,5.946,0,0,1-.7.3h0v-.5h0c0-.1.1-.2,0-.2a.1.1,0,0,0-.1-.1h-.4c-.1,0-.2.1-.3.1S27.2,10.6,27.1,10.6Z"
          fill="#fff"
        />
        <path
          d="M28.1,31v.1h-.2a.1.1,0,0,0-.1.1h0V31h.1c.1-.1.1-.1.2,0Z"
          fill="#fff"
        />
        <path
          d="M25.6,30.4h.2a.1.1,0,0,0,.1-.1V30l-.1-.1-.2-.2h.3a.1.1,0,0,0,.1-.1v-.1a2.958,2.958,0,0,0,.4.3c.1,0,.1.1.2.1s.2.1.3.1a.367.367,0,0,0,.3-.1c.1-.1.2-.1.3-.2a1.689,1.689,0,0,1,.5-.2,1.38,1.38,0,0,1,.3.4c.1.1.1.3.2.3h0a.6.6,0,0,0-.4.1c-.1,0-.1.1-.2.1a.349.349,0,0,0-.1.2h0a1.438,1.438,0,0,1-.3-.2l-.2-.2-.1-.1v.1c0,.1.1.1.1.2h-.1c-.1,0-.1,0-.2.1l-.1.1v.1h.6c.1,0,.3.1.4.1h-.2c0,.1,0,.1.1.2v.1h0c0,.1,0,.1.1.1a.1.1,0,0,0,.1.1h0a.1.1,0,0,1-.1-.1.349.349,0,0,0-.2-.1h-.2a.349.349,0,0,1-.2-.1v.5h-.2c-.1,0-.2-.1-.2-.3v-.3c-.1-.1-.2-.1-.2-.2C26.1,30.5,25.8,30.5,25.6,30.4Z"
          fill="#fff"
        />
        <path
          d="M18.8,17.5a.774.774,0,0,0,.1.3c0,.1.1.2.2.3a.52.52,0,0,0,.4.2h.8c.2.1.2.2.3.2v-.1c0-.1-.1-.1-.2-.2-.1,0-.2-.1-.4,0h-.4a.758.758,0,0,1-.4-.2c-.1-.1-.2-.2-.2-.3Zm-.6-.8h0v.2a.1.1,0,0,0,.1.1h.2a.1.1,0,0,0,.1.1.349.349,0,0,0,.2.1h.3a.349.349,0,0,0,.2-.1.1.1,0,0,0,.1-.1.1.1,0,0,1,.1-.1h.1c.2,0,.2.1.2.1v-.1a.349.349,0,0,0-.2-.1H19a.367.367,0,0,0-.3.1h-.2c-.3-.1-.3-.1-.3-.2Zm.1.5a1.38,1.38,0,0,1-.4-.3l-.1-.1h0v-.1h0v-.1l.1-.1h0a.349.349,0,0,1,.2-.1,1.949,1.949,0,0,1,.8-.1H20c.1,0,.1,0,.2.1a2.092,2.092,0,0,1,.6.4.908.908,0,0,1,.3.7,2.2,2.2,0,0,1,.1.8v1.2c0,.1.1.2.1.3a2.186,2.186,0,0,0,.3.5,4.349,4.349,0,0,0,.6.7c.1.1.2.2.3.2H17.1l.1-.1.2-.2a7.338,7.338,0,0,0,1-1.3.758.758,0,0,0,.2-.4c0-.1.1-.1.1-.2a.1.1,0,0,1,.1-.1v-.4c0-.1-.1-.1-.2-.2s-.2-.1-.4-.1h-.3c-.1,0-.1-.1-.1-.2a.31.31,0,0,1-.1-.2h0a.367.367,0,0,1,.3.1h.3a.139.139,0,0,0,0-.2.367.367,0,0,0-.1-.3.349.349,0,0,0-.2-.1c.4,0,.4,0,.3-.1Z"
          fill="#fff"
        />
        <path
          d="M11.7,30.5c-.1,0-.1-.1-.2-.1-.1-.1-.2-.1-.4-.1h0c.1-.1.1-.2.2-.3a.349.349,0,0,1,.1-.2l.1-.1c.1-.1.3,0,.5.2.1.1.2.1.3.2a.5.5,0,0,0,.6,0,.349.349,0,0,0,.2-.1c.2-.1.4-.2.4-.3h0v.1l.1.1h.3c-.1,0-.1.1-.2.2l-.1.1v.3l.1.1h.1a9.2,9.2,0,0,0-.9.3.215.215,0,0,0-.2.2v.3c0,.1-.1.2-.2.3h-.2v-.5a.349.349,0,0,0-.2.1h-.2c-.1,0-.1.1-.2.1a.1.1,0,0,0-.1.1h0l.1-.1a.1.1,0,0,1,.1-.1h0v-.1a.31.31,0,0,1,.1-.2h-.2c.1,0,.2-.1.4-.1h.5v-.1l-.1-.1a.349.349,0,0,0-.2-.1h-.1c.1-.1.1-.1.1-.2v-.1l-.1.1-.2.2c-.2.1-.3.1-.3.2h0C11.9,30.6,11.8,30.5,11.7,30.5Z"
          fill="#fff"
        />
        <path d="M11.5,30.9h.3v.2h0l-.1-.1h-.2c-.1.1,0,0,0-.1Z" fill="#fff" />
        <path
          d="M9,8.1c0-.2,0-.3.1-.4v.1c0,.1.1.3.1.4.1.1.2.3.3.4a1.38,1.38,0,0,0,.4.3,8.385,8.385,0,0,0,1.9.9c.2.1.4.1.5.2a.349.349,0,0,1,.2.1l.2.2h0v.1c0,.1-.1.1-.2.1a1.421,1.421,0,0,1-.6-.2c-.1,0-.2-.1-.3-.1h-.4a.1.1,0,0,0-.1.1v.2h0V11h0a5.946,5.946,0,0,1-.7-.3c-.1,0-.1-.1-.2-.1s-.1-.1-.2-.1a1.38,1.38,0,0,0-.4-.3,1.38,1.38,0,0,0-.4-.3L9,9.7l-.1-.1.1.2c-.1-.1-.1-.2-.2-.2a.349.349,0,0,0-.1-.2h0V9.3a.349.349,0,0,1,.1-.2A8.376,8.376,0,0,0,9,8.1Z"
          fill="#fff"
        />
        <path
          d="M7.5,30.4l-.2-.2c.1,0,.2.1.3.1a1.268,1.268,0,0,0,.6-.1c.1,0,.2-.1.3-.1s.2-.1.3-.1a3.73,3.73,0,0,1,1.2.2,1,1,0,0,0,.4.1c-.1.1-.2.2-.2.3s0,.2.1.2a1.213,1.213,0,0,0-.5-.1H9.2c-.2,0-.5.1-.7.1a1.072,1.072,0,0,1-.6-.2C7.8,30.7,7.6,30.6,7.5,30.4Z"
          fill="#fff"
        />
        <path
          d="M31,13.1a4.977,4.977,0,0,1-2,.9c-.1,0-.3.1-.4.1h-.5v.2a.758.758,0,0,1-.2.4v.1h.7a5.367,5.367,0,0,0,1.9-.5,7.018,7.018,0,0,0,.9-.6l.3-.3c.1-.1.2-.2.1-.3,0-.1-.1-.1-.2-.2h-.3C31.2,13,31.1,13.1,31,13.1Z"
          fill="#fff"
        />
        <path
          d="M31.5,11.9a8.938,8.938,0,0,1-1.6.9c-.5.3-.8.4-1.1.6-.1.1-.3.1-.3.2-.2.2-.4.3-.6.5h0a.1.1,0,0,1,.1.1,1.718,1.718,0,0,0,.8-.2,15.366,15.366,0,0,0,1.9-.9,8.65,8.65,0,0,0,1-.7c.1-.1.3-.2.4-.3l.3-.3a.367.367,0,0,0,.1-.3.1.1,0,0,1-.1-.1h-.3A3.807,3.807,0,0,1,31.5,11.9Z"
          fill="#fff"
        />
        <path
          d="M27.8,23.4h0a6.832,6.832,0,0,1-1.3-.4c-.2-.1-.3-.3-.4-.3h0a.6.6,0,0,1-.4.1.349.349,0,0,1,.1.2v.2l-.1.1.1.1c.1.1.2.1.3.2a6.658,6.658,0,0,0,1.2.6,1.7,1.7,0,0,0,.7.1.1.1,0,0,0,.1-.1h.1V24c0-.1-.1-.1-.1-.2a.1.1,0,0,1-.1-.1C27.9,23.5,27.8,23.5,27.8,23.4Z"
          fill="#fff"
        />
        <path
          d="M29.8,19.6c-.1,0-.2.1-.3.1a2.65,2.65,0,0,1-1.3-.1c-.3-.1-.5-.1-.5-.2h0l-.1.1a.1.1,0,0,0-.1.1c-.1,0-.1,0-.2.1.1,0,.1,0,.2.1.1,0,.1.1.2.1,0,.1.1.1.1.3h0c.1,0,.2.1.4.1a2.818,2.818,0,0,0,1.3,0c.2,0,.5-.1.7-.1a.1.1,0,0,0,.1-.1h0c0-.1,0-.1-.1-.2-.2-.2-.3-.3-.4-.3Z"
          fill="#fff"
        />
        <path
          d="M27.7,24.3c-.1,0-.3-.1-.4-.1a6.658,6.658,0,0,1-1.2-.6c-.1-.1-.3-.2-.3-.3l-.1-.1h-.5v.1c0,.1,0,.1.1.2v.1a1.727,1.727,0,0,0,.3.4,2.268,2.268,0,0,0,1.1.6,1.854,1.854,0,0,0,.7.2h.3c.1,0,.2,0,.2-.1v-.2c-.1,0-.1-.1-.2-.2Z"
          fill="#fff"
        />
        <path
          d="M28.3,22.7a2.133,2.133,0,0,1-1.2-.3,1.38,1.38,0,0,1-.4-.3h0a.6.6,0,0,1-.4.1h0a.349.349,0,0,1,.1.2v.2l-.1.1c.1,0,.2.1.4.2a8.338,8.338,0,0,0,1.2.4h.8a.1.1,0,0,0,.1-.1c.1-.1.1-.1.1-.2s-.1-.2-.2-.3Z"
          fill="#fff"
        />
        <path
          d="M29,22h0a4.049,4.049,0,0,1-1.6-.1c-.3-.1-.5-.2-.5-.3h-.1l.1.1a.349.349,0,0,1,.1.2c0,.1,0,.1-.1.1.1,0,.2.1.4.2a3.73,3.73,0,0,0,1.2.2,1.7,1.7,0,0,0,.7-.1c.1,0,.2-.1.3-.1a.1.1,0,0,0,.1-.1h0a.1.1,0,0,0-.1-.1.693.693,0,0,1-.5,0Z"
          fill="#fff"
        />
        <path
          d="M29.8,20.4a4.331,4.331,0,0,1-.5.1,4.888,4.888,0,0,1-1.3-.1c-.2,0-.3-.1-.4-.1a.1.1,0,0,1-.1.1l-.1.1c-.1,0-.2.1-.3.1h0c.1.1.2.2.2.3V21c.1,0,.2.1.4.1a3.75,3.75,0,0,0,1.1.1h.1a4.331,4.331,0,0,0,.5-.1.349.349,0,0,0,.2-.1.1.1,0,0,0,.1-.1c.2-.3.2-.4.1-.5Z"
          fill="#fff"
        />
        <path
          d="M29.2,21.2h-.3a4.121,4.121,0,0,1-1.2-.1c-.2-.1-.3-.1-.4-.2V21l-.1.1h-.4a.1.1,0,0,0,.1.1.139.139,0,0,1,0,.2.1.1,0,0,1-.1.1h0a1.689,1.689,0,0,1,.5.2,3.549,3.549,0,0,0,1.5.1c.1,0,.3-.1.4-.1s.2-.1.3-.2l.1-.1h0a.367.367,0,0,0-.3-.1C29.4,21.2,29.3,21.2,29.2,21.2Z"
          fill="#fff"
        />
        <path
          d="M28,31.7c-.1,0-.1-.1-.2-.1h0a.349.349,0,0,1-.2.1h-.3v-.2H27a.1.1,0,0,1-.1-.1h0v.1h0v.1h-.5l-.3.3-.4.4a5.716,5.716,0,0,1-.8.7H25c.1,0,.2,0,.2.1.2,0,.4.1.6.1v.6h0c.1-.1.1-.1.1-.2l.3-.3c.2-.2.3-.4.5-.5.3-.3.4-.5.4-.4a2.22,2.22,0,0,1-.4.5c-.1.2-.3.3-.4.5l-.2.2a.1.1,0,0,0,.1.1c0,.1.1.1.1.2a.1.1,0,0,0,.1.1v.1h0l.1-.1a.758.758,0,0,1,.4-.2c0,.1.1.3.1.4v.2h0l.1-.1a1.38,1.38,0,0,0,.3-.4c0,.1.1.1.1.2l.2.2h0c.1-.4.1-.8.2-1.2a4.331,4.331,0,0,1,.1-.5v-.3h0a.1.1,0,0,0-.1-.1v-.1h-.4a.367.367,0,0,1-.3-.1.1.1,0,0,1-.1-.1v-.1c0-.2.1-.2.1-.2v.3l.1.1a.367.367,0,0,1,.3.1h.5a.349.349,0,0,0,.2-.1h0C28.3,31.8,28.2,31.8,28,31.7Z"
          fill="#fff"
        />
        <path
          d="M30.6,14.5a5.34,5.34,0,0,1-2,.5h-.7v.5c0,.1-.1.1-.1.2s-.1.1-.1.2l-.1.1h.1a.31.31,0,0,1,.2.1v.1a.1.1,0,0,1-.1.1l-.2.2c-.1,0-.1.1-.2.1H28a9.735,9.735,0,0,0,1.6-.1,1.884,1.884,0,0,0,.8-.2c.1,0,.2-.1.3-.2s.2-.1.2-.2v-.2a.1.1,0,0,0-.1-.1l-.1-.1a1.421,1.421,0,0,1-.6.2,6.17,6.17,0,0,1-1.8.2.749.749,0,0,1-.5-.1h.6a12.765,12.765,0,0,0,1.7-.2,3.552,3.552,0,0,0,.9-.4c.1-.1.2-.2.3-.2.1-.1.2-.2.1-.3,0-.1-.1-.1-.2-.1s-.2,0-.2-.1h-.1A.187.187,0,0,0,30.6,14.5Z"
          fill="#fff"
        />
        <path
          d="M28.9,31.3a.367.367,0,0,1-.3-.1c-.1-.1-.2-.2-.3-.2v.2H28v.1l.1.1a.367.367,0,0,0,.3.1h.3c.1,0,.1-.1.2-.1a.494.494,0,0,0,.1-.3h0c0,.2-.1.2-.1.2Z"
          fill="#fff"
        />
        <path
          d="M28.3,30.6a.1.1,0,0,1,.1-.1.367.367,0,0,0-.3.1c-.1,0-.1,0-.2.1l-.1.1h.3Z"
          fill="#fff"
        />
        <path d="M27.4,31.2h0v.3h.4v-.2h-.1c-.1,0-.1,0-.2-.1Z" fill="#fff" />
        <path
          d="M25.9,21.2l.1.1a.1.1,0,0,1,.1.1.1.1,0,0,1,.1.1.367.367,0,0,1,.3.1h.3v-.1a.1.1,0,0,0-.1-.1l-.1-.1h-.1c-.1,0-.1-.1-.2-.1a.1.1,0,0,1-.1-.1l-.1-.1h0c0-.1,0,0-.2.2Z"
          fill="#fff"
        />
        <path
          d="M31.8,27.2c.1,0,.1-.1.2-.1a.1.1,0,0,0,.1-.1h.1a.1.1,0,0,0,.1-.1c0,.1.1.2.1.3v.1h0v-.1c.1-.1.1-.2.2-.4.1-.1.1-.3.2-.4,0-.1.1-.1.1-.2h-.1c-.1,0-.1,0-.2.1-.1,0-.3.1-.4.1a.774.774,0,0,1-.3.1,3.552,3.552,0,0,1-.4.9A.769.769,0,0,0,31.8,27.2Z"
          fill="#fff"
        />
        <path
          d="M28.6,30.4h0l-.2.2c-.1.1-.1.2-.2.2h0l.1.1c.1.1.2.1.3.2.1,0,.1.1.2,0h0l.1-.1a.367.367,0,0,0,.1-.3c0-.1,0-.2-.1-.2s-.1-.1-.2-.1Z"
          fill="#fff"
        />
        <path
          d="M32.4,24.2h0l-.1.1c0,.1-.1.1-.1.2-.1.1-.2.2-.2.3a.1.1,0,0,1-.1.1c.1-.1.2-.2.3-.2a2.181,2.181,0,0,1,.5-.4c-.2.1-.2,0-.3-.1Z"
          fill="#fff"
        />
        <path
          d="M22,34.7c-.1-.1-.1-.2-.2-.2a1.612,1.612,0,0,1-.4-.7c-.2-.5-.4-1-.6-1.4l-.3-1.2a2.485,2.485,0,0,1-.1-.8v-.2h-.2c0,.2.1.5.1,1,0,.3.1.7.1,1.2a8.875,8.875,0,0,0,.3,1.4,3.922,3.922,0,0,0,.5,1.3c.1.2.2.4.4.4.2.1.3.1.4,0,.1,0,.1-.1.1-.2v-.2a.367.367,0,0,0-.1-.3C22.1,34.8,22.1,34.8,22,34.7Z"
          fill="#fff"
        />
        <path
          d="M20.1,31.2v-1h-.2l-.1.1-.1-.1h-.2v1a5.739,5.739,0,0,1-.1,1.2,3.553,3.553,0,0,1-.2,1,2.252,2.252,0,0,0,.1.7c0,.2.1.5.1.8,0,.2.1.3.2.5.1.1.2.3.3.3s.2-.1.3-.3a.749.749,0,0,0,.1-.5,2.485,2.485,0,0,0,.1-.8v-.4a5.019,5.019,0,0,1-.2-1.2A4.512,4.512,0,0,0,20.1,31.2Z"
          fill="#fff"
        />
        <path
          d="M22.7,26.7V24.1h-.5v6h.7v-1A16.592,16.592,0,0,1,22.7,26.7Z"
          fill="#fff"
        />
        <path
          d="M19.3,31.2c0-.5.1-.8.1-1h-.2v.2c0,.2-.1.5-.1.8a10.377,10.377,0,0,0-.3,1.2,5.344,5.344,0,0,1-.6,1.4c-.1.2-.3.5-.4.7-.1.1-.1.2-.2.2v.2a.367.367,0,0,1-.1.3v.2c0,.1.1.1.1.2a.3.3,0,0,0,.4,0c.2-.1.3-.3.4-.4a5.023,5.023,0,0,0,.5-1.3c.1-.5.2-1,.3-1.4A7.174,7.174,0,0,0,19.3,31.2Z"
          fill="#fff"
        />
        <path
          d="M22.5,33.6a3.044,3.044,0,0,1-.8-.8,7.017,7.017,0,0,1-1-1.9c-.1-.4-.2-.7-.2-.8h-.2a4.284,4.284,0,0,0,.3,1,10.377,10.377,0,0,1,.3,1.2,5.344,5.344,0,0,0,.6,1.4,3.112,3.112,0,0,0,.4.6,1.205,1.205,0,0,0,.5.5c.1,0,.2.1.3.1s.2,0,.2-.1a.749.749,0,0,0,.1-.5c-.1-.2-.1-.3-.2-.4s-.2-.2-.2-.3Z"
          fill="#fff"
        />
        <path
          d="M21.2,30.2a.349.349,0,0,1,.1.2,5.9,5.9,0,0,0,.4.8c.2.3.4.7.7,1.1.2.2.3.4.5.6.1.1.2.1.3.2h.4c.1,0,.2-.1.3-.2a.1.1,0,0,1,.1-.1v-.2c0-.1-.1-.2-.2-.3l-.3-.3c-.4-.3-.8-.6-1.1-.8a1.874,1.874,0,0,1-.6-.7.774.774,0,0,1-.1-.3Z"
          fill="#fff"
        />
        <path
          d="M23.6,33.3h-.2a.758.758,0,0,1-.4-.2l-.6-.6a7.459,7.459,0,0,1-.7-1.2,5.9,5.9,0,0,1-.4-.8.367.367,0,0,1-.1-.3h-.6c0,.1.1.4.2.8a6.607,6.607,0,0,0,1,1.8,6.034,6.034,0,0,0,.8.8,5.9,5.9,0,0,0,.8.4h.2l.1-.1v-.2c0-.3,0-.4-.1-.4Z"
          fill="#fff"
        />
        <path
          d="M33.1,22h0l.2.2-.2.2-.1.1c-.1.1-.1.2-.2.2a.1.1,0,0,1-.1.1v.8a2.186,2.186,0,0,1,.5-.3c.1,0,.1-.1.2-.1a.31.31,0,0,1,.1-.2c0-.1.1-.2.1-.3s0-.1.1-.2a.158.158,0,1,1,.3-.1h0c0-.2.1-.3.1-.5s.1-.4.1-.6a.367.367,0,0,1,.1-.3h-.1l-.2.2c-.1.1-.3.2-.4.3a1.38,1.38,0,0,1-.4.3.31.31,0,0,1-.1.2Z"
          fill="#fff"
        />
        <path
          d="M27.7,16c.1,0,.1-.1,0,0,.1-.1.1-.1,0-.1l-.1-.1h-.2l-.1.1c-.1.1-.1.1-.2.1a5.9,5.9,0,0,1-.8.4.774.774,0,0,1-.3.1c0,.1-.1.3-.1.4.1,0,.1-.1.3-.1a5.9,5.9,0,0,0,.8-.4c.2-.1.3-.2.4-.2A1.238,1.238,0,0,0,27.7,16Z"
          fill="#fff"
        />
        <path
          d="M30.5,23.9h0c.1,0,.1.1.2.1a.31.31,0,0,0-.1.2.1.1,0,0,1-.1.1.1.1,0,0,1-.1.1v.4l.2-.2c.1-.1.2-.2.3-.2s.1-.1.2-.1l.3-.3c0,.2-.1.4-.1.7s-.1.5-.1.7v.1l.4-.4c0-.1.1-.1.1-.3.1-.1.1-.2.2-.4,0-.1.1-.1.1-.2.1-.1.1-.2.2-.3l.2.2h0V22.8h0l-.5.5-.3.3-.1.1h.1a.367.367,0,0,1,.3.1c-.1.2-.2.2-.2.3-.1.1-.1.1-.1.2a.31.31,0,0,0-.1.2.31.31,0,0,1,.1-.2c0-.1.1-.1.1-.2a.349.349,0,0,1,.1-.2h-.1a.756.756,0,0,1-.4-.1l.6-.6.7-.7v.2a.1.1,0,0,1,.1-.1c0-.1.1-.1.2-.2l.1-.1a.1.1,0,0,1,.1-.1H33c-.1,0-.1-.1-.2-.1a1.38,1.38,0,0,1,.4-.3v-.1c0-.1-.1-.1-.1-.2a.1.1,0,0,0-.1-.1h0l-.1.1c-.1.1-.2.1-.3.2-.2.2-.4.3-.6.5-.1.1-.2.1-.2.2l-.1.1h0a.1.1,0,0,1,.1.1c.1.1.2.1.3.2l-.1.1-.1.1-.1.1-.2.2c-.1.1-.1.1-.2.1v.7h-.1v-.8h0a.349.349,0,0,0-.1.2l-.4.4-.2.2c-.1-.3-.1-.3-.1-.2Z"
          fill="#fff"
        />
        <path
          d="M27.3,16.5h-.1a3.552,3.552,0,0,1-.9.4c-.1,0-.2.1-.3.1v.3h.3a3.091,3.091,0,0,0,.8-.2.52.52,0,0,0,.4-.2.1.1,0,0,0,0-.2A.215.215,0,0,0,27.3,16.5Z"
          fill="#fff"
        />
        <path
          d="M27.7,17.2v-.1a.1.1,0,0,0-.1-.1h-.1c-.1.1-.2.1-.4.2a2.769,2.769,0,0,1-.9.1h-.3v.5h.3a3.091,3.091,0,0,0,.8-.2c.2-.1.3-.1.4-.2A.769.769,0,0,0,27.7,17.2Z"
          fill="#fff"
        />
        <path
          d="M26.2,20.6l.2.2a.1.1,0,0,1,.1.1c.1,0,.1,0,.2.1.1,0,.1,0,.2.1h.3V21c0-.1-.1-.2-.1-.3l-.1-.1h-.3a.52.52,0,0,1-.4-.2v.2c-.1-.1-.1-.1-.1,0Z"
          fill="#fff"
        />
        <path
          d="M28.1,14.3v-.2H28l-.2.2-.6.6a4.349,4.349,0,0,0-.6.7c-.1.1-.1.2-.2.2.1,0,.2-.1.3-.1a9.223,9.223,0,0,0,.8-.5c.1-.1.3-.3.3-.4A2.186,2.186,0,0,1,28.1,14.3Z"
          fill="#fff"
        />
        <path
          d="M27.4,20.2a.1.1,0,0,0,.1-.1.31.31,0,0,0-.1-.2.1.1,0,0,0-.1-.1c-.1-.1-.2-.1-.3-.2a1.1,1.1,0,0,1-.8,0,.1.1,0,0,1-.1-.1v.4a.349.349,0,0,1,.1.2c.1.1.2.1.4.2h.5C27.3,20.3,27.3,20.3,27.4,20.2Z"
          fill="#fff"
        />
        <path
          d="M27.8,15.3a.31.31,0,0,0,.1-.2V15c-.1.1-.1.2-.2.2a1.382,1.382,0,0,1-.9.5.756.756,0,0,1-.4.1,1.421,1.421,0,0,0-.2.6c.1,0,.1-.1.3-.1a5.938,5.938,0,0,1,.8-.4A1.579,1.579,0,0,1,27.8,15.3Z"
          fill="#fff"
        />
        <path
          d="M30.4,24.4h0c0-.1,0-.1.1-.2,0-.1.1-.2,0-.2h-.2v.2C30.4,24.3,30.4,24.3,30.4,24.4Z"
          fill="#fff"
        />
        <path
          d="M18,33.7c.2-.5.4-.9.6-1.4l.3-1.2a2.947,2.947,0,0,1,.3-1H19c0,.1-.1.4-.2.8a5.847,5.847,0,0,1-1,1.9,3.044,3.044,0,0,1-.8.8l-.1.1c-.1.1-.1.2-.2.3a.52.52,0,0,0-.2.4c-.1.2-.1.3.1.5.1.1.1.1.2.1a.367.367,0,0,0,.3-.1,1.205,1.205,0,0,0,.5-.5A1.612,1.612,0,0,0,18,33.7Z"
          fill="#fff"
        />
        <path
          d="M27.7,17.9v-.1a.1.1,0,0,0-.1-.1h-.2c-.1,0-.2.1-.3.1a3.711,3.711,0,0,1-.9.2.367.367,0,0,1-.3-.1v.6h.3a3.091,3.091,0,0,0,.8-.2c.2-.1.3-.1.4-.2C27.6,17.9,27.7,17.9,27.7,17.9Z"
          fill="#fff"
        />
        <path
          d="M26.3,18.5a.367.367,0,0,1-.3-.1v.4h0a.349.349,0,0,0,.2.1,1.345,1.345,0,0,0,.8-.2c.1-.1.3-.1.4-.2a.1.1,0,0,0,.1-.1v-.1a.31.31,0,0,0-.2-.1h-.1A1.209,1.209,0,0,1,26.3,18.5Z"
          fill="#fff"
        />
        <path
          d="M28.3,29.9a1.007,1.007,0,0,0,.1.3h.2l.1-.1.5-.5a5.716,5.716,0,0,0,.7-.8c.3-.3.6-.7,1-1.1a.349.349,0,0,0,.1.2h0a.1.1,0,0,1,.1-.1,2.01,2.01,0,0,1,.4-.8c.1-.1.1-.3.2-.4,0-.1,0-.1.1-.2h-.1a.349.349,0,0,0-.2.1,1.38,1.38,0,0,0-.4.3.758.758,0,0,1-.4.2c-.1,0-.1.1-.2.1h-.1l.1.1.1.1.1.1-.1-.1a.1.1,0,0,1-.1-.1l-.1-.1-.1-.1c.2-.1.4-.3.7-.5.1,0,.1-.1.2-.1,0-.1.1-.1.1-.2.1-.1.2-.3.3-.4s.1-.2.2-.3.2-.3.3-.4c0,.1.1.2.1.3h0v-.1h0a.367.367,0,0,1,.1-.3c0-.1.1-.2.1-.4v-.1h0l-.1.1c-.1.1-.2.2-.3.2-.1.1-.2.1-.3.2l-.1.1h.2a1.577,1.577,0,0,0-.4.1c0,.2-.1.3-.1.5a.1.1,0,0,1-.1-.1h-.1a2653781653554.237,2653781653554.237,0,0,0-.2-.2h.3V25c0-.2.1-.5.1-.7V24h-.1c-.1,0-.1.1-.2.1-.1.1-.2.2-.3.2a1.238,1.238,0,0,0-.3.2c0,.2.1.4.1.6-.1-.1-.2-.1-.2-.2l-.1-.1-.1-.1h0v.1c0,.1-.1.1-.1.2a.1.1,0,0,1-.1.1v-.4c0-.1,0-.1.1-.2.1.1.2.1.3.2h.1v-.4c0-.2-.1-.4-.1-.6a.6.6,0,0,0-.1-.4v-.2h0v.1a.31.31,0,0,0-.1.2c-.1.2-.2.3-.2.4a1.238,1.238,0,0,1-.2.3v.2c.1.4.1.8.2,1.1h-.1a6.417,6.417,0,0,1-.5,1.6,4.908,4.908,0,0,1-.7,1.6s.2-.8.5-1.7c.3-.8.5-1.4.6-1.6-.1,0-.1-.1-.2-.1h-.1a4.331,4.331,0,0,1-.1.5c0,.1,0,.2-.1.2v-.4a.756.756,0,0,1,.1-.4c.1,0,.1.1.2.1h.1c0-.1,0-.2-.1-.2,0-.2-.1-.3-.1-.5-.1-.3-.1-.6-.2-1h0c0,.1-.1.1-.1.2-.1.1-.1.3-.2.4-.1.3-.3.6-.4.9,0,.1-.1.1-.1.2h.1c.1,0,.3-.1.4-.1-.1.6-.2,1.2-.3,1.7s-.2.9-.3,1.3a6.374,6.374,0,0,1-.2.9c0,.1,0,.2-.1.2l.1.1C28.3,29.8,28.3,29.8,28.3,29.9Zm1.3-3.2a5.071,5.071,0,0,1,.7-1.5s-.2.7-.5,1.5a5.071,5.071,0,0,1-.7,1.5C29,28.2,29.3,27.6,29.6,26.7ZM29,29.1l.7-1.4c.3-.5.5-1,.7-1.3a5.2,5.2,0,0,0,.3-.5,3.224,3.224,0,0,0-.2.6l-.7,1.4a3.6,3.6,0,0,1-.8,1.2,3.224,3.224,0,0,1-.2.6A3.224,3.224,0,0,0,29,29.1Z"
          fill="#fff"
        />
        <path
          d="M23.4,23.1a.1.1,0,0,0,.1-.1,1.38,1.38,0,0,1,.4-.3c.2-.1.4-.3.7-.5a5.008,5.008,0,0,0,.8-.7,2.181,2.181,0,0,0,.4-.5.367.367,0,0,0,.1-.3v-.3a7.719,7.719,0,0,0-.2-1.5,9.15,9.15,0,0,1-.1-1.7,3.486,3.486,0,0,1,.6-1.7,4.349,4.349,0,0,1,.6-.7l.6-.6a12.667,12.667,0,0,1,1.3-1.1c.4-.4.8-.7,1.1-1,.1-.1.2-.2.2-.3,0,0,.1-.1,0-.1h0c-.1,0-.2.1-.3.1s-.2.1-.3.1l-.9.6c-.3.2-.5.4-.7.5s-.3.3-.4.3l-.1.1h0v.1c0,.1-.1.2-.1.3a1.38,1.38,0,0,1-.3.4l-.3.3a2.958,2.958,0,0,0,.3-.4,2.186,2.186,0,0,0,.3-.5.367.367,0,0,0,.1-.3h0c-.1,0-.1.1-.2.1a4.349,4.349,0,0,1-.7.6c-.2.2-.4.5-.7.8a12.735,12.735,0,0,0-1.3,1.9c-.2.4-.3.7-.5,1.1a.446.446,0,0,0,0,.5c0,.2.1.3.2.5a1.777,1.777,0,0,1,.4,1,.972.972,0,0,1-.1.9,1.024,1.024,0,0,1-.5.6,2.186,2.186,0,0,0-.5.3.494.494,0,0,1-.3.1H23v.5C23.4,22.4,23.4,22.7,23.4,23.1Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default UscisAcceptanceIcon;
