import React from "react";

function UscisIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3415"
        data-name="Group 3415"
        transform="translate(801 -2627)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="36"
          height="36"
          rx="18"
          transform="translate(-801 2627)"
          fill="#ff7b16"
        />
        <g
          id="Group_3414"
          data-name="Group 3414"
          transform="translate(-202.731 2467.052)"
        >
          <path
            id="Path_3375"
            data-name="Path 3375"
            d="M-569.932,176.766c0-.148-.052-.216-.194-.231-.33-.035-.657-.093-.987-.116a.261.261,0,0,1-.275-.237,8.385,8.385,0,0,0-1.022-2.456.238.238,0,0,1,.019-.314c.2-.254.4-.512.6-.768a.227.227,0,0,0-.017-.352q-1.066-1.058-2.124-2.124a.226.226,0,0,0-.352-.021c-.256.2-.513.4-.768.6a.226.226,0,0,1-.3.026,8.762,8.762,0,0,0-2.513-1.038.223.223,0,0,1-.2-.225c-.031-.336-.091-.669-.117-1.006-.012-.145-.068-.216-.232-.215q-1.531.009-3.062,0c-.162,0-.221.064-.237.211-.035.329-.08.658-.114.987a.248.248,0,0,1-.217.252,8.6,8.6,0,0,0-2.475,1.027.247.247,0,0,1-.319-.02c-.249-.2-.5-.4-.754-.586-.188-.141-.212-.14-.377.025q-1.043,1.039-2.082,2.082c-.177.178-.178.2-.021.408.188.249.379.5.575.739a.236.236,0,0,1,.015.317,4.56,4.56,0,0,0-.234.4,8.584,8.584,0,0,0-.791,2.071.24.24,0,0,1-.252.214,6.389,6.389,0,0,0-.715.09c-.154.029-.333-.03-.46.112v3.355a.238.238,0,0,0,.169.084c.33.025.656.089.986.113a.273.273,0,0,1,.283.252,8.336,8.336,0,0,0,1,2.419.276.276,0,0,1-.024.367c-.2.247-.391.5-.588.752a.216.216,0,0,0,.02.336q1.072,1.064,2.136,2.136a.216.216,0,0,0,.336.02c.25-.2.505-.387.752-.588a.273.273,0,0,1,.367-.024,8.24,8.24,0,0,0,1.271.634,8.418,8.418,0,0,0,1.186.382c.107.026.2.06.206.194.015.246.054.49.088.734.023.167-.021.357.119.5h3.355a.253.253,0,0,0,.087-.188c.026-.324.087-.644.112-.967a.262.262,0,0,1,.235-.277,8.353,8.353,0,0,0,2.439-1.011.264.264,0,0,1,.35.017c.241.2.489.387.738.575.189.142.218.141.379-.019.7-.7,1.406-1.407,2.112-2.108a.224.224,0,0,0,.023-.351c-.2-.254-.4-.514-.6-.768a.242.242,0,0,1-.021-.319c.085-.131.162-.266.235-.4a8.6,8.6,0,0,0,.792-2.07.241.241,0,0,1,.252-.215c.337-.023.67-.084,1.006-.118.144-.014.193-.087.192-.233Q-569.94,178.3-569.932,176.766Zm-10.018,7.969a6.453,6.453,0,0,1-6.427-6.442,6.452,6.452,0,0,1,6.436-6.432,6.451,6.451,0,0,1,6.437,6.431A6.452,6.452,0,0,1-579.95,184.735Z"
            transform="translate(0)"
            fill="#fff"
          />
          <path
            id="Path_3376"
            data-name="Path 3376"
            d="M-494.12,259.254a5.277,5.277,0,0,0-5.266,5.272,5.278,5.278,0,0,0,5.257,5.261,5.278,5.278,0,0,0,5.275-5.263A5.276,5.276,0,0,0-494.12,259.254Zm2.656,4.023q-1.574,1.569-3.14,3.146c-.031.031-.063.06-.1.1-.038-.036-.067-.062-.094-.089q-.99-1-1.98-1.99c-.066-.066-.089-.106-.008-.182q.333-.315.648-.648c.077-.081.118-.058.183.008.376.377.76.747,1.132,1.129.1.1.15.088.242,0,.752-.755,1.512-1.5,2.261-2.26.11-.111.167-.105.268,0,.184.2.38.391.578.579C-491.394,263.14-491.372,263.185-491.464,263.277Z"
            transform="translate(-85.822 -86.223)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default UscisIcon;
