import React from "react";

function PharmaceuticalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M12,16.7a7.056,7.056,0,0,1,1.4-7.9h0a7.062,7.062,0,0,1,9.9,0l10,10a7.062,7.062,0,0,1,0,9.9h0a7.062,7.062,0,0,1-9.9,0l-.4-.4"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
        />
        <path
          d="M15.6,16.5a3.924,3.924,0,0,1,0-5.5"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
        />
        <line
          x1="5.9"
          y2="6"
          transform="translate(22.4 13.7)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
        />
        <line
          x1="6"
          y2="6"
          transform="translate(22.8 16.9)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
          stroke-dasharray="0.196 2.348"
        />
        <line
          x1="7.9"
          y2="7.9"
          transform="translate(23.9 19.9)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
          stroke-dasharray="0.196 2.348"
        />
        <line
          x1="4.3"
          y2="4.3"
          transform="translate(28.7 24.7)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
          stroke-dasharray="0.196 2.348"
        />
        <line
          x1="6"
          y2="6"
          transform="translate(23.4 19.3)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
          stroke-dasharray="0.196 2.348"
        />
        <line
          x1="6.1"
          y2="6"
          transform="translate(26.2 22.3)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
          stroke-dasharray="0.196 2.348"
        />
        <path
          d="M21.4,22.7c0,2.4-3.4,4.4-7.6,4.4s-7.6-2-7.6-4.4a2.878,2.878,0,0,1,.9-2.1c1.3-1.4,3.8-2.3,6.7-2.3C18,18.3,21.4,20.2,21.4,22.7Z"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
        />
        <line
          y1="3.6"
          x2="6.3"
          transform="translate(10.6 20.9)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
        />
        <path
          d="M6.1,22.7v4c0,2.2,3.4,4,7.6,4s7.6-1.8,7.6-4v-4"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.914"
        />
      </g>
    </svg>
  );
}
export default PharmaceuticalIcon;
