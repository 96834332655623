import React from "react";

function LifeSciencesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M19.9,15.4A26.937,26.937,0,0,1,22.8,17c.1,0,.1.1.2.1h0l.3.3c.2.2.4.3.5.5.1.1.2.2.2.3h0c0,.1.1.1.1.2s.1.1.1.2H22.8c-.4,0-.8.3-.7.7a.684.684,0,0,0,.7.7h2v1.3h0v.2H19.2c-.4,0-.8.3-.7.7a.684.684,0,0,0,.7.7h4.9a.1.1,0,0,1-.1.1h0c0,.1-.1.1-.1.2l-.3.3-.3.3h0a.1.1,0,0,0-.1.1c-.1.1-.2.1-.2.2a.738.738,0,1,0,.7,1.3,6.194,6.194,0,0,0,2.4-2.9,4.5,4.5,0,0,0,.2-2.8,6.559,6.559,0,0,0-1.5-3,12.84,12.84,0,0,0-2.6-2l-3-1.5a6.376,6.376,0,0,1-1.4-.8c-.2-.1-.5-.3-.7-.4-.1-.1-.2-.1-.3-.2h-.1c-.1,0-.1-.1-.2-.1-.2-.2-.4-.3-.6-.5.1,0,.1-.1,0-.2l-.1-.1h0l-.1-.1c-.1-.1-.1-.2-.2-.3h5c.4,0,.8-.3.7-.7a.684.684,0,0,0-.7-.7H14.8a.367.367,0,0,0-.1-.3V8.7h0v-1h0V7.5h2c.4,0,.8-.3.7-.7a.684.684,0,0,0-.7-.7H15.3V6s.1-.2.1-.1l.1-.1.1-.1c.3-.1.4-.2.4-.3l.2-.2.1-.1h0a4.348,4.348,0,0,1,.7-.4c.1-.1.2-.1.4-.2.1,0,.1-.1.2-.1h.3c.1,0,.1-.1.2-.1.3-.2.4-.3.6-.3a.728.728,0,0,0-.4-1.4,6.709,6.709,0,0,0-4.6,3.9,5.751,5.751,0,0,0,.9,5.2C15.8,13.5,17.9,14.4,19.9,15.4Z"
          fill="#fff"
        />
        <path
          d="M20,25.5a26.937,26.937,0,0,1-2.9-1.6c-.1,0-.1-.1-.2-.1h0l-.3-.3c-.2-.2-.4-.3-.5-.5-.1-.1-.2-.2-.2-.3h0c0-.1-.1-.1-.1-.2s-.1-.1-.1-.2h1.4c.4,0,.8-.3.7-.7a.684.684,0,0,0-.7-.7h-2V19.6h0v-.2h5.6c.4,0,.8-.3.7-.7a.684.684,0,0,0-.7-.7H15.8a.1.1,0,0,1,.1-.1h0c0-.1.1-.1.1-.2l.3-.3.3-.3h0a.1.1,0,0,0,.1-.1c.1-.1.2-.1.2-.2a.738.738,0,0,0-.7-1.3,4.447,4.447,0,0,0-2.5,2.7,4.5,4.5,0,0,0-.2,2.8A6.559,6.559,0,0,0,15,24a12.84,12.84,0,0,0,2.6,2l3,1.5a6.376,6.376,0,0,1,1.4.8c.2.1.5.3.7.4.1.1.2.1.3.2h.1c.1,0,.1.1.2.1.2.2.4.3.6.5.1.1.2.2.2.3l.1.1h0l.1.1c.1.1.1.2.2.3h-5c-.4,0-.8.3-.7.7a.684.684,0,0,0,.7.7h5.7a.367.367,0,0,0,.1.3v.1h0v1h0v.2h-2c-.4,0-.8.3-.7.7a.684.684,0,0,0,.7.7h1.4v.1s-.1.2-.1.1l-.1.1-.1.1-.2.2-.2.2-.1.1h0a4.348,4.348,0,0,1-.7.4c-.1.1-.2.1-.4.2-.1,0-.1.1-.2.1h-.3c-.1,0-.1.1-.2.1-.2.1-.3.1-.5.2A.728.728,0,0,0,22,38a6.709,6.709,0,0,0,4.6-3.9,5.751,5.751,0,0,0-.9-5.2A23.265,23.265,0,0,0,20,25.5Z"
          fill="#fff"
        />
        <path
          d="M27,6.9a.752.752,0,0,0-.7-.5H20c-.4,0-.8.3-.7.7a.684.684,0,0,0,.7.7h5.7V9.5H23.6c-.4,0-.8.3-.7.7a.684.684,0,0,0,.7.7h1.5c0,.1-.1.1-.1.2l-.1.1-.1.1-.3.3a2.651,2.651,0,0,1-.6.5c-.1,0-.1.1-.2.1h0a1.38,1.38,0,0,1-.4.3.738.738,0,0,0,.7,1.3A6.785,6.785,0,0,0,26.5,11,4.572,4.572,0,0,0,27,6.9Z"
          fill="#fff"
        />
        <path
          d="M20.7,33.8a.684.684,0,0,0-.7-.7H14.3V31.4h2.1c.4,0,.8-.3.7-.7a.684.684,0,0,0-.7-.7H14.9c0-.1.1-.1.1-.2l.1-.1.1-.1.3-.3a2.651,2.651,0,0,1,.6-.5c.1,0,.1-.1.2-.1h0a1.38,1.38,0,0,1,.4-.3.738.738,0,1,0-.7-1.3,6.785,6.785,0,0,0-2.5,2.8,6.374,6.374,0,0,0-.4,4.3.752.752,0,0,0,.7.5h6.3A1.224,1.224,0,0,0,20.7,33.8Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default LifeSciencesIcon;
