import React from "react";

function LocalizationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path d="M13.4,16.6Z" fill="#fff" />
        <path
          d="M20.3,19.2v-.5a.4.4,0,1,0-.8,0h0v2.4a.4.4,0,0,0,.8,0V19.2Z"
          fill="#fff"
        />
        <path d="M16.4,11.3Z" fill="#fff" />
        <path
          d="M22.5,14.8a2.545,2.545,0,0,1-1.2,1.1h0a3.194,3.194,0,0,1-1.4.3,4.253,4.253,0,0,1-1.1-.2,4.053,4.053,0,0,1-.9-.5h0a3.915,3.915,0,0,1-.8-1h0a3.194,3.194,0,0,1-.3-1.4,2.822,2.822,0,0,1,.4-1.5h0a4.263,4.263,0,0,1,1.1-1.2,3.022,3.022,0,0,1,1.6-.5,5.019,5.019,0,0,1,1.2.2,3.007,3.007,0,0,1,1.4,1.1,3.371,3.371,0,0,1,.5,1.7,3.09,3.09,0,0,1-.5,1.9Zm1.1-3.2a4.887,4.887,0,0,0-1.4-1.8A4.1,4.1,0,0,0,20,9.1a3.7,3.7,0,0,0-2.1.6,4.009,4.009,0,0,0-1.4,1.5,4.136,4.136,0,0,0-.5,1.9,5.84,5.84,0,0,0,.4,1.8h0a3.482,3.482,0,0,0,1.1,1.3h0a2.384,2.384,0,0,0,1.1.6,2.953,2.953,0,0,0,1.3.2,3.922,3.922,0,0,0,1.8-.4h0a3.638,3.638,0,0,0,1.6-1.4,3.7,3.7,0,0,0,.6-2.1,4.013,4.013,0,0,0-.3-1.5Z"
          fill="#fff"
        />
        <path d="M15.6,18.2Z" fill="#fff" />
        <path d="M13.7,17.3Z" fill="#fff" />
        <path
          d="M22.7,20.2h0c-.3.4-.5.7-.7,1-.1,0-.1.1-.1.2-.1.2-.3.4-.4.6h0c-.2.4-.5.7-.7,1.1h0l-.1.2h0L20,24.4l-.7-1.1h0l-.3-.5h0c-.2-.3-.3-.5-.5-.8h0c-.2-.2-.3-.5-.5-.7h0c-.3-.4-.5-.7-.8-1.1h0c-.3-.4-.5-.7-.8-1.1h0a2.7,2.7,0,0,0-.7-.8,5.008,5.008,0,0,0-.7-.8h0c-.2-.2-.3-.4-.5-.6h0l-.3-.6h0a6.117,6.117,0,0,1-.5-1.2h0a5.663,5.663,0,0,1-.3-1.8h0a5.918,5.918,0,0,1,.7-2.9h0a5.823,5.823,0,0,1,2.3-2.6,6.742,6.742,0,0,1,3.4-1h.1a6.551,6.551,0,0,1,2.5.5,6.194,6.194,0,0,1,2.9,2.4,6.379,6.379,0,0,1,1.1,3.6,10.039,10.039,0,0,1-.4,2.4,8.175,8.175,0,0,1-1.2,2h0c-.4.5-.9,1-1.3,1.6h0a1.8,1.8,0,0,0-.8.9Zm-4.2,3.6h0l1,1.7a.5.5,0,0,0,.8,0l1-1.7h0l.1-.2h0c.2-.4.5-.7.7-1.1h0a2.186,2.186,0,0,1,.3-.5h7v6.8H10.3V22h7c.1.2.2.3.3.5h0c.2.3.3.5.5.8h0l.4.5Zm-6-10.6Zm18.8,6.2a2.767,2.767,0,0,0-1.6-.5h-5c.2-.3.5-.5.7-.8h0a10.232,10.232,0,0,0,1.4-2.2,7.635,7.635,0,0,0,.5-2.7,7.173,7.173,0,0,0-.6-2.9A6.511,6.511,0,0,0,24,7.1a7.387,7.387,0,0,0-4.1-1.3h-.1A7.469,7.469,0,0,0,15.9,7a7.58,7.58,0,0,0-2.6,3h0a7.216,7.216,0,0,0-.8,3.2h0a7.613,7.613,0,0,0,.3,2.1h0a4.235,4.235,0,0,0,.6,1.4,5.736,5.736,0,0,0,.4.7c.2.2.3.5.5.7h0a5.008,5.008,0,0,0,.7.8h0l.1.1H10.2a3.6,3.6,0,0,0-1.1.2,2.94,2.94,0,0,0-1.2,1,2.767,2.767,0,0,0-.5,1.6V29a3.6,3.6,0,0,0,.2,1.1,2.94,2.94,0,0,0,1,1.2,2.767,2.767,0,0,0,1.6.5h1.4a.4.4,0,0,0,0-.8H10.2a1.445,1.445,0,0,1-.7-.2,1.779,1.779,0,0,1-.8-.7A2.016,2.016,0,0,1,8.4,29V21.7a1.445,1.445,0,0,1,.2-.7,1.779,1.779,0,0,1,.7-.8,2.016,2.016,0,0,1,1.1-.3H16c.2.3.5.6.7.9h0c.1.1.2.3.3.4H10.2c-.1,0-.2.1-.3.2a.367.367,0,0,0-.1.3v7.5c0,.1.1.2.2.3a.367.367,0,0,0,.3.1H30.1c.1,0,.2-.1.3-.2a.367.367,0,0,0,.1-.3V21.6c0-.1-.1-.2-.2-.3a.367.367,0,0,0-.3-.1H23.4a1.38,1.38,0,0,0,.3-.4h0c.2-.3.5-.6.7-.9h5.7a1.445,1.445,0,0,1,.7.2,1.779,1.779,0,0,1,.8.7,2.016,2.016,0,0,1,.3,1.1V29a1.445,1.445,0,0,1-.2.7,1.779,1.779,0,0,1-.7.8,2.016,2.016,0,0,1-1.1.3H14.4a.4.4,0,0,0,0,.8H29.7a3.6,3.6,0,0,0,1.1-.2,2.94,2.94,0,0,0,1.2-1,2.767,2.767,0,0,0,.5-1.6V21.5a3.6,3.6,0,0,0-.2-1.1,4.714,4.714,0,0,0-1-1Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default LocalizationIcon;
