import React from "react";

function RushIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M9.2,19.4h1.6a.7.7,0,0,0,0-1.4H9.2a.7.7,0,0,0,0,1.4Z"
          fill="#fff"
        />
        <path
          d="M7.5,21.8H9.9a.7.7,0,0,0,0-1.4H7.5a.7.7,0,0,0,0,1.4Z"
          fill="#fff"
        />
        <path d="M9,24.2a.7.7,0,0,0,0-1.4H5.8a.7.7,0,0,0,0,1.4Z" fill="#fff" />
        <path
          d="M33.6,13a.641.641,0,0,0-.8.6,5.716,5.716,0,0,1-1.5,3,5.8,5.8,0,0,1-2.9,1.5v1.5a7.433,7.433,0,0,0,4-1.9,6.9,6.9,0,0,0,1.9-3.8C34.2,13.4,34,13.1,33.6,13Z"
          fill="#fff"
        />
        <path
          d="M33.6,7a.684.684,0,0,0-.7.7v1l-.6-.6a6.76,6.76,0,0,0-9.5,0,6.611,6.611,0,0,0-1.7,6.6h1.5a5.311,5.311,0,0,1,1.2-5.6,5.133,5.133,0,0,1,7.4,0l.6.6h-1a.7.7,0,1,0,0,1.4h2.7a.684.684,0,0,0,.7-.7V7.7A.563.563,0,0,0,33.6,7Z"
          fill="#fff"
        />
        <path
          d="M29.7,14.2a.752.752,0,0,0,.7-.5.715.715,0,0,0-.5-.9l-1.7-.5V9.9a.7.7,0,0,0-1.4,0v3a.752.752,0,0,0,.5.7l2.2.6Z"
          fill="#fff"
        />
        <path
          d="M26.1,16H21.5a1,1,0,0,0-1,1v1.9h1.8a2.837,2.837,0,0,1,1.7.7,2.413,2.413,0,0,1,.7,1.7,2.016,2.016,0,0,1-.3,1.1h1.7a1,1,0,0,0,1-1V17A.945.945,0,0,0,26.1,16Z"
          fill="#fff"
        />
        <path
          d="M11.3,27.1l-.3-.3-.5.8-.1.1-3.8.5a1.418,1.418,0,0,0-1.2,1.5,1.357,1.357,0,0,0,1.5,1.2l3.8-.5a2.956,2.956,0,0,0,2.1-1.3l.2-.4a15.757,15.757,0,0,0-1.5-1.2Z"
          fill="#fff"
        />
        <path
          d="M22.3,20.2H19.7A1.854,1.854,0,0,1,19,20c-2.1-1.8-2.4-1.8-2.7-1.9l-1.8-.3a1.676,1.676,0,0,0-1.8,1l-1.2,3.6c-.6,1.6-.9,2.5,0,3.2.2.1.5.4.9.7,1.1.9,2.8,2.3,2.8,2.3a3.126,3.126,0,0,1,.9,1.1l1.6,3.5a1.3,1.3,0,0,0,1.2.8.749.749,0,0,0,.5-.1,1.356,1.356,0,0,0,.7-1.8l-1.6-3.5a6.524,6.524,0,0,0-1.6-2.1l-1.7-1.4L16.9,21a4.349,4.349,0,0,0,.7.6,3.865,3.865,0,0,0,2.1.8h2.6a1.1,1.1,0,0,0,0-2.2Z"
          fill="#fff"
        />
        <path
          d="M15.6,17.3a2.511,2.511,0,1,0-1.9-3A2.612,2.612,0,0,0,15.6,17.3Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default RushIcon;
