import React from "react";

function VideoRemoteInterpretingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M26.5,19.4H12.1a1.155,1.155,0,0,0-1.2,1.2V33.4a1.155,1.155,0,0,0,1.2,1.2H26.5a1.155,1.155,0,0,0,1.2-1.2V20.6A1.155,1.155,0,0,0,26.5,19.4Zm-12,13.9a.43.43,0,0,1-.4.4h-.9a.43.43,0,0,1-.4-.4V32.2a.43.43,0,0,1,.4-.4h.9a.43.43,0,0,1,.4.4Zm0-11.5a.43.43,0,0,1-.4.4h-.9a.43.43,0,0,1-.4-.4V20.7a.43.43,0,0,1,.4-.4h.9a.43.43,0,0,1,.4.4Zm3.8,11.5a.43.43,0,0,1-.4.4H17a.43.43,0,0,1-.4-.4V32.2a.43.43,0,0,1,.4-.4h.9a.43.43,0,0,1,.4.4Zm0-11.5a.43.43,0,0,1-.4.4H17a.43.43,0,0,1-.4-.4V20.7a.43.43,0,0,1,.4-.4h.9a.43.43,0,0,1,.4.4Zm3.8,11.5a.43.43,0,0,1-.4.4h-.9a.43.43,0,0,1-.4-.4V32.2a.43.43,0,0,1,.4-.4h.9a.43.43,0,0,1,.4.4Zm0-11.5a.43.43,0,0,1-.4.4h-.9a.43.43,0,0,1-.4-.4V20.7a.43.43,0,0,1,.4-.4h.9a.43.43,0,0,1,.4.4Zm3.7,11.5a.43.43,0,0,1-.4.4h-.9a.43.43,0,0,1-.4-.4V32.2a.43.43,0,0,1,.4-.4h.9a.43.43,0,0,1,.4.4Zm0-11.5a.43.43,0,0,1-.4.4h-.9a.43.43,0,0,1-.4-.4V20.7a.43.43,0,0,1,.4-.4h.9a.43.43,0,0,1,.4.4ZM18,24l4.1,2.5a.567.567,0,0,1,0,1L18,29.9a.589.589,0,0,1-.9-.5v-5C17.2,24,17.7,23.7,18,24Z"
          fill="#fff"
        />
        <path
          d="M22.5,12.7a.756.756,0,0,1-.4-.1l-2.4-1.4c-.2-.1-.3-.2-.3-.4a.749.749,0,0,1,.1-.5l.5-1,.4-.1-.1-.4,1.1-1.9.4-.1-.1-.4.5-.9a.777.777,0,0,1,1-.3l2.4,1.4a.777.777,0,0,1,.3,1l-.5.9-.4.1.1.4L24,10.9l-.4.1.1.4-.5.9A.755.755,0,0,1,22.5,12.7Zm-2.3-2.1,2.3,1.3.3-.6-.1-.4.3-.6.4-.1.8-1.3-.1-.4.3-.6.4-.1.3-.6L22.8,5.9l-.3.6.1.4-.3.6-.3.1-.8,1.3.1.4-.3.6-.4.1Z"
          fill="#fff"
        />
        <path
          d="M30.3,14.6a1.854,1.854,0,0,1-.7-.2c-.3-.2-1.1-.7-2-1.3-1.2-.8-2.8-1.8-3.7-2.4l-.3-.2.4-.7.3.2c1,.5,2.5,1.6,3.8,2.4.9.6,1.7,1.1,2,1.3.3.1.6.1.7-.2a.6.6,0,0,0,.1-.4c0-.1-.1-.2-.2-.3-.3-.2-1.1-.6-2.1-1.1-1.3-.7-3-1.5-4-2.1l-.3-.2.4-.7.2.3c.9.5,2.6,1.4,3.9,2,1,.5,1.8.9,2.1,1.1a1.555,1.555,0,0,1,.6.8,1.729,1.729,0,0,1-.1,1A1.081,1.081,0,0,1,30.3,14.6Z"
          fill="#fff"
        />
        <rect
          width="0.8"
          height="1.9"
          transform="matrix(0.5, -0.866, 0.866, 0.5, 22.701, 7.925)"
          fill="#fff"
        />
        <rect
          width="0.8"
          height="1.9"
          transform="matrix(0.5, -0.866, 0.866, 0.5, 21.446, 10.014)"
          fill="#fff"
        />
        <path
          d="M23.9,17.5H15.5V16.3a1.155,1.155,0,0,1,1.2-1.2h1.2v.8H16.7a.43.43,0,0,0-.4.4v.4h6.8v-.4a.43.43,0,0,0-.4-.4H19.1v-.8h3.6a1.155,1.155,0,0,1,1.2,1.2v1.2Z"
          fill="#fff"
        />
        <path
          d="M23.1,15.5h-.8v-.8a.43.43,0,0,0-.4-.4H17.5a.43.43,0,0,0-.4.4v.8h-.8v-.8a1.155,1.155,0,0,1,1.2-1.2h4.4a1.155,1.155,0,0,1,1.2,1.2v.8Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default VideoRemoteInterpretingIcon;
