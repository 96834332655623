import React from "react";

function ManufacturingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M10.2,8.1a2.033,2.033,0,0,0,1.7-.9c.3-.3.4-.4.6-.4s.3.1.6.4a2.314,2.314,0,0,0,1.7.9,2.033,2.033,0,0,0,1.7-.9c.3-.3.4-.4.6-.4s.3.1.6.4a2.314,2.314,0,0,0,1.7.9,2.033,2.033,0,0,0,1.7-.9c.3-.3.4-.4.6-.4s.3.1.6.4a2.314,2.314,0,0,0,1.7.9,2.033,2.033,0,0,0,1.7-.9c.3-.3.4-.4.6-.4H27a.9.9,0,0,1,.9.9V9.4h1.5V7.7A2.433,2.433,0,0,0,27,5.3h-.5a2.033,2.033,0,0,0-1.7.9c-.3.3-.4.4-.6.4s-.3-.1-.6-.4a2.314,2.314,0,0,0-1.7-.9,2.033,2.033,0,0,0-1.7.9c-.3.3-.4.4-.6.4s-.3-.1-.6-.4a2.314,2.314,0,0,0-1.7-.9,2.033,2.033,0,0,0-1.7.9c-.3.3-.4.4-.6.4s-.3-.1-.6-.4a2.314,2.314,0,0,0-1.7-.9,2.033,2.033,0,0,0-1.7.9c-.3.3-.4.4-.6.4a.789.789,0,0,0-.8.8C9.6,7.9,9.7,8.1,10.2,8.1Z"
          fill="#fff"
        />
        <path
          d="M20.5,31.7H34.1a.8.8,0,1,0,0-1.6h-1l-2-18.9a.882.882,0,0,0-.9-.8H27a.948.948,0,0,0-.9.8l-.8,7.1-4.5-4a.443.443,0,0,0-.7.2l-.6,3.6-4.3-3.8a.443.443,0,0,0-.7.2L14,18.2,9.7,14.4a.443.443,0,0,0-.7.2L6.5,30.2h-1a.8.8,0,1,0,0,1.6H19m5.4-9.7a.645.645,0,0,1,.6-.6h1.8a.645.645,0,0,1,.6.6v1.2a.645.645,0,0,1-.6.6H25a.645.645,0,0,1-.6-.6Zm0,4.6a.645.645,0,0,1,.6-.6h1.8a.645.645,0,0,1,.6.6v1.2a.645.645,0,0,1-.6.6H25a.645.645,0,0,1-.6-.6Zm-6.1-4.6a.645.645,0,0,1,.6-.6h1.8a.645.645,0,0,1,.6.6v1.2a.645.645,0,0,1-.6.6H18.9a.645.645,0,0,1-.6-.6Zm0,4.6a.645.645,0,0,1,.6-.6h1.8a.645.645,0,0,1,.6.6v1.2a.645.645,0,0,1-.6.6H18.9a.645.645,0,0,1-.6-.6Zm-6.2-4.6a.645.645,0,0,1,.6-.6h1.8a.645.645,0,0,1,.6.6v1.2a.645.645,0,0,1-.6.6H12.7a.645.645,0,0,1-.6-.6Zm0,4.6a.645.645,0,0,1,.6-.6h1.8a.645.645,0,0,1,.6.6v1.2a.645.645,0,0,1-.6.6H12.7a.645.645,0,0,1-.6-.6Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default ManufacturingIcon;
