import * as React from "react";
import BoxContainer from "components/BoxContainer";
import Text from "../Text";
import Button from "../Button";
import { Link } from "gatsby";

const SupportedLanguages = ({ data, background, btnClasses }) => {
  return (
    <BoxContainer
      background={`${background ? background : "bg-white"}`}
      className="py-10 relative"
    >
      <div className="md:w-10/12 lg:w-11/12 xl:w-10/12 m-auto 2xl:max-w-6xl">
        <Text
          highlightedColor={data?.highlightedColor}
          highlightedText={data?.highlightWords}
          as="h2"
          className={`text-3xl2 font-semibold font-secondary my-5 text-midBlue max-w-2xl`}
        >
          {data?.title}
        </Text>
        <div className="flex flex-col lg:flex-row xl:gap-10 items-baseline">
          <div className="max-w-xl xl:max-w-2xl">
            <Text
              className={`text-sm2 font-regular font-opensans pt-1 text-midBlue`}
              as="div"
            >
              {data?.page_content}
            </Text>
            <Button
              title={data?.cta?.text}
              href={data?.cta?.href}
              className={`w-44 h-[52px] text-base text-center text-white font-opensans font-medium bg-yellow rounded-full cursor-pointer flex items-center justify-center mt-7 mb-5 hover:bg-newOrange-100 transition-colors ${
                btnClasses ? btnClasses : " "
              }`}
            />
          </div>
          {data.languages && (
            <div className="flex flex-col lg:w-1/2 lg:px-12 2xl:max-w-370">
              <ul className="grid gap-5 pt-6 pb-12 grid-column-end grid-cols-1 md:grid-cols-2 gap-x-24">
                {data.languages.length > 0
                  ? data.languages.map(({ text, link }, i) => (
                      <>
                        {link ? (
                          <li key={i} className="flex gap-3 items-center">
                            <img
                              src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                              alt="check"
                              className="w-6 h-5"
                            />
                            <Link
                              to={link}
                              className="text-base font-opensans text-midBlue cursor-pointer hover:text-newOrange-100 transition-colors"
                            >
                              {text}
                            </Link>
                          </li>
                        ) : (
                          <li key={i} className="flex gap-3 items-center">
                            <img
                              src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                              alt="check"
                              className="w-6 h-5"
                            />
                            <span className="text-base font-opensans text-midBlue">
                              {text}
                            </span>
                          </li>
                        )}
                      </>
                    ))
                  : ""}
              </ul>
            </div>
          )}
        </div>
      </div>
    </BoxContainer>
  );
};

export default SupportedLanguages;
