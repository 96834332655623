import React from "react";

function LinguisticIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M29.3,31.5c-.1,0-.2,0-.2-.1l-2.8-2.8a1.606,1.606,0,0,0-1.1-.5H17.1a1.24,1.24,0,0,1-1.3-1.2V24.2h-4a1.606,1.606,0,0,0-1.1.5L7.9,27.5c-.1.1-.1.1-.2.1H7.6c-.1,0-.2-.2-.2-.3V15.6a1.24,1.24,0,0,1,1.3-1.2h9.8A7.305,7.305,0,1,1,31,21.5l1.6,1.6a.367.367,0,0,1,.1.3c-.1.1-.2.2-.3.2H29.8v7.6c0,.1-.1.2-.2.3h-.3ZM16.4,26.9a.669.669,0,0,0,.7.6h8.1a2.452,2.452,0,0,1,1.6.6l2.3,2.3V23.5H28a7.512,7.512,0,0,1-2.6.5,6.679,6.679,0,0,1-4.1-1.3V23A1.24,1.24,0,0,1,20,24.2H16.6v2.7ZM8.6,15a.669.669,0,0,0-.7.6V26.5l2.3-2.3a2.271,2.271,0,0,1,1.6-.6h8.1a.669.669,0,0,0,.7-.6v-.8A7.079,7.079,0,0,1,18,16.7a8.807,8.807,0,0,1,.2-1.7H8.6Z"
          fill="#fff"
        />
        <path
          d="M25.2,9.6a7.17,7.17,0,0,1,7.2,7.2,7.333,7.333,0,0,1-1.7,4.7l1.6,1.6c.1.1.1.1,0,.2,0,.1-.1.1-.2.1H29.4v7.7a.349.349,0,0,1-.1.2h-.1c-.1,0-.1,0-.1-.1l-2.8-2.8a1.594,1.594,0,0,0-1.2-.4H17a1.135,1.135,0,0,1-1.2-1.1V24.1H11.7a1.845,1.845,0,0,0-1.2.5L7.7,27.4l-.1.1H7.5c-.1,0-.1-.1-.1-.2V15.6a1.135,1.135,0,0,1,1.2-1.1h9.8a7.211,7.211,0,0,1,6.8-4.9M7.8,26.7l2.5-2.5a2.035,2.035,0,0,1,1.5-.6h8.1a.773.773,0,0,0,.8-.7v-.8a7.079,7.079,0,0,1-2.6-5.5,11.019,11.019,0,0,1,.2-1.8H8.6a.773.773,0,0,0-.8.7V26.7m9.3.8h8.1a2.035,2.035,0,0,1,1.5.6l2.5,2.5V23.4H28a7.512,7.512,0,0,1-2.6.5,6.847,6.847,0,0,1-4.2-1.4V23A1.135,1.135,0,0,1,20,24.1H16.4v2.8c-.1.3.2.6.7.6M25.2,9.3a7.569,7.569,0,0,0-7,4.9H8.6a1.347,1.347,0,0,0-1.4,1.3V27.2c0,.2.1.3.3.4h.2a.367.367,0,0,0,.3-.1l2.8-2.8a1.284,1.284,0,0,1,1-.4h3.9v2.6a1.347,1.347,0,0,0,1.4,1.3h8.1a1.284,1.284,0,0,1,1,.4L29,31.4a.367.367,0,0,0,.3.1h.2c.2-.1.3-.2.3-.4V23.6h2.4c.2,0,.3-.1.4-.3s0-.3-.1-.5L31,21.4a7.163,7.163,0,0,0,1.6-4.6,7.469,7.469,0,0,0-7.4-7.5ZM8,26.2V15.6a.562.562,0,0,1,.6-.5H18a8.752,8.752,0,0,0-.2,1.6,7.262,7.262,0,0,0,2.6,5.6V23a.562.562,0,0,1-.6.5H11.7a1.99,1.99,0,0,0-1.6.7L8,26.2Zm8.5-1.9h3.3A1.347,1.347,0,0,0,21.2,23h0a7.211,7.211,0,0,0,4,1.2,8.574,8.574,0,0,0,2.7-.5h.9v6.5l-2-2.2a2.36,2.36,0,0,0-1.6-.7H17.1a.56.56,0,0,1-.6-.5V24.3Z"
          fill="#fff"
        />
        <path
          d="M11.2,22.4h-.1a.4.4,0,0,1-.2-.4l2.3-5.6c.1-.1.2-.2.3-.2s.2.1.3.2L16.1,22c.1.2,0,.4-.2.4h-.1c-.1,0-.2-.1-.3-.2L15,21.1H12l-.5,1.1c0,.1-.1.2-.3.2Zm3.7-2-1.3-3.2-1.3,3.2Z"
          fill="#fff"
        />
        <path
          d="M13.6,16.2a.349.349,0,0,1,.2.1l1.8,4.3h0l.5,1.3a.367.367,0,0,1-.1.3h-.1c-.1,0-.2,0-.2-.1L15.3,21H12l-.5,1.2c0,.1-.1.1-.2.1h-.1a.192.192,0,0,1-.1-.3l.5-1.3h0l1.8-4.3a.215.215,0,0,1,.2-.2m-1.5,4.3h3L13.6,17l-1.5,3.5M13.6,16c-.2,0-.3.1-.4.3l-1.8,4.3-.5,1.3a.481.481,0,0,0,.2.6h.2c.2,0,.3-.1.4-.3l.4-1h3l.4,1c.1.2.2.3.4.3h.2a.445.445,0,0,0,.2-.6l-.5-1.3L14,16.2a.52.52,0,0,0-.4-.2Zm-1.2,4.3,1.2-2.8,1.2,2.8Z"
          fill="#fff"
        />
        <path
          d="M21.6,13.6h3.5v-.4a.2.2,0,0,1,.4,0v.4h3.4a.2.2,0,1,1,0,.4H23.4a12.964,12.964,0,0,0,1.8,3.5,12.981,12.981,0,0,0,1.5-2.8.224.224,0,1,1,.4.2,13.691,13.691,0,0,1-1.7,3,5.9,5.9,0,0,0,2.6,2,.192.192,0,0,1,.1.3c0,.1-.1.1-.2.1h-.1a5.286,5.286,0,0,1-2.7-2.1,5.286,5.286,0,0,1-2.7,2.1h-.1a.349.349,0,0,1-.2-.1.367.367,0,0,1,.1-.3,5.9,5.9,0,0,0,2.6-2,16.022,16.022,0,0,1-2-3.9H21.4a.215.215,0,0,1-.2-.2C21.4,13.7,21.5,13.6,21.6,13.6Z"
          fill="#f47b20"
          stroke="#f63"
          stroke-miterlimit="10"
          stroke-width="0.447"
        />
      </g>
    </svg>
  );
}
export default LinguisticIcon;
