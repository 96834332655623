import React from "react";

function ClinicalTranslationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M19.6,19.3l-.4,1.5-5.1-1.4.4-1.5Zm7.2,14.6H11.5a6.175,6.175,0,0,1,2.6-5.2v-1H12.7V25.1h7.9a4.574,4.574,0,0,0,2-8.7,2.875,2.875,0,0,1-1.8.7h-.5L20,18.3l-5.1-1.4,2.4-9,.7.2.7-2.5h0a.728.728,0,0,1,.4-1.4l3.5.9a.731.731,0,0,1-.3,1.4h-.2L21.4,9l.8.2-.7,2.5A2.317,2.317,0,0,1,23,13.1a7.9,7.9,0,0,1,5.2,7.3A7.768,7.768,0,0,1,24,27.3v1.4A6.349,6.349,0,0,1,26.8,33.9ZM21.2,14.3a.5.5,0,1,0-.5.5C21,14.9,21.2,14.6,21.2,14.3Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default ClinicalTranslationIcon;
