import React from "react";

function SimultaneousInterpretingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(-0.1 -0.1)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0.1 0.1)"
          fill="#f47b20"
        />
        <path
          d="M25.7,22.8h.2a.51.51,0,0,0,.5-.4.67.67,0,0,0-.3-.8,11.4,11.4,0,0,1-1.9-1,5.872,5.872,0,0,0,2.2-4.3h1.1a.6.6,0,1,0,0-1.2H23.8V14a.6.6,0,0,0-1.2,0v1.1H19a.6.6,0,1,0,0,1.2h6.4a4.918,4.918,0,0,1-2,3.6,5.356,5.356,0,0,1-2-3,.752.752,0,0,0-.7-.5.752.752,0,0,0-.5.7,6.393,6.393,0,0,0,2.1,3.5c-.6.3-1.2.7-1.9,1a.63.63,0,0,0-.3.8.664.664,0,0,0,.5.4h.2a13.574,13.574,0,0,0,2.4-1.3,11.656,11.656,0,0,0,2.5,1.3Z"
          fill="#fff"
        />
        <path
          d="M16.7,23.3,15,19.6a.654.654,0,0,0-1.1,0L11,26.2a.6.6,0,0,0,1.1.5l1.1-2.5h2.6l1.1,2.5a.55.55,0,0,0,.5.3c.1,0,.2,0,.2-.1a.63.63,0,0,0,.3-.8l-1.2-2.8Zm-3-.3.8-1.7.8,1.7Z"
          fill="#fff"
        />
        <path
          d="M21.5,7.1l1.7-1a.54.54,0,0,0,.2-.8.54.54,0,0,0-.8-.2L19.2,7h0l-.1.1-.1.1a.1.1,0,0,1-.1.1v.1h0v.2c0,.1.1.1.1.2h0l2.7,2.8a.758.758,0,0,0,.4.2.758.758,0,0,0,.4-.2.608.608,0,0,0,0-.8L21,8.2A12.138,12.138,0,0,1,31.7,20.3a12.344,12.344,0,0,1-7.9,11.4.53.53,0,0,0-.3.8.664.664,0,0,0,.5.4h.2a13.566,13.566,0,0,0,8.6-12.5A13.29,13.29,0,0,0,21.5,7.1Z"
          fill="#fff"
        />
        <path
          d="M17.3,29.9a.566.566,0,0,0-.8.8l1.7,1.6a12.17,12.17,0,0,1-3-23.5.53.53,0,0,0,.3-.8.53.53,0,0,0-.8-.3,13.271,13.271,0,0,0,3.1,25.6l-1.7,1.1a.54.54,0,0,0-.2.8.55.55,0,0,0,.5.3.367.367,0,0,0,.3-.1L20,33.3a.536.536,0,0,0,.3-.4c0-.2,0-.4-.2-.5Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default SimultaneousInterpretingIcon;
