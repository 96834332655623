import React from "react";

function VoiceOverIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M15.6,31.5H14.5v-7h3.2a1.11,1.11,0,0,0,1.1-1.1V21.8h-.5a2.22,2.22,0,0,1-2.2-2.2V17.4h2.7V14.7h3.1a.55.55,0,0,0,.5-.3.446.446,0,0,0,0-.5l-3-6,1-.5,3,6a1.81,1.81,0,0,1-.1,1.6,1.6,1.6,0,0,1-1.4.8h-2v2.7H17.2v1.1a1.11,1.11,0,0,0,1.1,1.1h1.6v2.7a2.22,2.22,0,0,1-2.2,2.2H15.5v5.9Z"
          fill="#fff"
        />
        <path
          d="M19.9,33.1a12.95,12.95,0,0,1,0-25.9,12.6,12.6,0,0,1,7.9,2.7l-.7.9A11.827,11.827,0,0,0,8,20.1,11.994,11.994,0,0,0,19.9,32a11.271,11.271,0,0,0,7.2-2.5l.7.9A12.6,12.6,0,0,1,19.9,33.1Z"
          fill="#fff"
        />
        <path
          d="M29,29.3l-.8-.8a11.881,11.881,0,0,0,3.5-8.4,11.642,11.642,0,0,0-3.5-8.4L29,11a12.916,12.916,0,0,1,0,18.3Z"
          fill="#fff"
        />
        <path
          d="M27.5,27.8l-.7-.8a9.757,9.757,0,0,0,2.8-6.9,9.933,9.933,0,0,0-2.8-6.9l.8-.8A10.749,10.749,0,0,1,30.8,20,11.067,11.067,0,0,1,27.5,27.8Z"
          fill="#fff"
        />
        <path
          d="M26,26.2l-.8-.8a7.484,7.484,0,0,0,0-10.6L26,14a8.692,8.692,0,0,1,0,12.2Z"
          fill="#fff"
        />
        <path
          d="M24.5,24.7l-.8-.7a5.312,5.312,0,0,0,0-7.6l.8-.8a6.42,6.42,0,0,1,1.9,4.6A6.19,6.19,0,0,1,24.5,24.7Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default VoiceOverIcon;
