import React from "react";

function TechnicalManualIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M19.1,31.1a.43.43,0,0,1-.4-.4A1.752,1.752,0,0,0,17,29H6.5a.43.43,0,0,1-.4-.4v-16a.43.43,0,0,1,.4-.4H17a2.433,2.433,0,0,1,2.4,2.4V30.7C19.5,31,19.3,31.1,19.1,31.1ZM6.9,28.4H17a2.413,2.413,0,0,1,1.7.7V14.7A1.752,1.752,0,0,0,17,13H6.9Z"
          fill="#fff"
        />
        <path
          d="M19.1,31.1a.43.43,0,0,1-.4-.4v-16a2.433,2.433,0,0,1,2.4-2.4H31.6a.43.43,0,0,1,.4.4v2a.4.4,0,0,1-.8,0V13.1h-10a1.752,1.752,0,0,0-1.7,1.7V29.2a2.413,2.413,0,0,1,1.7-.7h0a.4.4,0,1,1,0,.8h0A1.752,1.752,0,0,0,19.5,31,1.034,1.034,0,0,1,19.1,31.1Z"
          fill="#fff"
        />
        <path
          d="M16.3,16.2H9.4a.446.446,0,0,1-.4-.3.43.43,0,0,1,.4-.4h6.9a.43.43,0,0,1,.4.4C16.6,16,16.5,16.2,16.3,16.2Z"
          fill="#fff"
        />
        <path
          d="M16.3,18.4H9.4a.4.4,0,1,1,0-.8h6.9a.4.4,0,0,1,0,.8Z"
          fill="#fff"
        />
        <path
          d="M14.9,22H11.4a.4.4,0,0,1,0-.8h3.5a.4.4,0,1,1,0,.8Z"
          fill="#fff"
        />
        <path
          d="M26.5,16.2H22a.4.4,0,0,1,0-.8h4.5a.43.43,0,0,1,.4.4C26.8,16,26.7,16.2,26.5,16.2Z"
          fill="#fff"
        />
        <path
          d="M25.1,18.4H21.9a.4.4,0,0,1,0-.8h3.2a.4.4,0,0,1,0,.8Z"
          fill="#fff"
        />
        <path
          d="M23.7,20.6H22a.4.4,0,0,1,0-.8h1.7a.4.4,0,0,1,0,.8Z"
          fill="#fff"
        />
        <path
          d="M14.9,23.8H11.4a.4.4,0,0,1,0-.8h3.5a.4.4,0,0,1,0,.8Z"
          fill="#fff"
        />
        <path
          d="M30.9,31.1a2.476,2.476,0,0,1-2.5-2.5v-5a4.522,4.522,0,0,1,.7-8h.3c.1.1.2.2.2.3V19l1.3,1.1L32.2,19V16c0-.1.1-.2.2-.3a.187.187,0,0,1,.3,0,4.522,4.522,0,0,1,.7,8v5A2.6,2.6,0,0,1,30.9,31.1Zm-2-14.5a3.816,3.816,0,0,0,0,6.6c.1.1.2.2.2.3v5.2a1.685,1.685,0,0,0,1.7,1.7,1.752,1.752,0,0,0,1.7-1.7V23.5c0-.1.1-.2.2-.3a3.859,3.859,0,0,0,1.9-3.3,3.74,3.74,0,0,0-1.9-3.3v2.7a.367.367,0,0,1-.1.3L31,21a.446.446,0,0,1-.5,0L29,19.5a.367.367,0,0,1-.1-.3Z"
          fill="#fff"
        />
        <path
          d="M30.9,29.3a1,1,0,0,1-1-1,1,1,0,1,1,1,1Zm0-1.3a.3.3,0,1,0,.3.3A.319.319,0,0,0,30.9,28Z"
          fill="#fff"
        />
        <path
          d="M27.7,31.1a.55.55,0,0,1-.5-.3l-.4-.8h-.3l-.4.8a.517.517,0,0,1-.7.3l-1-.4a.517.517,0,0,1-.3-.7l.3-.8-.2-.2-.8.3a.517.517,0,0,1-.7-.3l-.4-1a.517.517,0,0,1,.3-.7l.8-.4v-.3l-.8-.4a.517.517,0,0,1-.3-.7l.4-1a.517.517,0,0,1,.7-.3l.8.3.2-.2-.3-.8a.517.517,0,0,1,.3-.7l1-.4a.517.517,0,0,1,.7.3l.4.8h.1c.2,0,.3.2.3.4s-.2.3-.4.3h0a.55.55,0,0,1-.5-.3l-.4-.9-.7.3.3.8c.1.2,0,.4-.2.6l-.3.3c-.1.2-.4.2-.6.2l-.8-.3-.3.7.8.4a.55.55,0,0,1,.3.5v.4a.55.55,0,0,1-.3.5l-.8.4.3.7.8-.3c.2-.1.4,0,.6.2l.3.3c.2.1.2.4.2.6l-.3.8.7.3.4-.8a.55.55,0,0,1,.5-.3h.4a.55.55,0,0,1,.5.3l.4.8c.1,0,.2.1.3.2a.365.365,0,0,1-.2.5Zm-2.9-.9Zm1.6-.1ZM24.7,30Zm-.4-1Zm-1.4-1.3Zm.4-.8Zm.1-.1Zm-.1-.3Zm-.4-.7Zm1.4-1.3Zm.4-1.1Zm.1-.2Z"
          fill="#fff"
        />
        <path
          d="M26.7,28.4a1.952,1.952,0,0,1-.7-.1,1.591,1.591,0,0,1-.9-.9,1.658,1.658,0,0,1,.9-2.2,1.7,1.7,0,0,1,1.4,0,.381.381,0,1,1-.3.7,1.1,1.1,0,0,0-.8,0,.917.917,0,0,0-.5,1.3,1.205,1.205,0,0,0,.5.5,1.1,1.1,0,0,0,.8,0h.1a.381.381,0,1,1,.3.7h-.8Z"
          fill="#fff"
        />
        <path
          d="M9.8,22H9.4a.4.4,0,1,1,0-.8h.5a.43.43,0,0,1,.4.4C10.3,21.8,10,22,9.8,22Z"
          fill="#fff"
        />
        <path
          d="M9.8,23.8H9.4a.4.4,0,0,1,0-.8h.5a.43.43,0,0,1,.4.4C10.3,23.6,10,23.8,9.8,23.8Z"
          fill="#fff"
        />
        <path
          d="M13.2,25.6H11.4a.4.4,0,0,1,0-.8h1.8a.4.4,0,0,1,0,.8Z"
          fill="#fff"
        />
        <path
          d="M9.8,25.6H9.4a.4.4,0,0,1,0-.8h.5a.43.43,0,0,1,.4.4C10.3,25.4,10,25.6,9.8,25.6Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default TechnicalManualIcon;
