import React from "react";

function AviationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#f47b20" />
      <path
        d="M29,12.4c-1.4,1-5.3,3.8-5.3,3.8a.3.3,0,0,1-.4,0l-2.6-1.5-.1-.1h0l.4-.4h0a3.736,3.736,0,0,1,.7-.6.91.91,0,0,0,.1-1.3.864.864,0,0,0-.6-.3,1.143,1.143,0,0,0-.7.3c-.3.2-.7.6-1.1.9a2.959,2.959,0,0,0-.4.3c-.1.1-.2.1-.2,0l-2-1.2c-.1,0-.1-.1,0-.2l.5-.5a.91.91,0,0,0,.1-1.3h0c-.5-.6-1.1-.2-1.4,0a11.486,11.486,0,0,1-1,.8h-.2c-1.1-.7-3.3-1.9-4.9-2.9-1.1-.7-1.7-.1-1.7.2,0,.2.6.7,1,1.2,2.3,2.4,9.3,9.8,9.3,9.8a.637.637,0,0,1,0,.6l-6.8,5.5c-.1,0-.1.1-.2.1h-.4c-2.3-1-4.4-1.9-4.9-2.2-.9-.4-.8.3-.4.7,1.4,1.3,3.1,2.8,3.5,3.2.1.1.2.2,0,.5-.5.5-1.4,1.6-1.1,2s1.6-.2,2.3-.5c.4-.1.5,0,.5.1.3.5,1.2,2.6,2,4.3.2.5.8.9.8-.2-.1-.8-.3-2.7-.6-5.4a.6.6,0,0,1,.1-.4c0-.1.1-.1.2-.1l7.3-4.8c.2-.1.5-.1.6.1,0,0,4.9,8.8,6.5,11.8a4.769,4.769,0,0,0,.9,1.4c.3.2,1-.3.7-1.6-.4-1.8-1-4.3-1.3-5.5,0-.1,0-.1.1-.2.4-.3.8-.5,1-.7a.9.9,0,0,0,.4-1.3h0a1.009,1.009,0,0,0-1.3-.3,2.092,2.092,0,0,0-.6.4c-.1,0-.1,0-.2-.1l-.6-2c0-.1,0-.2.1-.2a5.2,5.2,0,0,0,.5-.3c.4-.3.9-.6,1.2-.8a1.024,1.024,0,0,0,.5-.6.631.631,0,0,0-.1-.7,1.009,1.009,0,0,0-1.3-.3,5.938,5.938,0,0,1-.8.4h0a5.2,5.2,0,0,1-.5.3h-.1a.1.1,0,0,1-.1-.1l-.7-2.9a.6.6,0,0,1,.1-.4s3.9-2.9,5.3-3.9c1.5-1.1,2.7-3.1,2.2-3.9C32.9,10.7,30.6,11.2,29,12.4Z"
        fill="#fff"
      />
    </svg>
  );
}
export default AviationIcon;
