import React from "react";

function TranslationTechnologyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M14.7,27.1a.4.4,0,1,1,0-.8H32.9a1.538,1.538,0,0,0,1.5-1.5V11.9a1.538,1.538,0,0,0-1.5-1.5H11.6a1.538,1.538,0,0,0-1.5,1.5v6.4a.4.4,0,1,1-.8,0V11.9a2.263,2.263,0,0,1,2.3-2.3H32.9a2.263,2.263,0,0,1,2.3,2.3V24.8a2.263,2.263,0,0,1-2.3,2.3H14.7Z"
          fill="#fff"
        />
        <path
          d="M20.9,30a.43.43,0,0,1-.4-.4V26.7a.43.43,0,0,1,.4-.4h2.7a.43.43,0,0,1,.4.4v2.9a.43.43,0,0,1-.4.4Zm.4-.7h2V27.1h-2Z"
          fill="#fff"
        />
        <path d="M18.3,30a.4.4,0,1,1,0-.8h7.9a.4.4,0,1,1,0,.8Z" fill="#fff" />
        <path
          d="M24.4,19a.367.367,0,0,1-.3-.1.283.283,0,0,1,0-.5L27,15.2a.5.5,0,0,1,.6,0,.283.283,0,0,1,0,.5l-2.9,3.2A.367.367,0,0,1,24.4,19Z"
          fill="#fff"
        />
        <path
          d="M22.9,19.3h-.2l-2-1.2c-.2-.1-.2-.3-.1-.5.1-.1.2-.2.3-.2h.2l2,1.2c.2.1.2.3.1.5C23.2,19.2,23.1,19.3,22.9,19.3Z"
          fill="#fff"
        />
        <path
          d="M15.9,21.6a.426.426,0,0,1-.3-.7l3.5-3.3a.5.5,0,0,1,.6,0,.454.454,0,0,1,0,.5l-3.5,3.3C16.1,21.5,16,21.6,15.9,21.6Z"
          fill="#fff"
        />
        <path
          d="M20.1,18.5a1.3,1.3,0,1,1,1.3-1.3A1.4,1.4,0,0,1,20.1,18.5Zm0-1.8a.5.5,0,1,0,.5.5A.536.536,0,0,0,20.1,16.7Z"
          fill="#fff"
        />
        <path
          d="M23.7,20.6A1.3,1.3,0,1,1,25,19.3,1.261,1.261,0,0,1,23.7,20.6Zm0-1.7a.5.5,0,1,0,.5.5C24.3,19.1,24,18.9,23.7,18.9Z"
          fill="#fff"
        />
        <path
          d="M28,16a1.3,1.3,0,1,1,1.3-1.3A1.391,1.391,0,0,1,28,16Zm0-1.7a.5.5,0,1,0,.5.5A.536.536,0,0,0,28,14.3Z"
          fill="#fff"
        />
        <path
          d="M15.8,23.5a.43.43,0,0,1-.4-.4V13.5a.4.4,0,0,1,.8,0v9.3H29.7a.4.4,0,0,1,0,.8H15.8Z"
          fill="#fff"
        />
        <path
          d="M16.7,14.9a.367.367,0,0,1-.3-.1l-.7-.8-.6.7a.426.426,0,0,1-.6-.6l.9-.9a.5.5,0,0,1,.6,0l.9.9a.454.454,0,0,1,0,.5C16.9,14.8,16.8,14.9,16.7,14.9Z"
          fill="#fff"
        />
        <path
          d="M28.7,24.5a.426.426,0,0,1-.3-.7l.7-.7-.7-.6a.454.454,0,0,1,0-.5.5.5,0,0,1,.6,0l.9.9a.454.454,0,0,1,0,.5l-.9.9C28.9,24.4,28.8,24.5,28.7,24.5Z"
          fill="#fff"
        />
        <path
          d="M14.7,30a.43.43,0,0,1-.4-.4V26.3a2.006,2.006,0,0,0-2-2H6.4a2.006,2.006,0,0,0-2,2v3.3A.43.43,0,0,1,4,30a.43.43,0,0,1-.4-.4V26.3a2.689,2.689,0,0,1,2.7-2.7h5.9a2.689,2.689,0,0,1,2.7,2.7v3.3C15.1,29.9,14.9,30,14.7,30Z"
          fill="#fff"
        />
        <path
          d="M9.4,22.7a2.351,2.351,0,1,1,2.4-2.3A2.348,2.348,0,0,1,9.4,22.7Zm0-3.9A1.6,1.6,0,1,0,11,20.4,1.58,1.58,0,0,0,9.4,18.8Z"
          fill="#fff"
        />
        <path
          d="M6.3,30a.43.43,0,0,1-.4-.4v-3a.4.4,0,0,1,.8,0v3A.366.366,0,0,1,6.3,30Z"
          fill="#fff"
        />
        <path
          d="M12.5,30a.43.43,0,0,1-.4-.4v-3a.4.4,0,1,1,.8,0v3A.366.366,0,0,1,12.5,30Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default TranslationTechnologyIcon;
