import React from "react";
import Text from "components/Text";
import Accordion from "components/Accordion";

function FAQ({ data }) {
  return (
    <div className="bg-midBlue py-14 md:py-24">
      <div className="max-w-4xl mx-auto">
        <Text
          as="h2"
          className="text-40 text-white text-center font-secondary font-bold mb-8 px-4 md:px-0"
        >
          {data.title}
        </Text>
        <Text
          as="p"
          className="text-lg leading-8 text-gray text-center  font-opensans mb-10 px-4 lg:px-0"
        >
          {data.description}
        </Text>
      </div>
      {data.accordionItems.map((item, i) => {
        return (
          <Accordion
            key={i}
            heading={item.heading}
            description={item.description}
          />
        );
      })}
    </div>
  );
}
export default FAQ;
