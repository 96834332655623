import React from "react";

function ExpertFinanceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(-0.1 -0.1)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0.1 0.1)"
          fill="#f47b20"
        />
        <ellipse
          cx="7"
          cy="1.9"
          rx="7"
          ry="1.9"
          transform="translate(11 14.3)"
          fill="#fff"
        />
        <path
          d="M18,19.6c-3,0-5.8-.5-6.8-1.3-.1-.1-.3,0-.3.2v2.2c0,1.1,3.2,2,7.2,2s7.2-.9,7.2-2V18.5c0-.2-.2-.3-.3-.2C23.7,19.1,21,19.6,18,19.6Z"
          fill="#fff"
        />
        <path
          d="M18,24.3c-3,0-5.8-.5-6.8-1.3-.1-.1-.3,0-.3.2v2.2c0,1.1,3.2,2,7.2,2s7.2-.9,7.2-2V23.2c0-.2-.2-.3-.3-.2A16.67,16.67,0,0,1,18,24.3Z"
          fill="#fff"
        />
        <path
          d="M18,28.9c-3,0-5.8-.5-6.8-1.3-.1-.1-.3,0-.3.2V30c0,1.1,3.2,2,7.2,2s7.2-.9,7.2-2V27.8c0-.2-.2-.3-.3-.2C23.7,28.4,21,28.9,18,28.9Z"
          fill="#fff"
        />
        <path
          d="M25.5,35.6a8.025,8.025,0,0,1-3-.6,7.354,7.354,0,0,1-4-4,6.583,6.583,0,0,1-.7-3,8.025,8.025,0,0,1,.6-3,7.354,7.354,0,0,1,4-4,7.306,7.306,0,0,1,3-.6,8.025,8.025,0,0,1,3,.6,7.354,7.354,0,0,1,4,4,7.306,7.306,0,0,1,.6,3,8.025,8.025,0,0,1-.6,3,7.354,7.354,0,0,1-4,4A7.173,7.173,0,0,1,25.5,35.6Z"
          fill="#fff"
        />
        <circle
          cx="6.4"
          cy="6.4"
          r="6.4"
          transform="translate(19.1 21.6)"
          fill="#f47b20"
        />
        <path
          d="M25,31.6v-.5h-.1a4.851,4.851,0,0,1-2.1-.8l.7-1a3.708,3.708,0,0,0,2,.7q.9,0,.9-.6c0-.4-.6-.6-1.2-.9a6.109,6.109,0,0,1-1.4-.7,1.457,1.457,0,0,1-.7-1.3,1.927,1.927,0,0,1,1.8-1.8H25v-.5a.472.472,0,0,1,.5-.5h0a.472.472,0,0,1,.5.5v.5h.1a2.842,2.842,0,0,1,1.7.9l-.8.9a2.767,2.767,0,0,0-1.6-.5c-.8,0-.8.4-.8.6,0,.4.5.5,1.1.8a6.109,6.109,0,0,1,1.4.7,1.457,1.457,0,0,1,.7,1.3,1.656,1.656,0,0,1-.5,1.2,2.649,2.649,0,0,1-1.4.7h-.1v.5a.472.472,0,0,1-.5.5h0C25.3,32.1,25,31.9,25,31.6Z"
          fill="#fff"
        />
        <path
          d="M8.6,16.2a.713.713,0,0,1-.6-.3.817.817,0,0,1,.1-1.2l7.2-6.6a.769.769,0,0,1,1-.1l4,2.5,5.9-5.8a.913.913,0,0,1,1.2,0,.913.913,0,0,1,0,1.2L21,12.2a.9.9,0,0,1-1.1.1l-4-2.5L9.2,16A.845.845,0,0,1,8.6,16.2Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default ExpertFinanceIcon;
