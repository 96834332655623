import React from "react";

function FashionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g transform="translate(0 -0.1)">
        <circle
          cx="20"
          cy="20"
          r="20"
          transform="translate(0 0.1)"
          fill="#f47b20"
        />
        <path
          d="M21.8,12.4a9.635,9.635,0,0,0-5.3,2,35.472,35.472,0,0,1-4.7,3.2,23.578,23.578,0,0,1,.2-3.8A7.228,7.228,0,0,1,13.6,10a6.71,6.71,0,0,1,3.5-1.9,6.62,6.62,0,0,1,4.8.2c1.1.5,1.8,1.8,2.7,2.6a.52.52,0,0,0,.4.2.6.6,0,0,0,.4-.1c1.3-.5,2.6-1,4-1.4a8.559,8.559,0,0,1,4.2-.2c.2.1.4.1.5.3-.1-.2-1.8.1-2.1.1a15.985,15.985,0,0,0-2.1.5,28.255,28.255,0,0,0-4,1.7,14.723,14.723,0,0,0-2.2,1c-.7.4-1.4.7-2.1,1.1a13.933,13.933,0,0,0-1.9,1.5,31.3,31.3,0,0,1-5.4,4.2,36.269,36.269,0,0,0-9,6.5,5.5,5.5,0,0,0-.7.9c.2-.2-.1-1,.1-1.3a8.446,8.446,0,0,1,1.5-1.8c2.3-2.4,5.1-4.2,7.7-6.2,2-1.5,3.9-3.1,6-4.5.4-.2.7-.5,1.1-.7C21,12.9,21.5,12.4,21.8,12.4Z"
          fill="#fff"
        />
        <path
          d="M5.4,22.1a.6.6,0,0,1,.1-.4.215.215,0,0,1,.2-.2c.1,0,.1.2.2.3h.2a1.779,1.779,0,0,0,.8-.7c.6-1,1-2.4,2.2-3a2.356,2.356,0,0,1,2.3.2.471.471,0,0,1,.1.6.247.247,0,0,1-.4.1c-.7-.9-2.2.4-2.7.9-.7.7-.8,1.7-1.3,2.5a1,1,0,0,1-.5.4.714.714,0,0,1-1-.6C5.4,22.2,5.4,22.1,5.4,22.1Z"
          fill="#fff"
        />
        <path
          d="M19.8,13.1c-.1.1-.2.3-.4.4-1.2.9-2.5,1.8-3.7,2.7a23.07,23.07,0,0,1-1.9,1.4c-.3.2-.6.5-.9.7s-.5.6-.8.7c-.1.1-.2.2-.4.1a.3.3,0,0,1,0-.4c0-.1.1-.2.1-.3,0-.5.7-.7,1-.9a9.062,9.062,0,0,0,1.6-1.1,30.835,30.835,0,0,1,4.8-3.2c.1-.1.3-.1.4-.2C19.7,13.1,19.7,13.1,19.8,13.1Z"
          fill="#fff"
        />
        <path
          d="M9,20.8c-.3,0-.7-.1-.7-.4,0-.2,0-.2.2-.2a2.513,2.513,0,0,0,1.4-.6c.3-.2.5-.5.8-.6h.1a1.274,1.274,0,0,1-.5,1.1,2.286,2.286,0,0,1-1,.7Z"
          fill="#fff"
        />
        <path
          d="M34.7,10.6c-.2-.1-.5-.2-.7-.3-1.6-.3-3.3.4-4.8,1a16.293,16.293,0,0,1-3.1,1.1,2.01,2.01,0,0,0-.8.4c-.2.1-.2.1,0,.3l.3.3a1.95,1.95,0,0,1-.1,1.3c-.1.3-.2.3-.4,0,0-.1-.1-.1-.2-.2a2.651,2.651,0,0,1-.5-.6l-.2-.2a.31.31,0,0,1-.2-.1v.2a11.084,11.084,0,0,1,.9,1.9c0,.1.1.1.2,0l.1-.1a.52.52,0,0,1,.4-.2c.2,0,.2.2.2.3v1a9.852,9.852,0,0,0-.2,1.7,1.421,1.421,0,0,1-.2.6c0,.1-.2.2-.2.1-.3-.1-.3.1-.3.3a.9.9,0,0,1-.1.5,4.234,4.234,0,0,1-1.2,3c-.9.9-1.9,2-3.2,2.1a3.718,3.718,0,0,1-2.3-.5c-.7-.5-1.4-1-2.1-1.6a3.587,3.587,0,0,1-1-1.4,1.38,1.38,0,0,1-.3-.4v1.3a14.919,14.919,0,0,1-.2,2.1c0,.1-.3.6-.4.2a10.19,10.19,0,0,1,.1-3.9c0-.2,0-.2-.1-.2s-.2.1-.3.1A37.166,37.166,0,0,0,9,23.4a21.585,21.585,0,0,0-4.1,3.9A.784.784,0,0,0,5,28.4a6.353,6.353,0,0,0,3.3,1.8,10.925,10.925,0,0,0,3.6.2,36.316,36.316,0,0,0,4.5-1c.3-.1.3-.1.2-.3-.2-1.7-.3-3.4-.5-5v-.3c.1.1.1.1.1.2.3,1.1.5,2.3.7,3.5a16.367,16.367,0,0,1,.4,3.8,2.2,2.2,0,0,1-.9,2.1.1.1,0,0,1-.1.1h.1a.735.735,0,0,0,.6-.2,4.728,4.728,0,0,0,.7-1.2c.5-1.6-.2-3.3.6-4.8.6-1.3,1.8-1.4,2.9-2,.9-.5,1.4-1.5,2.2-2,.3-.2.4-.1.4.2v2.8a2.106,2.106,0,0,1-.5,1.3,3.287,3.287,0,0,0-.4,1.7,5.028,5.028,0,0,1,1.6-2.2c.3-.2.5-.4.8-.6a3.5,3.5,0,0,1,3-.2c.3.1,2.1,1.4,2,1.7.1-.1,0-.3,0-.4a3.087,3.087,0,0,0-1.8-1.9,3.591,3.591,0,0,0-2.7-.1c.1,0,.3-.4.4-.5a2.092,2.092,0,0,0,.6-.4c.5-.3.9-.7,1.4-1,.9-.7,1.8-1.5,2.7-2.3a18.64,18.64,0,0,0,4.3-5.7C35.6,14.4,36.5,11.7,34.7,10.6ZM25.7,23c0,.2-.1.4-.3.3-.1,0-.1-.1-.1-.2a6.135,6.135,0,0,1-.2-3.3c0-.1.1-.1.1-.2l.2.2a11.8,11.8,0,0,1,.4,2C25.7,21.8,25.8,22.5,25.7,23Z"
          fill="#fff"
        />
        <path
          d="M21.7,21.3h0c-.1-.1-.2-.1-.3-.2s-.2-.1-.3-.2a4.875,4.875,0,0,0-.7-.3c-.2,0-.5.1-.7.1s-.4-.1-.6-.1a1.421,1.421,0,0,0-.6.2.758.758,0,0,1-.4.2c-.1,0-.1.1-.2.1s-.1,0-.2.1.1.2.1.2a4.62,4.62,0,0,0,.7.7,1.777,1.777,0,0,0,1,.4,1.645,1.645,0,0,0,1-.2,1.756,1.756,0,0,0,.5-.4c.2-.1.3-.3.4-.4l.1-.1c.1.1.2,0,.2-.1Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default FashionIcon;
