import React from "react";

function GovernmentNewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3406"
        data-name="Group 3406"
        transform="translate(685 -2503)"
      >
        <rect
          id="Rectangle_1629"
          data-name="Rectangle 1629"
          width="36"
          height="36"
          rx="18"
          transform="translate(-685 2503)"
          fill="#ff7b16"
        />
        <path
          id="Icon_map-local-government"
          data-name="Icon map-local-government"
          d="M19.736,11.958v8.027h-6.02V18.379H8.5v1.605H2.88V11.958ZM16.1,10.495a4.6,4.6,0,0,0-1.569-2.569A8.909,8.909,0,0,0,11.714,6.74h0V4.2l.21-.057.46-.171a4.423,4.423,0,0,1,.5-.145,1.651,1.651,0,0,1,.355-.055,2.749,2.749,0,0,1,.567.073c.228.055.471.115.718.183s.487.137.71.187a2.434,2.434,0,0,0,.537.069,3.883,3.883,0,0,0,.755-.1V1.274a2.749,2.749,0,0,1-.755.148,2.25,2.25,0,0,1-.542-.076c-.222-.043-.451-.109-.708-.183q-.344-.1-.7-.179a2.138,2.138,0,0,0-.514-.077,3.756,3.756,0,0,0-1.6.344V.72h-.8V6.74h-.025A9,9,0,0,0,8.063,7.949,4.648,4.648,0,0,0,6.49,10.461a.653.653,0,0,0-.769.694H16.947c0-.8-.843-.66-.843-.66Z"
          transform="translate(-677.882 2510.421)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default GovernmentNewIcon;
