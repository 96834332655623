import React from "react";

function BusinessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3407"
        data-name="Group 3407"
        transform="translate(745 -2503)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="36"
          height="36"
          rx="18"
          transform="translate(-745 2503)"
          fill="#ff7b16"
        />
        <path
          id="Icon_material-business-center"
          data-name="Icon material-business-center"
          d="M10.541,16.754v-.943H3.952l-.009,3.771a1.879,1.879,0,0,0,1.885,1.885h13.2a1.879,1.879,0,0,0,1.885-1.885V15.812h-6.6v.943Zm9.426-8.484h-3.78V6.385L14.3,4.5H10.532L8.646,6.385V8.271H4.885A1.891,1.891,0,0,0,3,10.156v2.828a1.879,1.879,0,0,0,1.885,1.885h5.656V12.984h3.771v1.885h5.656a1.891,1.891,0,0,0,1.885-1.885V10.156A1.891,1.891,0,0,0,19.967,8.271Zm-5.656,0H10.541V6.385h3.771Z"
          transform="translate(-739.094 2508.315)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default BusinessIcon;
