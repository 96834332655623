import React from "react";

function NativeLinguistTwoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M29.5,17.2a3.785,3.785,0,0,0,4-3.8,3.736,3.736,0,0,0-3.8-3.8,3.787,3.787,0,0,0-4,3.8A3.622,3.622,0,0,0,29.5,17.2Z"
          fill="#fff"
        />
        <path
          d="M26.6,18a16.657,16.657,0,0,1,.7,3.5c.1,2.1.1,4.1,0,6.2,0,.4-.1.8-.1,1.3h8a6.468,6.468,0,0,0,.4-1.3c.1-2,0-4,0-6-.1-1.5-1.1-3.1-2.5-3.3C30.8,18,28.7,18.2,26.6,18Z"
          fill="#fff"
        />
        <path
          d="M20.2,16.6a4.2,4.2,0,1,0-4.3-4.2A4.247,4.247,0,0,0,20.2,16.6Z"
          fill="#fff"
        />
        <path
          d="M4.7,29h7.9c0-.2-.1-.4-.1-.6,0-3.5-.8-6.9.7-10.2-1.9,0-3.8-.1-5.7,0a3.446,3.446,0,0,0-3.2,3.6c0,1.9-.1,3.8,0,5.7A11.311,11.311,0,0,0,4.7,29Z"
          fill="#fff"
        />
        <path
          d="M10.7,17.2a3.654,3.654,0,0,0,3.7-3.8,3.9,3.9,0,1,0-7.8-.2A3.752,3.752,0,0,0,10.7,17.2Z"
          fill="#fff"
        />
        <path
          d="M13.4,27.4a4.013,4.013,0,0,0,.3,1.5H26.2a3.553,3.553,0,0,0,.2-1,52.973,52.973,0,0,0,.1-6.9c0-2.3-1.7-3.7-4-3.7h-5a4.033,4.033,0,0,0-4.1,4C13.4,23.3,13.4,25.5,13.4,27.4Z"
          fill="#fff"
        />
        <path
          d="M20.4,24.5l-1.1-1.1h0a7.847,7.847,0,0,0,1.6-2.8h1.3v-.9h-3v-.9h-.9v.9h-3v.9h4.8a5.935,5.935,0,0,1-1.4,2.3,5.6,5.6,0,0,1-1-1.5h-.9a8.577,8.577,0,0,0,1.3,2l-2.2,2.2.6.6L18.7,24l1.4,1.4.3-.9Zm2.5-2.2H22l-2,5.2h.9l.5-1.3h2.1l.5,1.3h.9Zm-1.2,3,.7-1.9.7,1.9Z"
          fill="#f47b20"
        />
      </g>
    </svg>
  );
}
export default NativeLinguistTwoIcon;
