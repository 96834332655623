import React from "react";

function ContentExtractionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(-0.1)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0.1 0)"
          fill="#f47b20"
        />
        <path
          d="M13.3,13.4a.4.4,0,1,1,.4-.4.43.43,0,0,1-.4.4Zm-1.9,0a.4.4,0,1,1,.4-.4.43.43,0,0,1-.4.4Zm-1.9,0a.4.4,0,1,1,.4-.4c.1.2-.1.4-.4.4Zm21.7-2.6H8.8a1.11,1.11,0,0,0-1.1,1.1V15H32.4V11.9a1.2,1.2,0,0,0-1.2-1.1Z"
          fill="#fff"
        />
        <path d="M15.1,18.6h-5v7.5h5Z" fill="#fff" />
        <path
          d="M9.2,18a.43.43,0,0,1,.4-.4h6.1a.43.43,0,0,1,.4.4v8.6a.43.43,0,0,1-.4.4H9.5a.43.43,0,0,1-.4-.4V18Zm9.1,7.3h5a.43.43,0,0,1,.4.4v.1c-.1.2-.2.3-.4.3h-5a.4.4,0,1,1,0-.8Zm0-2.3H30.4a.4.4,0,0,1,0,.8H18.3a.4.4,0,1,1,0-.8Zm0-2.2h3a.43.43,0,0,1,.4.4.354.354,0,0,1-.4.4h-3a.4.4,0,1,1,0-.8Zm12.5.4a.43.43,0,0,1-.4.4H24.2a.43.43,0,0,1-.4-.4.354.354,0,0,1,.4-.4h6.2a.43.43,0,0,1,.4.4ZM18.3,18.5H30.4a.4.4,0,0,1,0,.8H18.3a.4.4,0,1,1,0-.8ZM7.7,27.8a1.11,1.11,0,0,0,1.1,1.1H21.2a1.05,1.05,0,0,1,.9.5L24.2,33a1.122,1.122,0,0,0,1.9,0l2.1-3.6a1.05,1.05,0,0,1,.9-.5h2.2a1.11,1.11,0,0,0,1.1-1.1V15.9H7.7V27.8Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default ContentExtractionIcon;
