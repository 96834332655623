import React from "react";

function PowerAndEnergyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#f47b20" />
      <path
        d="M35.4,13a1.1,1.1,0,1,0-2.2,0c0,.2,0,.3.1.4a1.3,1.3,0,0,0-1,1.3,1.3,1.3,0,1,0,2.6,0,1.884,1.884,0,0,0-.2-.8A.856.856,0,0,0,35.4,13Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        d="M9.1,16.7a.6.6,0,1,0-.6-.6A.645.645,0,0,0,9.1,16.7Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        d="M24.5,7.2a1.3,1.3,0,0,0,0-2.6A1.415,1.415,0,0,0,23.2,6,1.24,1.24,0,0,0,24.5,7.2Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        d="M8.6,30.9V18.7H8.5a.6.6,0,0,0-.1-.4.835.835,0,0,0-.3-1.6c-.2,0-.3.1-.5.1a.764.764,0,0,0-.5-.2.684.684,0,0,0-.7.7v.2H6.3a.684.684,0,0,0-.7.7.6.6,0,0,0,.1.4H5.5V32.3H8.7V30.9Z"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        d="M30.5,20v-.2A1.806,1.806,0,0,0,31.9,17v-.3a1.678,1.678,0,0,0-.6-1.4,1.3,1.3,0,0,0,.8-1.2,1.3,1.3,0,1,0-2.6,0v.1a1.159,1.159,0,0,0-1-.4,1.424,1.424,0,0,0-1.3.9,1.483,1.483,0,0,0-.7-.1,2.006,2.006,0,0,0-2,2V17h-.1a2.036,2.036,0,0,0-1.7,3.1h-.4a47.152,47.152,0,0,1-.7,9.9c-1-5-1-11.6-1-15.9h-.9v-.3a1.568,1.568,0,0,0,.9-1.4v-.2a2.342,2.342,0,0,0,.8-1.8,2.413,2.413,0,0,0-.7-1.7,1.5,1.5,0,0,0,1-1.4,1.5,1.5,0,0,0-3,0v.1a1.606,1.606,0,0,0-1.1-.5A1.625,1.625,0,0,0,16.1,8a1.949,1.949,0,0,0-.8-.1A2.263,2.263,0,0,0,13,10.2a.749.749,0,0,0,.1.5h-.2A2.263,2.263,0,0,0,10.6,13a2.016,2.016,0,0,0,.3,1.1h-.6c0,5.1,0,13.3-1.6,18.4H32.3C31,28.4,31,24.3,31,20.1A.749.749,0,0,0,30.5,20Z"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
}
export default PowerAndEnergyIcon;
