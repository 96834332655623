import React from "react";
import BoxContainer from "components/BoxContainer";
import Text from "components/Text";
import Button from "../Button/index";
import imageResolver from "utils/image-resolver";

function IsoCertified({ data, background = "bg-white" }) {
  return (
    <BoxContainer background={background} className="pt-10 pb-24 px-5 lg:px-0">
      <div className="max-w-5xl mx-auto px-5 xl:px-0">
        <Text
          as="h2"
          highlightedText={data.highlightWords}
          highlightedColor={data.highlightedColor}
          className="text-4xl2 text-midBlue font-secondary font-bold text-center mb-6"
        >
          {data.title}
        </Text>
        <Text
          as="div"
          className="text-lg leading-8 text-midBlue font-opensans text-center"
        >
          {data.description}
        </Text>
        <Button
          title="Learn more"
          href="/iso-certifications"
          className="w-[218px] h-[53px] text-lg text-white text-center uppercase font-opensans font-medium bg-yellow rounded-full cursor-pointer flex items-center justify-center mx-auto mt-7 mb-12 hover:bg-newOrange-100 transition-colors"
        />
        <div className="max-w-4xl mx-auto mt-12">
          <div className="flex flex-col lg:flex-row gap-6 lg:gap-[118px]">
            {data.certificates.map(
              ({ title, icon, className, description }, i) => {
                return (
                  <div key={i} className="flex flex-col items-center">
                    {imageResolver(title, icon, className)}
                    <p className="text-lg leading-[22px] text-lightBluegray font-opensans font-semibold w-56 text-center">
                      {title}
                      <span className="block font-bold text-center">
                        {description}
                      </span>
                    </p>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </BoxContainer>
  );
}

export default IsoCertified;
