import * as React from "react";
import BoxContainer from "components/BoxContainer";
import Text from "components/Text";
import { Link } from "gatsby";

const TranslationServiceGrid = ({ data }) => {
  return (
    <BoxContainer background="bg-white" className="items-center py-16">
      <div className="text-midBlue md:w-10/12 m-auto text-center sm:text-left 2xl:w-full 2xl:max-w-6xl">
        <Text
          highlightedColor={data?.highlightedColor}
          highlightedText={data?.highlightWords}
          as="h2"
        >
          {data?.title}
        </Text>
        <Text
          className="text-sm2 font-opensans text-midBlue text-center sm:text-left"
          as="div"
        >
          {data?.subtitle}
        </Text>
      </div>
      <ul className="list-none gap-1 justify-center grid md:grid-cols-3 2xl:gap-x-4 w-full md:w-10/12 2xl:w-full max-w-6xl m-auto mt-10 pb-5">
        {data?.clients.length > 0
          ? data.clients.map((item, i) => (
              <>
                {item.link ? (
                  <li key={i} className="flex gap-3 items-center">
                    <img
                      src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                      alt="check"
                      className="w-6 h-5"
                    />
                    <Link
                      to={item.link}
                      className="text-sm2 text-midBlue font-opensans cursor-pointer hover:text-newOrange-100 transition-colors"
                    >
                      {item.title}
                    </Link>
                  </li>
                ) : (
                  <li className="text-sm2 text-midBlue font-opensans flex gap-3 items-center">
                    <img
                      src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                      alt="check"
                      className="w-6 h-5"
                    />
                    <span>{item.title}</span>
                  </li>
                )}
              </>
            ))
          : ""}
      </ul>
    </BoxContainer>
  );
};

export default TranslationServiceGrid;
