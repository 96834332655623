import React from "react";

function HealthcareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M19.1,32.1l1.5,1.5L32,22.2h0A7.509,7.509,0,0,0,34,17c0-4-3-7.3-6.7-7.3S20.6,13,20.6,17h0c0-4-3-7.3-6.7-7.3S7.3,13,7.3,17"
          fill="#f47b20"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="1.242"
        />
        <path
          d="M19.7,27.7c-1,2.3-4.6,5.8-7,5.8-2.3,0-6.1-3.3-7-5.4s-.4-8.9-.4-8.9,3.7.8,7.4-2.8h0c3.7,3.6,7.4,2.7,7.4,2.7S20.6,25.7,19.7,27.7Zm-.8-7.2s-3,.5-6.2-2.5h0c-3.1,3-6.2,2.4-6.2,2.4s-.4,5.6.3,7.4c.8,1.8,3.9,4.6,5.9,4.5,2,0,5.1-3,5.9-4.8C19.3,25.8,18.9,20.5,18.9,20.5Zm-3,4.3H13.4V22.3a.7.7,0,1,0-1.4,0v2.5H9.5a.7.7,0,0,0,0,1.4H12v2.5a.7.7,0,1,0,1.4,0V26.2h2.5a.684.684,0,0,0,.7-.7A.842.842,0,0,0,15.9,24.8Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default HealthcareIcon;
