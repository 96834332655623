import React from "react";

function DtpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M12.8,33.7a.367.367,0,0,1-.3-.1c-.1-.1-.2-.3-.1-.4l.9-3.4c.1-.2.3-.4.5-.3s.4.3.3.5l-.7,2.7,2.7-.7a.412.412,0,1,1,.2.8l-3.4.9Z"
          fill="#fff"
        />
        <path
          d="M29.6,19.3a.367.367,0,0,1-.3-.1c-.2-.2-.2-.5,0-.6l1.2-1.2c.1-.1.1-.6-.6-1.2-.7-.7-1.1-.7-1.2-.6l-1.2,1.2c-.2.2-.5.2-.6,0-.2-.2-.2-.5,0-.6L28.1,15s1-.9,2.5.6.7,2.4.6,2.5L30,19.3Zm.9-2Zm-1.8-1.8Z"
          fill="#fff"
        />
        <path
          d="M17.8,31.1a.367.367,0,0,1-.3-.1c-.2-.2-.2-.5,0-.6L25,22.9c.2-.2.5-.2.6,0,.2.2.2.5,0,.6L18.1,31C18.1,31.1,17.9,31.1,17.8,31.1Z"
          fill="#fff"
        />
        <path
          d="M25.3,23.6a.367.367,0,0,1-.3-.1c-.2-.2-.2-.5,0-.6L28.9,19l-1.8-2-3.9,4c-.2.2-.5.2-.6,0-.2-.2-.2-.5,0-.6l4.2-4.2c.1-.2.2-.2.3-.2a.367.367,0,0,1,.3.1l2.5,2.5c.2.2.2.5,0,.6l-4.3,4.3C25.6,23.6,25.4,23.6,25.3,23.6Z"
          fill="#fff"
        />
        <path
          d="M16.7,27.2a.367.367,0,0,1-.3-.1c-.2-.2-.2-.5,0-.6l6.1-6.1c.2-.2.5-.2.6,0,.2.2.2.5,0,.6L17,27.1C17,27.2,16.9,27.2,16.7,27.2Z"
          fill="#fff"
        />
        <path
          d="M16.2,32.8a.367.367,0,0,1-.3-.1l-2.5-2.5c-.2-.2-.2-.5,0-.6l1.7-1.7c.2-.2.5-.2.6,0,.2.2.2.5,0,.6l-1.3,1.3,1.8,1.8,1.3-1.3c.2-.2.5-.2.6,0,.2.2.2.5,0,.6l-1.7,1.7A.215.215,0,0,1,16.2,32.8Z"
          fill="#fff"
        />
        <path
          d="M15.4,28.6a.367.367,0,0,1-.3-.1L13,26.4a5.919,5.919,0,0,1,0-8.2,5.664,5.664,0,0,1,4.1-1.7h0a5.835,5.835,0,0,1,4.1,1.7l.8.9c.2.2.2.5,0,.6-.2.2-.5.2-.6,0l-.8-.8a4.829,4.829,0,0,0-6.8,0,4.889,4.889,0,0,0,0,6.9l2.1,2.1c.2.2.2.5,0,.6A1.338,1.338,0,0,1,15.4,28.6Z"
          fill="#fff"
        />
        <path
          d="M21.7,32.8A5.884,5.884,0,0,1,18,31.4h0l-.5-.4c-.2-.2-.2-.5,0-.6.2-.2.5-.2.6,0l.4.4a4.9,4.9,0,0,0,6.9-6.9l-.4-.4c-.2-.2-.2-.5,0-.6.2-.2.5-.2.6,0l.4.4h0a6.282,6.282,0,0,1,1.4,4,6.311,6.311,0,0,1-1.7,3.9A5.946,5.946,0,0,1,21.7,32.8Z"
          fill="#fff"
        />
        <path
          d="M28.3,20.6a.367.367,0,0,1-.3-.1l-2.2-2.2c-.2-.2-.2-.5,0-.6.2-.2.5-.2.6,0l2.2,2.2c.2.2.2.5,0,.6A.438.438,0,0,1,28.3,20.6Z"
          fill="#fff"
        />
        <path
          d="M10.6,16.3a.367.367,0,0,1-.3-.1,3.8,3.8,0,0,1,0-5.4A3.864,3.864,0,0,1,13,9.7a4.049,4.049,0,0,1,2.7,1.1c.2.2.2.5,0,.6-.2.2-.5.2-.6,0a2.973,2.973,0,0,0-2-.8,2.792,2.792,0,0,0-2,.8,2.959,2.959,0,0,0,0,4.1c.2.2.2.5,0,.6C10.8,16.2,10.7,16.3,10.6,16.3Z"
          fill="#fff"
        />
        <path
          d="M18.2,13.6a2.5,2.5,0,0,1-1.8-.8L15,11.4c-.2-.2-.2-.5,0-.6.2-.2.5-.2.6,0L17,12.2a1.656,1.656,0,0,0,1.2.5,2.106,2.106,0,0,0,1.3-.5,1.751,1.751,0,0,0,0-2.5L16.4,6.6c-.2-.2-.2-.5,0-.6.2-.2.5-.2.6,0l3.1,3.1a2.657,2.657,0,0,1,0,3.7A2.732,2.732,0,0,1,18.2,13.6Z"
          fill="#fff"
        />
        <path
          d="M17.6,23.3a.367.367,0,0,1-.3-.1l-6.9-6.9c-.2-.2-.2-.5,0-.6.2-.2.5-.2.6,0l6.9,6.9c.2.2.2.5,0,.6A.367.367,0,0,1,17.6,23.3Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default DtpIcon;
