import React from "react";

function ChemicalsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#f47b20" />
      <path
        d="M29.3,30.2,23.6,18.5V10.6h.1a.7.7,0,1,0,0-1.4H16.2a.7.7,0,1,0,0,1.4h.1v7.9L10.6,30.2a3.12,3.12,0,0,0,.2,3.1,3.282,3.282,0,0,0,2.7,1.5H26.4a3.282,3.282,0,0,0,2.7-1.5A3.12,3.12,0,0,0,29.3,30.2Zm-1.4,2.3a1.763,1.763,0,0,1-1.5.8H13.6a1.9,1.9,0,0,1-1.5-.8,2.007,2.007,0,0,1-.1-1.7l5.8-12V10.5h4.3v8.3l5.8,12A1.505,1.505,0,0,1,27.9,32.5Zm-.5-1.3-3.7-7.7c-3-1.6-4.9,1.8-7.3,0l-3.7,7.7a1.167,1.167,0,0,0,0,1,1.05,1.05,0,0,0,.9.5H26.5a1.05,1.05,0,0,0,.9-.5A1.7,1.7,0,0,0,27.4,31.2Zm-9.6-2.7a.7.7,0,1,1,.7-.7A.684.684,0,0,1,17.8,28.5Zm2.9,2.7a.5.5,0,1,1,.5-.5A.472.472,0,0,1,20.7,31.2Zm1.1-4.4a1,1,0,0,1-1-1,.945.945,0,0,1,1-1,1,1,0,0,1,0,2ZM21.3,7.7a.5.5,0,1,0,.5.5A.472.472,0,0,0,21.3,7.7Zm-.1.4A.349.349,0,0,1,21,8a.1.1,0,0,1,.2,0C21.3,8,21.2,8.1,21.2,8.1ZM18.4,5a.7.7,0,1,0,.7.7C19.2,5.3,18.9,5,18.4,5Zm-.2.6a.2.2,0,1,1,.2-.2A.215.215,0,0,1,18.2,5.6Zm3.1-3.5a1,1,0,0,0-1,1,.945.945,0,0,0,1,1,1,1,0,0,0,1-1A.945.945,0,0,0,21.3,2.1Zm-.3.8a.215.215,0,0,1-.2-.2.2.2,0,0,1,.4,0C21.3,2.8,21.2,2.9,21,2.9Z"
        fill="#fff"
      />
    </svg>
  );
}
export default ChemicalsIcon;
