import React from "react";

function SampleSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 43.303 48.654"
    >
      <defs></defs>
      <g transform="translate(-25.274)">
        <g transform="translate(25.274)">
          <path
            fill="#FF7B16"
            className="a"
            d="M60.658,282.093a10.581,10.581,0,0,1-8.565,4.37H47.159a3.943,3.943,0,0,1-3.706-2.6,12.514,12.514,0,0,1-1.993-.788,13.422,13.422,0,0,1-3.722-2.755A15.893,15.893,0,0,0,25.274,295.84v.993a2.472,2.472,0,0,0,2.472,2.472H66.1a2.472,2.472,0,0,0,2.472-2.472v-.993A15.882,15.882,0,0,0,60.658,282.093Z"
            transform="translate(-25.274 -250.652)"
          />
          <path
            fill="#FF7B16"
            className="a"
            d="M94.423,24.575a2.814,2.814,0,0,0,2.467-1.458l.038.1.011.031a10.987,10.987,0,0,0,6.2,6.523,3.938,3.938,0,0,1,3.337-1.845h4.934a2.762,2.762,0,0,0,1.352-.363,5.577,5.577,0,0,0,1.594-1.841,14.229,14.229,0,0,0,1.234-2.608,2.829,2.829,0,0,0,.778.9v1.21a4.964,4.964,0,0,1-4.958,4.958h-4.934a1.689,1.689,0,1,0,0,3.378h4.934a8.346,8.346,0,0,0,8.336-8.336v-1.21a2.811,2.811,0,0,0,1.126-2.252V14.714a2.811,2.811,0,0,0-1.169-2.283,13.5,13.5,0,0,0-26.923,0,2.811,2.811,0,0,0-1.169,2.283V21.76A2.816,2.816,0,0,0,94.423,24.575Zm11.815-21.2A10.14,10.14,0,0,1,116.315,12.5a2.825,2.825,0,0,0-.791.978c-1.594-4.158-5.152-7.053-9.285-7.053-4.223,0-7.724,2.995-9.279,7.037l-.006.017a2.825,2.825,0,0,0-.791-.978A10.141,10.141,0,0,1,106.238,3.378Z"
            transform="translate(-84.587)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SampleSvg;
