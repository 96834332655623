import React from "react";

function AllInOneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(-0.1)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0.1 0)"
          fill="#f47b20"
        />
        <path
          d="M14.7,31.6l-.3-.6h0l-3.1-5.6h0L11,25a.54.54,0,0,0-.8-.2L7.7,26.2,12,33.9l2.5-1.4a1.38,1.38,0,0,0,.3-.4h0A.9.9,0,0,0,14.7,31.6Z"
          fill="#fff"
        />
        <path
          d="M31.7,24.9l-.2-.4a1.38,1.38,0,0,0-.3-.4h0a.764.764,0,0,0-.5-.2c-.1,0-.3-.1-.4-.1h0a1.854,1.854,0,0,0-.7.2l-2.8,1.6-1.3.8h0v-.1h0l.1-.1h0a4.169,4.169,0,0,0,.2-.6,1.469,1.469,0,0,0-.3-1.3,1.193,1.193,0,0,0-.9-.5h0l-7.2-.9a2.492,2.492,0,0,0-1.7.3l-2.9,1.7-.4.3-.5.3,3.2,5.7.1-.1h0c.3-.1.5-.2.8-.3a1.387,1.387,0,0,1,.9,0l1.1.1,1.4.2,3,.4h.3a2.3,2.3,0,0,0,1.4-.4l2.1-1.2h0l1.2-.7h0l1.2-.7.9-.5h0l1.6-.9a.1.1,0,0,0,.1-.1h0l.1-.1h0a1.612,1.612,0,0,0,.4-.7h0A1.446,1.446,0,0,0,31.7,24.9Z"
          fill="#fff"
        />
        <path d="M17.8,20.2,19.1,12H17.5l.6-2.2h4L20.5,20.2Z" fill="#fff" />
        <path
          d="M26.5,11.6a.367.367,0,0,0,.3-.1l4.1-1.6a.7.7,0,0,0,.4-1,.7.7,0,0,0-1-.4l-4.1,1.6a.7.7,0,0,0-.4,1A.752.752,0,0,0,26.5,11.6Z"
          fill="#fff"
        />
        <path
          d="M32.1,14.6l-4.4-.1a.849.849,0,0,0-.8.7.752.752,0,0,0,.5.7c.1,0,.2.1.3.1l4.4.1a.849.849,0,0,0,.8-.7A.789.789,0,0,0,32.1,14.6Z"
          fill="#fff"
        />
        <path
          d="M30.3,20.9l-4-1.9a.731.731,0,0,0-1,.4.755.755,0,0,0,.1.8l.2.2,4,1.9a.731.731,0,0,0,1-.4A.693.693,0,0,0,30.3,20.9Z"
          fill="#fff"
        />
        <path
          d="M13.4,11.7h-.2L9.5,10.2A.715.715,0,0,1,9,9.3.687.687,0,0,1,9.9,9l3.7,1.5a.751.751,0,0,1,.4.9A.6.6,0,0,1,13.4,11.7Z"
          fill="#fff"
        />
        <path
          d="M8.3,14.5l4-.1a.684.684,0,0,1,.7.7.675.675,0,0,1-.4.6c-.1,0-.2,0-.2.1l-4,.1a.684.684,0,0,1-.7-.7A.563.563,0,0,1,8.3,14.5Z"
          fill="#fff"
        />
        <path
          d="M9.9,20.3l3.6-1.7a.687.687,0,0,1,.9.3.755.755,0,0,1-.1.8c-.1.1-.1.1-.2.1l-3.6,1.7a.687.687,0,0,1-.9-.3C9.5,20.8,9.6,20.4,9.9,20.3Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default AllInOneIcon;
