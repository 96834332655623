import React from "react";

function BankingFinanceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#f47b20" />
      <path
        d="M20,10.8a1,1,0,1,1,1-1,1.075,1.075,0,0,1-1,1Zm0-3.1a2.051,2.051,0,0,0-2.1,2.1,2.1,2.1,0,0,0,4.2,0A2.051,2.051,0,0,0,20,7.7Z"
        fill="#fff"
      />
      <path
        d="M8.7,28.9V27.5H31.4v1.4Zm24,1.1v.8H7.3V30H32.7ZM8.5,13.2Zm.1,0L20,5.4l11.4,7.7-22.8.1Zm1.7,12.1c.1,0,.1-.1.2-.1a.758.758,0,0,0,.2-.4h0V16a.55.55,0,0,0-.3-.5V14.4h3v1.1a.55.55,0,0,0-.3.5v8.8h0a.4.4,0,0,0,.2.4.349.349,0,0,0,.2.1v.9h-3v-.9Zm3.8-.9V16.3a.908.908,0,0,0,.3-.7V14.3H25.6v1.3a.908.908,0,0,0,.3.7v8.1a.908.908,0,0,0-.3.7v1.2H14.4V25.1a.908.908,0,0,0-.3-.7Zm12.7.9c.1,0,.1-.1.2-.1a.758.758,0,0,0,.2-.4h0V16a.55.55,0,0,0-.3-.5V14.4h3v1.1a.55.55,0,0,0-.3.5v8.8h0a.4.4,0,0,0,.2.4.349.349,0,0,0,.2.1v.9H27l-.2-.9Zm6,3.6h-.2V27.5a1.11,1.11,0,0,0-1.1-1.1h-.6V25.2a.908.908,0,0,0-.3-.7V16.4a.908.908,0,0,0,.3-.7V14.4h.7a1.17,1.17,0,0,0,1.1-.8,1.04,1.04,0,0,0-.4-1.2l-11.7-8a.91.91,0,0,0-1.2,0L7.8,12.3a1,1,0,0,0-.4,1.2,1.084,1.084,0,0,0,1.1.8h.7v1.3a.908.908,0,0,0,.3.7v8.1a.908.908,0,0,0-.3.7v1.2H8.7a1.11,1.11,0,0,0-1.1,1.1v1.4H7.3a1.11,1.11,0,0,0-1.1,1.1v.8a1.11,1.11,0,0,0,1.1,1.1H32.8a1.11,1.11,0,0,0,1.1-1.1V30a1.11,1.11,0,0,0-1.1-1.1Z"
        fill="#fff"
      />
      <path
        d="M20.5,20.9v-.6l.1.1c.1.1.1.1.1.2-.1.1-.1.2-.2.3Zm-1.7.6a1.38,1.38,0,0,0,.4.3c.1.1.2.1.4.1h0a.5.5,0,0,0,1,0h0c.1,0,.2-.1.3-.1.1-.1.3-.2.4-.3s.2-.3.3-.4a1.268,1.268,0,0,0,.1-.6.9.9,0,0,0-.1-.5,1.768,1.768,0,0,0-.7-.7c-.1-.1-.2-.1-.4-.2v-.5c0,.1,0,.1.1.1a.367.367,0,0,0,.3.1c.1,0,.3,0,.3-.1s.1-.2.1-.4a.6.6,0,0,0-.1-.4c-.1-.1-.1-.2-.2-.3s-.2-.2-.3-.2-.2-.1-.3-.1c0-.3-.2-.4-.5-.4s-.4.2-.4.5c-.1,0-.2.1-.3.1l-.3.3a.52.52,0,0,0-.2.4,4.331,4.331,0,0,0-.1.5.9.9,0,0,0,.1.5.758.758,0,0,0,.2.4l.3.3c.1.1.2.1.3.2v1.1c-.1-.1-.2-.2-.2-.3-.1-.2-.2-.4-.5-.4a.367.367,0,0,0-.3.1.52.52,0,0,0-.2.4.6.6,0,0,0,.1.4c.2-.1.3,0,.4.1Z"
        fill="#fff"
      />
      <path
        d="M17.6,17.2h0a3.25,3.25,0,0,1,2.4-1,3.492,3.492,0,0,1,2.4,1,3.25,3.25,0,0,1,1,2.4,3.492,3.492,0,0,1-1,2.4A3.25,3.25,0,0,1,20,23a3.492,3.492,0,0,1-2.4-1,3.25,3.25,0,0,1-1-2.4,2.913,2.913,0,0,1,1-2.4ZM20,24.3A5.069,5.069,0,0,0,23.3,23a4.446,4.446,0,0,0,1.3-3.3,5.069,5.069,0,0,0-1.3-3.3A4.446,4.446,0,0,0,20,15.1a5.069,5.069,0,0,0-3.3,1.3,4.446,4.446,0,0,0-1.3,3.3A4.872,4.872,0,0,0,16.7,23,5.069,5.069,0,0,0,20,24.3Z"
        fill="#fff"
      />
    </svg>
  );
}
export default BankingFinanceIcon;
