import React from "react";

function AcademicIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M18.9,11.2l15.6,6.3-15.6,7L2.8,17.8Z"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.717"
        />
        <path
          d="M28.5,20.2v6.2s-1.8,4.8-9.8,4.8-9.4-4.7-9.4-4.7V20.9"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.717"
        />
        <path
          d="M31.5,23.8,30,28.3h3.4Z"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.226"
        />
        <line
          y2="5.3"
          transform="translate(31.5 18.9)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.226"
        />
      </g>
    </svg>
  );
}
export default AcademicIcon;
