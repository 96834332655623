import React from "react";

function OnsiteCourtIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M6.9,13.1,17.6,7.4l10.7,5.7c.1,0,.2.1.3.1a.55.55,0,0,0,.5-.3.778.778,0,0,0-.2-.8L18,6.2a.446.446,0,0,0-.5,0L6.4,12a.524.524,0,0,0-.2.8A.517.517,0,0,0,6.9,13.1Z"
          fill="#fff"
        />
        <path
          d="M28.7,14.9a.645.645,0,0,0-.6-.6H7.2a.6.6,0,0,0,0,1.2H28.1A.645.645,0,0,0,28.7,14.9Z"
          fill="#fff"
        />
        <path
          d="M33.1,27.6l-1.9-3.8c.5-.3.9-.6,1-1,.1-.3-.1-.6-.5-.7a.569.569,0,0,0-.7.4,1.684,1.684,0,0,1-1.2.5,3.488,3.488,0,0,1-2.3-.8,4.442,4.442,0,0,0-2.3-.9v-.6a.6.6,0,0,0-1.2,0v.6a4.992,4.992,0,0,0-2.3.9,3.488,3.488,0,0,1-2.3.8,1.684,1.684,0,0,1-1.2-.5.67.67,0,0,0-.7-.4.752.752,0,0,0-.5.7c.1.4.5.7,1,1l-1.9,3.8h-.8v.8a3.591,3.591,0,0,0,3.8,3.3,3.591,3.591,0,0,0,3.8-3.3v-.8h-.8l-1.8-3.5a5.116,5.116,0,0,0,2.1-.9,3.8,3.8,0,0,1,1.7-.7V32.6a6.713,6.713,0,0,1-2.3.3.6.6,0,1,0,0,1.2,6.777,6.777,0,0,0,2.9-.5,7.544,7.544,0,0,0,2.8.4.6.6,0,1,0,0-1.2,6.713,6.713,0,0,1-2.3-.3v-10a3.8,3.8,0,0,1,1.7.7,3.726,3.726,0,0,0,2.1.9l-1.8,3.5h-.8v.8a3.591,3.591,0,0,0,3.8,3.3A3.591,3.591,0,0,0,34,28.4v-.8Zm-14,2.9a2.607,2.607,0,0,1-2.6-1.7h5.1A2.293,2.293,0,0,1,19.1,30.5Zm-1.6-2.9,1.7-3.3,1.7,3.3Zm12.6-3.3,1.7,3.3H28.5Zm.1,6.2a2.607,2.607,0,0,1-2.6-1.7h5.1A2.446,2.446,0,0,1,30.2,30.5Z"
          fill="#fff"
        />
        <path
          d="M20,11.4a2.3,2.3,0,1,0-2.3,2.3A2.263,2.263,0,0,0,20,11.4Zm-3.5,0a1.216,1.216,0,0,1,1.2-1.2,1.155,1.155,0,0,1,1.2,1.2,1.2,1.2,0,0,1-2.4,0Z"
          fill="#fff"
        />
        <path
          d="M14.2,28.2h-7a.6.6,0,0,0,0,1.2h7a.6.6,0,0,0,0-1.2Z"
          fill="#fff"
        />
        <path
          d="M13.9,17.4l.3-.5a.6.6,0,0,0-1.1-.5l-.3.5a.55.55,0,0,1-.5.3H9.7a.55.55,0,0,1-.5-.3l-.3-.5a.6.6,0,0,0-1.1.5l.3.5a1.765,1.765,0,0,0,.9.8v7.3c-.4.2-.7.4-.8.8l-.3.5a.67.67,0,0,0,.3.8.67.67,0,0,0,.8-.3l.3-.5a.55.55,0,0,1,.5-.3h2.6a.55.55,0,0,1,.5.3l.3.5a.55.55,0,0,0,.5.3.367.367,0,0,0,.3-.1.63.63,0,0,0,.3-.8l-.3-.5a2.047,2.047,0,0,0-.8-.8V18.1A1.542,1.542,0,0,0,13.9,17.4Zm-2,1v7H10.2v-7Z"
          fill="#fff"
        />
        <path
          d="M15.9,18.2v3.1h1.2V18.4h1.7v2.9H20V18.2c.4-.2.7-.4.8-.8l.3-.5a.6.6,0,1,0-1.1-.5l-.3.5a.55.55,0,0,1-.5.3H16.6a.55.55,0,0,1-.5-.3l-.3-.5a.6.6,0,0,0-1.1.5l.3.5A3.24,3.24,0,0,0,15.9,18.2Z"
          fill="#fff"
        />
        <path
          d="M27,20.1V18.2c.4-.2.7-.4.8-.8l.3-.5a.6.6,0,1,0-1.1-.5l-.3.5a.55.55,0,0,1-.5.3H23.6a.55.55,0,0,1-.5-.3l-.3-.5a.6.6,0,1,0-1.1.5l.3.5a2.047,2.047,0,0,0,.8.8v1.3H24V18.3h1.7V20H27Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default OnsiteCourtIcon;
