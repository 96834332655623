import React from "react";

function MedicalReportsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.3)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.3)"
          fill="#f47b20"
        />
        <path
          d="M18.2,23.5a.472.472,0,0,1,.5-.5h4.9a3.126,3.126,0,0,0-.9,1.1h-4A.738.738,0,0,1,18.2,23.5Zm.5,4.2h3.1c0-.2-.1-.5-.1-.7v-.3H18.6a.472.472,0,0,0-.5.5C18.2,27.4,18.4,27.7,18.7,27.7Zm4.9-15.8v.3h2.8v9.4h1.1V12.1c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1l-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1a.1.1,0,0,1-.1-.1.31.31,0,0,1-.2-.1H23.5a.9.9,0,0,1,.1.5C23.6,11.7,23.6,11.8,23.6,11.9Zm-4.9,8.4h5.4a.5.5,0,0,0,0-1H18.7a.472.472,0,0,0-.5.5A.536.536,0,0,0,18.7,20.3Zm0-3.7h5.4a.5.5,0,0,0,0-1H18.7a.5.5,0,0,0,0,1Zm3.9,13.1H12.1V12.2h2.7v-.5a.749.749,0,0,1,.1-.5H12c-.1,0-.1,0-.2.1a.1.1,0,0,0-.1.1l-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1a.1.1,0,0,1-.1.1.31.31,0,0,1-.1.2V29.8c0,.1,0,.1.1.2a.1.1,0,0,0,.1.1l.1.1.1.1.1.1.1.1.1.1.1.1a.1.1,0,0,1,.1.1.31.31,0,0,1,.2.1H23.4C23.1,30.4,22.8,30.1,22.6,29.7ZM17.2,14.6a.483.483,0,0,0-.7.1l-1,1.2-.7-.7a.495.495,0,0,0-.7.7L15.2,17a.52.52,0,0,0,.4.2h0A.52.52,0,0,0,16,17l1.3-1.7A.616.616,0,0,0,17.2,14.6Zm0,3.7a.483.483,0,0,0-.7.1l-1,1.2-.7-.7a.495.495,0,0,0-.7.7l1.1,1.1a.52.52,0,0,0,.4.2h0a.52.52,0,0,0,.4-.2L17.3,19C17.4,18.8,17.4,18.4,17.2,18.3Zm-1.8-5.9v-.7a4.331,4.331,0,0,1,.1-.5c.4-1.2,2-2,3.8-2h0a4.148,4.148,0,0,1,3.8,2,.9.9,0,0,1,.1.5v.7c0,.1-.2.3-.6.4a17.752,17.752,0,0,1-6.5,0C15.6,12.6,15.4,12.5,15.4,12.4Zm3.2-1.9a.7.7,0,1,0,.7-.7A.842.842,0,0,0,18.6,10.5ZM17.2,22a.483.483,0,0,0-.7.1l-1,1.2-.7-.7a.495.495,0,1,0-.7.7l1.1,1.1a.52.52,0,0,0,.4.2h0a.52.52,0,0,0,.4-.2l1.3-1.7C17.4,22.5,17.4,22.1,17.2,22Zm0,3.7a.483.483,0,0,0-.7.1l-1,1.2-.7-.7a.495.495,0,0,0-.7.7l1.1,1.1a.52.52,0,0,0,.4.2h0a.52.52,0,0,0,.4-.2l1.3-1.7C17.4,26.2,17.4,25.8,17.2,25.7Zm14.6,1.2a4.7,4.7,0,1,1-4.7-4.7A4.695,4.695,0,0,1,31.8,26.9ZM30,26.3a.265.265,0,0,0-.3-.3H28V24.3a.265.265,0,0,0-.3-.3H26.4a.265.265,0,0,0-.3.3V26H24.4a.265.265,0,0,0-.3.3v1.3a.265.265,0,0,0,.3.3h1.7v1.7a.265.265,0,0,0,.3.3h1.3a.265.265,0,0,0,.3-.3V27.9h1.7a.265.265,0,0,0,.3-.3Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default MedicalReportsIcon;
