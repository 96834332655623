import React from "react";
import SampleSvg from "assets/svg/sample-svg";
import Availability from "assets/svg/24-7-availability";
import GuaranteeIcon from "assets/svg/GuaranteeIcon";
import BusinessCustomersIcon from "assets/svg/95000-business-customers";
import ContractIcon from "assets/svg/contract";
import TranslateIcon from "assets/svg/translate";
import LegalIcon from "assets/svg/legal";
import NativeIcon from "assets/svg/native";
import SecurityIcon from "assets/svg/security";
import AtaIcon from "assets/svg/ata";
import BusinessIcon from "assets/svg/business";
import CertifyIcon from "assets/svg/certify";
import EducationIcon from "assets/svg/education";
import FastDeliveryIcon from "assets/svg/fast-delivery";
import GovernmentNewIcon from "assets/svg/government-new";
import MedicalIcon from "assets/svg/medical";
import UscisIcon from "assets/svg/uscis";
import BankStatementIcon from "assets/svg/Bank/BankStatementIcon";
import CertifiedBankStatementIcon from "assets/svg/Bank/CertifiedBankStatmentIcon";
import FastDeliveryTwoIcon from "assets/svg/Bank/FastDeliveryTwoIcon";
import ExpertFinanceIcon from "assets/svg/Bank/ExpertFinanceIcon";
import SecureConfidentialIcon from "assets/svg/Bank/SecureConfidentialIcon";
import ExpertTranslatorsIcon from "assets/svg/ExpertTranslators";
import CostEffectiveIcon from "assets/svg/Divorce/CostEffectiveIcon";
import DivorceDecreeIcon from "assets/svg/Divorce/DivorceDecreeIcon";
import DivorceCertificateIcon from "assets/svg/Divorce/DivorceCertificate";
import RushIcon from "assets/svg/Divorce/RushIcon";
import UscisAcceptanceIcon from "assets/svg/Divorce/UscisAcceptanceIcon";
import AcademicIcon from "assets/svg/Industries/AcademicIcon";
import AutomotiveIcon from "assets/svg/Industries/AutomotiveIcon";
import AviationIcon from "assets/svg/Industries/AviationIcon";
import BankingFinanceIcon from "assets/svg/Industries/BankingFinanceIcon";
import ChemicalsIcon from "assets/svg/Industries/ChemicalsIcon";
import ECommerceIcon from "assets/svg/Industries/ECommerceIcon";
import ElectronicIcon from "assets/svg/Industries/ElectronicIcon";
import FashionIcon from "assets/svg/Industries/FashionIcon";
import DinnerIcon from "assets/svg/Industries/DinnerIcon";
import GamingIcon from "assets/svg/Industries/GamingIcon";
import GovernmentIcon from "assets/svg/Industries/GovernmentIcon";
import HealthcareIcon from "assets/svg/Industries/HealthcareIcon";
import LegalTwoIcon from "assets/svg/Industries/LegalTwoIcon";
import LifeSciencesIcon from "assets/svg/Industries/LifeSciencesIcon";
import LuxuryIcon from "assets/svg/Industries/LuxuryIcon";
import ManufacturingIcon from "assets/svg/Industries/ManufacturingIcon";
import MediaIcon from "assets/svg/Industries/MediaIcon";
import MilitaryIcon from "assets/svg/Industries/MilitaryIcon";
import PharmaceuticalIcon from "assets/svg/Industries/PharmaceuticalIcon";
import PowerAndEnergyIcon from "assets/svg/Industries/PowerAndEnergyIcon";
import SportsIcon from "assets/svg/Industries/SportsIcon";
import TechnologyIcon from "assets/svg/Industries/TechnologyIcon";
import TelecommunicationIcon from "assets/svg/Industries/TelecommunicationIcon";
import TourismIcon from "assets/svg/Industries/TourismIcon";
import LogisticsIcon from "assets/svg/Industries/LogisticsIcon";
import AudioTranscriptionIcon from "assets/svg/LegalInterpreting/AudioTranscriptionIcon";
import InterpretingByPhoneIcon from "assets/svg/LegalInterpreting/InterpretingByPhoneIcon";
import LegalInterpretingIcon from "assets/svg/LegalInterpreting/LegalInterpretingIcon";
import LegalTranslationIcon from "assets/svg/LegalInterpreting/LegalTranslationIcon";
import OnsiteCourtIcon from "assets/svg/LegalInterpreting/OnsiteCourtIcon";
import VideoRemoteInterpretingIcon from "assets/svg/LegalInterpreting/VideoRemoteInterpretingIcon";
import AllInOneIcon from "assets/svg/SocialMediaMarketing/AllInOneIcon";
import ContentExtractionIcon from "assets/svg/SocialMediaMarketing/ContentExtractionIcon";
import DtpIcon from "assets/svg/SocialMediaMarketing/DtpIcon";
import LinguisticIcon from "assets/svg/SocialMediaMarketing/LinguisticIcon";
import LocalizationIcon from "assets/svg/SocialMediaMarketing/LocalizationIcon";
import SocialMediaIcon from "assets/svg/SocialMediaMarketing/SocialMediaIcon";
import WorkflowIcon from "assets/svg/SocialMediaMarketing/WorkflowIcon";
import AtaLogoIcon from "assets/svg/MedicalTranslation/AtaLogoIcon";
import HipaaIcon from "assets/svg/MedicalTranslation/HipaaIcon";
import MedicalReportsIcon from "assets/svg/MedicalTranslation/MedicalReportsIcon";
import NativeLinguistIcon from "assets/svg/MedicalTranslation/NativeLinguistIcon";
import AccurateIcon from "assets/svg/MedicalTranslation/AccurateIcon";
import ClinicalTranslationIcon from "assets/svg/MedicalTranslation/ClinicalTranslationIcon";
import ConsistencyIcon from "assets/svg/MedicalTranslation/ConsistencyIcon";
import ProjectManagementIcon from "assets/svg/ProjectManagementIcon";
import MsdsIcon from "assets/svg/MsdsIcon";
import OnsiteInterpretationIcon from "assets/svg/OnsiteInterpretationIcon";
import SimultaneousInterpretingIcon from "assets/svg/SimultaneousInterpretingIcon";
import VideoRemoteIcon from "assets/svg/VideoRemoteIcon";
import ExpertTranslatorTwoIcon from "assets/svg/TechnicalTranslations/ExpertTranslatorTwoIcon";
import FileFormatsIcon from "assets/svg/TechnicalTranslations/FileFormatsIcon";
import TechnicalManualIcon from "assets/svg/TechnicalTranslations/TechnicalManualIcon";
import TranslationTechnologyIcon from "assets/svg/TechnicalTranslations/TranslationTechnologyIcon";
import FilmsIcon from "assets/svg/VideoTranslation/FilmsIcon";
import QualifiedTranslatorIcon from "assets/svg/VideoTranslation/QualifiedTranslatorIcon";
import ScriptTranslatorIcon from "assets/svg/VideoTranslation/ScriptTranslatorIcon";
import FileFormatsTwoIcon from "assets/svg/VoiceOverTranslation/FileFormatsTwoIcon";
import SyncIcon from "assets/svg/VoiceOverTranslation/SyncIcon";
import MultilingualIcon from "assets/svg/VoiceOverTranslation/MultilingualIcon";
import NativeLinguistTwoIcon from "assets/svg/VoiceOverTranslation/NativeLinguistTwoIcon";
import VoiceOverIcon from "assets/svg/VoiceOverTranslation/VoiceOverIcon";

export default function (icon) {
  if (icon === "availability") {
    return <Availability />;
  } else if (icon === "guarantee") {
    return <GuaranteeIcon />;
  } else if (icon === "business-customers") {
    return <BusinessCustomersIcon />;
  } else if (icon === "contract") {
    return <ContractIcon />;
  } else if (icon === "translate") {
    return <TranslateIcon />;
  } else if (icon === "legal") {
    return <LegalIcon />;
  } else if (icon === "native") {
    return <NativeIcon />;
  } else if (icon === "security") {
    return <SecurityIcon />;
  } else if (icon === "ata") {
    return <AtaIcon />;
  } else if (icon === "business") {
    return <BusinessIcon />;
  } else if (icon === "certify") {
    return <CertifyIcon />;
  } else if (icon === "education") {
    return <EducationIcon />;
  } else if (icon === "fast-delivery") {
    return <FastDeliveryIcon />;
  } else if (icon === "government-new") {
    return <GovernmentNewIcon />;
  } else if (icon === "medical") {
    return <MedicalIcon />;
  } else if (icon === "uscis") {
    return <UscisIcon />;
  } else if (icon === "bank-statement") {
    return <BankStatementIcon />;
  } else if (icon === "certified-bank-statement") {
    return <CertifiedBankStatementIcon />;
  } else if (icon === "fast-delivery") {
    return <FastDeliveryTwoIcon />;
  } else if (icon === "expert-finance") {
    return <ExpertFinanceIcon />;
  } else if (icon === "secure-confidential") {
    return <SecureConfidentialIcon />;
  } else if (icon === "expert-translators") {
    return <ExpertTranslatorsIcon />;
  } else if (icon === "cost-effective") {
    return <CostEffectiveIcon />;
  } else if (icon === "divorce-decree") {
    return <DivorceDecreeIcon />;
  } else if (icon === "divorce-certificate") {
    return <DivorceCertificateIcon />;
  } else if (icon === "rush") {
    return <RushIcon />;
  } else if (icon === "uscis-acceptance") {
    return <UscisAcceptanceIcon />;
  } else if (icon === "academic") {
    return <AcademicIcon />;
  } else if (icon === "automotive") {
    return <AutomotiveIcon />;
  } else if (icon === "aviation") {
    return <AviationIcon />;
  } else if (icon === "banking-finance") {
    return <BankingFinanceIcon />;
  } else if (icon === "chemicals") {
    return <ChemicalsIcon />;
  } else if (icon === "e-commerce") {
    return <ECommerceIcon />;
  } else if (icon === "electronic") {
    return <ElectronicIcon />;
  } else if (icon === "fashion") {
    return <FashionIcon />;
  } else if (icon === "dinner") {
    return <DinnerIcon />;
  } else if (icon === "gaming") {
    return <GamingIcon />;
  } else if (icon === "government") {
    return <GovernmentIcon />;
  } else if (icon === "healthcare") {
    return <HealthcareIcon />;
  } else if (icon === "legal-two") {
    return <LegalTwoIcon />;
  } else if (icon === "life-sciences") {
    return <LifeSciencesIcon />;
  } else if (icon === "luxury") {
    return <LuxuryIcon />;
  } else if (icon === "manufacturing") {
    return <ManufacturingIcon />;
  } else if (icon === "media") {
    return <MediaIcon />;
  } else if (icon === "military") {
    return <MilitaryIcon />;
  } else if (icon === "pharmaceutical") {
    return <PharmaceuticalIcon />;
  } else if (icon === "power-and-energy") {
    return <PowerAndEnergyIcon />;
  } else if (icon === "sports") {
    return <SportsIcon />;
  } else if (icon === "technology") {
    return <TechnologyIcon />;
  } else if (icon === "telecommunication") {
    return <TelecommunicationIcon />;
  } else if (icon === "tourism") {
    return <TourismIcon />;
  } else if (icon === "logistics") {
    return <LogisticsIcon />;
  } else if (icon === "audio-transcription") {
    return <AudioTranscriptionIcon />;
  } else if (icon === "interpreting-by-phone") {
    return <InterpretingByPhoneIcon />;
  } else if (icon === "legal-interpreting") {
    return <LegalInterpretingIcon />;
  } else if (icon === "legal-translation") {
    return <LegalTranslationIcon />;
  } else if (icon === "onsite-court") {
    return <OnsiteCourtIcon />;
  } else if (icon === "video-remote-interpreting") {
    return <VideoRemoteInterpretingIcon />;
  } else if (icon === "all-in-one") {
    return <AllInOneIcon />;
  } else if (icon === "content-extraction") {
    return <ContentExtractionIcon />;
  } else if (icon === "dtp") {
    return <DtpIcon />;
  } else if (icon === "linguistic") {
    return <LinguisticIcon />;
  } else if (icon === "localization") {
    return <LocalizationIcon />;
  } else if (icon === "social-media") {
    return <SocialMediaIcon />;
  } else if (icon === "workflow") {
    return <WorkflowIcon />;
  } else if (icon === "ata-logo") {
    return <AtaLogoIcon />;
  } else if (icon === "hipaa") {
    return <HipaaIcon />;
  } else if (icon === "medical-reports") {
    return <MedicalReportsIcon />;
  } else if (icon === "native-linguist") {
    return <NativeLinguistIcon />;
  } else if (icon === "accurate") {
    return <AccurateIcon />;
  } else if (icon === "clinical-translation") {
    return <ClinicalTranslationIcon />;
  } else if (icon === "consistency") {
    return <ConsistencyIcon />;
  } else if (icon === "project-management") {
    return <ProjectManagementIcon />;
  } else if (icon === "msds") {
    return <MsdsIcon />;
  } else if (icon === "onsite-interpretation") {
    return <OnsiteInterpretationIcon />;
  } else if (icon === "simultaneous-interpreting") {
    return <SimultaneousInterpretingIcon />;
  } else if (icon === "video-remote") {
    return <VideoRemoteIcon />;
  } 
  else if (icon === "expert-translator") {
    return <ExpertTranslatorTwoIcon />;
  } else if (icon === "file-formats") {
    return <FileFormatsIcon />;
  } else if (icon === "technical-manual") {
    return <TechnicalManualIcon />;
  } else if (icon === "translation-technology") {
    return <TranslationTechnologyIcon />;
  } else if (icon === "films") {
    return <FilmsIcon />;
  } else if (icon === "qualified-translator") {
    return <QualifiedTranslatorIcon />;
  } else if (icon === "script-translator") {
    return <ScriptTranslatorIcon />;
  } else if (icon === "file-formats-two") {
    return <FileFormatsTwoIcon />;
  } else if (icon === "sync") {
    return <SyncIcon />;
  } else if (icon === "multilingual") {
    return <MultilingualIcon />;
  } else if (icon === "native-linguist-two") {
    return <NativeLinguistTwoIcon />;
  } else if (icon === "voice-over") {
    return <VoiceOverIcon />;
  } else if (icon === "icon_1") {
    return <SampleSvg />;
  }
  return false;
}
