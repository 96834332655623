import React from "react";

function FileFormatsTwoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M20.5,14.6l2,2,3.7-3.7-.4-.4-2.9,2.9a.608.608,0,0,1-.8,0l-1.2-1.2Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          d="M19.1,22.6c-.1,0-.2,0-.2-.1a.6.6,0,0,1-.3-.6V19.7h-.2a1.793,1.793,0,0,1-1.8-1.8v-7a1.793,1.793,0,0,1,1.8-1.8h10a1.793,1.793,0,0,1,1.8,1.8V18a1.793,1.793,0,0,1-1.8,1.8H22l-2.5,2.6A.758.758,0,0,1,19.1,22.6Zm-.8-12.4a.684.684,0,0,0-.7.7V18a.684.684,0,0,0,.7.7h.8a.707.707,0,0,1,.6.6v1.4l1.7-1.8a.52.52,0,0,1,.4-.2h6.7a.684.684,0,0,0,.7-.7V10.9a.684.684,0,0,0-.7-.7Z"
          fill="#fff"
        />
        <path
          d="M13.9,24h-.2a2.582,2.582,0,0,1-2.6-2.6v-.9a2.582,2.582,0,0,1,2.6-2.6h.2a2.582,2.582,0,0,1,2.6,2.6v.9A2.582,2.582,0,0,1,13.9,24Zm-.2-4.9a1.367,1.367,0,0,0-1.4,1.4v.9a1.367,1.367,0,0,0,1.4,1.4h.2a1.367,1.367,0,0,0,1.4-1.4v-.9a1.367,1.367,0,0,0-1.4-1.4Z"
          fill="#fff"
        />
        <path
          d="M27.8,28.7H22.3a.6.6,0,1,1,0-1.2h5.1L28.8,23H21.4l-1,2.8a.539.539,0,1,1-1-.4l1-3.2a.675.675,0,0,1,.6-.4h8.6c.2,0,.3.1.5.2.1.2.2.3.1.5l-1.8,5.6C28.2,28.6,28,28.7,27.8,28.7Z"
          fill="#fff"
        />
        <path
          d="M29.2,30.3H13.6a.6.6,0,1,1,0-1.2H29.3a.576.576,0,0,1,.6.6C29.8,29.9,29.6,30.3,29.2,30.3Z"
          fill="#fff"
        />
        <path
          d="M19,30.3H16.3a.52.52,0,0,1-.4-.2l-2.8-3.4A.57.57,0,0,1,14,26l2.6,3.1H19a1,1,0,0,0,1-1H17.8a.52.52,0,0,1-.4-.2l-2.8-3.3a1.149,1.149,0,0,0-1.1-.6h-1a2.773,2.773,0,0,0-2.7,2.2l-.7,3.5c-.1.3-.3.5-.6.4s-.5-.3-.4-.6L8.7,26a3.861,3.861,0,0,1,3.8-3.1h1a2.643,2.643,0,0,1,2.1,1L18.2,27h2.5a.707.707,0,0,1,.6.6V28A2.473,2.473,0,0,1,19,30.3Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default FileFormatsTwoIcon;
