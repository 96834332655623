import React from "react";

function ProjectManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(-0.1 -0.1)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0.1 0.1)"
          fill="#f47b20"
        />
        <path
          d="M13.9,21.5a6.3,6.3,0,0,1,.3-1.3,2.437,2.437,0,0,0,0-1.2l-.4-.9a10.313,10.313,0,0,1-6.5,5.3v6.4H9.7a6.4,6.4,0,0,1,.5-1.9c-.8-.2-1.4-.4-1.4-.4-.2,0-.2-.2-.2-.4s.2-.2.4-.2a17.9,17.9,0,0,0,1.9.5h.3a6.951,6.951,0,0,0,2.3.2c.3-.1.2-1.2.2-1.3s.1-.2.3-.2a.187.187,0,0,0,0-.3l-.5-.3c-.1-.1-.1-.2,0-.2l.9-.3c.1,0,.1-.2.1-.3-.1-.3-.4-.7-.4-.9,0-.1.3-.2.6-.3s.4-.4.2-.6A5.288,5.288,0,0,1,13.9,21.5Z"
          fill="#fff"
        />
        <path
          d="M25.6,18.1l-.3.9a2.437,2.437,0,0,0,0,1.2,3.07,3.07,0,0,1,.3,1.3,4.479,4.479,0,0,1-1,1.3c-.2.2-.1.5.2.6s.6.2.6.3a3.076,3.076,0,0,1-.4.9c-.1.1,0,.2.1.3l.9.3c.1,0,.1.2,0,.2l-.5.3c-.1.1-.1.3,0,.3a1.439,1.439,0,0,0,.3.2c0,.2-.1,1.2.2,1.3a6.951,6.951,0,0,0,2.3-.2h.3a17.9,17.9,0,0,0,1.9-.5c.2,0,.3,0,.4.2,0,.2,0,.3-.2.4-.1,0-.6.2-1.4.4a6.4,6.4,0,0,1,.5,1.9h2.4V23.3A10.434,10.434,0,0,1,25.6,18.1Z"
          fill="#fff"
        />
        <path
          d="M26.7,13.6a6.2,6.2,0,0,1-.9,3.2A11.3,11.3,0,0,0,31,22.1l1.1.4V13.2C31.7,13.1,28.4,12.7,26.7,13.6Z"
          fill="#fff"
        />
        <path
          d="M13.7,16.8a6.954,6.954,0,0,1-.8-3.1c-1.7-.9-5.1-.6-5.5-.5v9.3l1.1-.4A11.621,11.621,0,0,0,13.7,16.8Z"
          fill="#fff"
        />
        <path
          d="M23.6,17.9a5.852,5.852,0,0,0,2.1-4.5,5.9,5.9,0,1,0-11.8,0A5.852,5.852,0,0,0,16,17.9l-.5,1.5a.308.308,0,0,0,.4.4l2-.8a5.822,5.822,0,0,0,1.9.3,5.663,5.663,0,0,0,1.8-.3l2,.8a.308.308,0,0,0,.4-.4Zm-4-3h-.1a14.51,14.51,0,0,1-1.8-1,4.454,4.454,0,0,1-1.8,1h-.1c-.2,0-.3-.1-.3-.2a.365.365,0,0,1,.2-.5,3.766,3.766,0,0,0,1.5-.9,7.509,7.509,0,0,0-.7-.8c-.1-.2-.1-.4.1-.5s.4-.1.5.1a6.818,6.818,0,0,1,.5.7,3.438,3.438,0,0,0,.8-1.4H15.5a.4.4,0,0,1,0-.8h1.7v-.5a.4.4,0,0,1,.8,0v.5h1.7a.4.4,0,1,1,0,.8h-.6a5.8,5.8,0,0,1-1,1.9,9.878,9.878,0,0,0,1.5.9.365.365,0,0,1,.2.5C19.9,14.8,19.8,14.9,19.6,14.9Zm3.8,1.4L23,15.2H21.2l-.5,1.2c-.1.1-.2.2-.3.2h-.1A.446.446,0,0,1,20,16l.6-1.4h0l1.2-2.9c.1-.1.2-.2.3-.2.2,0,.3.1.3.2L24.1,16a.381.381,0,1,1-.7.3Z"
          fill="#fff"
        />
        <path d="M21.4,14.4h1.3L22,12.9Z" fill="#fff" />
      </g>
    </svg>
  );
}
export default ProjectManagementIcon;
