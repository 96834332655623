import React from "react";

function CertifyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3418"
        data-name="Group 3418"
        transform="translate(573 -2580)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="36"
          height="36"
          rx="18"
          transform="translate(-573 2580)"
          fill="#ff7b16"
        />
        <g
          id="Group_3416"
          data-name="Group 3416"
          transform="translate(335 220)"
        >
          <g
            id="Group_3417"
            data-name="Group 3417"
            transform="translate(-900 2368)"
          >
            <path
              id="Path_3377"
              data-name="Path 3377"
              d="M-637.735,704.143q-.541-.515-1.091-1.02a2.2,2.2,0,0,1-.673-1.183c-.107-.484-.222-.967-.338-1.45a2.047,2.047,0,0,0-1.759-1.634,16.307,16.307,0,0,0-1.679-.113,2.289,2.289,0,0,1-1.36-.482c-.394-.3-.8-.6-1.195-.894a2.126,2.126,0,0,0-1.289-.44,2.167,2.167,0,0,0-1.341.474c-.4.3-.808.6-1.211.907a2.046,2.046,0,0,1-1.1.423q-.808.056-1.618.09a2.092,2.092,0,0,0-1.981,1.564c-.138.512-.255,1.031-.37,1.549a2.229,2.229,0,0,1-.685,1.2c-.352.322-.7.65-1.046.976a2.1,2.1,0,0,0-.479,2.666c.22.437.435.876.663,1.309a2.14,2.14,0,0,1,.233,1.259c-.055.494-.11.988-.178,1.48a2.113,2.113,0,0,0,1.317,2.33c.45.192.9.393,1.35.577a2.214,2.214,0,0,1,1.061.886c.29.454.573.914.891,1.348a2,2,0,0,0,2.1.849c.613-.113,1.2-.345,1.794-.515a2.082,2.082,0,0,1,1.181-.006c.5.146.993.292,1.488.443a2.107,2.107,0,0,0,2.524-.9c.274-.414.548-.829.816-1.247a2.167,2.167,0,0,1,1.015-.847c.5-.207.995-.413,1.484-.638a2.086,2.086,0,0,0,1.227-2.148c-.041-.4-.081-.795-.149-1.187a2.738,2.738,0,0,1,.322-1.96c.2-.34.368-.706.545-1.063A2.1,2.1,0,0,0-637.735,704.143Zm-9.39,9.624a6.888,6.888,0,0,1-6.885-6.884,6.885,6.885,0,0,1,6.881-6.866,6.888,6.888,0,0,1,6.9,6.873A6.889,6.889,0,0,1-647.125,713.766Z"
              transform="translate(657.23 -696.926)"
              fill="#fff"
            />
            <path
              id="Path_3378"
              data-name="Path 3378"
              d="M-581.7,763.905a6.214,6.214,0,0,0-6.222,6.184,6.215,6.215,0,0,0,6.207,6.221,6.214,6.214,0,0,0,6.222-6.185A6.213,6.213,0,0,0-581.7,763.905Zm3.479,5.028q-1.809,1.832-3.626,3.656a.912.912,0,0,1-1.363-.005q-.993-.983-1.979-1.975a.918.918,0,0,1-.034-1.346.931.931,0,0,1,1.35.051c.447.444.89.893,1.3,1.306.786-.765,1.565-1.521,2.341-2.279.235-.23.461-.47.7-.7a.928.928,0,0,1,1.35-.039A.918.918,0,0,1-578.22,768.933Z"
              transform="translate(591.815 -760.142)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default CertifyIcon;
