import React from "react";

function AtaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3410"
        data-name="Group 3410"
        transform="translate(685 -2503)"
      >
        <rect
          id="Rectangle_1629"
          data-name="Rectangle 1629"
          width="36"
          height="36"
          rx="18"
          transform="translate(-685 2503)"
          fill="#ff7b16"
        />
        <g
          id="Icon_ionic-ios-people"
          data-name="Icon ionic-ios-people"
          transform="translate(-680.347 2506.017)"
        >
          <path
            id="Path_3372"
            data-name="Path 3372"
            d="M19.725,18.88c-.555-.2-1.463-.208-1.864-.362a2.634,2.634,0,0,1-.863-.4,5.331,5.331,0,0,1-.144-1.413,2.283,2.283,0,0,0,.491-.694,7.715,7.715,0,0,0,.238-1.364s.327.139.456-.516c.109-.565.317-.863.263-1.279s-.288-.317-.288-.317a3.713,3.713,0,0,0,.288-1.855,2.967,2.967,0,0,0-3.2-2.931,2.978,2.978,0,0,0-3.208,2.931,3.768,3.768,0,0,0,.283,1.855s-.233-.1-.288.317.149.714.263,1.279c.129.659.456.516.456.516a7.79,7.79,0,0,0,.238,1.364,2.283,2.283,0,0,0,.491.694,5.331,5.331,0,0,1-.144,1.413,2.535,2.535,0,0,1-.863.4c-.4.154-1.3.174-1.864.372a3.5,3.5,0,0,0-2.271,3.332h13.8A3.493,3.493,0,0,0,19.725,18.88Z"
            transform="translate(-1.762)"
            fill="#fff"
          />
          <path
            id="Path_3373"
            data-name="Path 3373"
            d="M7.595,17.485a3.366,3.366,0,0,0,1.656-.461C8.482,15.859,8.9,14.5,8.74,13.231a2.092,2.092,0,0,0-2.316-2.023H6.4a2.1,2.1,0,0,0-2.3,2.023c-.159,1.264.283,2.777-.506,3.793a3.115,3.115,0,0,0,1.661.412h0a2.511,2.511,0,0,1-.05.833,1.252,1.252,0,0,1-.6.273,11.5,11.5,0,0,0-1.284.357,2.088,2.088,0,0,0-1.121,1.845H6.2a3.562,3.562,0,0,1,1.587-1.478,3.33,3.33,0,0,1,1.225-.258s.188-.3-.431-.412a4.227,4.227,0,0,1-.952-.332C7.53,18.155,7.595,17.485,7.595,17.485Z"
            transform="translate(0 -1.02)"
            fill="#fff"
          />
          <path
            id="Path_3374"
            data-name="Path 3374"
            d="M25.464,17.485a3.366,3.366,0,0,1-1.656-.461c.769-1.165.352-2.524.511-3.793a2.092,2.092,0,0,1,2.316-2.023h.02a2.1,2.1,0,0,1,2.3,2.023c.159,1.264-.283,2.777.506,3.793a3.115,3.115,0,0,1-1.661.412h0a2.511,2.511,0,0,0,.05.833,1.252,1.252,0,0,0,.6.273,11.505,11.505,0,0,1,1.284.357,2.088,2.088,0,0,1,1.121,1.845H26.862a3.562,3.562,0,0,0-1.587-1.478,3.33,3.33,0,0,0-1.225-.258s-.188-.3.431-.412a4.227,4.227,0,0,0,.952-.332C25.528,18.155,25.464,17.485,25.464,17.485Z"
            transform="translate(-6.365 -1.02)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default AtaIcon;
