import * as React from "react";
import BoxContainer from "components/BoxContainer";
import Button from "../Button";
import Text from "components/Text";
import "./CaseStudyGrid.style.css";

const CaseStudyGrid = ({ data, background, textClasses }) => {
  return (
    <BoxContainer
      background={`${background ? background : "bg-midBlueNew"}`}
      className="items-center text-center py-16"
    >
      <div className="px-5 md:px-48 pb-5 2xl:max-w-6xl 2xl:mx-auto">
        <Text
          highlightedColor={data?.highlightedColor}
          highlightedText={data?.highlightWords}
          as="h2"
          className="text-3xl2 text-white font-secondary font-semibold my-5"
        >
          {data?.title}
        </Text>
        <Text
          className={`text-lg font-opensans text-lightGray mb-12 ${
            textClasses ? textClasses : " "
          }`}
          as="div"
        >
          {data?.subtitle}
        </Text>
        <div className="flex text-white w-fill justify-center">
          <Button
            title={data.cta.text}
            href={data.cta.href}
            className="w-48 h-[52px] text-lg text-center text-white font-opensans font-medium bg-yellow rounded-full cursor-pointer flex items-center justify-center uppercase hover:bg-newOrange-100 transition-colors"
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default CaseStudyGrid;
