import * as React from "react";
import BoxContainer from "components/BoxContainer";
import Text from "components/Text";
import iconResolver from "utils/icon-resolver";
import Button from "components/Button";

const DoubleCols = ({ data }) => {
  return (
    <>
      <BoxContainer
        background="bg-white"
        className="items-center pt-[80px] px-5 md:px-8"
      >
        <div className="md:w-10/12 2xl:w-full m-auto 2xl:max-w-4xl">
          <Text
            highlightedText={data?.highlightWords}
            highlightedColor={data?.highlightedColor}
            className="font-semibold text-center text-3xl2 text-midBlue font-secondary"
            as="h2"
          >
            {data?.title}
          </Text>
          <Text
            as="p"
            className="text-lg leading-8 text-midBlue font-opensans mt-10 text-center"
          >
            {data?.subtitle}
          </Text>
        </div>
      </BoxContainer>
      <BoxContainer background="bg-white" className="items-center pt-9">
        <div className="pb-[40px] my-10 md:w-10/12 2xl:w-full m-auto 2xl:max-w-6xl">
          <div className="md:grid grid-cols-2 gap-y-24 gap-x-32">
            {data?.maindata.map((item, i) => (
              <div key={i}>
                <div className="flex flex-col md:flex-row gap-6 items-center sm:items-start mb-12 md:mb-0">
                  {item.icon && (
                    <div className="w-min">{iconResolver(item.icon)}</div>
                  )}
                  <div
                    className={`${
                      item.cta ? "md:h-96 md:pb-4 md:relative" : ""
                    }`}
                  >
                    <Text
                      as="h3"
                      className="text-xl2 text-midBlue mb-3 font-secondary font-medium text-24 text-center sm:text-left"
                    >
                      {item.heading}
                    </Text>
                    <Text
                      as="div"
                      className="text-midBlue leading-8 font-opensans text-lg text-center sm:text-left mb-5 md:mb-0"
                    >
                      {item.maintext}
                    </Text>
                    {item.cta && (
                      <Button
                        title={item.cta?.text}
                        href={item.cta?.href}
                        className="w-44 h-[52px] text-base text-center text-white font-opensans font-medium uppercase bg-yellow rounded-full cursor-pointer flex items-center justify-center md:absolute md:bottom-0 md:left-0 mx-auto sm:mx-0 hover:bg-newOrange-100 transition-colors"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {data.description && (
            <Text
              as="div"
              className="text-lg leading-8 text-midBlue font-opensans mt-10 text-center sm:text-left"
            >
              {data.description}
            </Text>
          )}
        </div>
      </BoxContainer>
    </>
  );
};
export default DoubleCols;
