import React from "react";

function ExpertTranslatorsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M12.2,22.7h-3a5.371,5.371,0,0,0-5,5.4v1.1H6.5v0h8.8l-.2-6A25,25,0,0,0,12.2,22.7Z"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M12.2,21.9v.9a1.52,1.52,0,0,1-1.5,1.3,1.538,1.538,0,0,1-1.5-1.5v-.8"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M16.7,16.6v.8a1,1,0,0,1-1,1h-.2a.749.749,0,0,1,.1.5,1,1,0,0,1-1,1,1.445,1.445,0,0,1-.7-.2H7.6a2.353,2.353,0,0,1-.8.2.945.945,0,0,1-1-1,.9.9,0,0,1,.1-.5H5.7a.945.945,0,0,1-1-1,.891.891,0,0,1,.5-.8.974.974,0,0,1-.8-1,.875.875,0,0,1,.6-.9.961.961,0,0,1-.6-.9.974.974,0,0,1,.8-1,.908.908,0,0,1-.3-.7,1,1,0,0,1,1-1,.367.367,0,0,1,.3.1c0-.1-.1-.2-.1-.3a1,1,0,0,1,1-1,1.445,1.445,0,0,1,.7.2V10a1,1,0,0,1,1-1,1.05,1.05,0,0,1,.9.5V9.4a.1.1,0,0,1,.1-.1V9.2l.1-.1h0L10,9a.1.1,0,0,0,.1-.1h1.2a.1.1,0,0,1,.1.1l.1.1h0l.1.1v.1l.1.1a.1.1,0,0,0,.1.1,1.05,1.05,0,0,1,.9-.5.945.945,0,0,1,1,1v.1a1.445,1.445,0,0,1,.7-.2.974.974,0,0,1,1,.8"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M8.6,20.8a2.561,2.561,0,0,1-1.2-2.3v-.4a1.4,1.4,0,1,1,0-2.8V15a2.79,2.79,0,0,1,2-2.7v.1a1.135,1.135,0,0,0,1.2,1.1h.3a1.135,1.135,0,0,0,1.2-1.1v-.1a2.791,2.791,0,0,1,2,2.6v.4a1.4,1.4,0,0,1,0,2.8v.4a2.79,2.79,0,0,1-1.2,2.3"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M11.4,22.2H10a1.367,1.367,0,0,1-1.4-1.4V18.3a.9.9,0,0,1,.9-.9H12a.9.9,0,0,1,.9.9v2.6A1.608,1.608,0,0,1,11.4,22.2Z"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M11.9,19.5a1.671,1.671,0,0,1-2.4,0"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M30.9,21.4h-3c-.4,0-2.8.1-3.9,1.8l3.1,6h8.8V26.8A5.446,5.446,0,0,0,30.9,21.4Z"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M30.9,19.7v1.8a1.5,1.5,0,0,1-3,0V19.7"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M25.7,14.1a1.424,1.424,0,0,0-.9,1.3,1.367,1.367,0,0,0,1.4,1.4v.1a3.009,3.009,0,0,0,3,3h.6a3.009,3.009,0,0,0,3-3v-.1a1.367,1.367,0,0,0,1.4-1.4,1.424,1.424,0,0,0-.9-1.3"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M25.3,11.9l3.9-2.4a.446.446,0,0,1,.5,0l6,3.8a.429.429,0,0,1-.2.8H23.9"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <line
          y1="3.3"
          transform="translate(33.5 25.9)"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M30.5,17.6a1.693,1.693,0,0,1-2.3,0"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          d="M21.9,21.3H18.8a5.585,5.585,0,0,0-5.2,5.6v2.3H27V26.9A5.5,5.5,0,0,0,21.9,21.3Z"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M21.9,19.5v1.8a1.5,1.5,0,0,1-3,0V19.5"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M24.8,14.1V11.5a2.133,2.133,0,0,0-1.3-2H21.3l2,4.2Z"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M20.1,10.4H22a1.752,1.752,0,0,0,1.7-1.7h-5a2.8,2.8,0,0,0-2.8,2.8v2.6"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M23.7,13.6v-.4a2.8,2.8,0,0,0-2.8-2.8H19.7a2.8,2.8,0,0,0-2.8,2.8v.4a1.4,1.4,0,1,0,0,2.8v.1A3.116,3.116,0,0,0,20,19.6h.6a3.116,3.116,0,0,0,3.1-3.1v-.1a1.4,1.4,0,1,0,0-2.8Z"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <path
          d="M21.5,17.3a1.671,1.671,0,0,1-2.4,0"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <line
          y2="3.3"
          transform="translate(16 25.9)"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
        <line
          y2="3.3"
          transform="translate(24.6 25.9)"
          fill="#f47b20"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.716"
        />
      </g>
    </svg>
  );
}
export default ExpertTranslatorsIcon;
