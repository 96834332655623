import React from "react";

function LuxuryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path d="M12.5,20.6l6.7-5.7L6.9,12.1h0Z" fill="#fff" />
        <path d="M2.2,17.3l9,3.1L5.8,12.2Z" fill="#fff" />
        <path d="M3.1,18.6,18.4,34.7l-2.5-5.1-3.9-8Z" fill="#fff" />
        <path d="M12,21.6l-8.9-3h0l8.9,3Z" fill="#fff" />
        <path d="M20.2,36.2l7.1-14.5H13.1Z" fill="#fff" />
        <path d="M13.1,21.7h0l7.1,14.5h0Z" fill="#fff" />
        <path d="M15,19.6l-1.4,1.2H26.7l-6.5-5.5Z" fill="#fff" />
        <path d="M20.2,15.3,15,19.6l5.2-4.3Z" fill="#fff" />
        <path d="M27.9,20.6l5.6-8.5-.6.2L21.2,14.9Z" fill="#fff" />
        <path d="M27.9,20.6l-6.7-5.7h0l6.7,5.7Z" fill="#fff" />
        <path d="M34.6,12.2l-5.4,8.2,8.9-3.1Z" fill="#fff" />
        <path d="M22.1,34.5l-.2.2L37.2,18.6l-8.9,3Z" fill="#fff" />
        <path d="M28.3,21.6,22.1,34.5l6.2-12.9Z" fill="#fff" />
        <path d="M37.2,18.6h0l-8.9,3h0Z" fill="#fff" />
        <path d="M28.3,21.6Z" fill="#fff" />
        <path d="M33.5,11.2,31,9,20.2,7.5,9.4,9,6.8,11.2l13.4,3Z" fill="#fff" />
        <path d="M6.8,11.2h0l13.4,3h0Z" fill="#fff" />
        <path d="M20.2,14.2l13.3-3h0l-13.3,3Z" fill="#fff" />
        <path d="M20.2,14.2Z" fill="#fff" />
      </g>
    </svg>
  );
}
export default LuxuryIcon;
