import React from "react";

function Availability() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <defs></defs>
      <path
        class="a"
        fill="#ff7b16"
        d="M3.406,176.733H.645a.645.645,0,0,0-.645.645v6.9a.645.645,0,0,0,.645.645H3.406a.645.645,0,0,0,.645-.645v-6.9A.645.645,0,0,0,3.406,176.733Z"
        transform="translate(0 -169.139)"
      />
      <path
        class="a"
        fill="#ff7b16"
        d="M269.2,183.169a.645.645,0,0,0,.645-.645V181.1h.046a.645.645,0,0,0,0-1.289h-.046v-3.5a.645.645,0,0,0-1.1-.456l-2.966,2.965a2.333,2.333,0,0,0-.674,1.633.645.645,0,0,0,.645.645h2.807v1.427A.645.645,0,0,0,269.2,183.169Zm-2.579-3.36a1.011,1.011,0,0,1,.07-.078l1.865-1.864v1.942Z"
        transform="translate(-253.709 -168.118)"
      />
      <path
        class="a"
        fill="#ff7b16"
        d="M133.275,182.525a.645.645,0,0,0-.645-.645h-2.579a1,1,0,0,1,.07-.078l2.965-2.964a.644.644,0,0,0,.189-.456v-.345a2.37,2.37,0,0,0-4.741,0h0a.645.645,0,0,0,1.289,0,1.081,1.081,0,0,1,2.163,0v.078l-2.778,2.776a2.333,2.333,0,0,0-.674,1.633.645.645,0,0,0,.645.645h3.452a.645.645,0,0,0,.644-.645Z"
        transform="translate(-123.011 -168.119)"
      />
      <path
        class="a"
        fill="#ff7b16"
        d="M77.623,1.289a6.962,6.962,0,0,1,6.732,5.22,1.921,1.921,0,0,1,.863-.2h.415a8.239,8.239,0,0,0-16.018,0h.415a1.921,1.921,0,0,1,.863.2,6.962,6.962,0,0,1,6.732-5.22Z"
        transform="translate(-66.623)"
      />
      <path
        class="a"
        fill="#ff7b16"
        d="M217.5,391.057a4.194,4.194,0,0,1-3.844,2.527h-.842a2.025,2.025,0,1,0,0,1.289h.842a5.486,5.486,0,0,0,5.124-3.544h-.292a1.921,1.921,0,0,1-.988-.273Z"
        transform="translate(-199.892 -374.254)"
      />
      <path
        class="a"
        fill="#ff7b16"
        d="M421.139,176.733h-2.762a.645.645,0,0,0-.644.645v6.9a.645.645,0,0,0,.644.645h2.762a.645.645,0,0,0,.645-.645v-6.9A.645.645,0,0,0,421.139,176.733Z"
        transform="translate(-399.784 -169.139)"
      />
    </svg>
  );
}

export default Availability;
