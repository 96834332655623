import React from "react";

function AtaLogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M12.2,16.1c-.3-.5-.7-.4-1-.4a9.656,9.656,0,0,0-7.7,4.1,4.1,4.1,0,0,0-.9,2.8c.2,2,1.4,3.2,3.5,2.9a6.543,6.543,0,0,0,3.3-1.7c.1-.1.2-.2.3-.2a5.171,5.171,0,0,0,.6,1.3,1.448,1.448,0,0,0,1.8.7,2.355,2.355,0,0,0,1-.5c.6-.5,1.3-1,1.9-1.5l.3.9a1.564,1.564,0,0,0,2.2,1,7.844,7.844,0,0,0,1.8-1.1c.6-.5,1.1-1,1.7-1.6a8.265,8.265,0,0,0,.7,1.3,2.9,2.9,0,0,0,3.8,1.2,7.476,7.476,0,0,0,2.4-1.5c.1-.1.2-.1.3-.2a4.876,4.876,0,0,0,.3.7,1.922,1.922,0,0,0,2.6,1.2,5.311,5.311,0,0,0,3.1-2.4c.7-1.1,1.3-2.3,1.9-3.4a.775.775,0,0,0-.1-1,1.756,1.756,0,0,0-.4.5c-.4.6-.9,1.2-1.3,1.7A5.547,5.547,0,0,1,32,22.6a1.283,1.283,0,0,1-1.8-.9,1.268,1.268,0,0,1-.1-.6c0-.6.1-1.1.1-1.7v-.6h-.1c-.1.1-.2.3-.3.4A6.956,6.956,0,0,1,27.5,22a3.819,3.819,0,0,1-1.6.6A1.584,1.584,0,0,1,24,21.1a3.19,3.19,0,0,1,.4-2.1,6.411,6.411,0,0,1,5-2.9c.3,0,.5-.1.8-.1v-.2a1.689,1.689,0,0,0-.5-.2c-2.2,0-4.3.3-6,2-1.2,1.3-2.4,2.6-3.7,3.8a7.551,7.551,0,0,1-1.4,1.1.8.8,0,0,1-1.3-.7,4.869,4.869,0,0,1,.1-1.2A18.887,18.887,0,0,1,20,15.5a.764.764,0,0,1,.5-.2h3.6a6.552,6.552,0,0,0,1.4,0,.749.749,0,0,0,.8-.5.618.618,0,0,0-.5-.8,3.553,3.553,0,0,0-1-.2l-3.6.3h-.4c.2-.7.8-1.3.3-2.1-.2.3-.4.4-.5.7a9.993,9.993,0,0,0-.8,1.4.548.548,0,0,1-.6.4,12.31,12.31,0,0,0-2,.2c-.1.3.1.4.4.4h1.7c-.5,1-.9,1.8-1.4,2.7a14.2,14.2,0,0,1-3.2,4.1,3.293,3.293,0,0,1-1.3.7A1.065,1.065,0,0,1,12,21.5a6.882,6.882,0,0,1,.2-1.3c.1-.5.1-1,.2-1.4-.1,0-.1,0-.2-.1a8.794,8.794,0,0,0-.6,1,6.62,6.62,0,0,1-2.8,2.7,2.748,2.748,0,0,1-2,.2A1.594,1.594,0,0,1,5.6,21a3.157,3.157,0,0,1,.7-1.9,7.06,7.06,0,0,1,5.5-2.9C11.9,16.1,12,16.1,12.2,16.1Z"
          fill="#fff"
        />
        <path
          d="M12.2,16.1c-.2,0-.3.1-.4.1a7.541,7.541,0,0,0-5.5,2.9A3.41,3.41,0,0,0,5.6,21a1.426,1.426,0,0,0,1.1,1.5,2.8,2.8,0,0,0,2-.2,6.62,6.62,0,0,0,2.8-2.7,8.794,8.794,0,0,0,.6-1c.1,0,.1,0,.2.1-.1.5-.1,1-.2,1.4a5.067,5.067,0,0,0-.2,1.3c0,.8.6,1.4,1.4,1.1a3.293,3.293,0,0,0,1.3-.7,14.2,14.2,0,0,0,3.2-4.1c.5-.8.9-1.7,1.4-2.7H17.5c-.3,0-.4-.1-.4-.4a15.029,15.029,0,0,1,2-.2.548.548,0,0,0,.6-.4c.3-.5.5-.9.8-1.4.1-.2.3-.4.5-.7.4.8-.1,1.4-.3,2.1h.4l3.6-.3a3.553,3.553,0,0,1,1,.2.618.618,0,0,1,.5.8c-.1.4-.4.5-.8.5h-5a.764.764,0,0,0-.5.2,17.581,17.581,0,0,0-2.6,5.1,4.869,4.869,0,0,0-.1,1.2c.1.7.7,1.1,1.3.7a7.551,7.551,0,0,0,1.4-1.1c1.2-1.3,2.4-2.5,3.7-3.8,1.6-1.7,3.7-2,6-2a.764.764,0,0,1,.5.2v.2c-.3,0-.5.1-.8.1a6.411,6.411,0,0,0-5,2.9,2.977,2.977,0,0,0-.4,2.1,1.641,1.641,0,0,0,1.9,1.5,5.228,5.228,0,0,0,1.6-.6,6.956,6.956,0,0,0,2.3-2.8c.1-.1.2-.3.3-.4h.1v.6c0,.6-.1,1.1-.1,1.7,0,.2.1.4.1.6a1.344,1.344,0,0,0,1.8.9,4.806,4.806,0,0,0,2.3-1.7c.5-.6.9-1.2,1.3-1.7.1-.2.3-.3.4-.5a.769.769,0,0,1,.1,1A37.183,37.183,0,0,1,34.1,23,5.736,5.736,0,0,1,31,25.4a1.893,1.893,0,0,1-2.6-1.2c-.1-.2-.2-.5-.3-.7-.1.1-.2.1-.3.2a7.476,7.476,0,0,1-2.4,1.5A2.9,2.9,0,0,1,21.6,24a5.388,5.388,0,0,1-.7-1.3c-.6.5-1.1,1.1-1.7,1.6a17.394,17.394,0,0,1-1.8,1.1,1.564,1.564,0,0,1-2.2-1l-.3-.9c-.6.5-1.2,1-1.9,1.5a9.292,9.292,0,0,1-1,.5,1.448,1.448,0,0,1-1.8-.7c-.2-.4-.4-.8-.6-1.3-.1.1-.2.2-.3.2A6.974,6.974,0,0,1,6,25.4c-2.1.3-3.3-.9-3.5-2.9a3.658,3.658,0,0,1,.9-2.8,9.959,9.959,0,0,1,7.7-4.1C11.6,15.6,11.9,15.6,12.2,16.1Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default AtaLogoIcon;
