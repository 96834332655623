import * as React from "react";
import BoxContainer from "../BoxContainer";
import Text from "components/Text";

const ServiceStats = ({ data }) => {
  return (
    <>
      <BoxContainer
        background="bg-midBlueNew"
        className="items-center text-center py-24"
      >
        <div className="pb-5 md:px-48">
          <Text
            as="h2"
            highlightedText={data?.highlightWords}
            highlightedColor={data?.highlightedColor}
            className="font-secondary  text-white text-40 leading-relaxed"
          >
            {data?.title}
          </Text>
        </div>
        <div className="flex flex-col lg:flex-row justify-between pt-8 lg:w-10/12 m-auto items-center lg:items-start gap-7 lg:gap-0">
          {data?.stats.map((item, i) => (
            <div key={i} className="flex flex-col items-center">
              <Text
                as="p"
                className="font-secondary font-medium text-[70px] text-yellow"
              >
                {item.value}
              </Text>
              <Text
                as="p"
                className="font-secondary font-medium text-[22px] text-white w-[200px]"
              >
                {item.name}
              </Text>
            </div>
          ))}
        </div>
      </BoxContainer>
    </>
  );
};

export default ServiceStats;
