import React from "react";

function MultilingualIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M28.8,15.1a3.379,3.379,0,0,0-2.3-3,5.019,5.019,0,0,1,.2,1.2c0,2.4-2.3,4.4-5.4,5a6.689,6.689,0,0,0,2.6.5,7.613,7.613,0,0,0,2.1-.3l2.6,1.1-.9-2A4.066,4.066,0,0,0,28.8,15.1Z"
          fill="#fff"
        />
        <path
          d="M18.8,7.5c-3.9,0-7.1,2.3-7.1,5.1a4.625,4.625,0,0,0,1.9,3.5L12.2,19l3.6-1.6a9.246,9.246,0,0,0,2.9.4c3.9,0,7.1-2.3,7.1-5.1C25.9,9.8,22.7,7.5,18.8,7.5Zm-2.9,5.8a.7.7,0,1,1,.7-.7A.842.842,0,0,1,15.9,13.3Zm2.9,0a.7.7,0,1,1,.7-.7A.842.842,0,0,1,18.8,13.3Zm2.9,0a.7.7,0,1,1,.7-.7A.842.842,0,0,1,21.7,13.3Z"
          fill="#fff"
        />
        <path
          d="M29.8,29.2c-.8-.6-2.1-.3-2.9-1.3,0,0-.3-.3.4-1.2a4.19,4.19,0,0,0,.6-3.1,2.147,2.147,0,0,0-4.2,0,3.779,3.779,0,0,0,.6,3.1,1.626,1.626,0,0,1,.4.9,3.552,3.552,0,0,1,.9.4,3.167,3.167,0,0,1,1.1,3.1h3.8A2.066,2.066,0,0,0,29.8,29.2Z"
          fill="#fff"
        />
        <path
          d="M14.4,28a4.053,4.053,0,0,1,.9-.5,1.626,1.626,0,0,1,.4-.9,4.19,4.19,0,0,0,.6-3.1,2.147,2.147,0,0,0-4.2,0,3.779,3.779,0,0,0,.6,3.1c.7.8.4,1.2.4,1.2-.8,1.1-2.1.7-2.9,1.3s-.6,2-.6,2h3.7A3.41,3.41,0,0,1,14.4,28Z"
          fill="#fff"
        />
        <path
          d="M25.1,28.7a3,3,0,0,0-1-.4c-.9-.3-1.9-.3-2.7-1.3,0,0-.4-.4.5-1.4a5.237,5.237,0,0,0,.7-3.9A2.677,2.677,0,0,0,20,19.6a2.578,2.578,0,0,0-2.6,2.1,5.324,5.324,0,0,0,.7,3.9c.8,1,.5,1.4.5,1.4A3.931,3.931,0,0,1,16,28.2a3,3,0,0,0-1,.4c-1,.7-.8,2.4-.8,2.4H25.8C25.8,31.1,26.1,29.4,25.1,28.7Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default MultilingualIcon;
