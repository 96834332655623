import React from "react";

function VideoRemoteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(-0.1 -0.1)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0.1 0.1)"
          fill="#f47b20"
        />
        <path
          d="M26.7,16a2.951,2.951,0,0,1,1.8-1.8,1.08,1.08,0,0,0,.8.4.867.867,0,0,0,.8-.4c.2.1,1.6.7,1.8,1.8,0,.2-.8.7-2.5.7h-.2C27.5,16.8,26.7,16.2,26.7,16Z"
          fill="#fff"
        />
        <path
          d="M28.6,14.1a1.528,1.528,0,0,1-.4-1,1.115,1.115,0,1,1,2.2,0,1.23,1.23,0,0,1-1.1,1.3.908.908,0,0,1-.7-.3Z"
          fill="#fff"
        />
        <path d="M22.6,29.3l1.1,2.5H17.2l.9-2.5Z" fill="#fff" />
        <path
          d="M28.4,32.9a.86.86,0,0,1-.8.8h-14a.8.8,0,1,1,0-1.6h14a.736.736,0,0,1,.8.8Z"
          fill="#fff"
        />
        <path
          d="M26.8,25.1a2.951,2.951,0,0,1,1.8-1.8,1.08,1.08,0,0,0,.8.4.867.867,0,0,0,.8-.4c.2.1,1.6.7,1.8,1.8,0,.2-.8.7-2.5.7h-.2C27.6,25.8,26.7,25.3,26.8,25.1Z"
          fill="#fff"
        />
        <path
          d="M28.6,23.1a1.528,1.528,0,0,1-.4-1,1.115,1.115,0,1,1,1.1,1.3,1.08,1.08,0,0,1-.7-.3Z"
          fill="#fff"
        />
        <path
          d="M34.5,9.4H27v1h6.5a1.216,1.216,0,0,1,1.2,1.2v7.1H24.1V14h-.8V27.1H7.8a1.216,1.216,0,0,1-1.2-1.2V11.5a1.216,1.216,0,0,1,1.2-1.2h6v-1h-7a1.216,1.216,0,0,0-1.2,1.2V27.6a1.216,1.216,0,0,0,1.2,1.2H34.5a1.216,1.216,0,0,0,1.2-1.2v-17a1.287,1.287,0,0,0-1.2-1.2ZM34.7,26a1.216,1.216,0,0,1-1.2,1.2H24V19.5H34.6V26Z"
          fill="#fff"
        />
        <path
          d="M13.2,17.7a2.254,2.254,0,0,1,2.1-2.4,2.324,2.324,0,0,1,2.1,2.4,2.254,2.254,0,0,1-2.1,2.4,2.106,2.106,0,0,1-1.3-.5l-.1-.1A2.666,2.666,0,0,1,13.2,17.7Z"
          fill="#fff"
        />
        <path
          d="M10.2,18.6a5.456,5.456,0,0,0,1.1,3.3,6.032,6.032,0,0,1,2.6-2,2.331,2.331,0,0,0,1.5.7,1.906,1.906,0,0,0,1.5-.7,6.8,6.8,0,0,1,2.7,2,5.142,5.142,0,0,0,1-3,4.918,4.918,0,0,0-.4-2.1A3.67,3.67,0,0,0,19.1,15l.5-.5a5.328,5.328,0,0,1,1.3,2,6.874,6.874,0,0,1,.5,2.4,5.7,5.7,0,0,1-1.9,4.2,5.835,5.835,0,0,1-4.1,1.7h-.1a5.7,5.7,0,0,1-4.2-1.9,6.31,6.31,0,0,1-1.7-4.3,5.431,5.431,0,0,1,1.2-3.4,5.63,5.63,0,0,1,2.9-2.1l.2.7a5.066,5.066,0,0,0-2.5,1.8,5.389,5.389,0,0,0-1,3Z"
          fill="#fff"
        />
        <path
          d="M15,12.8a1.27,1.27,0,0,0,.7.4c.7,0,.1,1.2.1,1.2a27.411,27.411,0,0,1,3.8-1.3H25a1,1,0,0,0,1-1V7.3a1,1,0,0,0-1-1H15.7a1,1,0,0,0-1,1v4.8a4.875,4.875,0,0,0,.3.7Zm1.9-5h7v.8h-7Zm0,1.6h7v.8h-7Zm0,1.8h3.9V12H16.9Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default VideoRemoteIcon;
