import React from "react";

function MsdsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M19.8,26.9l1.4,1.7a1.816,1.816,0,0,1-1.4.6A2.47,2.47,0,0,1,17.9,28c-2.9-.5-5.8-.8-8.8.2-.3.1-.5,0-.5-.3V14.7c0-.4.2-.6.9-.8v-.8c0-.2.2-.5.4-.5a15.613,15.613,0,0,1,9.8.9,15.013,15.013,0,0,1,9.8-.9.664.664,0,0,1,.4.5v.8c.6.2.9.3.9.8v6.9a1.1,1.1,0,0,1-2.2,0V13.7c-5.9-1-8.5,1.1-8.5,1.1v8a.4.4,0,1,1-.8,0v-8s-2.6-2.1-8.5-1.1V26C16.4,25.1,19.5,26.7,19.8,26.9Zm10.5-2.6-3.9-.8v-4a1.09,1.09,0,0,0-1.1-1,1.11,1.11,0,0,0-1.1,1.1v7c-.6-.6-1.4-1.5-3.3-.5a.864.864,0,0,0,.3.6l2.9,2.9a4.361,4.361,0,0,0,2.7,1.1h1.7a2.677,2.677,0,0,0,2.8-2.8V25.2C31.3,24.7,30.9,24.4,30.3,24.3Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default MsdsIcon;
