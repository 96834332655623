import React from "react";

function ElectronicIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="20"
          cy="20"
          r="20"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M20,37.7A1.752,1.752,0,0,1,18.3,36V30.4a.4.4,0,0,1,.8,0V36a.8.8,0,0,0,1.6,0V30.4a.4.4,0,0,1,.8,0V36A1.431,1.431,0,0,1,20,37.7Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.284"
        />
        <path
          d="M16.1,15.4H13.8a.43.43,0,0,1-.4-.4V7.5a1.6,1.6,0,1,1,3.2,0V15A.664.664,0,0,1,16.1,15.4Zm-1.9-.8h1.4V7.5a.7.7,0,0,0-1.4,0v7.1Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.284"
        />
        <path
          d="M26.2,15.4H23.9a.43.43,0,0,1-.4-.4V7.5a1.6,1.6,0,0,1,3.2,0V15A.664.664,0,0,1,26.2,15.4Zm-1.9-.8h1.4V7.5a.7.7,0,0,0-1.4,0v7.1Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.284"
        />
        <path
          d="M30.1,19H9.9a1.367,1.367,0,0,1-1.4-1.4,3.116,3.116,0,0,1,3.1-3.1H28.5a3.116,3.116,0,0,1,3.1,3.1A1.52,1.52,0,0,1,30.1,19ZM11.6,15.4a2.22,2.22,0,0,0-2.2,2.2.472.472,0,0,0,.5.5H30.1a.472.472,0,0,0,.5-.5,2.22,2.22,0,0,0-2.2-2.2H11.6Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.284"
        />
        <path
          d="M20,30.9a16.883,16.883,0,0,1-6.6-1.3,4.236,4.236,0,0,1-2.6-3.9V18.6a.43.43,0,0,1,.4-.4H28.9a.43.43,0,0,1,.4.4v7.1a4.136,4.136,0,0,1-2.6,3.9h0A17.733,17.733,0,0,1,20,30.9ZM11.6,19v6.7a3.252,3.252,0,0,0,2.1,3.1,16.2,16.2,0,0,0,12.6,0h0a3.252,3.252,0,0,0,2.1-3.1V19Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.284"
        />
        <path
          d="M23.9,24H16.1a.4.4,0,0,1,0-.8h7.8a.4.4,0,0,1,0,.8Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.284"
        />
        <path
          d="M23.9,26.2H16.1a.4.4,0,0,1,0-.8h7.8a.4.4,0,0,1,0,.8Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.284"
        />
      </g>
    </svg>
  );
}
export default ElectronicIcon;
