import React from "react";

function FilmsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M25.7,16.7a17.858,17.858,0,0,0,5.8-.9v-.3c0-2.1-1.2-3.3-3.4-4.1a5.536,5.536,0,0,1-2.6.6,7.091,7.091,0,0,1-2.6-.6c-2.2.7-3.4,2-3.4,4.1v.3A19.014,19.014,0,0,0,25.7,16.7Z"
          fill="#fff"
        />
        <path
          d="M25.5,4.5a3.3,3.3,0,1,0,3.3,3.3,3.4,3.4,0,0,0-3.3-3.3Z"
          fill="#fff"
        />
        <path
          d="M13.4,16.7a17.858,17.858,0,0,0,5.8-.9v-.3c0-2.1-1.2-3.3-3.4-4.1a5.536,5.536,0,0,1-2.6.6,7.091,7.091,0,0,1-2.6-.6c-2.2.7-3.4,2-3.4,4.1v.3a19.8,19.8,0,0,0,6.2.9Z"
          fill="#fff"
        />
        <path
          d="M13.3,4.5a3.3,3.3,0,1,0,3.3,3.3,3.469,3.469,0,0,0-3.3-3.3Z"
          fill="#fff"
        />
        <path
          d="M19.5,19.2a17.858,17.858,0,0,0,5.8-.9V18c0-2.1-1.2-3.3-3.4-4.1a5.536,5.536,0,0,1-2.6.6,7.091,7.091,0,0,1-2.6-.6c-2.2.7-3.4,2-3.4,4.1v.3a19.8,19.8,0,0,0,6.2.9Z"
          fill="#fff"
          stroke="#f47b20"
          stroke-miterlimit="10"
          stroke-width="1.142"
        />
        <path
          d="M19.4,7a3.3,3.3,0,1,0,3.3,3.3A3.4,3.4,0,0,0,19.4,7Z"
          fill="#fff"
          stroke="#f47b20"
          stroke-miterlimit="10"
          stroke-width="1.142"
        />
        <path
          d="M18.4,29.2a4.349,4.349,0,0,0,.7.6,2.92,2.92,0,0,0,.9.2,1.483,1.483,0,0,0,.7-.1c.2-.1.3-.1.5-.2a1.2,1.2,0,0,0,.5-.5c.1-.1.1-.2.2-.4,0-.1.1-.3.1-.4V28c-.1-.2-.1-.4-.3-.5a4.349,4.349,0,0,0-.7-.6,2.92,2.92,0,0,0-.9-.2,1.483,1.483,0,0,0-.7.1c-.2.1-.3.1-.5.2a1.2,1.2,0,0,0-.5.5c-.1.1-.1.2-.2.4,0,.1-.1.3-.1.4v.4C18.2,28.9,18.2,29.1,18.4,29.2Zm.5-1A.349.349,0,0,1,19,28a1.38,1.38,0,0,1,.4-.3,1.268,1.268,0,0,1,.6-.1.9.9,0,0,1,.5.1c.1,0,.2.1.3.1l.3.3.1.1v.3a.349.349,0,0,1-.1.2,1.38,1.38,0,0,1-.4.3,1.268,1.268,0,0,1-.6.1.9.9,0,0,1-.5-.1c-.1,0-.2-.1-.3-.1l-.3-.3-.1-.1Z"
          fill="#fff"
        />
        <path
          d="M23.3,27.8c.3,0,.6-.1.8-.1a2.092,2.092,0,0,0,.6-.4L25,27c.1-.1.1-.3.2-.4v-.8c-.1-.2-.1-.4-.3-.5a4.349,4.349,0,0,0-.7-.6,2.92,2.92,0,0,0-.9-.2,1.683,1.683,0,0,0-1.3.4c-.2.1-.3.3-.4.4s-.1.2-.2.3c0,.1-.1.2-.1.4h0v.5c.1.2.1.4.3.5a4.349,4.349,0,0,0,.7.6,2.958,2.958,0,0,0,1,.2Zm-1.1-1.5v-.2h0v-.2a.1.1,0,0,1,.1-.1h0c0-.1.1-.1.2-.2s.2-.1.3-.2h0a4.331,4.331,0,0,1,.5-.1.9.9,0,0,1,.5.1c.1,0,.2.1.3.1l.3.3.1.1v.3h0c0,.1,0,.1-.1.2,0,.1-.1.1-.1.2h0c-.1.1-.2.2-.3.2h0a.749.749,0,0,1-.5.1.9.9,0,0,1-.5-.1c-.1,0-.2-.1-.3-.1l-.3-.3c-.2,0-.2,0-.2-.1Z"
          fill="#fff"
        />
        <path
          d="M19.2,25.5a2.92,2.92,0,0,0,.9.2,2.92,2.92,0,0,0,.9-.2,1.794,1.794,0,0,0,.7-.5c.1-.1.2-.3.3-.4a.9.9,0,0,0,.1-.5v-.4c-.1-.2-.1-.4-.3-.5a4.349,4.349,0,0,0-.7-.6,2.92,2.92,0,0,0-.9-.2,1.483,1.483,0,0,0-.7.1c-.2.1-.3.1-.5.2a1.024,1.024,0,0,0-.5.6c-.1.1-.1.2-.2.4,0,.1-.1.3-.1.4a1.268,1.268,0,0,0,.1.6c.1.2.2.3.3.5a1.546,1.546,0,0,1,.6.3Zm-.3-1.7a.349.349,0,0,1,.1-.2,1.38,1.38,0,0,1,.4-.3,1.268,1.268,0,0,1,.6-.1.9.9,0,0,1,.5.1c.1,0,.2.1.3.1l.3.3.1.1v.4c0,.1-.1.1-.1.2h0a1.38,1.38,0,0,1-.4.3.9.9,0,0,1-.5.1.749.749,0,0,1-.5-.1c-.2-.1-.3-.2-.4-.2-.1-.1-.1-.1-.1-.2v-.2c-.3-.2-.3-.2-.3-.3Z"
          fill="#fff"
        />
        <path
          d="M17,24.5a1.483,1.483,0,0,0-.7.1c-.2.1-.3.1-.5.2a1.2,1.2,0,0,0-.5.5c-.1.1-.1.2-.2.4,0,.1-.1.3-.1.4v.4a.758.758,0,0,0,.2.4l.3.3a2.092,2.092,0,0,0,.6.4,1.949,1.949,0,0,0,.8.1,1.483,1.483,0,0,0,.7-.1c.2-.1.3-.1.5-.2a1.205,1.205,0,0,0,.5-.5c.1-.1.1-.2.2-.4,0-.1.1-.3.1-.4V26a.6.6,0,0,0-.1-.4c0-.1-.1-.2-.2-.3-.1-.2-.3-.3-.4-.4-.2-.1-.3-.2-.5-.3A1.7,1.7,0,0,0,17,24.5Zm1,1.3h0l.1.1v.2h0v.1a.349.349,0,0,1-.1.2,1.38,1.38,0,0,1-.4.3,1.268,1.268,0,0,1-.6.1.749.749,0,0,1-.5-.1h0c-.1-.1-.3-.1-.3-.2h0c-.1-.1-.1-.1-.1-.2s-.1-.1-.1-.2h0v-.2a.349.349,0,0,1,.1-.2,1.38,1.38,0,0,1,.4-.3,1.268,1.268,0,0,1,.6-.1.9.9,0,0,1,.5.1h0c.1,0,.2.1.3.2C17.8,25.7,17.9,25.7,18,25.8Z"
          fill="#fff"
        />
        <path
          d="M32.5,27.7c0-.1-.1-.1-.1-.2-.1-.1-.1-.2-.2-.2-.1-.1-.2-.1-.3-.2h0a3.644,3.644,0,0,0-1.4-.2,4.869,4.869,0,0,0-1.2.1c-.6.1-1.2.2-1.7.3-.4.1-.8.1-1.2.2v-.1a.6.6,0,0,0-.1-.4,2.769,2.769,0,0,0,.1-.9,3.083,3.083,0,0,0-.1-1,3.506,3.506,0,0,0-.6-1.4,11.985,11.985,0,0,0-1.1-1.2.43.43,0,1,0-.5.7,3.339,3.339,0,0,1,1.1,1.3,6.89,6.89,0,0,1,.3.8,2.2,2.2,0,0,1,.1.8,1.949,1.949,0,0,1-.1.8,2.389,2.389,0,0,1-.7,1.3,5.755,5.755,0,0,1-1.9,1.5,5.954,5.954,0,0,1-2.7.6,6.648,6.648,0,0,1-2.1-.3,5.388,5.388,0,0,1-1.3-.7,4.66,4.66,0,0,1-1.4-1.4A3.552,3.552,0,0,1,15,27a2.769,2.769,0,0,1-.1-.9,1.949,1.949,0,0,1,.1-.8,2.389,2.389,0,0,1,.7-1.3,5.755,5.755,0,0,1,1.9-1.5,5.954,5.954,0,0,1,2.7-.6,6.448,6.448,0,0,1,2,.3.412.412,0,1,0,.2-.8,8.524,8.524,0,0,0-2.3-.3,6.051,6.051,0,0,0-2.4.4,5.226,5.226,0,0,0-1.5.7,5.513,5.513,0,0,0-1.7,1.7,3.7,3.7,0,0,0-.5,1.1,5.019,5.019,0,0,0-.2,1.2,2.769,2.769,0,0,0,.1.9,2.769,2.769,0,0,0-.1.9v1.5a6.963,6.963,0,0,0,.5,2.5,6.35,6.35,0,0,0,2.3,2.8,11.938,11.938,0,0,0,1.6.8,5.822,5.822,0,0,0,1.9.3,6.448,6.448,0,0,0,2-.3,6.532,6.532,0,0,0,2.4-1.5,5.789,5.789,0,0,0,1.5-2.5c0-.1.1-.2.1-.3a3.083,3.083,0,0,0,1-.1c.6-.1,1.1-.2,1.7-.3a8.752,8.752,0,0,1,1.6-.2,2.537,2.537,0,0,1,1.2.2h.4c.1,0,.1-.1.2-.1l.1-.1c0-.1.1-.1.1-.2V27.7Zm-7.3,3.5a6.621,6.621,0,0,1-1.9,2.7,5.3,5.3,0,0,1-1.5.8,4.671,4.671,0,0,1-1.7.3,7.117,7.117,0,0,1-2.1-.4,5.2,5.2,0,0,1-2.4-2,6.109,6.109,0,0,1-.7-1.4,1.38,1.38,0,0,1,.3.4,8.008,8.008,0,0,0,2.1,1.5,5.923,5.923,0,0,0,2.7.5,8.6,8.6,0,0,0,2.4-.4,6.738,6.738,0,0,0,2.8-2Zm-.1-1.4a8.342,8.342,0,0,1-.8,1.2,5.936,5.936,0,0,1-1.8,1.3,6.042,6.042,0,0,1-2.4.5,4.638,4.638,0,0,1-2.1-.4,6.138,6.138,0,0,1-2.4-1.7,7.458,7.458,0,0,1-.7-1.2.6.6,0,0,1-.1-.4A6.2,6.2,0,0,0,17,30.8a7.83,7.83,0,0,0,3.1.7,6.051,6.051,0,0,0,2.4-.4,5.226,5.226,0,0,0,1.5-.7,5.022,5.022,0,0,0,1.3-1.2c.1,0,0,.3-.2.6Zm1.6.5h-.3c0-.3.1-.6.1-.9v-1h.3v1.9Zm2.3-.4c-.5.1-.9.2-1.4.3h-.1V28.3a5.853,5.853,0,0,1,1.3-.2H29Zm2.7-.1a4.869,4.869,0,0,0-1.2-.1h-.6V27.8h.6a2.537,2.537,0,0,1,1.2.2h0Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default FilmsIcon;
