import React from "react";

function GovernmentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <defs>
        <clipPath id="a">
          <rect width="38.7" height="33.8" transform="translate(0.7 3.4)" />
        </clipPath>
      </defs>
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <g clip-path="url(#a)">
          <path
            d="M8.9,24.7h4.8v-.4l-.3-.3a.349.349,0,0,0,.1-.2v-.1a.215.215,0,0,0-.2-.2h-.2V16.2l.2-.1v-.3h-.2v-.5l.4-.4v-.3H9.1v.3l.4.4v.5H9.3v.3l.2.1v7.3H9.3a.215.215,0,0,0-.2.2v.1c0,.1,0,.1.1.2l-.2.2v.5Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M14.8,24.7h4.8v-.4l-.3-.3a.349.349,0,0,0,.1-.2v-.1a.215.215,0,0,0-.2-.2H19V16.2l.2-.1v-.3H19v-.5l.4-.4v-.3H15v.3l.4.4v.5h-.2v.3l.2.1v7.3h-.2a.215.215,0,0,0-.2.2v.1c0,.1,0,.1.1.2l-.2.2v.5Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M20.6,24.7h4.8v-.4l-.2-.3a.349.349,0,0,0,.1-.2v-.1a.215.215,0,0,0-.2-.2h-.2V16.2l.2-.1v-.3h-.2v-.5l.4-.4v-.3H20.9v.3l.4.4v.5H21v.3l.2.1v7.3H21a.215.215,0,0,0-.2.2v.1c0,.1,0,.1.1.2l-.2.2v.5Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M26.5,24.7h4.8v-.4L31,24a.349.349,0,0,0,.1-.2v-.1a.215.215,0,0,0-.2-.2h-.2V16.2l.2-.1v-.3h-.2v-.5l.4-.4v-.3H26.9v.3l.4.4v.5h-.2v.3l.2.1v7.3h-.2a.215.215,0,0,0-.2.2v.1c0,.1,0,.1.1.2l-.2.2v.5Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path d="M32.5,14.1V11.4L20.1,7.9,7.6,11.4v2.7H32.5Z" fill="#fff" />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M33.5,28V26.4h-1V25.1H7.6v1.3h-1V28H5.5v1H34.6V28Z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
export default GovernmentIcon;
