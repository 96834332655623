import React from "react";

function MediaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M26.1,17.7a2.1,2.1,0,1,1,0-4.2,2.1,2.1,0,1,1,0,4.2Zm0-3.3a1.3,1.3,0,1,0,1.3,1.3A1.324,1.324,0,0,0,26.1,14.4Z"
          fill="#fff"
        />
        <path
          d="M26.1,25.1A3.1,3.1,0,1,1,29.2,22,3.052,3.052,0,0,1,26.1,25.1Zm0-5.4A2.3,2.3,0,1,0,28.4,22,2.263,2.263,0,0,0,26.1,19.7Z"
          fill="#fff"
        />
        <path
          d="M31.6,20.9a.749.749,0,0,1-.5-.1,2.122,2.122,0,0,1-1.3-1,1.8,1.8,0,0,1-.2-1.6,2.122,2.122,0,0,1,1-1.3,1.8,1.8,0,0,1,1.6-.2,2.122,2.122,0,0,1,1.3,1,1.8,1.8,0,0,1,.2,1.6,2.122,2.122,0,0,1-1,1.3A2.016,2.016,0,0,1,31.6,20.9Zm0-3.3a1.421,1.421,0,0,0-.6.2,1.242,1.242,0,0,0-.6.8,1.729,1.729,0,0,0,.1,1,1.242,1.242,0,0,0,.8.6,1.729,1.729,0,0,0,1-.1,1.242,1.242,0,0,0,.6-.8,1.729,1.729,0,0,0-.1-1,1.242,1.242,0,0,0-.8-.6A.6.6,0,0,0,31.6,17.6Z"
          fill="#fff"
        />
        <path
          d="M31.6,27.2a1.689,1.689,0,0,1-1-.3,2.209,2.209,0,0,1-1-1.3,2.257,2.257,0,0,1,.2-1.6,2.209,2.209,0,0,1,1.3-1,2.257,2.257,0,0,1,1.6.2,2.209,2.209,0,0,1,1,1.3,2.257,2.257,0,0,1-.2,1.6,2.209,2.209,0,0,1-1.3,1A1.268,1.268,0,0,1,31.6,27.2Zm0-3.3h-.3a1.555,1.555,0,0,0-.8.6,1.147,1.147,0,0,0-.1,1,1.555,1.555,0,0,0,.6.8,1.147,1.147,0,0,0,1,.1,1.555,1.555,0,0,0,.8-.6,1.147,1.147,0,0,0,.1-1,1.555,1.555,0,0,0-.6-.8C32.1,24,31.9,23.9,31.6,23.9Z"
          fill="#fff"
        />
        <path
          d="M26.1,30.4a2.1,2.1,0,1,1,0-4.2,2.1,2.1,0,1,1,0,4.2Zm0-3.3a1.3,1.3,0,1,0,1.3,1.3A1.324,1.324,0,0,0,26.1,27.1Z"
          fill="#fff"
        />
        <path
          d="M20.7,27.2a.749.749,0,0,1-.5-.1,2.122,2.122,0,0,1-1.3-1,1.8,1.8,0,0,1-.2-1.6,2.122,2.122,0,0,1,1-1.3,1.8,1.8,0,0,1,1.6-.2,2.122,2.122,0,0,1,1.3,1,1.8,1.8,0,0,1,.2,1.6,2.122,2.122,0,0,1-1,1.3A2.607,2.607,0,0,1,20.7,27.2Zm0-3.3a1.421,1.421,0,0,0-.6.2,1.242,1.242,0,0,0-.6.8,1.729,1.729,0,0,0,.1,1,1.242,1.242,0,0,0,.8.6,1.729,1.729,0,0,0,1-.1,1.242,1.242,0,0,0,.6-.8,1.729,1.729,0,0,0-.1-1,1.242,1.242,0,0,0-.8-.6A.756.756,0,0,0,20.7,23.9Z"
          fill="#fff"
        />
        <path
          d="M20.6,20.9a1.689,1.689,0,0,1-1-.3,2.209,2.209,0,0,1-1-1.3,2.257,2.257,0,0,1,.2-1.6,2.209,2.209,0,0,1,1.3-1,2.257,2.257,0,0,1,1.6.2,2.209,2.209,0,0,1,1,1.3,2.257,2.257,0,0,1-.2,1.6,2.209,2.209,0,0,1-1.3,1A1.268,1.268,0,0,1,20.6,20.9Zm.1-3.3h-.3a1.555,1.555,0,0,0-.8.6,1.147,1.147,0,0,0-.1,1,1.555,1.555,0,0,0,.6.8,1.147,1.147,0,0,0,1,.1,1.555,1.555,0,0,0,.8-.6,1.147,1.147,0,0,0,.1-1,1.555,1.555,0,0,0-.6-.8A1.952,1.952,0,0,0,20.7,17.6Z"
          fill="#fff"
        />
        <path
          d="M26.1,31.9A9.9,9.9,0,1,1,36,22,9.859,9.859,0,0,1,26.1,31.9Zm0-19A9.1,9.1,0,1,0,35.2,22,9.133,9.133,0,0,0,26.1,12.9Z"
          fill="#fff"
        />
        <path
          d="M25.6,12.7H6.4a.43.43,0,0,1-.4-.4V9.1a.789.789,0,0,1,.8-.8H25.2a.789.789,0,0,1,.8.8v3.2C26,12.6,25.9,12.7,25.6,12.7ZM6.8,11.9H25.2V9.1H6.8v2.8Z"
          fill="#fff"
        />
        <path
          d="M20.5,14.8H7.3a.789.789,0,0,1-.8-.8V12.4a.43.43,0,0,1,.4-.4H25.1a.43.43,0,0,1,.4.4v.2a.43.43,0,0,1-.4.4,9.887,9.887,0,0,0-4.4,1.7C20.6,14.8,20.6,14.8,20.5,14.8ZM7.3,12.7V14h13a10.076,10.076,0,0,1,2.3-1.2H7.3Z"
          fill="#fff"
        />
        <path
          d="M17.5,18.4H8.7a.43.43,0,0,1-.4-.4V14.8a.789.789,0,0,1,.8-.8H20.4c.2,0,.3.1.4.3a.44.44,0,0,1-.1.5,8.722,8.722,0,0,0-2.8,3.5C17.8,18.3,17.7,18.4,17.5,18.4Zm-8.4-.8h8.1a8.26,8.26,0,0,1,2.1-2.8H9.1Z"
          fill="#fff"
        />
        <path
          d="M16.9,20.5H9.7a.789.789,0,0,1-.8-.8V18a.43.43,0,0,1,.4-.4h8.3c.1,0,.3.1.3.2a.3.3,0,0,1,0,.4,6.637,6.637,0,0,0-.6,2A.536.536,0,0,1,16.9,20.5ZM9.7,18.4v1.2h6.9a5.638,5.638,0,0,1,.4-1.2Z"
          fill="#fff"
        />
        <path
          d="M16.8,24.1H6.9a.43.43,0,0,1-.4-.4V20.5a.789.789,0,0,1,.8-.8h9.6c.1,0,.2.1.3.2a.367.367,0,0,1,.1.3,11.019,11.019,0,0,0-.2,1.8,11.135,11.135,0,0,0,.2,1.7.367.367,0,0,1-.1.3A.6.6,0,0,1,16.8,24.1Zm-9.5-.8h9.1a5.7,5.7,0,0,1-.1-1.3,7.569,7.569,0,0,1,.1-1.5H7.3Z"
          fill="#fff"
        />
        <path
          d="M17.5,26.2H7.8a.789.789,0,0,1-.8-.8V23.8a.43.43,0,0,1,.4-.4h9.4c.2,0,.4.1.4.3a7.619,7.619,0,0,0,.6,2c.1.1,0,.3,0,.4C17.7,26.1,17.6,26.2,17.5,26.2ZM7.8,24.1v1.2h9c-.1-.4-.3-.8-.4-1.2Z"
          fill="#fff"
        />
        <path
          d="M20.3,29.8H8.8a.43.43,0,0,1-.4-.4V26.2a.789.789,0,0,1,.8-.8h8.2a.52.52,0,0,1,.4.2,8.492,8.492,0,0,0,2.7,3.5.44.44,0,0,1,.1.5C20.6,29.7,20.4,29.8,20.3,29.8ZM9.2,29h10a11.019,11.019,0,0,1-2-2.8h-8V29Z"
          fill="#fff"
        />
        <path
          d="M26.1,31.9H9.7a.789.789,0,0,1-.8-.8V29.5a.43.43,0,0,1,.4-.4H20.2a.367.367,0,0,1,.3.1,8.929,8.929,0,0,0,5.6,2,.43.43,0,0,1,.4.4C26.6,31.7,26.4,31.9,26.1,31.9ZM9.7,29.8V31H22.2a11.252,11.252,0,0,1-2.1-1.2Z"
          fill="#fff"
        />
        <path
          d="M34.6,31.9H26.1a.4.4,0,0,1,0-.8h8.5a.43.43,0,0,1,.4.4A.366.366,0,0,1,34.6,31.9Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default MediaIcon;
