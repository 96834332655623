import React from "react";

function MilitaryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M30.9,23.1,24.1,19a11.5,11.5,0,0,0,2.1-6.4v-.1l1.6.2h.1a.764.764,0,0,0,.5-.2,2.417,2.417,0,0,0,.6-2.1c-.2-1.3-1.4-2.6-3.6-3.7-.6-.3-1.1-.6-1.7-.9-4.3-2.3-8-4.2-10-.5a9.036,9.036,0,0,0-.8,4.7c0,.3.3.5.6.6l.5.1a5.462,5.462,0,0,0-.1,1.8A11.5,11.5,0,0,0,16,18.9L9.2,23a3.4,3.4,0,0,0-1.7,3v5.1a.472.472,0,0,0,.5.5H32.4a.472.472,0,0,0,.5-.5V26A4.227,4.227,0,0,0,30.9,23.1Z"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.984"
        />
        <path
          d="M15,23.5l-3.6,2a.54.54,0,0,1-.8-.2l-.6-1a.54.54,0,0,1,.2-.8l3.6-2a.54.54,0,0,1,.8.2l.6,1A.554.554,0,0,1,15,23.5Z"
          fill="#fff"
        />
        <path
          d="M16.3,6.7l.6-1.2.6,1.2,1.4.2-1,1,.3,1.4-1.3-.7-1.2.6L16,7.9l-1-1Z"
          fill="#fff"
        />
        <path
          d="M20,19.8c-2.2,0-4.5-2.3-4.8-7.3a8.083,8.083,0,0,1,.1-1.6l9.4,1.4v.2C24.5,17.5,22.2,19.8,20,19.8Z"
          fill="#fff"
        />
        <path
          d="M30,24.2l-.6,1a.54.54,0,0,1-.8.2l-3.6-2a.54.54,0,0,1-.2-.8l.6-1a.54.54,0,0,1,.8-.2l3.6,2A.54.54,0,0,1,30,24.2Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default MilitaryIcon;
