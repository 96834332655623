import React from "react";

function AutomotiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#f47b20" />
      <path
        d="M32.1,15.6h0a.367.367,0,0,1,.3-.1L34,15c.5-.2,1-.6,1-.9,0-.4-.4-.7-1-.8L32.4,13a.865.865,0,0,0-1,.9v.3l-1.3-2.5a4.561,4.561,0,0,0-2.8-2.4A48.366,48.366,0,0,0,20,9a48.367,48.367,0,0,0-7.3.3,4.582,4.582,0,0,0-2.8,2.4L8.6,14.2v-.3a.827.827,0,0,0-1-.9l-1.5.2c-.5.1-1,.4-1,.8a1.069,1.069,0,0,0,1,.9l1.6.5H8v.1a5.453,5.453,0,0,0-3.8,4.9v5.4a1.936,1.936,0,0,0,1,1.7v2a1.324,1.324,0,0,0,1.3,1.3H7.8a1.324,1.324,0,0,0,1.3-1.3V27.8H31v1.7a1.324,1.324,0,0,0,1.3,1.3h1.3a1.324,1.324,0,0,0,1.3-1.3v-2a1.936,1.936,0,0,0,1-1.7V20.4A5.329,5.329,0,0,0,32.1,15.6ZM11.4,22l-5.3-.2a1,1,0,0,1-1-1v-1a.7.7,0,0,1,1-.7l4.3,1.1a2.165,2.165,0,0,1,1.5,1.1C12.2,21.7,12,22,11.4,22ZM9.1,15.3l1.6-3a3.518,3.518,0,0,1,2.2-1.9,54.65,54.65,0,0,1,7-.3c5,0,6.8.2,7,.3h0a3.448,3.448,0,0,1,2.2,1.9l1.6,3a85.773,85.773,0,0,0-10.9-.5A84.21,84.21,0,0,0,9.1,15.3Zm25.8,5.5a1,1,0,0,1-1,1l-5.3.2c-.6,0-.8-.3-.5-.8a3.269,3.269,0,0,1,1.5-1.1L33.9,19c.6-.1,1,.2,1,.7Z"
        fill="#fff"
      />
    </svg>
  );
}
export default AutomotiveIcon;
