import React from "react";

function LegalTwoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M21.4,30.2,21,12.8a1.929,1.929,0,0,1-1,.3,1.689,1.689,0,0,1-1-.3l-.4,17.4c-4.3.2-7.8,1.4-8.7,2.9H30.1C29.1,31.6,25.7,30.4,21.4,30.2Z"
          fill="#fff"
        />
        <path d="M4.3,19.8Z" fill="#fff" />
        <path
          d="M14.4,19.8,10.1,9.3l7.8,2.1V11a1.9,1.9,0,0,1,.5-1.3L8.2,6.9a.945.945,0,0,0-1.1.6.921.921,0,0,0,.7,1.1l1,.3L4.3,19.8a4.835,4.835,0,0,0,5,4.1A4.868,4.868,0,0,0,14.4,19.8Zm-8.8-.1,3.7-8.8L13,19.7Z"
          fill="#fff"
        />
        <path d="M25.6,25.7Z" fill="#fff" />
        <path
          d="M31.2,14.9l.5.1a.945.945,0,0,0,1.1-.6h0a.945.945,0,0,0-.6-1.1L22,10.6V11a1.9,1.9,0,0,1-.5,1.3L30,14.6,25.5,25.7a4.835,4.835,0,0,0,5,4.1,4.765,4.765,0,0,0,5-4.1ZM26.9,25.7l3.7-8.8,3.7,8.8Z"
          fill="#fff"
        />
        <circle
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(18.4 9.5)"
          fill="#fff"
        />
        <path
          d="M20.9,9.2V8.1a.9.9,0,1,0-1.8,0V9.2A2.92,2.92,0,0,1,20,9,1.02,1.02,0,0,1,20.9,9.2Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default LegalTwoIcon;
