import React from "react";
import Text from "components/Text";
import Button from "components/Button";

function GlobalCta({ data, background, className, btnClasses }) {
  return (
    <div
      className={`${
        background
          ? background
          : "bg-gradient-to-r from-[#EB478C] to-[#EC9D5B] max-w-6xl mx-auto"
      }`}
    >
      <div
        className={`${
          className
            ? className
            : "flex flex-col lg:flex-row items-center gap-7 max-w-4xl mx-auto mb-16 px-5 lg:px-0 py-12"
        } `}
      >
        <Text
          className="text-[30px] leading-[46px] text-white font-secondary font-medium max-w-2xl"
          as="p"
        >
          {data.heading}
        </Text>
        <Button
          title={data.text}
          href={data.href}
          className={`uppercase cursor-pointer flex items-center justify-center ${
            btnClasses
              ? btnClasses
              : "w-60 h-16 text-sm2 bg-midBlue hover:bg-hoverBtnBlue text-white font-opensans font-semibold rounded-full transition-colors"
          }`}
        />
      </div>
    </div>
  );
}

export default GlobalCta;
