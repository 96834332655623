import React from "react";

function LegalTranslationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <rect
          width="4.6"
          height="1"
          transform="matrix(0.447, -0.895, 0.895, 0.447, 13.699, 15.798)"
          fill="#fff"
        />
        <rect
          width="1"
          height="4.6"
          transform="translate(16.222 12.163) rotate(-26.552)"
          fill="#fff"
        />
        <path
          d="M16.4,19.1a3.135,3.135,0,0,1-3.3-2.9v-.7h6.6v.7A3.135,3.135,0,0,1,16.4,19.1Zm-2.2-2.6A2.118,2.118,0,0,0,16.4,18a2.143,2.143,0,0,0,2.2-1.5Z"
          fill="#fff"
        />
        <path
          d="M16.7,12.5a2.525,2.525,0,0,1-2.1-1.2c-.1-.3.1-.5.4-.6a.6.6,0,0,1,.6.3,1.932,1.932,0,0,0,1.1.4,2.822,2.822,0,0,0,2-.7,3.957,3.957,0,0,1,2.6-.9.5.5,0,1,1,0,1,2.822,2.822,0,0,0-2,.7A3.683,3.683,0,0,1,16.7,12.5Zm-1-1.4Z"
          fill="#fff"
        />
        <rect
          width="1"
          height="4.6"
          transform="translate(25.826 12.183) rotate(-26.552)"
          fill="#fff"
        />
        <rect
          width="4.6"
          height="1"
          transform="matrix(0.447, -0.895, 0.895, 0.447, 23.325, 15.839)"
          fill="#fff"
        />
        <path
          d="M26.1,19.1a3.135,3.135,0,0,1-3.3-2.9v-.7h6.6v.7A3.135,3.135,0,0,1,26.1,19.1Zm-2.3-2.6A2.118,2.118,0,0,0,26,18a2.143,2.143,0,0,0,2.2-1.5Z"
          fill="#fff"
        />
        <path
          d="M25.8,12.5a4.189,4.189,0,0,1-2.6-.9,2.822,2.822,0,0,0-2-.7.5.5,0,0,1,0-1,4.189,4.189,0,0,1,2.6.9,2.822,2.822,0,0,0,2,.7,2.3,2.3,0,0,0,1.1-.4.486.486,0,0,1,.6-.3c.3.1.5.3.4.6A2.711,2.711,0,0,1,25.8,12.5Zm1-1.4Z"
          fill="#fff"
        />
        <path
          d="M21.2,20.6a.472.472,0,0,1-.5-.5V9.4a.5.5,0,0,1,1,0V20.1C21.8,20.4,21.5,20.6,21.2,20.6Z"
          fill="#fff"
        />
        <path
          d="M19.2,21.1a.5.5,0,0,1,0-1,2.861,2.861,0,0,0,1.7-.4.495.495,0,0,1,.7.7C21,21.1,19.5,21.1,19.2,21.1Z"
          fill="#fff"
        />
        <path
          d="M23.3,21.1c-.3,0-1.8,0-2.4-.7a.495.495,0,0,1,.7-.7,3.53,3.53,0,0,0,1.7.4.5.5,0,0,1,0,1Z"
          fill="#fff"
        />
        <path
          d="M15.2,29.6l-.7-.7.1-.1a1.577,1.577,0,0,1,1.3-.6h7.4A19.364,19.364,0,0,0,27,24.8a33.493,33.493,0,0,0,2.3-3.6,2.65,2.65,0,0,0-1.3-.1c-.7.2-1.1.6-1.7,1.8a.215.215,0,0,1-.2.2l-2.2,1.1-.5-.9,2.1-1.1c.6-1.1,1.2-1.9,2.3-2.1a2.6,2.6,0,0,1,2.6.7.749.749,0,0,1,.1.5,23.967,23.967,0,0,1-2.6,4.2s-3.5,3.7-4.4,3.7H16c-.5,0-.6.1-.8.4Z"
          fill="#fff"
        />
        <path
          d="M22.3,26.2H17.7a.5.5,0,0,1,0-1h4.6c.7,0,1-.4,1-.8s-.3-.8-1-.8h-3a1.389,1.389,0,0,1-1-.5c-.3-.2-.6-.5-.8-.5H15a2.852,2.852,0,0,0-1.7.9c-.7.7-2.1,2-2.1,2a.495.495,0,1,1-.7-.7s1.3-1.2,2.1-2A4.237,4.237,0,0,1,15,21.6h2.5a3.4,3.4,0,0,1,1.5.7c.2.1.4.3.5.3h3a1.846,1.846,0,0,1,2,1.8A2.186,2.186,0,0,1,22.3,26.2Z"
          fill="#fff"
        />
        <path
          d="M12.8,32.8a.6.6,0,0,1-.4-.1L6.8,27.1a.483.483,0,0,1,0-.7l2.5-2.5a.483.483,0,0,1,.7,0l5.6,5.6a.483.483,0,0,1,0,.7l-2.5,2.5C13,32.7,12.9,32.8,12.8,32.8ZM7.9,26.7l4.9,4.9,1.8-1.8L9.7,24.9Z"
          fill="#fff"
        />
        <rect
          width="1"
          height="1.4"
          transform="translate(9.353 27.077) rotate(-45)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default LegalTranslationIcon;
