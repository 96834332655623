import React from "react";

function SyncIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M30.9,22.3a11.325,11.325,0,0,1-5.5,3.2,13.538,13.538,0,0,1-5,.6l.1-1.2a.927.927,0,0,0-1.2-1l-8,2.7a.976.976,0,0,0-.1,1.8l7.5,3.9a.985.985,0,0,0,1.4-.8l.1-1.2C27.5,30.1,31.1,25.1,30.9,22.3Z"
          fill="#fff"
        />
        <path
          d="M30.4,21.1A12.83,12.83,0,0,1,27,23.5c.1-.2.2-.3.2-.5a13.107,13.107,0,0,0,1.3-6.4A7,7,0,0,1,30.4,21.1Z"
          fill="#fff"
        />
        <path
          d="M8.9,18.6a11.325,11.325,0,0,1,5.5-3.2,13.538,13.538,0,0,1,5-.6L19.3,16a.927.927,0,0,0,1.2,1l8-2.7a.976.976,0,0,0,.1-1.8L21.1,8.6a.985.985,0,0,0-1.4.8l-.1,1.2C12.3,10.8,8.7,15.8,8.9,18.6Z"
          fill="#fff"
        />
        <path
          d="M9,20.1a12.83,12.83,0,0,1,3.4-2.4c-.1.2-.2.3-.2.5a13.107,13.107,0,0,0-1.3,6.4A6.116,6.116,0,0,1,9,20.1Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default SyncIcon;
