import React from "react";

const GuaranteeIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.614"
    height="22"
    viewBox="0 0 16.614 22"
  >
    <defs></defs>
    <g transform="translate(-0.002 0.001)">
      <path
        fill="#ff7b16"
        class="a"
        d="M15.475,367.509a2.646,2.646,0,0,1-3.038-1.106,2.652,2.652,0,0,1-1.593-.617q-.085-.072-.164-.15l-1.437,3.007a.653.653,0,0,0,.733.918l2.881-.653,1.3,2.652a.653.653,0,0,0,.586.365h0a.653.653,0,0,0,.585-.371l1.671-3.5a2.652,2.652,0,0,1-1.529-.55Zm0,0"
        transform="translate(-8.783 -349.926)"
      />
      <path
        fill="#ff7b16"
        class="a"
        d="M201.538,365.786a2.652,2.652,0,0,1-1.593.617,2.646,2.646,0,0,1-3.038,1.106,2.652,2.652,0,0,1-1.529.55l1.67,3.5a.653.653,0,0,0,.586.371h0a.653.653,0,0,0,.586-.365l1.3-2.652,2.881.653a.653.653,0,0,0,.733-.918l-1.437-3.007Q201.624,365.715,201.538,365.786Zm0,0"
        transform="translate(-186.982 -349.926)"
      />
      <path
        fill="#ff7b16"
        class="a"
        d="M16.331,8.012a1.342,1.342,0,0,0-.346-1.965.652.652,0,0,1-.275-.755,1.342,1.342,0,0,0-1-1.728A.653.653,0,0,1,14.2,2.95a1.342,1.342,0,0,0-1.528-1.282.653.653,0,0,1-.7-.4A1.341,1.341,0,0,0,10.1.583a.653.653,0,0,1-.791-.14,1.342,1.342,0,0,0-1.995,0,.653.653,0,0,1-.791.14,1.341,1.341,0,0,0-1.875.682.652.652,0,0,1-.7.4A1.342,1.342,0,0,0,2.421,2.95a.653.653,0,0,1-.516.615,1.342,1.342,0,0,0-1,1.727.653.653,0,0,1-.275.755A1.342,1.342,0,0,0,.286,8.012a.653.653,0,0,1,0,.8A1.342,1.342,0,0,0,.633,10.78a.653.653,0,0,1,.275.755,1.342,1.342,0,0,0,1,1.728.653.653,0,0,1,.516.615A1.342,1.342,0,0,0,3.95,15.161a.653.653,0,0,1,.7.4,1.342,1.342,0,0,0,1.875.682.653.653,0,0,1,.791.139,1.342,1.342,0,0,0,1.995,0,.653.653,0,0,1,.791-.139,1.342,1.342,0,0,0,1.875-.682.653.653,0,0,1,.7-.4A1.342,1.342,0,0,0,14.2,13.878a.653.653,0,0,1,.516-.615,1.342,1.342,0,0,0,1-1.728.653.653,0,0,1,.275-.755,1.341,1.341,0,0,0,.346-1.965.653.653,0,0,1,0-.8ZM8.309,14.133a5.719,5.719,0,1,1,5.719-5.719,5.726,5.726,0,0,1-5.719,5.719Zm0,0"
        transform="translate(0)"
      />
      <path
        fill="#ff7b16"
        class="a"
        d="M95.015,93.105a4.414,4.414,0,1,0,4.414,4.414A4.419,4.419,0,0,0,95.015,93.105Zm-2.757,3.6a.653.653,0,0,1,.923,0l1.3,1.3,2.367-2.367a.653.653,0,0,1,.923.923L94.944,99.4a.653.653,0,0,1-.923,0l-1.762-1.762A.653.653,0,0,1,92.258,96.71Zm0,0"
        transform="translate(-86.707 -89.105)"
      />
    </g>
  </svg>
);

export default GuaranteeIcon;
