import * as React from "react";
import Text from "components/Text";
import iconResolver from "utils/icon-resolver";

export const CertifiedComponent = ({ data }) => {
  return (
    <div className="pt-16 pb-[40px] w-full bg-white px-5 lg:px-0">
      <div className="lg:flex lg:w-10/12 2xl:max-w-6xl mx-auto gap-28">
        <div className="max-w-[531px] flex flex-col items-center sm:block">
          {data.icon && (
            <div className="bg-yellow rounded-full p-3 inline-block mb-3">
              <span className="w-9 h-9">{iconResolver(data.icon)}</span>
            </div>
          )}
          <Text
            as="p"
            className="text-base text-lightBluegray font-secondary font-medium mb-6 text-center sm:text-left"
          >
            {data?.subTitle}
          </Text>
          <Text
            highlightedText={data?.highlightWords}
            highlightedColor={data?.highlightedColor}
            as="h2"
            className="mb-16 text-3xl2 text-midBlue font-secondary font-semibold text-center sm:text-left"
          >
            {data?.title}
          </Text>
        </div>
        <div className="max-w-md mb-16">
          <Text
            className="text-lg leading-8 font-opensans mt-3 mb-8  text-midBlue text-center sm:text-left"
            as="div"
          >
            {data.description}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default CertifiedComponent;
