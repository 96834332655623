import React from "react";

function QualifiedTranslatorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M26.3,34.1a17.858,17.858,0,0,0,5.8-.9v-.3c0-2.1-1.2-3.3-3.4-4.1a5.536,5.536,0,0,1-2.6.6,7.091,7.091,0,0,1-2.6-.6c-2.2.7-3.4,2-3.4,4.1v.3a23.234,23.234,0,0,0,6.2.9Z"
          fill="#fff"
        />
        <path
          d="M26.1,21.8a3.3,3.3,0,1,0,3.3,3.3,3.3,3.3,0,0,0-3.3-3.3Z"
          fill="#fff"
        />
        <path
          d="M14.1,34.1a17.858,17.858,0,0,0,5.8-.9v-.3c0-2.1-1.2-3.3-3.4-4.1a5.536,5.536,0,0,1-2.6.6,7.091,7.091,0,0,1-2.6-.6c-2.2.7-3.4,2-3.4,4.1v.3a22.143,22.143,0,0,0,6.2.9Z"
          fill="#fff"
        />
        <path
          d="M13.9,21.8a3.3,3.3,0,1,0,3.3,3.3,3.4,3.4,0,0,0-3.3-3.3Z"
          fill="#fff"
        />
        <path
          d="M20.2,36.5a17.858,17.858,0,0,0,5.8-.9v-.3c0-2.1-1.2-3.3-3.4-4.1a5.536,5.536,0,0,1-2.6.6,7.091,7.091,0,0,1-2.6-.6c-2.2.7-3.4,2-3.4,4.1v.3a19.8,19.8,0,0,0,6.2.9Z"
          fill="#fff"
          stroke="#f47b20"
          stroke-miterlimit="10"
          stroke-width="1.142"
        />
        <path
          d="M20,24.3a3.3,3.3,0,1,0,3.3,3.3A3.3,3.3,0,0,0,20,24.3Z"
          fill="#fff"
          stroke="#f47b20"
          stroke-miterlimit="10"
          stroke-width="1.142"
        />
        <path
          d="M15.6,14l-1.1-2.6a.385.385,0,0,0-.7,0c-.5,1.1-1.4,3.4-1.9,4.5-.2.5.5.7.7.3l.7-1.6H15l.7,1.6a.381.381,0,0,0,.7-.3Zm-1.9-.2.5-1.3.5,1.3Z"
          fill="#fff"
        />
        <path
          d="M28.7,4.1H19a1.538,1.538,0,0,0-1.5,1.5V9.7H9.4a1.538,1.538,0,0,0-1.5,1.5v5.6a1.538,1.538,0,0,0,1.5,1.5h1.1v2.6c0,.2.1.3.2.3.1.1.3,0,.4-.1L14,18.2h5a1.538,1.538,0,0,0,1.5-1.5V12.6h3.6L27,15.5a.6.6,0,0,0,.4.1c.1-.1.2-.2.2-.3V12.7h1.1a1.538,1.538,0,0,0,1.5-1.5V5.6a1.538,1.538,0,0,0-1.5-1.5ZM19.8,16.8a.684.684,0,0,1-.7.7H13.9a.367.367,0,0,0-.3.1L11.2,20V17.9a.43.43,0,0,0-.4-.4H9.4a.684.684,0,0,1-.7-.7V11.2a.684.684,0,0,1,.7-.7h9.7a.684.684,0,0,1,.7.7Zm9.6-5.6a.684.684,0,0,1-.7.7H27.2a.43.43,0,0,0-.4.4v2.1l-2.3-2.3a.367.367,0,0,0-.3-.1H20.5v-.7A1.538,1.538,0,0,0,19,9.8h-.7V5.6a.684.684,0,0,1,.7-.7h9.7a.684.684,0,0,1,.7.7v5.6Z"
          fill="#fff"
        />
        <path
          d="M25.7,7.1a.4.4,0,0,0,0-.8H24.2V6a.4.4,0,1,0-.8,0v.4H22a.351.351,0,1,0,0,.7h.4a3.974,3.974,0,0,0,.9,2.3,4.58,4.58,0,0,1-1.4.7.365.365,0,0,0-.2.5c.1.2.2.3.4.3h.1a5.4,5.4,0,0,0,1.7-.9,5.4,5.4,0,0,0,1.7.9h.1c.2,0,.3-.1.4-.3a.365.365,0,0,0-.2-.5,3.653,3.653,0,0,1-1.4-.7,3.756,3.756,0,0,0,.9-2.3h.3ZM23.9,8.9a2.748,2.748,0,0,1-.7-1.8h1.5A5.209,5.209,0,0,1,23.9,8.9Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default QualifiedTranslatorIcon;
