import React from "react";

function DivorceDecreeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M16.4,33h-.2a.6.6,0,0,1-.3-.6l4-10.6H16.2c-.1,0-.3-.1-.3-.2a.6.6,0,0,1-.1-.4L20.7,5.3c0-.2.3-.3.5-.2s.4.3.3.5L16.8,21h3.8a.758.758,0,0,1,.4.2.3.3,0,0,1,0,.4L16.8,32.8A.52.52,0,0,1,16.4,33Z"
          fill="#fff"
        />
        <path
          d="M10.1,21.7a.43.43,0,0,1-.4-.4V7a.43.43,0,0,1,.4-.4H20.5a.4.4,0,0,1,0,.8H10.6V21.2A.472.472,0,0,1,10.1,21.7Z"
          fill="#fff"
        />
        <path
          d="M19.4,10.9H13.7a.4.4,0,1,1,0-.8h5.7a.4.4,0,0,1,0,.8Z"
          fill="#fff"
        />
        <path
          d="M18.4,13.5H13.6a.4.4,0,0,1,0-.8h4.8a.43.43,0,0,1,.4.4C18.9,13.3,18.7,13.5,18.4,13.5Z"
          fill="#fff"
        />
        <path
          d="M17.6,16.1H13.7a.4.4,0,0,1,0-.8h3.9a.4.4,0,0,1,0,.8Z"
          fill="#fff"
        />
        <path
          d="M16.8,18.7H13.6a.4.4,0,0,1,0-.8h3.2a.43.43,0,0,1,.4.4C17.3,18.5,17.1,18.7,16.8,18.7Z"
          fill="#fff"
        />
        <path
          d="M26.9,16.1H20.5a.4.4,0,0,1,0-.8h6.4a.43.43,0,0,1,.4.4C27.4,15.9,27.2,16.1,26.9,16.1Z"
          fill="#fff"
        />
        <path
          d="M26.9,13.5H21.4a.4.4,0,0,1,0-.8h5.5a.43.43,0,0,1,.4.4C27.4,13.3,27.2,13.5,26.9,13.5Z"
          fill="#fff"
        />
        <path
          d="M26.9,10.9H22.3a.4.4,0,1,1,0-.8h4.6a.43.43,0,0,1,.4.4C27.4,10.7,27.2,10.9,26.9,10.9Z"
          fill="#fff"
        />
        <path
          d="M30.4,20.1a.43.43,0,0,1-.4-.4V7.4H23.3a.4.4,0,0,1,0-.8h7.1a.43.43,0,0,1,.4.4V19.7A.43.43,0,0,1,30.4,20.1Z"
          fill="#fff"
        />
        <path
          d="M19,33h-.1a.55.55,0,0,1-.3-.5l4.6-12.9H19.3c-.1,0-.3-.1-.3-.2a.6.6,0,0,1-.1-.4L23.2,5.4c.1-.2.3-.4.5-.3s.4.3.3.5L19.8,18.7h3.9a.758.758,0,0,1,.4.2.6.6,0,0,1,.1.4L19.4,32.8C19.4,32.9,19.2,33,19,33Z"
          fill="#fff"
        />
        <path
          d="M11,27.6a2.6,2.6,0,0,1-2.3-2.8A2.375,2.375,0,0,1,11,22.3a2.375,2.375,0,0,1,2.3,2.5A2.6,2.6,0,0,1,11,27.6Zm0-4.4a1.561,1.561,0,0,0-1.5,1.6A1.79,1.79,0,0,0,11,26.7a1.79,1.79,0,0,0,1.5-1.9A1.631,1.631,0,0,0,11,23.2Z"
          fill="#fff"
        />
        <path
          d="M14.8,32.4a.43.43,0,0,1-.4-.4l-.1-1a1.779,1.779,0,0,0-1.1-1.5l-.6-.2a2.585,2.585,0,0,1-1.4-2,.382.382,0,0,1,.3-.5c.2,0,.5.1.5.3a1.912,1.912,0,0,0,.9,1.4l.6.2a2.654,2.654,0,0,1,1.6,2.2h0l.1,1c0,.3-.1.5-.4.5Z"
          fill="#fff"
        />
        <path
          d="M7.1,32.4h0c-.3,0-.5-.2-.4-.5l.1-1h0a2.654,2.654,0,0,1,1.6-2.2l.6-.2a1.624,1.624,0,0,0,.9-1.4c.1-.2.3-.4.5-.3s.4.3.3.5a2.425,2.425,0,0,1-1.4,2l-.6.2A1.779,1.779,0,0,0,7.6,31l-.1,1A.43.43,0,0,1,7.1,32.4Z"
          fill="#fff"
        />
        <path
          d="M28.3,27.8A2.567,2.567,0,0,1,26,25.1a2.3,2.3,0,1,1,4.6,0A2.562,2.562,0,0,1,28.3,27.8Zm0-4.3a1.483,1.483,0,0,0-1.4,1.6,1.689,1.689,0,0,0,1.4,1.8c.7,0,1.4-.9,1.4-1.8A1.424,1.424,0,0,0,28.3,23.5Z"
          fill="#fff"
        />
        <path
          d="M32.1,32.4a.43.43,0,0,1-.4-.4l-.1-.9a1.709,1.709,0,0,0-1-1.4l-.6-.2a2.585,2.585,0,0,1-1.4-2,.382.382,0,0,1,.3-.5c.2,0,.5.1.5.3.2.7.4,1.2.9,1.4l.6.2A2.742,2.742,0,0,1,32.4,31h0l.1,1a.43.43,0,0,1-.4.4Z"
          fill="#fff"
        />
        <path
          d="M24.6,32.4h0c-.3,0-.5-.2-.4-.5l.1-1h0a2.452,2.452,0,0,1,1.5-2.1l.6-.2c.5-.2.7-.6.9-1.4.1-.2.3-.4.5-.3s.4.3.3.5a2.585,2.585,0,0,1-1.4,2l-.6.2a1.663,1.663,0,0,0-1,1.4L25,32A.43.43,0,0,1,24.6,32.4Z"
          fill="#fff"
        />
        <path
          d="M28.9,23.6a.367.367,0,0,1-.3-.1.3.3,0,0,1-.4,0,.481.481,0,0,1-.2-.6c.1-.1.7-1.5,1.8-1.8a1.95,1.95,0,0,1,1.3.1c1,.5,1.5.5,1.7.4.2-.2.4-.2.5-.1a.481.481,0,0,1,.2.6v.1h0a3.065,3.065,0,0,1-2.1,1.3.9.9,0,0,1-.5-.1,1.8,1.8,0,0,0-1.5,0c-.2.1-.3.2-.5.2Zm.4-.5Zm0,0Zm.6-.7a5.16,5.16,0,0,1,1.1.2.778.778,0,0,0,.8-.2,5.133,5.133,0,0,1-1.2-.5,1.268,1.268,0,0,0-.6-.1,1.794,1.794,0,0,0-.7.5A1.268,1.268,0,0,0,29.9,22.4Zm2.8-.8Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default DivorceDecreeIcon;
