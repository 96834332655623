import React from "react";

function CostEffectiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M7.3,14.3a3.178,3.178,0,0,0,2,1.6,11.409,11.409,0,0,0,4.1.7,11.842,11.842,0,0,0,4.9-.9A3.33,3.33,0,0,0,20,14.3a9.7,9.7,0,0,1-.1,1.7c-.1.6-.6.9-1.1,1.2a9.643,9.643,0,0,1-3,1,13.59,13.59,0,0,1-6-.4,5.9,5.9,0,0,1-2.2-1.2,1.284,1.284,0,0,1-.4-1A5.283,5.283,0,0,0,7.3,14.3Z"
          fill="#fff"
        />
        <path
          d="M27.4,26.2v1.4c-.1.8-.7,1.2-1.3,1.6a8.344,8.344,0,0,1-3.5,1,12.187,12.187,0,0,1-2.7,0,10.034,10.034,0,0,1-4.1-1.1,2.2,2.2,0,0,1-1.2-1.6V26.2a3.124,3.124,0,0,0,2.1,1.6,13.32,13.32,0,0,0,5.6.7,8.782,8.782,0,0,0,3.9-1.1A2.334,2.334,0,0,0,27.4,26.2Z"
          fill="#fff"
        />
        <path
          d="M7.3,20.7c.3.8.9,1.1,1.6,1.5a8.873,8.873,0,0,0,3.2.8,11.483,11.483,0,0,0,1.9.1.758.758,0,0,0-.2.4c-.1.4-.1.8-.2,1.1,0,.1,0,.2-.2.2a10.88,10.88,0,0,1-3.6-.6A4.835,4.835,0,0,1,7.7,23a1.777,1.777,0,0,1-.4-1C7.3,21.6,7.3,21.2,7.3,20.7Z"
          fill="#fff"
        />
        <path
          d="M34.7,24v1.4a1.541,1.541,0,0,1-.9,1.3A6.855,6.855,0,0,1,31,27.8c-.8.1-1.7.2-2.5.3-.1,0-.2,0-.2-.2V26.6c0-.2.1-.2.3-.2a10.879,10.879,0,0,0,4-.7A4.43,4.43,0,0,0,34.7,24Z"
          fill="#fff"
        />
        <path
          d="M27.3,29.5a3.535,3.535,0,0,1-2,1.6,12.959,12.959,0,0,1-7.3.4,4.858,4.858,0,0,1-3.4-2v1.2a2.014,2.014,0,0,0,1.1,1.6,8.691,8.691,0,0,0,3.2,1.1,15.284,15.284,0,0,0,3.7.1,8.381,8.381,0,0,0,4.1-1.4,1.524,1.524,0,0,0,.6-1A8.534,8.534,0,0,0,27.3,29.5Z"
          fill="#fff"
        />
        <path
          d="M34.7,20.8a2.66,2.66,0,0,1-1,1.1A9.708,9.708,0,0,1,30.3,23c-.7.1-1.5.1-2.3.2a2.822,2.822,0,0,1,.4,1.5v.2h.3a19.67,19.67,0,0,0,2.4-.3A6.654,6.654,0,0,0,34,23.4a1.356,1.356,0,0,0,.7-1.1Z"
          fill="#fff"
        />
        <path
          d="M20.1,18.6v-.9l-.2.2h0l-.6.6a5.535,5.535,0,0,1-2.2,1,13.279,13.279,0,0,1-4,.4,10.21,10.21,0,0,1-3.8-.7,3.178,3.178,0,0,1-2-1.6V19a1.489,1.489,0,0,0,.4.9,3.449,3.449,0,0,0,1.2.9,8.645,8.645,0,0,0,3.5.9,11.379,11.379,0,0,0,3.7-.2,7.822,7.822,0,0,0,2.7-.9A2.3,2.3,0,0,0,20,18.9h0l.1-.3Z"
          fill="#fff"
        />
        <path
          d="M34,18.4a5.7,5.7,0,0,1-2.2,1.1,12.949,12.949,0,0,1-3.9.4,10.723,10.723,0,0,1-3.9-.7,3.178,3.178,0,0,1-2-1.6v1.1h0a1.848,1.848,0,0,0,1.1,1.7,9.708,9.708,0,0,0,3.4,1.1,14.081,14.081,0,0,0,5.4-.3A4.572,4.572,0,0,0,34.3,20a2.177,2.177,0,0,0,.5-.8,8.084,8.084,0,0,0,.1-1.5A7.051,7.051,0,0,0,34,18.4Z"
          fill="#fff"
        />
        <path
          d="M13.7,26.6a.265.265,0,0,0-.3-.3,10.879,10.879,0,0,1-4-.7A3.645,3.645,0,0,1,7.3,24h0v1.3h0a1.9,1.9,0,0,0,1,1.6,2.544,2.544,0,0,1,.7.2,10.075,10.075,0,0,0,4.5.9c.2,0,.2-.1.2-.2Z"
          fill="#fff"
        />
        <path
          d="M27,23.5a5.444,5.444,0,0,0-1.8-1.1,14.342,14.342,0,0,0-4.3-.7,2.485,2.485,0,0,0-.8.1,9.291,9.291,0,0,0-2.9.5,5.382,5.382,0,0,0-2,1,1.415,1.415,0,0,0,0,2.2,5.45,5.45,0,0,0,2.4,1.2,10.919,10.919,0,0,0,4,.4,13.5,13.5,0,0,0,3.5-.6,4.248,4.248,0,0,0,1.7-1A1.35,1.35,0,0,0,27,23.5Zm-2.3,1.4a1.9,1.9,0,0,1-.8.5h-.1l.4.2-1.2.6-.7-.3a9.306,9.306,0,0,1-1.8,0h-.1c-.2,0-.4-.1-.7-.1h-.1l-.3-.1h0l.8-.4h0a7.471,7.471,0,0,0,1.1.2h.6a2.92,2.92,0,0,0,.9-.2c.1,0,.1-.1.2-.1s.1-.1.1-.2h0v-.1h0c-.1-.2-.4-.1-.4-.1h-.5l-.5.1c-.4,0-.8.1-1.1.1h0a8.253,8.253,0,0,1-1.6,0h-.1a1.682,1.682,0,0,1-1.3-.5.375.375,0,0,1,0-.6,7.762,7.762,0,0,1,.7-.4,1.38,1.38,0,0,0-.4-.3V23h.1l1.1-.3.6.3a10.029,10.029,0,0,1,2.7.2c.2,0,.3.1.4.1h0l-.1.1h0l-.7.4a6.622,6.622,0,0,0-1.5-.1h-.6a1.421,1.421,0,0,0-.6.2c-.1,0-.1.1-.2.1,0-.1,0,0,0,0,0,.2.5.3.5.3h0l.6-.1c.5-.1,1-.1,1.5-.2H24a1.207,1.207,0,0,1,.8.5v.1h0a.774.774,0,0,0-.1.3Z"
          fill="#fff"
        />
        <path
          d="M13.7,9.6c-3.5,0-6.4,1.3-6.4,2.9s2.9,2.9,6.4,2.9,6.4-1.3,6.4-2.9S17.3,9.6,13.7,9.6Zm3.8,3.5a1.9,1.9,0,0,1-.8.5h-.1l.4.2-1.3.5-.6-.3a5.306,5.306,0,0,1-1.9,0h-.1c-.2,0-.5-.1-.7-.1h-.1l-.3-.2h0l.8-.4h0a7.471,7.471,0,0,0,1.1.2h.6a2.92,2.92,0,0,0,.9-.2c.1,0,.1-.1.2-.1s.1-.1.1-.2h0v-.1h0c-.1-.2-.4-.1-.4-.1h-.5l-.6.1c-.4,0-.8.1-1.2.1H11.4a2.07,2.07,0,0,1-1.4-.5c-.2-.2-.2-.4.1-.6a5.436,5.436,0,0,1,.8-.4c-.1-.1-.3-.2-.4-.3l-.1-.1h.1l1.1-.3.6.3a10.88,10.88,0,0,1,2.8.2c.2,0,.3.1.4.1h0l-.1.1h0l-.7.4a7.083,7.083,0,0,0-1.5-.1h-.6a1.421,1.421,0,0,0-.6.2c-.1,0-.1.1-.2.1l-.2.2c0,.3.5.3.5.3h0l.6-.1c.5-.1,1-.1,1.6-.2h2.4a1.207,1.207,0,0,1,.8.5v.1h0c.2,0,.1.1.1.2Z"
          fill="#fff"
        />
        <path
          d="M28.3,12.8c-3.5,0-6.4,1.3-6.4,2.9s2.9,2.9,6.4,2.9,6.4-1.3,6.4-2.9S31.8,12.8,28.3,12.8Zm3.9,3.5a2.623,2.623,0,0,1-.8.6h-.1l.5.2-1.3.5-.7-.4a5.13,5.13,0,0,1-1.9,0h-.1c-.2,0-.5-.1-.7-.1H27l-.4-.1h0l.9-.4h0a7.072,7.072,0,0,0,1.2.2h.6a2.92,2.92,0,0,0,.9-.2c.1,0,.1-.1.2-.1s.1-.1.1-.2h0v-.1h0c-.1-.2-.5-.1-.5-.1h-.5l-.6-.1c-.4,0-.8.1-1.2.1h0a13.054,13.054,0,0,1-1.7.1h-.1a1.794,1.794,0,0,1-1.4-.5c-.3-.2-.2-.4.1-.7a5.436,5.436,0,0,1,.8-.4c-.1-.1-.3-.2-.4-.3l-.1-.1H25l1.2-.4.6.3a11.6,11.6,0,0,1,2.9.2c.2,0,.3.1.4.1h0l-.1.2h0l-.7.4a7.083,7.083,0,0,0-1.5-.1h-.6a1.421,1.421,0,0,0-.6.2c-.1,0-.1.1-.2.1l-.2.2c0,.3.5.3.5.3h0l.7-.1c.5-.1,1.1-.1,1.6-.2h2.5s.7.1.8.5V16h0a.367.367,0,0,1-.1.3Z"
          fill="#fff"
        />
        <path
          d="M20.8,9.5v2.8a.43.43,0,0,0,.4.4h1.5a.43.43,0,0,0,.4-.4V9.5h1.8L22,5.7,19,9.5Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default CostEffectiveIcon;
