import React from "react";

function AccurateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.3)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.3)"
          fill="#f47b20"
        />
        <path
          d="M21.2,19.9a1.268,1.268,0,0,1,.6.1.365.365,0,0,0,.5-.2h0c.1-.3,0-.6-.3-.6a1.077,1.077,0,0,0-.8-.2,3.116,3.116,0,0,0-3.1,3.1,3.031,3.031,0,0,0,3.1,3A3.116,3.116,0,0,0,24.3,22h0c0-.3-.2-.5-.5-.4h0a.43.43,0,0,0-.4.4h0a2.1,2.1,0,0,1-4.2,0A1.97,1.97,0,0,1,21.2,19.9Z"
          fill="#fff"
        />
        <path
          d="M21,22.1h0a.775.775,0,0,0,1,.2l7.8-5.7.9.1a3,3,0,0,0,2.4-.7l2.7-1.9-2.9-.6.4-3-2.7,1.9a3.755,3.755,0,0,0-1.4,2.1l-.2.9-7.8,5.7A.775.775,0,0,0,21,22.1Z"
          fill="#fff"
        />
        <path
          d="M19.2,10.8h3.6a.43.43,0,0,0,.4-.4v-1a.43.43,0,0,0-.4-.4H19.2a.43.43,0,0,0-.4.4v1A.366.366,0,0,0,19.2,10.8Z"
          fill="#fff"
        />
        <path
          d="M14.4,17.2h0A12.094,12.094,0,0,1,16,15.6H4.6a.8.8,0,1,0,0,1.6h9.8Z"
          fill="#fff"
        />
        <path
          d="M12.4,13.9h7.3a6.6,6.6,0,0,1,1.4-.1,8.354,8.354,0,0,1,5.5,2.1l1.2-.9-.5-.5.7-.9-1-.8-.7.9a9.646,9.646,0,0,0-3.8-1.4V11.1H19.9v1.1H12.6a.86.86,0,0,0-.8.8C11.6,13.6,11.9,13.9,12.4,13.9Z"
          fill="#fff"
        />
        <path
          d="M26.9,12.5l1.3,1c.2.1.5.1.6-.1l.1-.1c.1-.2.1-.5-.1-.6l-1.3-1c-.2-.1-.5-.1-.6.1l-.1.1C26.7,12.1,26.7,12.4,26.9,12.5Z"
          fill="#fff"
        />
        <path
          d="M14.6,26.8a8.636,8.636,0,0,1-1-1.6H9a.8.8,0,1,0,0,1.6h5.6Z"
          fill="#fff"
        />
        <path
          d="M13.1,20.4h0a7.494,7.494,0,0,1,.5-1.6h-6a.8.8,0,1,0,0,1.6h5.5Z"
          fill="#fff"
        />
        <path
          d="M28.4,18.6a8.841,8.841,0,0,1,.7,3.3A8.066,8.066,0,0,1,21,30a7.913,7.913,0,0,1-4.8-1.6H12.8a.8.8,0,1,0,0,1.6H16a9.829,9.829,0,0,0,5.1,1.5,9.6,9.6,0,0,0,9.6-9.6,9.021,9.021,0,0,0-.9-4Z"
          fill="#fff"
        />
        <path
          d="M12.1,22H9.9a.8.8,0,1,0,0,1.6h3.2c-.1-.5-.1-1.1-.2-1.6Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default AccurateIcon;
