import React from "react";

function TourismIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M15.7,5.4h1.1v5.5h1.1V5.4h4.4v5.5h1.1V5.4h1.1a.645.645,0,0,0,.6-.6V4a.645.645,0,0,0-.6-.6H15.7a.645.645,0,0,0-.6.6v.8A.576.576,0,0,0,15.7,5.4Z"
          fill="#fff"
        />
        <path
          d="M14,35.5a.86.86,0,0,0,.8.8h1.8a.86.86,0,0,0,.8-.8V34.4H14Z"
          fill="#fff"
        />
        <path
          d="M22.8,35.5a.86.86,0,0,0,.8.8h1.8a.86.86,0,0,0,.8-.8V34.4H22.8v1.1Z"
          fill="#fff"
        />
        <path
          d="M11.7,31.4a1.9,1.9,0,0,0,1.9,1.9h13a1.9,1.9,0,0,0,1.9-1.9V16.1H11.7Zm3.3-5,.1-.1a.959.959,0,0,1,.9-.2l1.1.4L19.4,24l-2.8-2.2a.3.3,0,0,1,0-.4,1.287,1.287,0,0,1,1.2-.1l3,1.1,1.7-1.9a3.6,3.6,0,0,1,2.1-1.1c.4,0,.7.3.6.6a3.6,3.6,0,0,1-1.1,2.1L22.3,24l1.1,3a1.262,1.262,0,0,1-.1,1.2c-.1.1-.3.2-.4,0l-2.2-2.8-2.5,2.3.4,1.1a.8.8,0,0,1-.2.9l-.1.1a.3.3,0,0,1-.4,0L17,28.3a1.7,1.7,0,0,0-.6-.6L15,26.8A.3.3,0,0,1,15,26.4Z"
          fill="#fff"
        />
        <path
          d="M26.6,12h-13a1.9,1.9,0,0,0-1.9,1.9V15H28.4V13.9A1.88,1.88,0,0,0,26.6,12Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default TourismIcon;
