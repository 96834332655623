import React from "react";

function MedicalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3411"
        data-name="Group 3411"
        transform="translate(801 -2579)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="36"
          height="36"
          rx="18"
          transform="translate(-801 2579)"
          fill="#ff7b16"
        />
        <path
          id="Icon_map-health"
          data-name="Icon map-health"
          d="M17.435,6.434a.354.354,0,0,0-.348-.357H12.56a.354.354,0,0,1-.348-.357V1.077A.354.354,0,0,0,11.863.72H6.292a.354.354,0,0,0-.348.357V5.72a.354.354,0,0,1-.348.357H1.068a.354.354,0,0,0-.348.357v5.714a.354.354,0,0,0,.348.357H5.6a.354.354,0,0,1,.348.357v4.643a.354.354,0,0,0,.348.357h5.572a.354.354,0,0,0,.348-.357V12.862a.354.354,0,0,1,.348-.357h4.527a.354.354,0,0,0,.348-.357Z"
          transform="translate(-792.078 2587.709)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default MedicalIcon;
