import React from "react";

function AudioTranscriptionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M32,24a.608.608,0,0,0-.8,0l-1.3,1.3v-1a.43.43,0,0,0-.4-.4H22.6a.43.43,0,0,0-.4.4v1.6A1.526,1.526,0,0,0,21.1,27c-.1.4-1.3,4.2-1.3,4.2h4.3l.6-3.1a.215.215,0,0,1,.2-.2h3.8a1.231,1.231,0,0,0,1-.5l2.2-2.8c.3-.1.3-.4.1-.6Zm-9,1.8V24.7h6.2v1.1Z"
          fill="#fff"
        />
        <path
          d="M19.3,22.4a.354.354,0,0,0-.5.5,3.1,3.1,0,0,1,1,2.4,3.5,3.5,0,0,1-.8,2.2.446.446,0,0,0,0,.5.31.31,0,0,0,.2.1.367.367,0,0,0,.3-.1,4.268,4.268,0,0,0,1-2.7A3.847,3.847,0,0,0,19.3,22.4Z"
          fill="#fff"
        />
        <path
          d="M17.5,24.1a.283.283,0,0,0,0,.5,1.08,1.08,0,0,1,.3.7.749.749,0,0,1-.1.5.44.44,0,0,0,.1.5H18c.1,0,.3-.1.3-.2a1.486,1.486,0,0,0,.2-.9,2.45,2.45,0,0,0-.4-1.2C17.9,24,17.6,23.9,17.5,24.1Z"
          fill="#fff"
        />
        <path
          d="M15.7,20.8a4.016,4.016,0,0,1,.4-1.6,8.084,8.084,0,0,0,.1-1.5l-.4-1.2a13.2,13.2,0,0,1-8.3,6.7v8.1h3a9.206,9.206,0,0,1,.6-2.4,12.955,12.955,0,0,1-1.8-.5c-.3-.1-.4-.3-.3-.5a.55.55,0,0,1,.5-.3s1.1.3,2.4.6l.3.1a8.807,8.807,0,0,0,2.9.3c.3-.1.2-1.5.2-1.7,0-.1.2-.2.4-.3a.247.247,0,0,0,.1-.4l-.6-.4c-.1-.1-.1-.3,0-.3l1.1-.4c.1-.1.2-.2.1-.3a3.446,3.446,0,0,1-.5-1.1c0-.1.4-.3.8-.4a.477.477,0,0,0,.2-.8C16.4,21.9,15.7,21.1,15.7,20.8Z"
          fill="#fff"
        />
        <path
          d="M15.5,14.8h0a.349.349,0,0,0,.2.1c.5.4,1.2,1.4,1.7,3.8,0,.2.3.2.4,0a6.6,6.6,0,0,0-1.4-6.5,7.623,7.623,0,0,0-7-2.2,11.076,11.076,0,0,0-1.9.2V22.1l1.4-.6A14.335,14.335,0,0,0,15.5,14.8Z"
          fill="#fff"
        />
        <path
          d="M28.5,10.1H23a4.012,4.012,0,0,0-4,4V17a4.012,4.012,0,0,0,4,4h1.6l.8,1.6c.1.1.2.2.3.2h0c.1,0,.3-.1.3-.2l.7-1.6h1.7a4.012,4.012,0,0,0,4-4V14.1A3.807,3.807,0,0,0,28.5,10.1Z"
          fill="#fff"
        />
        <path
          d="M26.1,17,25,15.9h0a7.847,7.847,0,0,0,1.6-2.8h1.3v-.9h-3v-.9H24v.9H21V13h4.8a5.935,5.935,0,0,1-1.4,2.3,5.6,5.6,0,0,1-1-1.5h-.9a8.577,8.577,0,0,0,1.3,2L21.6,18l.6.6,2.2-2.2,1.4,1.4.3-.8Zm2.5-2.2h-.9l-2,5.2h.9l.5-1.3h2.1l.5,1.3h.9Zm-1.2,3,.7-1.9.7,1.9Z"
          fill="#f47b20"
        />
      </g>
    </svg>
  );
}
export default AudioTranscriptionIcon;
