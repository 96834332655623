import React from "react";

function LegalInterpretingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M23.8,28.6H14.5a.472.472,0,0,1-.5-.5V26.8a1.367,1.367,0,0,1,1.4-1.4H23a1.367,1.367,0,0,1,1.4,1.4v1.3A.634.634,0,0,1,23.8,28.6Zm-8.7-1h8.3v-.8a.43.43,0,0,0-.4-.4H15.5a.43.43,0,0,0-.4.4v.8Z"
          fill="#fff"
        />
        <path
          d="M17.6,23.2a.55.55,0,0,1-.5-.3l-1.8-5.4v-.4l.3-.3,3.5-1.2c.3-.1.6.1.6.3l1.8,5.4v.4l-.3.3-3.5,1.2Zm-1.2-5.6,1.5,4.5,2.5-.8-1.5-4.5Z"
          fill="#fff"
        />
        <path
          d="M5.9,25.1a1.5,1.5,0,0,1-1.4-1,1.378,1.378,0,0,1,.9-1.8l10.8-3.7c.3-.1.6.1.6.3l.6,1.8v.4l-.3.3L6.4,25C6.2,25,6.1,25.1,5.9,25.1Zm10.2-5.4L5.8,23.2a.55.55,0,0,0-.3.5c.1.3.3.4.5.3l10.3-3.5Z"
          fill="#fff"
        />
        <path
          d="M5.9,25.1a1.5,1.5,0,0,1-1.4-1,1.378,1.378,0,0,1,.9-1.8l10.8-3.7c.3-.1.6.1.6.3l.6,1.8v.4l-.3.3L6.4,25C6.2,25,6.1,25.1,5.9,25.1Zm10.2-5.4L5.8,23.2a.55.55,0,0,0-.3.5c.1.3.3.4.5.3l10.3-3.5Z"
          fill="#fff"
        />
        <path
          d="M15.2,17.9a.749.749,0,0,1-.5-.1,1.057,1.057,0,0,1-.6-.7.972.972,0,0,1,.1-.9,1.057,1.057,0,0,1,.7-.6l4.2-1.4a.972.972,0,0,1,.9.1,1.057,1.057,0,0,1,.6.7.972.972,0,0,1-.1.9,1.057,1.057,0,0,1-.7.6l-4.2,1.4Zm4.1-2.8-4.2,1.4a.1.1,0,0,0-.1.1v.1a.1.1,0,0,0,.1.1h.1l4.2-1.4a.1.1,0,0,0,.1-.1v-.1a.1.1,0,0,0-.1-.1Z"
          fill="#fff"
        />
        <path
          d="M17.5,24.6a.749.749,0,0,1-.5-.1,1.057,1.057,0,0,1-.6-.7.972.972,0,0,1,.1-.9,1.057,1.057,0,0,1,.7-.6l4.2-1.4a.972.972,0,0,1,.9.1,1.057,1.057,0,0,1,.6.7.972.972,0,0,1-.1.9,1.374,1.374,0,0,1-.8.5l-4.2,1.4A.367.367,0,0,1,17.5,24.6Zm4.1-2.8-4.2,1.4a.1.1,0,0,0-.1.1v.1a.1.1,0,0,0,.1.1h.1l4.2-1.4a.1.1,0,0,0,.1-.1v-.1a.1.1,0,0,0-.1-.1Z"
          fill="#fff"
        />
        <path
          d="M8,24.4a.55.55,0,0,1-.5-.3l-.6-1.8a.61.61,0,0,1,.3-.7c.3-.1.6.1.6.3l.6,1.8c.1.3-.1.6-.3.6C8.1,24.4,8.1,24.4,8,24.4Z"
          fill="#fff"
        />
        <path
          d="M29.5,32.2H12.6a.472.472,0,0,1-.5-.5V24.4a.5.5,0,0,1,1,0v6.8H29v-19L25.7,8.9H13.1v9.4a.5.5,0,0,1-1,0V8.3a.472.472,0,0,1,.5-.5H25.8c.1,0,.3.1.4.1l3.6,3.6c.1.1.1.2.1.4V31.6A.493.493,0,0,1,29.5,32.2Z"
          fill="#fff"
        />
        <path
          d="M29.5,12.5H25.9a.472.472,0,0,1-.5-.5V8.3a.55.55,0,0,1,.3-.5.661.661,0,0,1,.6.1l3.6,3.6a.614.614,0,0,1,.1.6A.51.51,0,0,1,29.5,12.5Zm-3.2-1h1.9L26.3,9.6Z"
          fill="#fff"
        />
        <path
          d="M23.5,12.2H16.2a.5.5,0,0,1,0-1h7.3a.472.472,0,0,1,.5.5A.642.642,0,0,1,23.5,12.2Z"
          fill="#fff"
        />
        <path
          d="M27,16.9H23.5a.5.5,0,0,1,0-1H27a.472.472,0,0,1,.5.5A.536.536,0,0,1,27,16.9Z"
          fill="#fff"
        />
        <path
          d="M27,19.4H23.5a.5.5,0,0,1,0-1H27a.472.472,0,0,1,.5.5A.642.642,0,0,1,27,19.4Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default LegalInterpretingIcon;
