import React from "react";

function SecureConfidentialIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path d="M23.7,13.6h5.1L23.7,8.4Z" fill="#fff" />
        <path
          d="M27.7,28.4a1.538,1.538,0,0,0,1.5-1.5V15.1H22.9a.684.684,0,0,1-.7-.7V8.1H14.6a1.538,1.538,0,0,0-1.5,1.5v9.9h1.3a4.118,4.118,0,0,1,4.1,4.1v.3A2.565,2.565,0,0,1,20,26.3v2.3h7.7Z"
          fill="#fff"
        />
        <path
          d="M14.4,20.9h-.9a2.647,2.647,0,0,0-2.6,2.6V25h-.3a1.216,1.216,0,0,0-1.2,1.2V31a1.216,1.216,0,0,0,1.2,1.2h6.8A1.216,1.216,0,0,0,18.6,31V26.2A1.216,1.216,0,0,0,17.4,25H17V23.4A2.562,2.562,0,0,0,14.4,20.9Zm.3,9.2a.215.215,0,0,1-.2.2H13.4a.215.215,0,0,1-.2-.2l.4-1.5a.9.9,0,1,1,1.3-.8.792.792,0,0,1-.5.8Zm.7-5.1h-3V23.4a1,1,0,0,1,1-1h.9a1,1,0,0,1,1,1V25Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default SecureConfidentialIcon;
