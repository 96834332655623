import React from "react";

function NativeLinguistIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M15,32.4H12.9c-.2-1.6-.5-3.2-.7-4.8H12c-.3,1.6-.5,3.2-.8,4.8H8.9V25.6a2.945,2.945,0,0,0-.8-2.5,3.594,3.594,0,0,1-.7-1.8c-.1-1.4,0-2.9,0-4.3a2.635,2.635,0,0,1,2.4-2.7c1.4-.1,2.9,0,4.3,0-1.1,2.5-.5,5.1-.5,7.7a7.842,7.842,0,0,0,.9,2.4c.1.2.3.4.3.6C15,27.4,15,29.9,15,32.4Z"
          fill="#fff"
        />
        <path
          d="M31,32.4H28.7c-.3-1.6-.5-3.2-.8-4.8h-.1c-.2,1.6-.5,3.2-.8,4.8H24.9V26.7a4.622,4.622,0,0,1,.7-3,4.282,4.282,0,0,0,.6-2.2,36.854,36.854,0,0,0,0-4.7,14.7,14.7,0,0,0-.5-2.6,38.663,38.663,0,0,1,4.8.3A2.7,2.7,0,0,1,32.4,17c0,1.5.1,3,0,4.5a4.535,4.535,0,0,1-1,2.1,1.845,1.845,0,0,0-.5,1.2v6.8A1.95,1.95,0,0,1,31,32.4Z"
          fill="#fff"
        />
        <path
          d="M20.1,13.1a3.309,3.309,0,0,1-3.3-3.2,3.2,3.2,0,0,1,6.4,0A3.2,3.2,0,0,1,20.1,13.1Z"
          fill="#fff"
        />
        <path
          d="M15,10.7a2.759,2.759,0,0,1-2.8,2.9,2.858,2.858,0,0,1-3.1-3,2.95,2.95,0,1,1,5.9.1Z"
          fill="#fff"
        />
        <path
          d="M25,10.7a2.95,2.95,0,0,1,5.9,0,2.808,2.808,0,0,1-3,2.9A2.783,2.783,0,0,1,25,10.7Z"
          fill="#fff"
        />
        <path
          d="M24.9,16.5a2.786,2.786,0,0,0-3-2.8H18.1a3.1,3.1,0,0,0-3.1,3v4.6a3.367,3.367,0,0,0,1.2,2.6,1.389,1.389,0,0,1,.5,1v8.6h1.7c.6,0,.8-.1.9-.7.2-1.5.5-3.1.7-4.6h.2c.3,1.8.6,3.5.8,5.3h2.2V25.2a1.826,1.826,0,0,1,.6-1.5,3.206,3.206,0,0,0,1-2A45.13,45.13,0,0,0,24.9,16.5Zm-1.7,3.9H21.3v1.9H18.8V20.4H16.9V17.9h1.9V16h2.5v1.9h1.9Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default NativeLinguistIcon;
