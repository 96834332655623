import React from "react";

function InterpretingByPhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M26.2,15.7a2.051,2.051,0,0,1-2.1-2.1v-.8a2.1,2.1,0,0,1,4.2,0v.8A2.051,2.051,0,0,1,26.2,15.7Zm0-4.2a1.208,1.208,0,0,0-1.3,1.3v.8a1.3,1.3,0,1,0,2.6,0v-.8A1.208,1.208,0,0,0,26.2,11.5Z"
          fill="#fff"
        />
        <rect
          width="0.8"
          height="4.2"
          transform="translate(27.9 19.5)"
          fill="#fff"
        />
        <rect
          width="0.8"
          height="2.5"
          transform="translate(27.9 27.4)"
          fill="#fff"
        />
        <rect
          width="0.8"
          height="5.9"
          transform="translate(25.4 24.1)"
          fill="#fff"
        />
        <path
          d="M32.1,27.8H27.5a.43.43,0,0,1-.4-.4V23.6a.43.43,0,0,1,.4-.4h4.6a.43.43,0,0,1,.4.4v3.8A.366.366,0,0,1,32.1,27.8ZM27.9,27h3.8V24.1H27.9Z"
          fill="#fff"
        />
        <rect
          width="0.8"
          height="10.5"
          transform="translate(23.7 19.5)"
          fill="#fff"
        />
        <path
          d="M24.1,24.5h-.4c-1,0-1.7-.8-1.7-2.1V19c0-1.6,1.8-2.9,4.2-2.9s4.2,1.3,4.2,2.9v4.6h-.8V19c0-1.1-1.5-2.1-3.4-2.1-1.8,0-3.4,1-3.4,2.1v3.4c0,.5.1,1.3.8,1.3H24v.8Z"
          fill="#fff"
        />
        <path
          d="M14.3,17.9a3.807,3.807,0,0,1,2,5.1.67.67,0,0,0,.3.8c.3.2.6,0,.8-.3a5.067,5.067,0,0,0-2.6-6.6.67.67,0,0,0-.8.3A.46.46,0,0,0,14.3,17.9Z"
          fill="#fff"
        />
        <path
          d="M15.2,15.9a5.972,5.972,0,0,1,3.1,8,.6.6,0,1,0,1.1.5,7.23,7.23,0,0,0-3.7-9.5.67.67,0,0,0-.8.3C14.7,15.5,14.9,15.7,15.2,15.9Z"
          fill="#fff"
        />
        <path
          d="M18.8,27.6l-3-3a.616.616,0,0,0-.7-.1l-1.5.6a.778.778,0,0,1-.8-.2v-.1h0a11.47,11.47,0,0,1-1.6-2.9,14.944,14.944,0,0,1-.8-3.2h0v-.1c0-.3.2-.5.5-.7l1.5-.6a.822.822,0,0,0,.4-.6l.1-4.2c0-.2-.2-.5-.4-.5a5.96,5.96,0,0,0-2.4,0h0c-2.9,1.1-3.7,6-1.7,11,1.9,5,5.8,8.1,8.7,7a4.3,4.3,0,0,0,1.7-1.6A.6.6,0,0,0,18.8,27.6Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default InterpretingByPhoneIcon;
