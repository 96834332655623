import React from "react";

function FastDeliveryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3409"
        data-name="Group 3409"
        transform="translate(745 -2503)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="36"
          height="36"
          rx="18"
          transform="translate(-745 2503)"
          fill="#ff7b16"
        />
        <path
          id="Icon_awesome-shipping-fast"
          data-name="Icon awesome-shipping-fast"
          d="M19.739,11.135h-.506V7.715a1.518,1.518,0,0,0-.446-1.072l-3.16-3.16a1.518,1.518,0,0,0-1.072-.446h-1.4V1.518A1.519,1.519,0,0,0,11.641,0h-8.1A1.519,1.519,0,0,0,2.025,1.518V3.037H.253A.254.254,0,0,0,0,3.29V3.8a.254.254,0,0,0,.253.253h8.6A.254.254,0,0,1,9.11,4.3v.506a.254.254,0,0,1-.253.253H1.265a.254.254,0,0,0-.253.253V5.82a.254.254,0,0,0,.253.253h6.58a.254.254,0,0,1,.253.253v.506a.254.254,0,0,1-.253.253H.253A.254.254,0,0,0,0,7.339v.506A.254.254,0,0,0,.253,8.1h6.58a.254.254,0,0,1,.253.253v.506a.254.254,0,0,1-.253.253H2.025v4.049a3.037,3.037,0,1,0,6.074,0h4.049a3.037,3.037,0,1,0,6.074,0h1.518a.508.508,0,0,0,.506-.506V11.641A.508.508,0,0,0,19.739,11.135ZM5.061,14.678A1.518,1.518,0,1,1,6.58,13.159,1.519,1.519,0,0,1,5.061,14.678Zm10.123,0A1.518,1.518,0,1,1,16.7,13.159,1.519,1.519,0,0,1,15.184,14.678ZM17.714,8.1H13.159V4.555h1.4l3.16,3.16Z"
          transform="translate(-736.623 2512.902)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default FastDeliveryIcon;
