import React from "react";

function EducationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_3405"
        data-name="Group 3405"
        transform="translate(801 -2503)"
      >
        <rect
          id="Rectangle_1544"
          data-name="Rectangle 1544"
          width="36"
          height="36"
          rx="18"
          transform="translate(-801 2503)"
          fill="#ff7b16"
        />
        <path
          id="Icon_map-university"
          data-name="Icon map-university"
          d="M13.9,17.425A11.292,11.292,0,0,0,6.76,19.641v2.516H21.033V19.641A11.294,11.294,0,0,0,13.9,17.425ZM27,16.134,13.861,10.08.729,16.134l2.189,1v1.541c-.549.114-.551.4-.551.743a.918.918,0,0,0,.194.58l-.751,1.607H4.489L3.745,20a.883.883,0,0,0,.228-.552c0-.341-.506-.657-.506-.771V17.394l2.745,1.233v-.109c1.647-1.432,4.54-2.341,7.684-2.341s6.039.909,7.686,2.341v.109Z"
          transform="translate(-796.729 2504.92)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default EducationIcon;
