import React from "react";

function OnsiteInterpretationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <defs>
        <clipPath id="a">
          <rect width="29.1" height="29.1" transform="translate(5.3 5.1)" />
        </clipPath>
      </defs>
      <g transform="translate(-0.1 -0.1)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0.1 0.1)"
          fill="#f47b20"
        />
        <g clip-path="url(#a)">
          <path
            d="M12.2,25.6a2.689,2.689,0,1,1,1.912-.788A2.7,2.7,0,0,1,12.2,25.6Zm0-4.6a1.793,1.793,0,1,0,1.275.525A1.8,1.8,0,0,0,12.2,21Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M7.2,33.8l-.9-.2,1.4-5.5c.4-1.6.9-3.5,3.4-3.5,1.9,0,2.4,1.1,2.9,2.1a15.248,15.248,0,0,1,.9,2.5l2.5.3a1.4,1.4,0,0,1,1.2,1.4,1.367,1.367,0,0,1-1.4,1.4H14a2.256,2.256,0,0,1-2.1-1.5L11,28.4a.474.474,0,1,1,.9-.3l.9,2.4a1.424,1.424,0,0,0,1.3.9h3.2a.472.472,0,0,0,.5-.5.458.458,0,0,0-.4-.5l-2.5-.3a.849.849,0,0,1-.8-.7,12.666,12.666,0,0,0-.8-2.3c-.4-.9-.8-1.6-2.1-1.6-1.6,0-2,.9-2.5,2.8Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M12.6,34.2a.472.472,0,0,1-.5-.5V31.4a.5.5,0,0,1,1,0v2.3a.472.472,0,0,1-.5.5Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M19.9,34.2H12.6a.5.5,0,1,1,0-1h7.3a.472.472,0,0,1,.5.5.536.536,0,0,1-.5.5Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M19.9,32.4H15.4a.5.5,0,0,1,0-1h4.5a.472.472,0,0,1,.5.5.536.536,0,0,1-.5.5Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M27.6,25.6a2.689,2.689,0,1,1,1.912-.788A2.7,2.7,0,0,1,27.6,25.6Zm0-4.6a1.793,1.793,0,1,0,1.275.525A1.8,1.8,0,0,0,27.6,21Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M19.9,25.6a2.689,2.689,0,1,1,1.912-.788A2.7,2.7,0,0,1,19.9,25.6Zm0-4.6a1.793,1.793,0,1,0,1.275.525A1.8,1.8,0,0,0,19.9,21Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M32.6,33.8l-1.4-5.5c-.5-1.9-.9-2.8-2.5-2.8-1.3,0-1.7.7-2.1,1.6a21.644,21.644,0,0,0-.8,2.3.969.969,0,0,1-.8.7l-2.5.3c-.3,0-.4.2-.4.5a.472.472,0,0,0,.5.5h3.2a1.424,1.424,0,0,0,1.3-.9l.9-2.4a.486.486,0,0,1,.6-.3c.2.1.4.4.3.6L28,30.9a2.256,2.256,0,0,1-2.1,1.5H22.7A1.367,1.367,0,0,1,21.3,31a1.4,1.4,0,0,1,1.2-1.4l2.5-.3a15.248,15.248,0,0,1,.9-2.5c.5-.9,1-2.1,2.9-2.1,2.5,0,3,2,3.4,3.5l1.4,5.5Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M27.2,34.2a.472.472,0,0,1-.5-.5V31.4a.5.5,0,0,1,1,0v2.3C27.6,34,27.4,34.2,27.2,34.2Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M27.2,34.2H19.9a.5.5,0,0,1,0-1h7.3a.472.472,0,0,1,.5.5.536.536,0,0,1-.5.5Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M24.4,32.4H19.9a.5.5,0,0,1,0-1h4.5a.5.5,0,0,1,0,1Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M23.5,30.1a.472.472,0,0,1-.5-.5V28.2a2.956,2.956,0,0,0-3.2-2.7,2.956,2.956,0,0,0-3.2,2.7v1.4a.5.5,0,0,1-1,0V28.2a3.844,3.844,0,0,1,4.1-3.6,3.844,3.844,0,0,1,4.1,3.6v1.4C24,29.9,23.8,30.1,23.5,30.1Z"
            fill="#fff"
          />
        </g>
        <g clip-path="url(#a)">
          <path
            d="M19.1,17h-.8c-.3,0-.5-.2-.4-.5s.2-.5.5-.4h.8a.472.472,0,0,1,.5.5C19.7,16.9,19.3,17,19.1,17Z"
            fill="#fff"
          />
          <path
            d="M15.4,16.9h0c-5.7-.6-6.9-2.7-6.9-5.8,0-3.8,1.7-6,10.5-6a.472.472,0,0,1,.5.5.366.366,0,0,1-.4.4C9.5,6,9.5,8.8,9.5,11.1s.5,4.3,6,4.9a.458.458,0,0,1,.4.5.664.664,0,0,1-.5.4Z"
            fill="#fff"
          />
          <path
            d="M19.1,17a.5.5,0,1,1,0-1c9.6,0,9.6-2.8,9.6-5.1a5.6,5.6,0,0,0-.5-2.5A.447.447,0,0,1,29,8a6.058,6.058,0,0,1,.6,2.9C29.6,14.8,27.9,17,19.1,17Z"
            fill="#fff"
          />
          <path
            d="M28.6,8.8a.52.52,0,0,1-.4-.2C27.6,7.5,26,6,19.1,6a.472.472,0,0,1-.5-.5.472.472,0,0,1,.5-.5c5.7,0,8.8.9,10,3a.481.481,0,0,1-.2.6C28.8,8.8,28.7,8.8,28.6,8.8Z"
            fill="#fff"
          />
          <path
            d="M24.5,20.6c-.1,0-.1,0-.2-.1L21,18.7a.447.447,0,1,1,.4-.8l2.3,1.2-.3-.7a.474.474,0,1,1,.9-.3l.7,1.8a.44.44,0,0,1-.1.5C24.7,20.5,24.6,20.6,24.5,20.6Z"
            fill="#fff"
          />
          <path
            d="M23.8,18.7a.664.664,0,0,1-.5-.4.458.458,0,0,1,.4-.5c6.5-.4,7-2.5,7-5,0-1.9-.4-3.2-2.4-4.1a.447.447,0,0,1,.4-.8c2.7,1.2,2.9,3.1,2.9,4.9C31.7,16.1,30.4,18.3,23.8,18.7Z"
            fill="#fff"
          />
          <path
            d="M14.4,19.2a.367.367,0,0,1-.3-.1.44.44,0,0,1-.1-.5l1-2.4a.447.447,0,1,1,.8.4l-.5,1.1L18,16.1a.447.447,0,1,1,.4.8l-3.9,2.2C14.6,19.2,14.5,19.2,14.4,19.2Z"
            fill="#fff"
          />
        </g>
        <path
          d="M22.2,12.3h.1c.1,0,.2-.1.3-.2.1-.2,0-.3-.2-.4a9.292,9.292,0,0,1-1-.5A2.8,2.8,0,0,0,22.5,9h.6a.3.3,0,0,0,0-.6H21.2V7.7a.3.3,0,0,0-.6,0v.6H18.7a.3.3,0,0,0,0,.6h3.4a2.968,2.968,0,0,1-1,1.9,2.335,2.335,0,0,1-1-1.6.764.764,0,0,0-.5-.2c-.2.1-.3.2-.3.4a3.412,3.412,0,0,0,1.1,1.8,3.128,3.128,0,0,1-1,.5.4.4,0,0,0-.2.4c.1.1.2.2.3.2h.1a3.458,3.458,0,0,0,1.2-.7,9.143,9.143,0,0,0,1.4.7Z"
          fill="#fff"
        />
        <path
          d="M17.6,11.9,16.7,10a.375.375,0,0,0-.6,0l-1.5,3.4c-.1.2,0,.3.2.4s.3,0,.4-.2l.6-1.3h1.3l.6,1.3c.1.1.2.2.3.2s.1,0,.1-.1a.4.4,0,0,0,.2-.4ZM16,11.8l.4-.9.4.9Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default OnsiteInterpretationIcon;
