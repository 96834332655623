import React from "react";

function FileFormatsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M23.1,16.6h0a.764.764,0,0,1-.5-.2l-3.5-3.5a.668.668,0,0,1,0-1l1.5-1.5a.668.668,0,0,1,1,0l1.5,1.5,3.6-3.6a.764.764,0,0,1,.5-.2h0a.764.764,0,0,1,.5.2l1.5,1.5a.668.668,0,0,1,0,1l-5.6,5.6A.764.764,0,0,1,23.1,16.6Zm-2.5-4.2,2.5,2.5,4.6-4.6-.5-.5-3.6,3.6a.668.668,0,0,1-1,0l-1.5-1.5Z"
          fill="#fff"
        />
        <path
          d="M18.9,22.3a.367.367,0,0,1-.3-.1.67.67,0,0,1-.4-.7V18.8h-.3a2.263,2.263,0,0,1-2.3-2.3V7.8a2.263,2.263,0,0,1,2.3-2.3H30.4a2.263,2.263,0,0,1,2.3,2.3v8.8a2.263,2.263,0,0,1-2.3,2.3h-8l-3.1,3.2A.758.758,0,0,1,18.9,22.3Zm-1-15.4a.9.9,0,0,0-.9.9v8.8a.9.9,0,0,0,.9.9h1a.684.684,0,0,1,.7.7v1.7l2.1-2.2a.764.764,0,0,1,.5-.2h8.3a.9.9,0,0,0,.9-.9V7.8a.9.9,0,0,0-.9-.9Z"
          fill="#fff"
        />
        <path
          d="M12.4,24.1h-.2A3.159,3.159,0,0,1,9,20.9V19.8a3.159,3.159,0,0,1,3.2-3.2h.2a3.159,3.159,0,0,1,3.2,3.2v1.1A3.159,3.159,0,0,1,12.4,24.1ZM12.2,18a1.793,1.793,0,0,0-1.8,1.8v1.1a1.793,1.793,0,0,0,1.8,1.8h.2a1.793,1.793,0,0,0,1.8-1.8V19.8A1.793,1.793,0,0,0,12.4,18Z"
          fill="#fff"
        />
        <path
          d="M29.7,30H22.9a.7.7,0,0,1,0-1.4h6.3L31,23H21.8l-1.2,3.5a.7.7,0,1,1-1.3-.5l1.3-4a.752.752,0,0,1,.7-.5H32a.864.864,0,0,1,.6.3.661.661,0,0,1,.1.6l-2.3,7C30.2,29.8,30,30,29.7,30Z"
          fill="#fff"
        />
        <path
          d="M31.5,31.9H12a.7.7,0,0,1,0-1.4H31.5a.684.684,0,0,1,.7.7A.751.751,0,0,1,31.5,31.9Z"
          fill="#fff"
        />
        <path
          d="M18.8,31.9H15.5a.55.55,0,0,1-.5-.3l-3.5-4.2a.711.711,0,0,1,1.1-.9l3.3,3.9h2.9a1.324,1.324,0,0,0,1.3-1.3H17.4a.764.764,0,0,1-.5-.2l-3.5-4.1a1.8,1.8,0,0,0-1.5-.7H10.7a3.326,3.326,0,0,0-3.3,2.7l-.9,4.3a.618.618,0,0,1-.8.5.618.618,0,0,1-.5-.8L6,26.5a4.817,4.817,0,0,1,4.7-3.8h1.2a3.367,3.367,0,0,1,2.6,1.2l3.3,3.8h3.1a.684.684,0,0,1,.7.7v.7A2.933,2.933,0,0,1,18.8,31.9Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default FileFormatsIcon;
