import React from "react";

function LogisticsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M7.3,18H3.7a.684.684,0,0,0-.7.7H3a.684.684,0,0,0,.7.7H7.3Z"
          fill="#fff"
        />
        <path
          d="M13.4,26.8A2.6,2.6,0,1,0,16,29.4,2.582,2.582,0,0,0,13.4,26.8Zm0,3.9a1.3,1.3,0,1,1,1.3-1.3A1.324,1.324,0,0,1,13.4,30.7Z"
          fill="#fff"
        />
        <path
          d="M35.2,27.5a19.022,19.022,0,0,0-.3-3.7c-.2-.6-2-1.3-2-1.3l-3.3-4.2H25.5v8.5h-.7V16.6a.713.713,0,0,0-.8-.7H9a.773.773,0,0,0-.8.7V26.8H7.7v2.7h2.6v-.1a3,3,0,0,1,6,0v.1H27v-.1a3,3,0,1,1,6,0v.1h2a4.132,4.132,0,0,0,.5-1.6C35.6,27.6,35.2,27.7,35.2,27.5Zm-4.1-4.9H26.3V19.1h2.9s1.5,2.1,2.1,2.8C31.7,22.4,31.6,22.6,31.1,22.6Z"
          fill="#fff"
        />
        <path
          d="M30,26.8a2.6,2.6,0,1,0,2.6,2.6A2.467,2.467,0,0,0,30,26.8Zm0,3.9a1.3,1.3,0,1,1,1.3-1.3A1.206,1.206,0,0,1,30,30.7Z"
          fill="#fff"
        />
        <path
          d="M7.3,21.1H4.9a.684.684,0,0,0-.7.7h0a.684.684,0,0,0,.7.7H7.3Z"
          fill="#fff"
        />
        <path
          d="M7.3,24.3H5.9a.684.684,0,0,0-.7.7h0a.684.684,0,0,0,.7.7H7.3Z"
          fill="#fff"
        />
        <path d="M20.7,7.3,11.9,4.8l-1.4.4,6.3,3.3Z" fill="#fff" />
        <path
          d="M26.1,8.7C22,9.9,13.5,12.1,13.5,12.1l-1.8-.4L8.5,9l1.7-.5,2.6,1.1s8-2.5,12.1-3.7c.6-.2,3,.1,3.2.7h0C28.4,7,26.7,8.5,26.1,8.7Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default LogisticsIcon;
