import React from "react";

function CertifiedBankStatementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(-0.1 -0.1)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0.1 0.1)"
          fill="#f47b20"
        />
        <path
          d="M29.5,12.9h.8V11.4a.265.265,0,0,0-.3-.3L20.1,7.2h-.3l-10,3.9a.319.319,0,0,0-.3.3v1.5h20Z"
          fill="#fff"
        />
        <path
          d="M22.5,14.4v-.8H17.6v.8a1.049,1.049,0,0,0,1.1,1.1v5.7a1.049,1.049,0,0,0-1.1,1.1V23h4.9v-.8a1.049,1.049,0,0,0-1.1-1.1V15.4A1.021,1.021,0,0,0,22.5,14.4Z"
          fill="#fff"
        />
        <path
          d="M28.2,14.4v-.8H23.3v.8a1.049,1.049,0,0,0,1.1,1.1v5.7a1.049,1.049,0,0,0-1.1,1.1V23h4.9v-.8a1.049,1.049,0,0,0-1.1-1.1V15.4A.972.972,0,0,0,28.2,14.4Z"
          fill="#fff"
        />
        <path
          d="M16.6,14.4v-.8H11.7v.8a1.049,1.049,0,0,0,1.1,1.1v5.7a1.049,1.049,0,0,0-1.1,1.1V23h4.9v-.8a1.049,1.049,0,0,0-1.1-1.1V15.4A.93.93,0,0,0,16.6,14.4Z"
          fill="#fff"
        />
        <path
          d="M30.3,25.1H29.2V23.6H10.6v1.5h-1a.319.319,0,0,0-.3.3v1.5a.319.319,0,0,0,.3.3H30.4a.319.319,0,0,0,.3-.3V25.4C30.7,25.2,30.4,25.1,30.3,25.1Z"
          fill="#fff"
        />
        <path
          d="M28.9,28.6l.5-.3a.319.319,0,0,0,.3-.3l.1-2,1.4-1.8a.3.3,0,0,0,0-.4l-1.3-1.4L29.6,20a.319.319,0,0,0-.3-.3l-2-.1-1.6-1.5h-.1c-.1,0-.1,0-.1.1l-1.6,1.4-2.4.4a.319.319,0,0,0-.3.3l-.1,2-1.4,1.6a.3.3,0,0,0,0,.4l1.4,1.6.3,2.2a.319.319,0,0,0,.3.3l.9.1h.1v.1l-1.8,4.7v.3a.438.438,0,0,0,.3.1h.1l1.5-.5.8,1.4c0,.1.1.1.3.1a1.007,1.007,0,0,0,.3-.1l1.6-4.2v-.1h.1v.1l1.9,3.9c0,.1.1.1.3.1a1.007,1.007,0,0,0,.3-.1l.6-1.5,1.5.4h.1a.319.319,0,0,0,.3-.3v-.1l-2-4v-.1h0Z"
          fill="#f47b20"
        />
        <path
          d="M25,25.8c-.1,0-.1,0-.3-.1l-1.1-1a.283.283,0,1,1,.4-.4l.9.8,1.6-2a.283.283,0,1,1,.4.4L25,25.7c.2.1.1.1,0,.1Z"
          fill="#fff"
        />
        <path
          d="M25.5,27.4a3.3,3.3,0,1,0-3.3-3.3A3.329,3.329,0,0,0,25.5,27.4Zm0-6a2.8,2.8,0,1,1-2.8,2.8A2.734,2.734,0,0,1,25.5,21.4Z"
          fill="#fff"
        />
        <path
          d="M29.4,28.3a.319.319,0,0,0,.3-.3l.1-2,1.4-1.8a.3.3,0,0,0,0-.4l-1.3-1.4L29.6,20a.319.319,0,0,0-.3-.3l-2-.1-1.6-1.5h-.1c-.1,0-.1,0-.1.1l-1.6,1.4-2.4.4a.319.319,0,0,0-.3.3l-.1,2-1.4,1.6a.3.3,0,0,0,0,.4l1.4,1.6.3,2.2a.319.319,0,0,0,.3.3l.9.1h.1v.1l-1.8,4.7v.3a.438.438,0,0,0,.3.1h.1l1.5-.5.8,1.4c0,.1.1.1.3.1h0a1.007,1.007,0,0,0,.3-.1l1.6-4.2v-.1h.1v.1l1.9,3.9c0,.1.1.1.3.1h0a1.007,1.007,0,0,0,.3-.1l.6-1.5,1.5.4h.1a.319.319,0,0,0,.3-.3v-.1l-2-4v-.1H29Zm-7.6-.5-.1-2v-.1l-1.3-1.5,1.4-1.6v-.1l.1-1.9,2-.1a.1.1,0,0,0,.1-.1l1.5-1.3,1.6,1.4a.1.1,0,0,1,.1.1l1.9.1.3,2.2a.1.1,0,0,0,.1.1l1.3,1.4L29.4,26v.1L29,27.9l-.8.1h-.3l-1,.1a.1.1,0,0,0-.1.1l-1.4,1.3h-.1l-1.5-1.3a.1.1,0,0,1-.1-.1l-.9-.1h-.3Zm1.6,5.8-.6-1.1c0-.1-.1-.1-.3-.1h-.1l-1.3.4,1.6-4.3v-.1h.6l1.4,1.3v.1Zm4.7-5L30,32.3,28.7,32h0c-.1,0-.3,0-.3.1l-.6,1.1L26,29.7v-.1l1.1-1.1,1,.1Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default CertifiedBankStatementIcon;
