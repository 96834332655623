import React from "react";
import OptimizedImage from "hooks/OptimizedImage";

function FullWidthImage({ data, className }) {
  return (
    <div className={`${className ? className : "hidden md:block w-full"}`}>
      <OptimizedImage
        src={data.image}
        alt="Banner"
        className="w-full max-w-full"
      />
    </div>
  );
}
export default FullWidthImage;
