import React from "react";

function SocialMediaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M32.4,23.1a4.016,4.016,0,0,1-1.3.4,2.114,2.114,0,0,0,1-1.3,9.039,9.039,0,0,1-1.4.6,2.756,2.756,0,0,0-1.9-.8,2.333,2.333,0,0,0-2.1,2.9,6.93,6.93,0,0,1-4.8-2.4,2.292,2.292,0,0,0,.7,3.1,1.579,1.579,0,0,1-1.1-.3,2.4,2.4,0,0,0,1.9,2.4,1.482,1.482,0,0,1-1,0,2.221,2.221,0,0,0,2.2,1.6,4.606,4.606,0,0,1-3.5.9,7.054,7.054,0,0,0,3.6,1.1,6.734,6.734,0,0,0,6.6-7A4.641,4.641,0,0,0,32.4,23.1Z"
          fill="#fff"
        />
        <path
          d="M14.2,9.2h1.1V7.4l-1.5-.1a2.534,2.534,0,0,0-2.3,1.1,2.2,2.2,0,0,0-.4,1.3v.5h0v1.3H9.3v2.1h1.8v5.2h2.1V13.6H15l.3-2.1H13.2V10.1h0C13.2,9.2,14.2,9.2,14.2,9.2Z"
          fill="#fff"
        />
        <path
          d="M14.4,27.2a2.112,2.112,0,0,1-2.1,2.1,2.051,2.051,0,0,1-2.1-2.1,2.1,2.1,0,0,1,4.2,0Zm3.3-2.7v5.7a2.476,2.476,0,0,1-2.5,2.5H9.5A2.476,2.476,0,0,1,7,30.2V24.5A2.476,2.476,0,0,1,9.5,22h5.7A2.476,2.476,0,0,1,17.7,24.5Zm-2.4,2.7a2.9,2.9,0,1,0-2.9,2.9A2.84,2.84,0,0,0,15.3,27.2Zm.1-3.6a.5.5,0,1,0,.5.5A.472.472,0,0,0,15.4,23.6Z"
          fill="#fff"
          stroke="#f47b20"
          stroke-miterlimit="10"
          stroke-width="0.638"
        />
        <path
          d="M32.8,11.7a1.88,1.88,0,0,0-1.9-1.8l-4.3-.1-4,.1a1.88,1.88,0,0,0-1.9,1.8l-.1,2.4.1,2.4a1.88,1.88,0,0,0,1.9,1.8l4,.1,4.3-.1a1.88,1.88,0,0,0,1.9-1.8l.1-2.4ZM25.5,16V12.3l3.2,1.9Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default SocialMediaIcon;
