import React from "react";

function BankStatementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M19.4,24.9H14.1V21.4h5.3v3.5Zm-6.6-4.1v4.5a.6.6,0,0,0,.1.4,1.38,1.38,0,0,0,.3.4.31.31,0,0,0,.2.1h6.3a.6.6,0,0,0,.4-.1,1.38,1.38,0,0,0,.4-.3.31.31,0,0,0,.1-.2V21.1a.6.6,0,0,0-.1-.4,1.38,1.38,0,0,0-.3-.4.367.367,0,0,0-.3-.1H13.6a.6.6,0,0,0-.4.1,1.38,1.38,0,0,0-.4.3c.1,0,0,.1,0,.2Z"
          fill="#fff"
        />
        <path
          d="M13.7,11h5.9a.7.7,0,1,0,0-1.4H13.7a.7.7,0,0,0,0,1.4Z"
          fill="#fff"
        />
        <path
          d="M13.7,13.8h5.9a.7.7,0,1,0,0-1.4H13.7a.7.7,0,0,0,0,1.4Z"
          fill="#fff"
        />
        <path
          d="M13.7,16.5h5.9a.7.7,0,1,0,0-1.4H13.7a.7.7,0,0,0,0,1.4Z"
          fill="#fff"
        />
        <path
          d="M30.5,32.2a1,1,0,0,1-.4.5.367.367,0,0,1-.3.1c-.1,0-.2.1-.3.1H15.9V32a.749.749,0,0,1,.1-.5,1.024,1.024,0,0,1,.5-.6c.1-.1.2-.1.4-.2.1,0,.3-.1.4-.1H29.5c.2,0,.3,0,.4.1a1,1,0,0,1,.5.4.367.367,0,0,1,.1.3c0,.1.1.2.1.3,0,.2-.1.3-.1.5ZM24,24.1h6.4v1.7H24V24.1Zm0-4.9h6.4v1.7H24V19.2Zm6.8,10.5-.6-.3a.367.367,0,0,1-.3-.1V27h.9a.6.6,0,0,0,.4-.1.781.781,0,0,0,.4-.4.367.367,0,0,0,.1-.3V23.6a.6.6,0,0,0-.1-.4.781.781,0,0,0-.4-.4.367.367,0,0,0-.3-.1H29.7v-.6h.9A.6.6,0,0,0,31,22a.781.781,0,0,0,.4-.4.367.367,0,0,0,.1-.3V18.7a.6.6,0,0,0-.1-.4.781.781,0,0,0-.4-.4.367.367,0,0,0-.3-.1H29.5V7.4a.9.9,0,0,0-.1-.5,2.092,2.092,0,0,0-.4-.6L28.4,6H11.1c-.1,0-.3.1-.4.1-.2.1-.3.3-.4.4a1.421,1.421,0,0,0-.2.6v8.6a.7.7,0,1,0,1.4,0h0V7.3H28.7V17.8H23.8a.6.6,0,0,0-.4.1.781.781,0,0,0-.4.4.367.367,0,0,0-.1.3v2.6a.6.6,0,0,0,.1.4.781.781,0,0,0,.4.4.367.367,0,0,0,.3.1h5.2v.6H24a.6.6,0,0,0-.4.1.781.781,0,0,0-.4.4.367.367,0,0,0-.1.3v2.6a.6.6,0,0,0,.1.4.781.781,0,0,0,.4.4.367.367,0,0,0,.3.1h5.2v2.2H17.3a4.253,4.253,0,0,0-1.1.2,2.335,2.335,0,0,0-1.2,1,2.118,2.118,0,0,0-.3.7,2.2,2.2,0,0,0-.1.8v.9H11.5V18.9a.7.7,0,1,0-1.4,0h0V33a.9.9,0,0,0,.1.5,1,1,0,0,0,.4.5c.1.1.2.1.3.2.1,0,.2.1.4.1H29.4a3.553,3.553,0,0,0,1-.2,3.126,3.126,0,0,0,1.1-.9l.3-.6a1.7,1.7,0,0,0,.1-.7,3.553,3.553,0,0,0-.2-1,2.262,2.262,0,0,0-.9-1.2Z"
          fill="#fff"
        />
        <path
          d="M24.4,11.7c0-.1-.1-.2-.1-.3a.367.367,0,0,1,.1-.3.215.215,0,0,1,.2-.2v1a.215.215,0,0,1-.2-.2Zm.9,1.6a1.38,1.38,0,0,1,.4.3.52.52,0,0,1,.2.4.764.764,0,0,1-.2.5,1.38,1.38,0,0,1-.4.3V13.3Zm-1.3.8c-.1-.2-.2-.3-.4-.3a.354.354,0,0,0-.4.4.9.9,0,0,0,.1.5,1.38,1.38,0,0,0,.3.4,2.186,2.186,0,0,0,.5.3,1.421,1.421,0,0,0,.6.2v.2c0,.3.1.4.4.4s.4-.1.4-.4v-.2a.764.764,0,0,0,.5-.2,1.38,1.38,0,0,0,.4-.3,2.186,2.186,0,0,0,.3-.5,1.483,1.483,0,0,0,.1-.7,1.268,1.268,0,0,0-.1-.6,1.38,1.38,0,0,0-.3-.4l-.4-.4a2.186,2.186,0,0,1-.5-.3V11a.215.215,0,0,1,.2.2c0,.1.1.2.1.3a.349.349,0,0,0,.1.2c0,.1.1.1.3.1a.354.354,0,0,0,.4-.4c0-.2,0-.3-.1-.4s-.1-.3-.2-.4a1.38,1.38,0,0,0-.4-.3c-.1-.1-.3-.1-.4-.2V10c0-.3-.1-.4-.4-.4s-.4.1-.4.4v.1a.758.758,0,0,0-.4.2l-.3.3a.758.758,0,0,0-.2.4.9.9,0,0,0-.1.5.749.749,0,0,0,.1.5c.1.1.1.3.2.4l.3.3a.758.758,0,0,0,.4.2v1.8a.877.877,0,0,1-.7-.6Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default BankStatementIcon;
