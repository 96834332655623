import React from "react";

function ECommerceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#f47b20" />
      <path
        d="M26.3,22.1c.2,0,.3,0,.4-.1a.1.1,0,0,0,.1-.1c.1-.1.2-.2.2-.3l.1-.1L32.3,12a.908.908,0,0,0,0-1,1.02,1.02,0,0,0-.8-.5H10.8L9.6,8.4a.891.891,0,0,0-.8-.5H6.5a1,1,0,0,0-1,1,.945.945,0,0,0,1,1H8.2l1.2,2.2h0l5,9L11.7,26a.908.908,0,0,0,0,1,1.02,1.02,0,0,0,.8.5H28.9a1,1,0,0,0,1-1,.945.945,0,0,0-1-1H14.2l1.9-3.4ZM11.9,12.6H29.8l-4.1,7.5H16Z"
        fill="#fff"
      />
      <path
        d="M27.4,29.2a2.2,2.2,0,1,0,2.2,2.2A2.22,2.22,0,0,0,27.4,29.2Z"
        fill="#fff"
      />
      <path
        d="M14.7,29.2a2.2,2.2,0,1,0,2.2,2.2A2.22,2.22,0,0,0,14.7,29.2Z"
        fill="#fff"
      />
    </svg>
  );
}
export default ECommerceIcon;
