import React from "react";

function DinnerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M35.1,17.1c-.1-1.6-1.2-5.2-1-6.7a.366.366,0,0,0-.4-.4H27.5a.43.43,0,0,0-.4.4c.2,1.5-.9,5.1-1,6.7-.2,3.2,3.9,3.7,3.9,3.7v7.7s-.1.5-.3.6c-.5.4-1.9.1-2.3.5-.1.1,0,.5,0,.5h6.4s.1-.4,0-.5c-.4-.5-1.8-.2-2.3-.5a3.287,3.287,0,0,1-.3-.6V20.8C31.3,20.8,35.3,20.2,35.1,17.1Zm-4.8,3Zm.8,0h-.8a8.355,8.355,0,0,1-1.8-.6A2.409,2.409,0,0,1,27,17.2a15.683,15.683,0,0,1,.4-2.5c.3-1.5.5-2.6.6-3.6a.43.43,0,0,1,.4-.4H33a.43.43,0,0,1,.4.4,22.154,22.154,0,0,0,.6,3.6,15.682,15.682,0,0,1,.4,2.5,2.391,2.391,0,0,1-1.5,2.4A10.411,10.411,0,0,1,31.1,20.1Z"
          fill="#fff"
        />
        <path
          d="M33.4,14.8c0-.2-.1-.5-.1-.7H28.2c0,.2-.1.4-.1.7a13.228,13.228,0,0,0-.4,2.4A1.889,1.889,0,0,0,28.9,19a12.688,12.688,0,0,0,1.6.6h.6a7.981,7.981,0,0,0,1.6-.5,1.889,1.889,0,0,0,1.2-1.8C33.8,16.6,33.6,15.7,33.4,14.8Z"
          fill="#fff"
        />
        <path d="M14.2,11.6a8.5,8.5,0,0,0,0,17Z" fill="#fff" />
        <path
          d="M14.2,29.5a9.4,9.4,0,0,1,0-18.8V8.8a11.3,11.3,0,0,0,0,22.6Z"
          fill="#fff"
        />
        <path
          d="M19.7,15.5a30.371,30.371,0,0,0,0-5.3h-.4v4h-.4v-4h-.5v4H18v-4h-.5v4h-.4v-4h-.4a30.215,30.215,0,0,0,0,5.3,2.489,2.489,0,0,0,1.1,1.7S16.1,30,18.1,30s.5-12.8.5-12.8A3.523,3.523,0,0,0,19.7,15.5Z"
          fill="#fff"
        />
        <path
          d="M24,20.9V10.5c-.1-.2-.6-.6-1.2.3-.7,1.1-1.8,7.1-.5,9a2.181,2.181,0,0,1,.4.5,2.252,2.252,0,0,1,.1.7s-1.2,9,.4,9C24.7,30,24,20.9,24,20.9Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default DinnerIcon;
