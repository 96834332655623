import React from "react";

function FastDeliveryTwoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M12,16.1h1.8a.8.8,0,0,0,0-1.6H12a.8.8,0,0,0,0,1.6Z"
          fill="#fff"
        />
        <path
          d="M10,18.8h2.8a.8.8,0,1,0,0-1.6H10a.736.736,0,0,0-.8.8A.789.789,0,0,0,10,18.8Z"
          fill="#fff"
        />
        <path
          d="M11.7,21.6a.8.8,0,1,0,0-1.6H8.1a.8.8,0,1,0,0,1.6Z"
          fill="#fff"
        />
        <path
          d="M31.4,12.2H26.1A1.11,1.11,0,0,0,25,13.3v2.2h2a3.176,3.176,0,0,1,2,.8,2.629,2.629,0,0,1,.8,2,2.768,2.768,0,0,1-.3,1.3h2a1.11,1.11,0,0,0,1.1-1.1V13.4Q32.45,12.2,31.4,12.2Z"
          fill="#fff"
        />
        <path
          d="M14.4,24.9l-.3-.3-.6.9-.1.1L9,26.2a1.632,1.632,0,0,0-1.4,1.7,1.569,1.569,0,0,0,1.7,1.4l4.4-.6a3.465,3.465,0,0,0,2.4-1.5l.2-.5a20.948,20.948,0,0,0-1.7-1.4Z"
          fill="#fff"
        />
        <path
          d="M27,17H24a2.353,2.353,0,0,1-.8-.2c-2.4-2.1-2.8-2.1-3.1-2.2L18,14.3a2.013,2.013,0,0,0-2.1,1.1l-1.4,4.1c-.7,1.8-1,2.9,0,3.7.2.1.6.5,1,.8,1.3,1,3.2,2.6,3.2,2.6a2.56,2.56,0,0,1,1,1.3l1.8,4a1.416,1.416,0,0,0,1.4.9c.2,0,.5,0,.6-.1a1.621,1.621,0,0,0,.8-2.1l-1.8-4a8.4,8.4,0,0,0-1.8-2.4l-2-1.6,2-4.7a5.716,5.716,0,0,0,.8.7,4.169,4.169,0,0,0,2.4.9h3a1.324,1.324,0,0,0,1.3-1.3A1.153,1.153,0,0,0,27,17Z"
          fill="#fff"
        />
        <path
          d="M19.3,13.7a2.864,2.864,0,1,0-2.2-3.4A3.079,3.079,0,0,0,19.3,13.7Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default FastDeliveryTwoIcon;
