import React from "react";

function ConsistencyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.3)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.3)"
          fill="#f47b20"
        />
        <path
          d="M26.2,26.6a9.011,9.011,0,0,1-6.3,2.6,8.833,8.833,0,0,1-6.3-2.6A9.011,9.011,0,0,1,11,20.3h4.2L12.3,17,9.5,13.7,6.8,17,4,20.2H8a11.538,11.538,0,0,0,3.5,8.4,12.128,12.128,0,0,0,8.4,3.5,11.538,11.538,0,0,0,8.4-3.5l.6-.6L27,25.8Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M31.8,20.2a11.538,11.538,0,0,0-3.5-8.4,11.538,11.538,0,0,0-8.4-3.5,11.538,11.538,0,0,0-8.4,3.5l-.6.6,1.9,2.3a5.716,5.716,0,0,1,.7-.8,9.011,9.011,0,0,1,6.3-2.6,8.833,8.833,0,0,1,6.3,2.6,9.011,9.011,0,0,1,2.6,6.3H24.5l2.8,3.3,2.8,3.3,2.8-3.3,2.8-3.3Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <circle
          cx="2.7"
          cy="2.7"
          r="2.7"
          transform="translate(17.2 17.5)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default ConsistencyIcon;
