import React from "react";

function TelecommunicationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M32.3,7.1a6.344,6.344,0,0,0-6.5-1.5.7.7,0,0,0-.4.9h0a.67.67,0,0,0,.8.3,5.3,5.3,0,0,1,5.2,1.3,4.915,4.915,0,0,1,1.2,5.1.67.67,0,0,0,.3.8h0a.608.608,0,0,0,.9-.4A6.562,6.562,0,0,0,32.3,7.1Z"
          fill="#fff"
        />
        <path
          d="M27.2,8.1a.747.747,0,0,0-.5,1,.517.517,0,0,0,.7.3,2.211,2.211,0,0,1,1.9.6,2.308,2.308,0,0,1,.6,1.9.779.779,0,0,0,.3.7.69.69,0,0,0,1-.5,3.318,3.318,0,0,0-1-3.1A3.248,3.248,0,0,0,27.2,8.1Z"
          fill="#fff"
        />
        <path
          d="M23.7,18.1c2.7,2.9,3.5,5.1,3.5,5.7-.7.1-3.3-1.1-6.9-4.6s-4.7-6.2-4.6-6.9c.6-.1,2.7.8,5.7,3.5l1.3-1.3C20,12,16,9.3,14.4,11a2.157,2.157,0,0,0-.5,1.9c-2.2,2.8-3.7,7-.5,11.1L8.3,32.3H7.6a1.1,1.1,0,1,0,0,2.2H19.7a1.1,1.1,0,1,0,0-2.2H19l-.9-4.9c3.2,1.1,6.3-.2,8.5-1.9.2,0,.4.1.6.1a2.389,2.389,0,0,0,1.4-.5c1.7-1.7-1-5.6-3.5-8.3Z"
          fill="#fff"
        />
        <path
          d="M21.2,18.3a.668.668,0,0,0,1,0l4.4-4.4a1.534,1.534,0,0,0,1.7-.4,1.7,1.7,0,0,0-2.4-2.4,1.614,1.614,0,0,0-.4,1.7l-4.4,4.4A.909.909,0,0,0,21.2,18.3Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default TelecommunicationIcon;
