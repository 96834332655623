import React from "react";

function ScriptTranslatorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M31.9,12.7h0v-.1h0L24.6,5.3H9.7a.472.472,0,0,0-.5.5V34a.472.472,0,0,0,.5.5H31.4a.472.472,0,0,0,.5-.5ZM24.7,6.8l5.4,5.4H24.7Zm6.2,26.3H10.2V6H23.5v6.7a.472.472,0,0,0,.5.5h6.7V33.1Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M13,27.3a.5.5,0,1,0,0,1H24.1a.5.5,0,0,0,0-1Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M18.5,30H13a.5.5,0,0,0,0,1h5.5a.472.472,0,0,0,.5-.5C19.1,30.2,18.8,30,18.5,30Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M13,13.1h5.5a.5.5,0,0,0,0-1H13a.472.472,0,0,0-.5.5.536.536,0,0,0,.5.5Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M14.1,16.4v-.7c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1.349.349,0,0,0-.2-.1h-.4c-.1,0-.1,0-.1.1l-.1.1v1h-.6V15.3h-.2v1.4H14a.367.367,0,0,0,.1-.3Zm-.2-.1v.2h-.7v-1l.1-.1h.1c.1,0,.1,0,.2.1a.1.1,0,0,1,.1.1c0,.1.1.1.1.2v.3c.1.1.1.1.1.2Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M15.5,16.7V15.4h.4v-.2H14.6v1.5h.2V15.4h.5v1.3Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M17.7,16.7l-.1.1c-.1,0-.1,0-.2.1h-1c-.1,0-.2,0-.2.1a.349.349,0,0,0-.1.2v.2h.2v-.3h1.1c.1,0,.2-.1.3-.1l.2-.2c0-.1.1-.2.1-.3v-.2c0-.1-.1-.1-.1-.2a.349.349,0,0,0-.2-.1h-.3v-.4h.5v-.2h0a.1.1,0,0,0-.1-.1V15a.1.1,0,0,1,.1-.1.1.1,0,0,0,.1-.1h.3l.1.1v1.9h.2V15.1c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1.1.1,0,0,1-.1-.1h-.5c-.1,0-.1.1-.2.1l-.1.1v.5H16.4V16a.349.349,0,0,0-.2.1c-.1.1-.1.1-.1.3v.2a.1.1,0,0,0,.1.1.349.349,0,0,0,.2.1h.4c.1,0,.1-.1.2-.1s.1-.1.1-.2V16h.2a.1.1,0,0,1,.1.1l.1.1v.4C17.5,16.7,17.7,16.6,17.7,16.7Zm-.6-.6a.367.367,0,0,1-.1.3c-.1.1-.1.1-.3.1h-.2a.1.1,0,0,1-.1-.1.1.1,0,0,0-.1-.1v-.1c0-.1,0-.1.1-.2H17v.1Zm0-.3h-.4v-.4h.4Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M19.9,15.1h0v.1H19v1.4h.2V15.4h.6v1.2l-.6.4.1.1.7-.5V15.4h.4v-.2h-.2a.1.1,0,0,0-.1-.1v-.3a.1.1,0,0,1,.1-.1.1.1,0,0,0,.1-.1h.3l.1.1v1.9h.2V14.9c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1.1.1,0,0,1-.1-.1h-.5c-.1,0-.1.1-.2.1l-.1.1v.2c.1.1.1.1.1.2Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M22,16.5h0v-.1h.7a.349.349,0,0,0,.2-.1c.1,0,.1-.1.1-.2v-.5c0-.1-.1-.1-.1-.2-.1,0-.1-.1-.2-.1h-.6a.349.349,0,0,0-.2.1.1.1,0,0,0-.1.1v.3a.1.1,0,0,0,.1.1l.1.1h.2a.1.1,0,0,0,.1-.1l.1-.1v-.3l-.1-.1h.3c.1,0,.1,0,.1.1l.1.1V16l-.1.1a.31.31,0,0,0-.2.1h-.6a.1.1,0,0,0-.1.1.1.1,0,0,1-.1.1c-.1,0,0,.1,0,.1v.1a.1.1,0,0,0,.1.1h2.3v-.2Zm.4-.9h0v.3h-.3v-.3h.2c0-.1,0-.1.1,0C22.3,15.5,22.4,15.5,22.4,15.6Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M24.8,15.4h.6v1.2l-.6.4.1.2.7-.5V15.4H26v-.2H24.6v1.5h.2Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M26.4,16.5a.1.1,0,0,0,.1.1c.1,0,.1.1.2.1h.2a.1.1,0,0,0,.1-.1l.1-.1v-.4A.1.1,0,0,0,27,16l-.1-.1h-.1c-.1,0-.1,0-.2.1a.1.1,0,0,0-.1.1h0V16a.367.367,0,0,1,.1-.3c0-.1.1-.1.2-.2s.1-.1.2-.1h.3a.6.6,0,0,1,.4.1c-.1.1-.2.2-.2.3s-.1.2-.1.4v.2c0,.1,0,.1.1.2a.1.1,0,0,0,.1.1H28a.1.1,0,0,0,.1-.1c0-.1.1-.1.1-.2V16c0-.1,0-.1-.1-.2a.31.31,0,0,1-.1-.2l-.1-.1c.1,0,.2-.1.3-.1h.2c.1,0,.1.1.2.1a.349.349,0,0,1,.1.2v.2a.367.367,0,0,1-.1.3c0,.1-.1.2-.2.3l.2.1c0-.1.1-.1.1-.2s.1-.1.1-.2,0-.1.1-.2v-.2a.367.367,0,0,0-.1-.3.349.349,0,0,0-.1-.2c-.1-.1-.1-.1-.2-.1s-.2-.1-.3-.1h-.3a.349.349,0,0,0-.2.1.349.349,0,0,1-.2-.1h-.3a.756.756,0,0,0-.4.1.769.769,0,0,0-.3.2c-.1.1-.2.2-.2.3s-.1.2-.1.4v.3C26.3,16.4,26.3,16.4,26.4,16.5Zm.1-.3h0l.1-.1h.3v.4h-.4Zm1.2-.6.2.2c0,.1.1.2.1.3a.367.367,0,0,1-.1.3c0,.1-.1.1-.2.1h-.1a.1.1,0,0,1-.1-.1v-.3a.367.367,0,0,1,.1-.3C27.6,15.7,27.6,15.6,27.7,15.6Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M12.4,19v1.4h1.8a.367.367,0,0,1,.1-.3v-.7c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1.349.349,0,0,0-.2-.1h-.4c-.1,0-.1,0-.1.1l-.1.1v1h-.6V19Zm.9.4v-.2l.1-.1h.1c.1,0,.1,0,.2.1a.1.1,0,0,1,.1.1c0,.1.1.1.1.2v.7h-.7v-.8Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M16,19H14.7v1.4h.2V19.1h.6v1.3h.2V19.1H16Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M18.7,18.9v-.2c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1.1.1,0,0,1-.1-.1h-.5c-.1,0-.1.1-.2.1l-.1.1V19H16.5v.6a.349.349,0,0,0-.2.1c-.1.1-.1.1-.1.3v.2a.1.1,0,0,0,.1.1.349.349,0,0,0,.2.1h.4c.1,0,.1-.1.2-.1s.1-.1.1-.2v-.3h.2a.1.1,0,0,1,.1.1l.1.1v.4c0,.1,0,.1-.1.1l-.1.1a.31.31,0,0,0-.2.1h-1c-.1,0-.2,0-.2.1a.349.349,0,0,0-.1.2v.2h.2v-.3h1.1c.1,0,.2-.1.3-.1l.2-.2a.367.367,0,0,0,.1-.3v-.2c0-.1-.1-.1-.1-.2a.349.349,0,0,0-.2-.1h-.3v-.4H18V19h0a.1.1,0,0,0-.1-.1v-.3a.1.1,0,0,1,.1-.1.1.1,0,0,0,.1-.1h.3l.1.1v1.9h.2Zm-1.5,1a.367.367,0,0,1-.1.3c-.1.1-.1.1-.3.1h-.2a.1.1,0,0,1-.1-.1l-.1-.1V20c0-.1,0-.1.1-.2h.6v.1Zm0-.3h-.4v-.4h.4Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M20.8,18.3h-.5c-.1,0-.1.1-.2.1l-.1.1V19h-.9v1.4h.2V19.2h.6v1.2l-.6.4.1.1.7-.5V19.2h.4V19h-.2a.1.1,0,0,0-.1-.1v-.3a.1.1,0,0,1,.1-.1.1.1,0,0,0,.1-.1h.3l.1.1v1.9H21V18.7c0-.1,0-.1-.1-.2C20.9,18.4,20.9,18.4,20.8,18.3Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M22.2,20.2h0v-.1h.7a.349.349,0,0,0,.2-.1c.1,0,.1-.1.1-.2v-.5c0-.1-.1-.1-.1-.2s-.1-.1-.2-.1h-.6a.349.349,0,0,0-.2.1.1.1,0,0,0-.1.1v.3a.1.1,0,0,0,.1.1l.1.1h.2a.1.1,0,0,0,.1-.1l.1-.1v-.3l-.1-.1h.3c.1,0,.1,0,.1.1l.1.1v.4l-.1.1a.31.31,0,0,0-.2.1h-.6a.1.1,0,0,0-.1.1.1.1,0,0,1-.1.1v.2a.1.1,0,0,0,.1.1h2.3v-.2Zm.3-.9h0v.3h-.3v-.3h.2c0-.1,0-.1.1,0Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M24.8,20.4h.1V19.1h.6v1.2l-.6.4.1.2.7-.5V19.1h.4V19H24.8Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M28.6,19c-.1,0-.2-.1-.3-.1H28a.349.349,0,0,0-.2.1.349.349,0,0,1-.2-.1h-.3a.756.756,0,0,0-.4.1c-.1,0-.2.1-.3.2s-.2.2-.2.3-.1.2-.1.4v.3c0,.1,0,.1.1.2a.1.1,0,0,0,.1.1c.1,0,.1.1.2.1h.2a.1.1,0,0,0,.1-.1l.1-.1V20a.1.1,0,0,0-.1-.1l-.1-.1h-.1c-.1,0-.1,0-.2.1a.1.1,0,0,0-.1.1h0v-.1a.367.367,0,0,1,.1-.3c0-.1.1-.1.2-.2s.1-.1.2-.1h.3a.6.6,0,0,1,.4.1c-.1.1-.2.2-.2.3s-.1.2-.1.4v.2c0,.1,0,.1.1.2a.1.1,0,0,0,.1.1H28a.1.1,0,0,0,.1-.1c0-.1.1-.1.1-.2v-.4c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1l-.1-.1c.1,0,.2-.1.3-.1h.2c.1,0,.1.1.2.1a.349.349,0,0,1,.1.2v.2a.367.367,0,0,1-.1.3c-.1.1-.1.2-.2.3l.2.1c0-.1.1-.1.1-.2s.1-.1.1-.2,0-.1.1-.2,0-.1,0-.2a.367.367,0,0,0-.1-.3.349.349,0,0,0-.1-.2C28.8,19.1,28.7,19,28.6,19Zm-1.9.9h0l.1-.1h.3v.4h-.4v-.1c-.1,0-.1-.1,0-.2C26.6,20,26.6,19.9,26.7,19.9Zm1.3-.4c0,.1.1.2.1.3a.367.367,0,0,1-.1.3c0,.1-.1.1-.2.1h-.1a.1.1,0,0,1-.1-.1v-.3a.367.367,0,0,1,.1-.3.215.215,0,0,1,.2-.2.349.349,0,0,1,.1.2Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M14.2,24.7V24c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1.349.349,0,0,0-.2-.1h-.4c-.1,0-.1,0-.1.1l-.1.1v1h-.6V23.6h-.2V25h1.8Q14.2,24.7,14.2,24.7Zm-.2-.1v.2h-.7v-1l.1-.1h.1c.1,0,.1,0,.2.1a.1.1,0,0,1,.1.1c0,.1.1.1.1.2v.3C14.1,24.4,14.1,24.5,14,24.6Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M15.7,24.9V23.7H16v-.2H14.7v1.4h.2V23.7h.6v1.2Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M18.7,24.9V23.2c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1.1.1,0,0,1-.1-.1h-.5c-.1,0-.1.1-.2.1l-.1.1v.5H16.5v.6a.349.349,0,0,0-.2.1c-.1.1-.1.1-.1.3v.2a.1.1,0,0,0,.1.1.349.349,0,0,0,.2.1h.4c.1,0,.1-.1.2-.1s.1-.1.1-.2v-.3h.2a.1.1,0,0,1,.1.1l.1.1v.4c0,.1,0,.1-.1.1l-.1.1c-.1,0-.1,0-.2.1h-1c-.1,0-.2,0-.2.1a.349.349,0,0,0-.1.2v.2h.2v-.3h1.1c.1,0,.2-.1.3-.1a2.475,2.475,0,0,0,.6-.4c0-.1.1-.2.1-.3v-.2c0-.1-.1-.1-.1-.2a.349.349,0,0,0-.2-.1h-.3v-.4H18v-.2h0a.1.1,0,0,0-.1-.1v-.3A.1.1,0,0,1,18,23a.1.1,0,0,0,.1-.1h.3l.1.1v1.9Zm-1.5-.5a.367.367,0,0,1-.1.3c-.1.1-.1.1-.3.1h-.2a.1.1,0,0,1-.1-.1l-.1-.1v-.1c0-.1,0-.1.1-.2h.6v.1Zm0-.3h-.4v-.4h.4Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M21,24.9V23.2c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1.1.1,0,0,1-.1-.1h-.5c-.1,0-.1.1-.2.1l-.1.1v.5H19v1.4h.2V23.7h.6v1.2l-.6.4.1.1.7-.5V23.7h.4v-.2h-.2a.1.1,0,0,0-.1-.1v-.3a.1.1,0,0,1,.1-.1.1.1,0,0,0,.1-.1h.3l.1.1v1.9Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M23,24.3a.31.31,0,0,0-.2.1h-.6a.1.1,0,0,0-.1.1.1.1,0,0,1-.1.1v.2a.1.1,0,0,0,.1.1h2.3v-.2H22.2v-.2h.7a.349.349,0,0,0,.2-.1c.1,0,.1-.1.1-.2v-.5c0-.1-.1-.1-.1-.2-.1,0-.1-.1-.2-.1h-.6a.349.349,0,0,0-.2.1.1.1,0,0,0-.1.1v.3a.1.1,0,0,0,.1.1l.1.1h.2a.1.1,0,0,0,.1-.1l.1-.1v-.3l-.1-.1h.3c.1,0,.1,0,.1.1l.1.1v.4C23,24.2,23,24.3,23,24.3Zm-.5-.5h0v.3h-.3v-.3h.2c0-.1,0,0,.1,0Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M24.8,24.9h.1V23.7h.6v1.2l-.6.4.1.1.7-.5V23.7h.4v-.2H24.8Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
        <path
          d="M28.8,23.7c-.1-.1-.1-.1-.2-.1s-.2-.1-.3-.1H28a.349.349,0,0,0-.2.1.349.349,0,0,1-.2-.1h-.3a.756.756,0,0,0-.4.1.769.769,0,0,0-.3.2c-.1.1-.2.2-.2.3s-.1.2-.1.4v.3c0,.1,0,.1.1.2a.1.1,0,0,0,.1.1c.1,0,.1.1.2.1h.2a.1.1,0,0,0,.1-.1l.1-.1v-.4a.1.1,0,0,0-.1-.1l-.1-.1h-.1c-.1,0-.1,0-.2.1a.1.1,0,0,0-.1.1h0v-.1a.367.367,0,0,1,.1-.3c0-.1.1-.1.2-.2s.1-.1.2-.1h.3a.6.6,0,0,1,.4.1c-.1.1-.2.2-.2.3s-.1.2-.1.4v.2c0,.1,0,.1.1.2a.1.1,0,0,0,.1.1H28a.1.1,0,0,0,.1-.1c0-.1.1-.1.1-.2v-.4c0-.1,0-.1-.1-.2a.1.1,0,0,0-.1-.1l-.1-.1c.1,0,.2-.1.3-.1h.2c.1,0,.1.1.2.1a.349.349,0,0,1,.1.2v.2a.367.367,0,0,1-.1.3c0,.1-.1.2-.2.3l.2.1c0-.1.1-.1.1-.2s.1-.1.1-.2,0-.1.1-.2v-.2a.367.367,0,0,0-.1-.3C29,23.8,28.9,23.7,28.8,23.7Zm-2.1.8h0l.1-.1h.3v.4h-.4v-.1c-.1-.1-.1-.1,0-.2Zm1.3-.4c0,.1.1.2.1.3a.367.367,0,0,1-.1.3c0,.1-.1.1-.2.1h-.1a.1.1,0,0,1-.1-.1v-.3a.367.367,0,0,1,.1-.3.215.215,0,0,1,.2-.2.349.349,0,0,1,.1.2Z"
          fill="#fff"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="0.065"
        />
      </g>
    </svg>
  );
}
export default ScriptTranslatorIcon;
