import React from "react";

function WorkflowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(-0.1)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0.1 0)"
          fill="#f47b20"
        />
        <path
          d="M15.9,10.9h3.5a.4.4,0,0,0,0-.8H15.9a.4.4,0,0,0,0,.8Z"
          fill="#fff"
        />
        <path
          d="M15.9,12.6h7.7a.4.4,0,0,0,0-.8H15.9a.4.4,0,1,0,0,.8Z"
          fill="#fff"
        />
        <path
          d="M24.1,14a.43.43,0,0,0-.4-.4H16a.4.4,0,0,0,0,.8h7.7A.43.43,0,0,0,24.1,14Z"
          fill="#fff"
        />
        <path
          d="M26.1,26.1h2.3A1.58,1.58,0,0,0,30,24.5V22.2a1.58,1.58,0,0,0-1.6-1.6H26.1a1.58,1.58,0,0,0-1.6,1.6v.7H22.3l-2.1-2.1V17.1h4.5a2.689,2.689,0,0,0,2.7-2.7V10a2.689,2.689,0,0,0-2.7-2.7H14.9A2.689,2.689,0,0,0,12.2,10v4.3A2.689,2.689,0,0,0,14.9,17h4.5v3.7l-2.1,2.1H15.5v-.7a1.58,1.58,0,0,0-1.6-1.6H9.3a1.58,1.58,0,0,0-1.6,1.6v2.3A1.58,1.58,0,0,0,9.3,26h4.6a1.58,1.58,0,0,0,1.6-1.6v-.7h1.8l2.1,2.1v3.4h-.7a1.58,1.58,0,0,0-1.6,1.6v2.3a1.58,1.58,0,0,0,1.6,1.6H21a1.58,1.58,0,0,0,1.6-1.6v-.7h2a2.7,2.7,0,1,0,0-.8h-2v-.7A1.58,1.58,0,0,0,21,29.3h-.7V25.9l2.1-2.1h2.2v.7A1.444,1.444,0,0,0,26.1,26.1ZM13,14.4V10a1.9,1.9,0,0,1,1.9-1.9h9.8A1.9,1.9,0,0,1,26.6,10v4.3a1.9,1.9,0,0,1-1.9,1.9H14.9A1.76,1.76,0,0,1,13,14.4Zm1.6,10.1a.684.684,0,0,1-.7.7H9.3a.684.684,0,0,1-.7-.7V22.2a.684.684,0,0,1,.7-.7h4.6a.684.684,0,0,1,.7.7v2.3Zm12.7,5.7a1.9,1.9,0,1,1-1.9,1.9A1.839,1.839,0,0,1,27.3,30.2Zm-5.6.7v2.3a.684.684,0,0,1-.7.7H18.7a.684.684,0,0,1-.7-.7V30.9a.684.684,0,0,1,.7-.7H21A.751.751,0,0,1,21.7,30.9Zm-1.9-5.8-1.7-1.7,1.7-1.7,1.7,1.7Zm5.6-2.9a.684.684,0,0,1,.7-.7h2.3a.684.684,0,0,1,.7.7v2.3a.684.684,0,0,1-.7.7H26.1a.684.684,0,0,1-.7-.7Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default WorkflowIcon;
