import React from "react";

function GamingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M33.5,12.9c-4.4-7.2-10.9-2.2-10.9-2.2a3.486,3.486,0,0,1-1.7.6H19.1a3.486,3.486,0,0,1-1.7-.6s-6.6-5-10.9,2.2A21.675,21.675,0,0,0,3.9,27.8c.3,1.9,1.3,3.2,3.2,3s6-5.1,6-5.1a2.36,2.36,0,0,1,1.6-.7H25.2a2.575,2.575,0,0,1,1.6.7s4.1,5,6,5.1c1.9.2,2.9-1.1,3.2-3a20.714,20.714,0,0,0-2.5-14.9ZM16,18.8H13.7V21a2.092,2.092,0,0,1-1.2.4,1.633,1.633,0,0,1-1.1-.4V18.9h-2a1.447,1.447,0,0,1-.3-1,2.071,2.071,0,0,1,.3-1.3h2.3V14.3a3.3,3.3,0,0,1,1.1-.2,2.539,2.539,0,0,1,1.2.3v2.2h2a1.986,1.986,0,0,1,.4,1.1,3.176,3.176,0,0,1-.4,1.1Zm10.4,2.4a1.8,1.8,0,1,1,1.8-1.8,1.733,1.733,0,0,1-1.8,1.8Zm0-4.9a1.8,1.8,0,1,1,1.8-1.8,1.793,1.793,0,0,1-1.8,1.8ZM30.9,19a1.8,1.8,0,1,1,1.8-1.8A1.933,1.933,0,0,1,30.9,19Zm0,0"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default GamingIcon;
