import React from "react";

function DivorceCertificateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.8"
      height="39.8"
      viewBox="0 0 39.8 39.8"
    >
      <g transform="translate(0 -0.2)">
        <circle
          cx="19.9"
          cy="19.9"
          r="19.9"
          transform="translate(0 0.2)"
          fill="#f47b20"
        />
        <path
          d="M8.3,30.2a.43.43,0,0,1-.4-.4V19.7a.367.367,0,0,1,.1-.3l2.2-2.2c.2-.2.5-.2.6,0,.2.2.2.5,0,.6l-2,2v9.9C8.7,30,8.5,30.2,8.3,30.2Z"
          fill="#fff"
        />
        <path
          d="M27,28a2.342,2.342,0,0,1-1.8-.8,3.564,3.564,0,0,1-.6-2.7l-2.4-1.7a1.424,1.424,0,0,1-.4-1.9,1.456,1.456,0,0,1,.9-.6,1.389,1.389,0,0,1,1.1.2l3.8,2.6.8-.4V6.4H11V22.7l.7.4,3.8-2.6a1.389,1.389,0,0,1,1.1-.2,1.456,1.456,0,0,1,.9.6,1.334,1.334,0,0,1-.4,1.9l-2.4,1.7a3.564,3.564,0,0,1-.6,2.7,2.2,2.2,0,0,1-1.8.8.4.4,0,0,1,0-.8,1.366,1.366,0,0,0,1.1-.4,3.26,3.26,0,0,0,.4-2.3c0-.2,0-.3.2-.4l2.6-1.8a.494.494,0,0,0,.1-.7c-.1-.1-.2-.2-.3-.2a.6.6,0,0,0-.4.1L12.1,24a.3.3,0,0,1-.4,0l-1.2-.6c-.2-.1-.2-.2-.2-.4V6a.43.43,0,0,1,.4-.4H29a.43.43,0,0,1,.4.4V23c0,.2-.1.3-.3.4l-1.2.6a.3.3,0,0,1-.4,0l-4.1-2.7a.6.6,0,0,0-.4-.1c-.1,0-.3.1-.3.2a.483.483,0,0,0,.1.7l2.6,1.8a.758.758,0,0,1,.2.4,3.415,3.415,0,0,0,.4,2.3,1.246,1.246,0,0,0,1.1.4.43.43,0,0,1,.4.4C27.5,27.6,27.3,28,27,28Z"
          fill="#fff"
        />
        <path
          d="M20.7,10.1H14.2a.4.4,0,0,1,0-.8h6.5a.4.4,0,1,1,0,.8Z"
          fill="#fff"
        />
        <path
          d="M21.1,12.9H14.2a.4.4,0,0,1,0-.8h6.9a.43.43,0,0,1,.4.4C21.6,12.7,21.4,12.9,21.1,12.9Z"
          fill="#fff"
        />
        <path
          d="M21.1,15.8H14.2a.4.4,0,0,1,0-.8h6.9a.43.43,0,0,1,.4.4A.366.366,0,0,1,21.1,15.8Z"
          fill="#fff"
        />
        <path
          d="M21.1,18.6H14.2a.4.4,0,0,1,0-.8h6.9a.43.43,0,0,1,.4.4A.366.366,0,0,1,21.1,18.6Z"
          fill="#fff"
        />
        <path
          d="M13.4,30.2c-.1,0-.2,0-.2-.1a.481.481,0,0,1-.2-.6l.8-1.3a.447.447,0,1,1,.8.4L13.8,30C13.7,30.2,13.6,30.2,13.4,30.2Z"
          fill="#fff"
        />
        <path
          d="M14.2,31.9a.43.43,0,0,1-.4-.4V30.3h-6v1.2a.4.4,0,0,1-.8,0V29.9a.43.43,0,0,1,.4-.4h6.8a.43.43,0,0,1,.4.4v1.6A.43.43,0,0,1,14.2,31.9Z"
          fill="#fff"
        />
        <path
          d="M14.8,33.6a.43.43,0,0,1-.4-.4V32H7.2v1.2a.4.4,0,1,1-.8,0V31.6a.43.43,0,0,1,.4-.4h8a.43.43,0,0,1,.4.4v1.6A.43.43,0,0,1,14.8,33.6Z"
          fill="#fff"
        />
        <path
          d="M31.2,30.2a.43.43,0,0,1-.4-.4V19.9l-2-2c-.2-.2-.2-.5,0-.6.2-.2.5-.2.6,0l2.2,2.2a.367.367,0,0,1,.1.3V29.9A.623.623,0,0,1,31.2,30.2Z"
          fill="#fff"
        />
        <path
          d="M26.1,30.2a.52.52,0,0,1-.4-.2l-.8-1.3a.447.447,0,0,1,.8-.4l.8,1.3a.4.4,0,0,1-.2.6Z"
          fill="#fff"
        />
        <path
          d="M32.1,31.9a.43.43,0,0,1-.4-.4V30.3h-6v1.2a.4.4,0,0,1-.8,0V29.9a.43.43,0,0,1,.4-.4h6.8a.43.43,0,0,1,.4.4v1.6C32.6,31.7,32.4,31.9,32.1,31.9Z"
          fill="#fff"
        />
        <path
          d="M32.7,33.6a.43.43,0,0,1-.4-.4V32H25.2v1.2a.4.4,0,1,1-.8,0V31.6a.43.43,0,0,1,.4-.4h8a.43.43,0,0,1,.4.4v1.6A.458.458,0,0,1,32.7,33.6Z"
          fill="#fff"
        />
        <path
          d="M25.3,28.9H14.2a.4.4,0,1,1,0-.8H25.3a.43.43,0,0,1,.4.4A.366.366,0,0,1,25.3,28.9Z"
          fill="#fff"
        />
        <path
          d="M24.6,13a2.862,2.862,0,0,1-2.8-2.8,2.8,2.8,0,1,1,5.6,0A2.8,2.8,0,0,1,24.6,13Zm0-4.8a1.9,1.9,0,1,0,1.9,1.9A1.9,1.9,0,0,0,24.6,8.2Z"
          fill="#fff"
        />
        <path
          d="M25.3,13.1a.908.908,0,0,1-.7-.3.806.806,0,0,1-.9.3.76.76,0,0,1-.6-.8.759.759,0,0,1-.9-.3.908.908,0,0,1,0-1,.871.871,0,0,1-.6-.8.76.76,0,0,1,.6-.8.908.908,0,0,1,0-1,.806.806,0,0,1,.9-.3.833.833,0,0,1,1.5-.5.806.806,0,0,1,.9-.3.76.76,0,0,1,.6.8.759.759,0,0,1,.9.3.908.908,0,0,1,0,1,.871.871,0,0,1,.6.8.76.76,0,0,1-.6.8.908.908,0,0,1,0,1,.806.806,0,0,1-.9.3.76.76,0,0,1-.6.8Zm-2.2-1.7a.764.764,0,0,1,.5.2,2.092,2.092,0,0,1,.4.6.967.967,0,0,1,1.4,0,.548.548,0,0,1,.4-.6.616.616,0,0,1,.7-.1.878.878,0,0,1-.1-.7,1.205,1.205,0,0,1,.5-.5,1.205,1.205,0,0,1-.5-.5.878.878,0,0,1,.1-.7.878.878,0,0,1-.7-.1,2.092,2.092,0,0,1-.4-.6.967.967,0,0,1-1.4,0,.548.548,0,0,1-.4.6.616.616,0,0,1-.7.1c.1,0,.2.3.1.5a1.205,1.205,0,0,1-.5.5,1.205,1.205,0,0,1,.5.5.878.878,0,0,1-.1.7C23,11.4,23,11.4,23.1,11.4ZM25,7.8l.4.2h0Z"
          fill="#fff"
        />
        <path
          d="M26.2,18.2a.367.367,0,0,1-.3-.1l-1.4-1.4L23.2,18a.44.44,0,0,1-.5.1c-.2-.1-.3-.2-.3-.4V12.2a.4.4,0,0,1,.8,0v4.4l.9-1a.5.5,0,0,1,.6,0l.9,1V12.2a.4.4,0,0,1,.8,0v5.5c0,.2-.1.3-.3.4C26.3,18.2,26.3,18.2,26.2,18.2Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default DivorceCertificateIcon;
